import EmailIcon from '@material-ui/icons/Email';
import Notifications from '@material-ui/icons/Notifications';
import PersonIcon from '@material-ui/icons/Person';
import HomeIcon from '@material-ui/icons/Home';
import PostAddIcon from '@material-ui/icons/PostAdd';
import PeopleIcon from '@material-ui/icons/People';
import AssignmentIcon from '@material-ui/icons/Assignment';
import SettingsIcon from '@material-ui/icons/Settings';
import AllInboxIcon from '@material-ui/icons/AllInbox';
import AccountBoxIcon from '@material-ui/icons/AccountBox';
import ContactPhoneIcon from '@material-ui/icons/ContactPhone';
import CallToActionIcon from '@material-ui/icons/CallToAction';
import HistoryIcon from '@material-ui/icons/History';
import EventNote from '@material-ui/icons/Event';
import ViewComfyIcon from '@material-ui/icons/ViewComfy';
import ViewAgendaIcon from '@material-ui/icons/ViewAgenda';
import TableChartIcon from '@material-ui/icons/TableChart';
import HomeWorkIcon from '@material-ui/icons/HomeWork';
import ApartmentIcon from '@material-ui/icons/Apartment';
import GroupWorkIcon from '@material-ui/icons/GroupWork';
import LocationCityIcon from '@material-ui/icons/LocationCity';
import LinkIcon from '@material-ui/icons/Link';
import NoteIcon from '@material-ui/icons/Note';
import LocalAtmIcon from '@material-ui/icons/LocalAtm';
import AnnouncementIcon from '@material-ui/icons/Announcement';
import SupervisedUserCircleIcon from '@material-ui/icons/SupervisedUserCircle';
import LabelIcon from '@material-ui/icons/Label';
import PermDeviceInformationIcon from '@material-ui/icons/PermDeviceInformation';
import InfoIcon from '@material-ui/icons/Info';
import ViewListIcon from '@material-ui/icons/ViewList';
import HeadsetMicIcon from '@material-ui/icons/HeadsetMic';

import { UrlCollection } from '../../common/url-collection';

const SideBar = [
  { id: 1, path: '/', icon: HomeIcon, title: 'Trang chủ' },
  {
    id: 2,
    path: UrlCollection.PlanningAnnouncementProcess,
    icon: AssignmentIcon,
    title: 'Quản lý quy hoạch',
  },

  {
    id: 3,
    //path: '',
    icon: NoteIcon,
    title: 'Dự Án',
    isSub: true,
    subSideBar: [
      {
        path: UrlCollection.ProjectManagement,
        icon: PersonIcon,
        title: 'Thông tin dự án',
      },
      {
        id: 9,
        path: UrlCollection.Investor,
        icon: SupervisedUserCircleIcon,
        title: 'Chủ đầu tư',
      },
    ],
  },

  // {
  //   id: 4,
  //   path: UrlCollection.LandPriceInfo,
  //   icon: LocalAtmIcon,
  //   title: 'Giá đất nhà nước',
  //   },
  {
    id: 15,
    path: UrlCollection.StatePriceFile,
    icon: LocalAtmIcon,
    title: 'File giá đất nhà nước',
  },
  {
    id: 5,
    path: UrlCollection.Classified,
    icon: HomeWorkIcon,
    title: 'Mua bán bất động sản',
  },
  {
    id: 6,
    //path: '',
    icon: AnnouncementIcon,
    title: 'Tin tức',
    isSub: true,
    subSideBar: [],
  },
  {
    id: 20,
    icon: PermDeviceInformationIcon,
    title: 'Vr360',
    isSub: true,
    subSideBar: [
      {
        path: UrlCollection.Vr360Project,
        icon: PermDeviceInformationIcon,
        title: 'Quản lý dự án',
      },
      {
        path: UrlCollection.SceneManage,
        icon: PermDeviceInformationIcon,
        title: 'Quản lý khung hình',
      },
      {
        path: UrlCollection.HotspotManage,
        icon: PermDeviceInformationIcon,
        title: 'Quản lý điểm nóng',
      },
      {
        path: UrlCollection.ConnectionSceneManage,
        icon: PermDeviceInformationIcon,
        title: 'Quản lý liên kết',
      },
    ],
  },
  { id: 7, path: UrlCollection.Faq, icon: PersonIcon, title: 'Hỏi - Đáp' },
  // {
  //   id: 14,
  //   path: UrlCollection.ConsultingServices,
  //   icon: HeadsetMicIcon,
  //   title: 'Dịch vụ tư vấn',
  // },
  { id: 11, path: UrlCollection.MetaTag, icon: LabelIcon, title: 'Meta tag' },
  {
    id: 12,
    path: UrlCollection.Introduce,
    icon: InfoIcon,
    title: 'Thông tin giới thiệu',
  },
  {
    id: 13,
    path: UrlCollection.MobileAppIntroduce,
    icon: PermDeviceInformationIcon,
    title: 'Thông tin giới thiệu mobile app',
  },
  {
    id: 16,
    icon: PermDeviceInformationIcon,
    title: 'Thông tin giao dịch',
    isSub: true,
    subSideBar: [
      {
        id: 17,
        path: UrlCollection.TransactionManage,
        icon: PermDeviceInformationIcon,
        title: 'Quản lý giao dịch',
      },
      {
        id: 18,
        path: UrlCollection.Edition,
        icon: SupervisedUserCircleIcon,
        title: 'Quản lý gói thành viên',
      },
    ],
  },
  {
    id: 10,
    path: UrlCollection.UserManagement,
    icon: AccountBoxIcon,
    title: 'Quản lý thành viên',
  },

  {
    id: 8,
    //path: '',
    icon: SettingsIcon,
    title: 'Hệ thống',
    isSub: true,
    subSideBar: [
      {
        path: UrlCollection.Notifications,
        icon: Notifications,
        title: 'Quản lý thông báo',
      },
      {
        path: UrlCollection.EmailTemplate,
        icon: EmailIcon,
        title: 'Email Template',
      },
      {
        path: UrlCollection.LimitAccess,
        icon: ViewListIcon,
        title: 'Whitelist/Blacklist IP',
      },
      {
        path: UrlCollection.EmailGenerated,
        icon: AllInboxIcon,
        title: 'Khởi tạo email',
      },
      {
        path: UrlCollection.ContactManagement,
        icon: ContactPhoneIcon,
        title: 'Liên hệ',
      },
      {
        path: UrlCollection.RoleManagement,
        icon: PeopleIcon,
        title: 'Nhóm quyền',
      },

      {
        path: UrlCollection.User_Admin_Management,
        icon: AccountBoxIcon,
        title: 'Quản lý người quản trị',
      },

      {
        path: UrlCollection.CommuneManagement,
        icon: ApartmentIcon,
        title: 'Quản lý xã/phường',
      },

      {
        path: UrlCollection.DistrictManagement,
        icon: HomeWorkIcon,
        title: 'Quản lý quận/huyện',
      },
      {
        path: UrlCollection.ProvinceManagement,
        icon: LocationCityIcon,
        title: 'Quản lý tỉnh/thành phố',
      },
      //{
      //  path: UrlCollection.LinkGroup,
      //  icon: GroupWorkIcon,
      //  title: 'Danh sách nhóm',
      //},
      //{
      //  title: 'Danh sách liên kết',
      //  path: UrlCollection.ServiceLink,
      //  icon: LinkIcon,
      //},
      {
        icon: HistoryIcon,
        path: UrlCollection.UserLogHistory,
        title: 'Nhật ký người dùng',
      },
      {
        icon: EventNote,
        path: UrlCollection.Log,
        title: 'Quản lý log',
      },
      //{
      //  icon: ViewComfyIcon,
      //  path: UrlCollection.LandType,
      //  title: 'Quản lý loại đất',
      //},
      //{
      //  icon: ViewAgendaIcon,
      //  path: UrlCollection.LandTypeDetail,
      //  title: 'Quản lý chi tiết loại đất',
      //},
      //{
      //  icon: CallToActionIcon,
      //  path: UrlCollection.OpinionForm,
      //  title: 'Tạo form ý kiến',
      //},
      //{
      //  icon: TableChartIcon,
      //  path: UrlCollection.TableStructure,
      //  title: 'Sửa cấu trúc bảng',
      //},
    ],
  },
];

export default SideBar;
