//---> Build-in Modules
import React from 'react';

//---> External Modules
import Checkbox from '@material-ui/core/Checkbox';
import { useFormContext } from 'react-hook-form';

const ProjectIsFeature = ({ defaultChecked = false }) => {
  const { register } = useFormContext();

  return (
    <div className='form-group'>
      <div className='row'>
        <div className='col-12 '>
          <label className='text-dark' style={{ marginRight: '5px' }}>
            Tiêu biểu
          </label>
          <Checkbox
            color='primary'
            inputProps={{ 'aria-label': 'secondary checkbox' }}
            className='p-0 mt-0 '
            name='isFeature'
            inputRef={register({ required: false })}
            defaultChecked={defaultChecked}
          />
        </div>
      </div>
    </div>
  );
};

export default ProjectIsFeature;
