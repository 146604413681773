import React from 'react';

//--- Material Control
import { DialogContent, Grid } from '@material-ui/core';

import MapGoogle from '../../../../google-map/MapGoogle';
import Map4dMap from '../../../../map4dMap/index';
import FormInput from '../../../FormInput';
import FormAutoComplete from '../../../FormAutoComplete';
import FormAsyncSelect from '../../../FormAsyncSelect';
import * as streetActions from '../../../../../redux/store/street/street.store';

export default function AddressFormItems(props) {
  const {
    provinceSelect,
    districtByProvinceId,
    communes,
    setChoosePlace,
    currentPosition = null,
  } = props;

  const filterStreetes = (inputValue) => {
    return streetActions
      .GetLookUpStreet(inputValue)
      .then((res) => {
        if (res?.content && res?.content.length > 0) {
          res.content.forEach((element) => {
            element.value = element.id;
            element.label = element.name;
          });
        } else {
          return [
            {
              value: 0,
              label: inputValue,
            },
          ];
        }
        return res.content;
      })
      .catch((err) => console.log(err));
  };

  const promiseStreetOptions = (inputValue) => {
    if (inputValue.length >= 2) {
      return new Promise((resolve) => {
        setTimeout(() => {
          resolve(filterStreetes(inputValue));
        }, 1000);
      });
    }
  };

  return (
    <DialogContent className='pt-4 pb-2'>
      <Grid container spacing={2}>
        <Grid item xs={6}>
        <Map4dMap
                  setChoosePlace={setChoosePlace}
                      currentPosition={currentPosition}
                />
                  {
                      //<MapGoogle
                      //    currentPosition={currentPosition}
                      //    setChoosePlace={setChoosePlace}
                      ///>
                  }
        </Grid>
        <Grid item xs={6}>
          <div className='form-group'>
            <FormAutoComplete
              title='Tỉnh/ Thành phố'
              placeholder='Tỉnh/ Thành phố'
              name='province'
              options={provinceSelect}
              validate={{
                required: 'Tỉnh/ Thành phố là dữ liệu bắt buộc',
              }}
            />
          </div>
          <div className='form-group'>
            <FormAutoComplete
              title='Quận/ Huyện'
              placeholder='Quận/ Huyện'
              name='district'
              options={districtByProvinceId}
              validate={{
                required: 'Quận/ Huyện là dữ liệu bắt buộc',
              }}
            />
          </div>
          <div className='form-group'>
            <FormAutoComplete
              title='Xã/ Phường'
              placeholder='Xã/ Phường'
              name='commune'
              options={communes}
              validate={{
                required: 'Xã/ Phường là dữ liệu bắt buộc',
              }}
            />
          </div>
          <div className='form-group'>
            <FormAsyncSelect
              title='Đường'
              name='street'
              placeholder='Đường'
              promiseOptions={promiseStreetOptions}
            />
          </div>
          <div className='form-group'>
            <FormInput
              title='Số nhà'
              name='generalInfor'
              placeholder='Số nhà'
            />
          </div>
                  {
                      //<Grid container spacing={2}>
                      //    <Grid item xs={6}>
                      //        <div className='form-group'>
                      //            <FormInput
                      //                title='X'
                      //                name='latitude'
                      //                placeholder='latitude'
                      //                validate={{
                      //                    required: 'X là dữ liệu bắt buộc',
                      //                }}
                      //                InputProps={{
                      //                    readOnly: false,
                      //                }}
                      //            />
                      //        </div>
                      //    </Grid>
                      //    <Grid item xs={6}>
                      //        <div className='form-group'>
                      //            <FormInput
                      //                title='Y'
                      //                name='longitude'
                      //                placeholder='longitude'
                      //                validate={{
                      //                    required: 'Y là dữ liệu bắt buộc',
                      //                }}
                      //                InputProps={{
                      //                    readOnly: false,
                      //                }}
                      //            />
                      //        </div>
                      //    </Grid>
                      //</Grid>
                  }
        </Grid>
      </Grid>
    </DialogContent>
  );
}
