import React, { useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { ApiUrl } from "../../../api/api-url";
import { DropzoneArea } from "material-ui-dropzone";

//--- Styles
import "../posts.scss";
import {
  Col,
  Nav,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane,
} from "reactstrap";
//--- Material Control
import {
  DialogActions,
  Button,
  TextField,
  DialogContent,
  DialogTitle,
  Dialog,
  Typography,
  IconButton,
  makeStyles,
  Checkbox,
  Radio,
  RadioGroup,
  FormControlLabel,
  Tabs,
  Tab,
  Box,
} from "@material-ui/core";
import TabPanel from "../../../components/tabpanel/tabpanel";
import a11yProps from "../../../utils/helpers/a11yProps";
//--- Material Icon
import CloseIcon from "@material-ui/icons/Close";
import SaveIcon from "@material-ui/icons/Save";
import ShowNotification from "../../../components/react-notifications/react-notifications";
import {
  NotificationMessageType,
  APIUrlDefault,
  MaxSizeImageUpload,
} from "../../../utils/configuration";
import * as viVN from "../../../language/vi-VN.json";
import SunEditor from "suneditor-react";
//import dialog from "suneditor/src/plugins/modules/dialog";
//import '../edit-posts/node_modules/suneditor/dist/css/suneditor.min.css';
import "suneditor/dist/css/suneditor.min.css";

//--- Action
//import * as newsAction from '../../../redux/store/news/news.store';
import * as postActions from "../../../redux/store/posts/posts.store";
import FileManagement from "../../../components/file_management/file_management";
import FormAsyncPlanning from "../../../components/FormItem/FormAsynceSelectPaginationPlanning";
import buildFormData from "../../../utils/helpers/formDataBuild";
import { UrlCollection } from "../../../common/url-collection";
import { ArrowBackIos } from "@material-ui/icons";
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import CircularProgress from '@material-ui/core/CircularProgress';
const useStyles = makeStyles((theme) => ({
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  formControl: {
    margin: theme.spacing(1),
  },
}));

export default function AddNews(props) {
  const classes = useStyles();
  const history = useHistory();
  const { langId } = useSelector((state) => state.language);
  const { id, postTypeId } = props.match.params;
  const categoryId = id;
  const [tab, setTab] = useState(1);
  const [status, setStatus] = useState(true);
  const [isHot, setIsHot] = useState(true);
  const [isHomePage, setHomePage] = useState(false);
  //const [image_Url, setImage_Url] = useState('');
  const [content, setContent] = useState();
  const [isShow, setShow] = useState(false);
  const [isShowFb, setShowFb] = useState(false);
  const [isShowTw, setShowTw] = useState(false);
  const [isShowDocs, setShowDocs] = useState(false);

  const [files, setFiles] = useState([]);
  const [filesTemp, setFilesTemp] = useState([]);
  const [fileDocs, setFileDocs] = useState([]);
  const [fileTempDocs, setFileTempDocs] = useState([]);
  const [fileFb, setFileFb] = useState([]);
  const [fileTempFb, setFileTempFb] = useState([]);
  const [fileTw, setFileTw] = useState([]);
  const [fileTempTw, setFileTempTw] = useState([]);
  const urlUploadImage = APIUrlDefault + ApiUrl.UrlUploadFromEditor;

  const [tabIndex, setTabIndex] = React.useState(0);

  const [fileImage, setFileImage] = useState([]);
  const [fileImageFb, setFileImageFb] = useState([]);
  const [fileImageTw, setFileImageTw] = useState([]);
  const [fileDoc, setFileDoc] = useState([]);
  const [isOwnerNews, setIsOwnerNews] = useState("true");
  const handleTabIndexChange = (event, newValue) => {
    setTabIndex(newValue);
  };

  const methods = useForm({
    mode: "all",
    reValidateMode: "onBlur",
  });

  const { register, handleSubmit, setError, errors, clearErrors, setValue, formState } =
    methods;
    const { isSubmitting } = formState;
  const onChangeContent = (editorContent) => {
    clearErrors(["editorContent"]);
    if (editorContent === "<p><br></p>") {
      setError("editorContent", { type: "required" });
      setContent("");
    } else {
      clearErrors("editorContent");
      setContent(editorContent);
    }
  };

  const handleRadioChange = (event) => {
    console.log(event.target.value);
    setIsOwnerNews(event.target.value);
    //setError(false);
  };

  const onSubmit = async(data) => {
    let object = {
      translations: [
        {
          langId: langId,
          title: data.title,
          description: data.description,
          content: content,
          isActive: status,
          metaTitle: data.metaTitle,
          metaDescription: data.metaDescription,
          metaKeyword: data.metaKeyword,
          fbDescription: data.fbDescription,
          twitterDescription: data.twitterDescription,
        },
      ],
      isOwnerNews: isOwnerNews == "true" ? true : false,
      sourceNews: data.sourceNews,
      sourceLink: data.sourceLink,
      categoryId: parseInt(categoryId),
      postTypeId: parseInt(postTypeId),
      isActive: status,
      isHot: isHot,
      isHomePage: isHomePage,
    };
    if (data.planning) object = { ...object, planningId: data.planning.value };

    if (fileImage && fileImage.length > 0) {
      object = {
        ...object,
        image: fileImage[0],
      };
    }

    if (fileImageFb && fileImageFb.length > 0) {
      object = {
        ...object,
        imageFb: fileImageFb[0],
      };
    }

    if (fileImageTw && fileImageTw.length > 0) {
      object = {
        ...object,
        imageTw: fileImageTw[0],
      };
    }

    if (fileDoc && fileDoc.length > 0) {
      object = {
        ...object,
        docFile: fileDoc[0],
      };
    }

    if (fileDocs && fileDocs.length > 0) {
      object = {
        ...object,
        translations: [
          {
            ...object.translations[0],
            fileId: fileDocs[0].fileId,
          },
        ],
      };
    }
    if (files && files.length > 0) {
      object = {
        ...object,
        imageId: files[0].fileId,
      };
    }
    if (fileTw && fileTw.length > 0) {
      object = {
        ...object,
        twitterImageId: fileTw[0].fileId,
      };
    }
    if (fileFb && fileFb.length > 0) {
      object = {
        ...object,
        fbImageId: fileFb[0].fileId,
      };
    }

    const formData = new FormData();
    buildFormData(formData, object);
    await postActions
      .PostsCreateWithUpload(formData)
      .then((res) => {
        history.push(`/tin-tuc/${id}/${postTypeId}`)
        ShowNotification(
          viVN.Success.NewsAddSuccess,
          NotificationMessageType.Success
        );
      })
      .catch(
        (err) => viVN.Errors[err.errorType],
        NotificationMessageType.Error
      );
  };

  const handleChangeStatus = (event) => {
    event.persist();
    setStatus(event.target.checked);
  };

  const handleChangeHot = (event) => {
    event.persist();
    setIsHot(event.target.checked);
  };

  const handleChangeHomePage = (event) => {
    event.persist();
    setHomePage(event.target.checked);
  };

  const onOpenSelectFile = () => {
    setShow(true);
    setFilesTemp(files);
  };

  const onOpenSelectFileFb = () => {
    setShowFb(true);
    setFileTempFb(fileFb);
  };

  const onOpenSelectFileTw = () => {
    setShowTw(true);
    setFileTempTw(fileTw);
  };

  const onOpenSelectFileDoc = () => {
    setShowDocs(true);
    setFileTempDocs(fileDocs);
  };

  const onCloseSelectFileFb = () => {
    setShowDocs(false);
    setFileFb(fileTempFb);
  };

  const onSaveSelectFileFb = () => {
    console.log(fileFb);
    setShowFb(false);
  };

  const onCloseSelectFileTw = () => {
    setShowTw(false);
    setFileTw(fileTempTw);
  };

  const onSaveSelectFileTw = () => {
    console.log(fileDocs);
    setShowTw(false);
  };

  const onCloseSelectFileDocs = () => {
    setShowDocs(false);
    setFileDocs(fileTempDocs);
  };

  const onSaveSelectFileDocs = () => {
    console.log(fileDocs);
    setShowDocs(false);
  };

  const onCloseSelectFile = () => {
    setShow(false);
    setFiles(filesTemp);
  };

  const onSaveSelectFile = () => {
    console.log(files);
    setShow(false);
  };
  const onGoBack = () => {
    const queryParams = window.location.search
    history.push(`/tin-tuc/${id}/${postTypeId}` + queryParams)
  };
  const renderTitle = () => {
    if(id == 3) {
      return "Thêm tin tức quy trình đầu tư dự án"
    }
    if(id == 4) {
      return "Thêm tin tức văn bản pháp lý"
    }
    if(id == 6) {
      return "Thêm tin tức quy hoạch"
    }
    if(id == 7) {
      return "Thêm tin tức dự án"
    }
    if(id == 9) {
      return "Thêm tin tức chung"
    } 
  }
  return (
    <div>
     
        <FormProvider {...methods}>
          <form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
          <div className="d-sm-flex align-items-center mb-2" onClick={onGoBack} style={{ cursor: "pointer", width: 100}}>
            <ArrowBackIos fontSize="small"></ArrowBackIos> Quay lại
          </div>
          <div className="d-sm-flex align-items-center justify-content-between mb-3">
            <h1 className="h3 mb-0 text-gray-800">
              {renderTitle()}
            </h1>
            <Button
              type="submit"
              color="primary"
              variant="contained"
              startIcon={!isSubmitting ? <SaveIcon></SaveIcon> : <CircularProgress size={20} color="white"/>}
              disabled={isSubmitting}
            >
              Lưu
            </Button>
          </div>
            <Nav tabs>
              <NavItem>
                <NavLink
                  className={`${tab === 1 ? "active" : ""}`}
                  onClick={() => setTab(1)}
                >
                  Thông tin dự án
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  className={`${tab === 2 ? "active" : ""}`}
                  onClick={() => setTab(2)}
                >
                  Thông tin SEO
                </NavLink>
              </NavItem>
            </Nav>
            <TabContent activeTab={tab}>
              <TabPane tabId={1}>
              <div className="row pt-4 pb-2">
                <div className="col-9">
                  <div className="form-group">
                    <label className="text-dark">
                      Tiêu đề<span className="required"></span>
                    </label>
                    <TextField
                      name="title"
                      error={
                        errors.title &&
                        (errors.title.type === "required" ||
                          errors.title.type === "maxLength")
                      }
                      fullWidth
                      type="text"
                      className="form-control"
                      inputRef={register({ required: true, maxLength: 150 })}
                      inputProps={{ maxLength: 150 }}
                      onChange={(e) => setValue("title", e.target.value)}
                    />
                    {errors.title && errors.title.type === "required" && (
                      <span className="error">Trường này là bắt buộc</span>
                    )}
                    {errors.title && errors.title.type === "maxLength" && (
                      <span className="error">
                        Trường này không quá 150 ký tự
                      </span>
                    )}
                  </div>
                  <div className="form-group">
                    <label className="text-dark">
                      Tóm tắt<span className="required"></span>
                    </label>
                    <textarea
                      name="description"
                      rows="5"
                      ref={register({ required: true, maxLength: 550 })}
                      maxLength="550"
                      className={
                        "form-control" +
                        (errors.description &&
                        errors.description.type === "required"
                          ? " is-invalid"
                          : "")
                      }
                    ></textarea>
                    {errors.description &&
                      errors.description.type === "required" && (
                        <span className="error">Trường này là bắt buộc</span>
                      )}
                    {errors.description &&
                      errors.description.type === "maxLength" && (
                        <span className="error">
                          Trường này không quá 550 ký tự
                        </span>
                      )}
                  </div>
                  <div className="form-group">
                    <label className="text-dark">Nội dung</label>
                    <span className="required"></span>
                    <SunEditor
                      enableToolbar={true}
                      showToolbar={true}
                      videoFileInput={false}
                      setOptions={{
                        height: 500,
                        imageUploadUrl: urlUploadImage,
                        imageUploadSizeLimit: MaxSizeImageUpload,
                        imageAccept: ".jpg,.jpeg,.png,.gift,.svg,.tif",
                        buttonList: [
                          [
                            "undo",
                            "redo",
                            "font",
                            "fontSize",
                            "formatBlock",
                            "paragraphStyle",
                            "blockquote",
                            "bold",
                            "underline",
                            "italic",
                            "strike",
                            "subscript",
                            "superscript",
                            "fontColor",
                            "hiliteColor",
                            "textStyle",
                            "removeFormat",
                            "outdent",
                            "indent",
                            "align",
                            "horizontalRule",
                            "list",
                            "lineHeight",
                            "table",
                            "link",
                            "image",
                            "video",
                            "audio",
                            "fullScreen",
                            "showBlocks",
                            "codeView",
                          ],
                        ],
                      }}
                      onChange={onChangeContent}
                      onBlur={(event, editorContents) =>
                        onChangeContent(editorContents)
                      }
                    />
                  </div>
                </div>
                <div className="col-3">
                  <div className="form-group row">
                    {
                      //<div className='col-12'>
                      //    <label className='text-dark'>
                      //        Ảnh đại diện<span className='required'></span>
                      //    </label>
                      //    {!isShow &&
                      //        files &&
                      //        files.length > 0 &&
                      //        files.map((item) => (
                      //            <div key={item.fileName} style={{ width: '150px' }}>
                      //                <img
                      //                    src={APIUrlDefault + item.filePreview}
                      //                    alt={item.fileName}
                      //                    className='img-fluid mb-2'
                      //                    style={{
                      //                        width: 'auto',
                      //                        height: 'auto',
                      //                        maxWidth: '100%',
                      //                        maxHeight: '100%',
                      //                    }}
                      //                />
                      //            </div>
                      //        ))}
                      //    <div>
                      //        <Button
                      //            variant='contained'
                      //            color='primary'
                      //            onClick={onOpenSelectFile}>
                      //            Chọn file
                      //        </Button>
                      //        <TextField
                      //            inputRef={register({ required: true })}
                      //            type='hidden'
                      //            name='image'
                      //            value={
                      //                (files && files.length > 0 && files[0].fileName) || ''
                      //            }
                      //        />
                      //        {errors.image && errors.image.type === 'required' && (
                      //            <p className='error'>Trường này là bắt buộc</p>
                      //        )}
                      //    </div>
                      //</div>
                    }
                    <div className="col-12">
                      <label className="text-dark">Ảnh đại diện</label>
                      <span className="required"></span>
                      <div>
                        <TextField
                          inputRef={register({ required: true })}
                          type="hidden"
                          name="avatar"
                          value={
                            (fileImage &&
                              fileImage.length > 0 &&
                              fileImage[0].name) ||
                            ""
                          }
                        />
                        {errors.avatar && errors.avatar.type === "required" && (
                          <p className="error">Trường này là bắt buộc</p>
                        )}
                      </div>
                      <DropzoneArea
                        acceptedFiles={["image/png, image/gif, image/jpeg, image/jpg"]}
                        filesLimit={1}
                        dropzoneText="Kéo thả ảnh đại diện vào đây"
                        onChange={(files) => {
                          files &&
                            files.length &&
                            console.log("Files:", files[0].name);
                          setFileImage(files);
                        }}
                      />
                    </div>
                  </div>
                  {postTypeId === "3" && (
                    <div className="col-12">
                      <label className="text-dark">Tài liệu</label>
                      <DropzoneArea
                        acceptedFiles={[
                          "image/*",
                          "application/pdf",
                          ".doc",
                          ".docx",
                          ".xls",
                          ".xlsx",
                          ".csv",
                          ".ppt",
                          ".pptx",
                        ]}
                        filesLimit={1}
                        maxFileSize={10485760}
                        dropzoneText="Kéo thả tệp tài liệu vào đây"
                        onChange={(files) => {
                          files &&
                            files.length &&
                            console.log("Files:", files[0].name);
                          setFileDoc(files);
                        }}
                      />
                    </div>
                  )}
                  {postTypeId === "1" && (
                    <>
                      <hr />
                      <div className="form-group row">
                        <div className="col-12 col-md-12 col-lg-12">
                          <FormAsyncPlanning
                            name="planning"
                            placeholder="Chọn quy hoạch..."
                          />
                        </div>
                      </div>
                    </>
                  )}

                  <hr />
                  <div className="form-group">
                    <div className="row">
                      <div className="col-12">
                        <label className="text-dark">Kích hoạt</label>
                        <Checkbox
                          checked={status}
                          onChange={handleChangeStatus}
                          color="primary"
                          inputProps={{ "aria-label": "secondary checkbox" }}
                          className="p-0 mt-0 ml-4"
                        />
                      </div>
                    </div>
                    {postTypeId !== "5" && (
                      <>
                        <div className="row">
                          <div className="col-12">
                            <label className="text-dark">Tin nóng</label>
                            <Checkbox
                              checked={isHot}
                              onChange={handleChangeHot}
                              color="primary"
                              inputProps={{
                                "aria-label": "secondary checkbox",
                              }}
                              className="p-0 mt-0 ml-4"
                            />
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-12">
                            <label className="text-dark">
                              Hiển thị ở trang chủ
                            </label>
                            <Checkbox
                              checked={isHomePage}
                              onChange={handleChangeHomePage}
                              color="primary"
                              inputProps={{
                                "aria-label": "secondary checkbox",
                              }}
                              className="p-0 mt-0 ml-4"
                            />
                          </div>
                        </div>
                      </>
                    )}

                    <div className="row">
                      <div className="col-12">
                        <RadioGroup
                          aria-label="isOwnerNews"
                          name="isOwnerNews"
                          value={isOwnerNews}
                          onChange={handleRadioChange}
                        >
                          <FormControlLabel
                            value="true"
                            control={<Radio />}
                            label="Tin tự viết"
                          />
                          <FormControlLabel
                            value="false"
                            control={<Radio />}
                            label="Tin trích dẫn"
                          />
                        </RadioGroup>
                      </div>
                    </div>
                    <div className="row">
                      <div className="form-group col-12">
                        <label className="text-dark">Nguồn trích dẫn</label>
                        <TextField
                          name="sourceNews"
                          disabled={isOwnerNews == "true"}
                          error={
                            errors.sourceNews &&
                            errors.sourceNews.type === "maxLength"
                          }
                          fullWidth
                          type="text"
                          className="form-control"
                          inputRef={register({ maxLength: 150 })}
                          inputProps={{ maxLength: 150 }}
                          onChange={(e) =>
                            setValue("sourceNews", e.target.value)
                          }
                        />
                        {errors.sourceNews &&
                          errors.sourceNews.type === "maxLength" && (
                            <span className="error">
                              Trường này không quá 150 ký tự
                            </span>
                          )}
                      </div>
                    </div>
                    <div className="row">
                      <div className="form-group col-12">
                        <label className="text-dark">Link trích dẫn</label>
                        <TextField
                          name="sourceLink"
                          disabled={isOwnerNews == "true"}
                          error={
                            errors.sourceLink &&
                            errors.sourceLink.type === "maxLength"
                          }
                          fullWidth
                          type="text"
                          className="form-control"
                          inputRef={register({
                            maxLength: 150,
                            pattern:
                              /(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b/g,
                          })}
                          inputProps={{ maxLength: 150 }}
                          onChange={(e) =>
                            setValue("sourceLink", e.target.value)
                          }
                        />
                        {errors.sourceLink &&
                          errors.sourceLink.type === "maxLength" && (
                            <span className="error">
                              Trường này không quá 150 ký tự
                            </span>
                          )}
                        {errors.sourceLink &&
                          errors.sourceLink.type === "pattern" && (
                            <span className="error">
                              Trường này chưa phải là liên kết
                            </span>
                          )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              </TabPane>
              <TabPane tabId={2}>
              <div className="row pt-4 pb-2">
                <div className="col-6">
                  <div className="form-group">
                    <label className="text-dark">Mô tả ngắn SEO</label>
                    <textarea
                      name="metaDescription"
                      rows="5"
                      ref={register({ maxLength: 550 })}
                      maxLength="500"
                      className={
                        "form-control" +
                        (errors.metaDescription &&
                        errors.metaDescription.type === "required"
                          ? " is-invalid"
                          : "")
                      }
                    ></textarea>
                    {errors.metaDescription &&
                      errors.metaDescription.type === "required" && (
                        <span className="error">Trường này là bắt buộc</span>
                      )}
                    {errors.metaDescription &&
                      errors.metaDescription.type === "maxLength" && (
                        <span className="error">
                          Trường này không quá 550 ký tự
                        </span>
                      )}
                  </div>
                  <div className="form-group">
                    <label className="text-dark">Tiêu đề SEO</label>
                    <TextField
                      name="metaTitle"
                      error={
                        errors.metaTitle &&
                        (errors.metaTitle.type === "required" ||
                          errors.metaTitle.type === "maxLength")
                      }
                      fullWidth
                      type="text"
                      className="form-control"
                      inputRef={register({ maxLength: 150 })}
                      inputProps={{ maxLength: 150 }}
                      onChange={(e) => setValue("metaTitle", e.target.value)}
                    />
                    {errors.metaTitle &&
                      errors.metaTitle.type === "required" && (
                        <span className="error">Trường này là bắt buộc</span>
                      )}
                    {errors.metaTitle &&
                      errors.metaTitle.type === "maxLength" && (
                        <span className="error">
                          Trường này không quá 150 ký tự
                        </span>
                      )}
                  </div>

                  <div className="form-group">
                    <label className="text-dark">Từ khoá SEO</label>
                    <TextField
                      name="metaKeyword"
                      error={
                        errors.metaKeyword &&
                        (errors.metaKeyword.type === "required" ||
                          errors.metaKeyword.type === "maxLength")
                      }
                      fullWidth
                      type="text"
                      className="form-control"
                      inputRef={register({ maxLength: 150 })}
                      inputProps={{ maxLength: 150 }}
                      onChange={(e) => setValue("metaKeyword", e.target.value)}
                    />
                    {errors.metaKeyword &&
                      errors.metaKeyword.type === "required" && (
                        <span className="error">Trường này là bắt buộc</span>
                      )}
                    {errors.metaKeyword &&
                      errors.metaKeyword.type === "maxLength" && (
                        <span className="error">
                          Trường này không quá 150 ký tự
                        </span>
                      )}
                  </div>
                </div>
                <div className="col-6">
                  <div className="form-group">
                    <label className="text-dark">Mô tả share Facebook</label>
                    <textarea
                      name="fbDescription"
                      rows="5"
                      ref={register({ maxLength: 550 })}
                      maxLength="550"
                      className={
                        "form-control" +
                        (errors.fbDescription &&
                        errors.fbDescription.type === "required"
                          ? " is-invalid"
                          : "")
                      }
                    ></textarea>
                    {errors.fbDescription &&
                      errors.fbDescription.type === "required" && (
                        <span className="error">Trường này là bắt buộc</span>
                      )}
                    {errors.fbDescription &&
                      errors.fbDescription.type === "maxLength" && (
                        <span className="error">
                          Trường này không quá 550 ký tự
                        </span>
                      )}
                  </div>
                  <div className="form-group">
                    <label className="text-dark">Mô tả share Twitter</label>
                    <textarea
                      name="twitterDescription"
                      rows="5"
                      ref={register({ maxLength: 550 })}
                      maxLength="550"
                      className={
                        "form-control" +
                        (errors.twitterDescription &&
                        errors.twitterDescription.type === "required"
                          ? " is-invalid"
                          : "")
                      }
                    ></textarea>
                    {errors.twitterDescription &&
                      errors.twitterDescription.type === "required" && (
                        <span className="error">Trường này là bắt buộc</span>
                      )}
                    {errors.twitterDescription &&
                      errors.twitterDescription.type === "maxLength" && (
                        <span className="error">
                          Trường này không quá 550 ký tự
                        </span>
                      )}
                  </div>
                </div>
              </div>
              <div className="form-group row">
                <div className="col-6">
                  <label className="text-dark">Ảnh Twitter</label>
                  {
                    //    {!isShowTw &&
                    //fileTw &&
                    //fileTw.length > 0 &&
                    //fileTw.map((item) => (
                    //<div key={item.fileName} style={{ width: '150px' }}>
                    //    <img
                    //        src={APIUrlDefault + item.filePreview}
                    //        alt={item.fileName}
                    //        className='img-fluid mb-2'
                    //        style={{
                    //            width: 'auto',
                    //            height: 'auto',
                    //            maxWidth: '100%',
                    //            maxHeight: '100%',
                    //        }}
                    //    />
                    //</div>
                    //))}
                    //<div>
                    //    <Button
                    //        variant='contained'
                    //        color='primary'
                    //        onClick={onOpenSelectFileTw}>
                    //        Chọn file
                    //    </Button>
                    //    <TextField
                    //        //inputRef={register({ required: true })}
                    //        type='hidden'
                    //        name='image'
                    //        value={
                    //            (fileTw && fileTw.length > 0 && fileTw[0].fileName) || ''
                    //        }
                    //    />
                    //</div>
                  }

                  <DropzoneArea
                    acceptedFiles={["image/png, image/gif, image/jpeg, image/jpg"]}
                    filesLimit={1}
                    dropzoneText="Kéo thả ảnh share twitter vào đây"
                    onChange={(files) => {
                      files &&
                        files.length &&
                        console.log("Files:", files[0].name);
                      setFileImageTw(files);
                    }}
                  />
                </div>
                <div className="col-6">
                  <label className="text-dark">Ảnh Facebook</label>
                  {
                    //                    {!isShowFb &&
                    //                fileFb &&
                    //                fileFb.length > 0 &&
                    //                fileFb.map((item) => (
                    //                <div key={item.fileName} style={{ width: '150px' }}>
                    //                    <img
                    //                        src={APIUrlDefault + item.filePreview}
                    //                        alt={item.fileName}
                    //                        className='img-fluid mb-2'
                    //                        style={{
                    //                            width: 'auto',
                    //                            height: 'auto',
                    //                            maxWidth: '100%',
                    //                            maxHeight: '100%',
                    //                        }}
                    //                    />
                    //                </div>
                    //                ))}
                    //                <div>
                    //                    <Button
                    //                        variant='contained'
                    //                        color='primary'
                    //                        onClick={onOpenSelectFileFb}>
                    //                        Chọn file
                    //                    </Button>
                    //                    <TextField
                    //                        //inputRef={register({ required: true })}
                    //                        type='hidden'
                    //                        name='image'
                    //                        value={
                    //                            (fileFb && fileFb.length > 0 && fileFb[0].fileName) || ''
                    //                        }
                    //                    />
                    //                    {/* {errors.image && errors.image.type === 'required' && (
                    //  <p className='error'>Trường này là bắt buộc</p>
                    //)} */}
                    //                </div>
                  }

                  <DropzoneArea
                    acceptedFiles={["image/png, image/gif, image/jpeg, image/jpg"]}
                    filesLimit={1}
                    dropzoneText="Kéo thả ảnh share facebook vào đây"
                    onChange={(files) => {
                      files &&
                        files.length &&
                        console.log("Files:", files[0].name);
                      setFileImageFb(files);
                    }}
                  />
                </div>
              </div>
              </TabPane>
            </TabContent>
          </form>
        </FormProvider>
     

      {isShow && (
        <Dialog
          onClose={onCloseSelectFile}
          open={isShow}
          fullWidth={true}
          maxWidth="md"
          className="dialog-preview-form"
        >
          <DialogTitle disableTypography>
            <Typography variant="h6">Quản lý file</Typography>
            <IconButton
              aria-label="close"
              className={classes.closeButton}
              onClick={onCloseSelectFile}
            >
              <CloseIcon />
            </IconButton>
          </DialogTitle>
          <DialogContent dividers>
            <FileManagement
              files={files}
              setFiles={setFiles}
              acceptedFiles={["jpeg", "png", "jpg", "gif"]}
            />
          </DialogContent>

          <DialogActions>
            <Button
              type="button"
              onClick={onCloseSelectFile}
              variant="contained"
              startIcon={<CloseIcon />}
            >
              Hủy
            </Button>
            {files && files.length > 0 && (
              <Button
                type="button"
                color="primary"
                variant="contained"
                startIcon={<SaveIcon />}
                onClick={onSaveSelectFile}
              >
                Lưu
              </Button>
            )}
          </DialogActions>
        </Dialog>
      )}
      {isShowFb && (
        <Dialog
          onClose={onCloseSelectFileFb}
          open={isShowFb}
          fullWidth={true}
          maxWidth="md"
          className="dialog-preview-form"
        >
          <DialogTitle disableTypography>
            <Typography variant="h6">Quản lý file</Typography>
            <IconButton
              aria-label="close"
              className={classes.closeButton}
              onClick={onCloseSelectFileFb}
            >
              <CloseIcon />
            </IconButton>
          </DialogTitle>
          <DialogContent dividers>
            <FileManagement
              files={fileFb}
              setFiles={setFileFb}
              acceptedFiles={["jpeg", "png", "jpg", "gif"]}
            />
          </DialogContent>

          <DialogActions>
            <Button
              type="button"
              onClick={onCloseSelectFileFb}
              variant="contained"
              startIcon={<CloseIcon />}
            >
              Hủy
            </Button>
            {fileFb && fileFb.length > 0 && (
              <Button
                type="button"
                color="primary"
                variant="contained"
                startIcon={<SaveIcon />}
                onClick={onSaveSelectFileFb}
              >
                Lưu
              </Button>
            )}
          </DialogActions>
        </Dialog>
      )}

      {isShowTw && (
        <Dialog
          onClose={onCloseSelectFileTw}
          open={isShowTw}
          fullWidth={true}
          maxWidth="md"
          className="dialog-preview-form"
        >
          <DialogTitle disableTypography>
            <Typography variant="h6">Quản lý file</Typography>
            <IconButton
              aria-label="close"
              className={classes.closeButton}
              onClick={onCloseSelectFileTw}
            >
              <CloseIcon />
            </IconButton>
          </DialogTitle>
          <DialogContent dividers>
            <FileManagement
              files={fileTw}
              setFiles={setFileTw}
              acceptedFiles={["jpeg", "png", "jpg", "gif"]}
            />
          </DialogContent>

          <DialogActions>
            <Button
              type="button"
              onClick={onCloseSelectFileTw}
              variant="contained"
              startIcon={<CloseIcon />}
            >
              Hủy
            </Button>
            {fileTw && fileTw.length > 0 && (
              <Button
                type="button"
                color="primary"
                variant="contained"
                startIcon={<SaveIcon />}
                onClick={onSaveSelectFileTw}
              >
                Lưu
              </Button>
            )}
          </DialogActions>
        </Dialog>
      )}

      {isShowDocs && (
        <Dialog
          onClose={onCloseSelectFileDocs}
          open={isShowDocs}
          fullWidth={true}
          maxWidth="md"
          className="dialog-preview-form"
        >
          <DialogTitle disableTypography>
            <Typography variant="h6">Quản lý file</Typography>
            <IconButton
              aria-label="close"
              className={classes.closeButton}
              onClick={onCloseSelectFileDocs}
            >
              <CloseIcon />
            </IconButton>
          </DialogTitle>
          <DialogContent dividers>
            <FileManagement
              files={fileDocs}
              setFiles={setFileDocs}
              acceptedFiles={["doc", "docx", "xls", "xlsx", "pdf"]}
            />
          </DialogContent>

          <DialogActions>
            <Button
              type="button"
              onClick={onCloseSelectFileDocs}
              variant="contained"
              startIcon={<CloseIcon />}
            >
              Hủy
            </Button>
            {fileDocs && fileDocs.length > 0 && (
              <Button
                type="button"
                color="primary"
                variant="contained"
                startIcon={<SaveIcon />}
                onClick={onSaveSelectFileDocs}
              >
                Lưu
              </Button>
            )}
          </DialogActions>
        </Dialog>
      )}
    </div>
  );
}
