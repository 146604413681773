export const ApiUrl = {
  //--- Account
  Login: 'api/admin/account/Login',
  GetUserAccountDetail: 'api/admin/account/GetUserAccountDetail',
  UpdateUserAccountDetail: 'api/admin/account/UpdateUserAccount',
  UpdateUserAccountPassword: '/api/Account/ChangePassword',
  Register: 'api/Account/Register',
  ForgotPassword: 'api/Account/ForgotPassword',
  ResetPassword: '​api​/Account​/ResetPassword',
  // Token
  RefreshToken: 'api/Account/RefreshToken',
  RemoveRefreshToken: 'api/Account/RemoveRefreshToken',

  //--- Email Generated
  GetListEmailGenerated: 'api/admin/EmailGenerated/GetListEmailGenerated',
  GetDetailEmailGenerated: 'api/admin/EmailGenerated/GetDetailEmailGenerated',

  //--- Email Template
  GetListEmailTemplates: 'api/admin/EmailTemplate/GetListEmailTemplates',
  GetDetailEmailTemplate: 'api/admin/EmailTemplate/GetDetailEmailTemplate',
  CreateEmailTemplate: 'api/admin/EmailTemplate/CreateEmailTemplate',
  UpdateEmailTemplate: 'api/admin/EmailTemplate/UpdateEmailTemplate',
  DeleteEmailTemplate: 'api/admin/EmailTemplate/DeleteEmailTemplate',
  // Notification
  GetListNotifications: '/api/admin/PushNotification/GetListAll',
  GetDetailNotification: '/api/admin/PushNotification/GetDetail/',
  CreateNotification: '/api/admin/PushNotification/Create',
  UpdateNotification: '/api/admin/PushNotification/Update/',
  DeleteNotification: '/api/admin/PushNotification/Delete/',
  ActiveNotification: '/api/admin/PushNotification/Active/',
  DeActiveNotification: '/api/admin/PushNotification/DeActive/',
  PushFirebaseNotification: '/api/admin/PushNotification/PushFirebase/',
  //--- Contact Management
  GetListContactManagement: 'api/admin/EmailGenerated/GetListContactToAdmin',
  GetContactDetail: 'api/admin/EmailGenerated/GetDetailContactToAdmin',
  ReplyUserContactByEmail: 'api/admin/EmailGenerated/ReplyUserContactByEmail',
  GetDetailEmailTemplate: 'api/admin/EmailTemplate/GetDetailEmailTemplate',

  //--- Role Management
  GetListRoleManagement: 'api/admin/Role/GetListRole',
  GetDetailRoleManagement: 'api/admin/Role/GetDetailRole',
  CreateRoleManagement: 'api/admin/Role/CreateRole',
  UpdateRoleManagement: 'api/admin/Role/UpdateRole',
  DeleteRoleManagement: 'api/admin/Role/DeleteRole',

  //--- Map
  CreateMap: 'api/admin/Map/create-map',
  GetMapById: 'api/admin/Map/get-map-by-id',
  GetAllBaseMapSetting: 'api/admin/BaseMapSetting/get-all',
  GetPgSchema: 'api/admin/PgTable/get-pg-schema',
  GetPageByTable: 'api/admin/PgTable/get-pg-table',
  UpdateMap: 'api/admin/Map/update-map',
  GetPgTable: 'api/admin/PgTable/get-pg-table',
  DeleteMap: 'api/admin/Map/delete-map',

  //--- Planning
  GetListPlanning: 'api/admin/Planning/GetListPlanning',
  GetLookUpPlanning: 'api/admin/Planning/GetLookUpPlanning',
  GetLookUpPlanningPagination: 'api/admin/Planning/GetLookUpPlanningPaging',
  GetLookUpPlanningStatus: 'api/admin/Planning/GetLookUpPlanningStatus',
  GetLookUpPlanningLevel: 'api/admin/Planning/GetLookUpPlanningLevel',
  GetLookUpPlanningType: 'api/admin/Planning/GetLookUpPlanningType',
  GetLookUpDocumentType: 'api/admin/Planning/GetLookUpDocumentType',
  GetDetailPlaning: 'api/admin/Planning/GetDetailPlaning',
  CreatePlanning: 'api/admin/Planning/CreatePlanning',
  UpdatePlanning: 'api/admin/Planning/UpdatePlanning',
  DeletePlanning: 'api/admin/Planning/DeletePlanning',
  PlanningRelationshipType:
    'api/admin/Planning/get-all-planning-relationship-type',
  PlanningRelationshipTypeById:
    'api/admin/Planning/get-all-planning-relationship-by-planning-id',
  PlanningApprovedById:
    'api/admin/Planning/get-look-up-approved-planning-by-id',
  PutPlanningRelationship:
    'api/admin/Planning/update-multiple-planning-relationship',
  GetAllBoundaries: 'api/admin/Planning/get-all-boundaries',
  CheckExistedPlanning: 'api/admin/Planning/CheckExistedPlanning',
  GetLookupLayerByPlanningId:
    'api/admin/Planning/get-lookup-layer-by-planning-id',

  //--- Consultant Community
  GetListConsultCommunity:
    'api/admin/ConsultantCommunity/GetListConsultCommunity',
  GetDetailConsultCommunity:
    'api/admin/ConsultantCommunity/GetDetailConsultCommunity',
  CreateConsultCommunity:
    'api/admin/ConsultantCommunity/CreateConsultCommunity',
  UpdateConsultCommunity:
    'api/admin/ConsultantCommunity/UpdateConsultCommunity',
  DeleteConsultCommunity:
    'api/admin/ConsultantCommunity/DeleteConsultCommunity',
  GetConsultCommunityStatusLookup:
    'api/admin/ConsultantCommunity/GetConsultCommunityStatusLookup',
  GetConsultCommunityByPlanning:
    'api/admin/ConsultantCommunity/GetConsultCommunityByPlanning',
  ExportConsultantList: 'api/ExportData/ExportConsultantList',

  //--- Statement
  GetListStatement: 'api/admin/Statement/GetListStatement',
  // GetDetailStatement: "​api​/admin​/Statement​/GetStatementByPlanning",
  CreateStatement: 'api/admin/Statement/CreateStatement',
  UpdateStatement: 'api/admin/Statement/UpdateStatement',
  DeleteStatement: 'api/admin/Statement/DeleteStatement',
  GetStatementStatusLookup: 'api/admin/Statement/GetStatementStatusLookup',
  GetListServiceLink: 'api/admin/Statement/GetListServiceLink',
  SearchStatement: 'api/admin/Statement/search-statement-by',
  GetStatementReport: 'api/admin/Statement/GetStatementReport',

  // Home page
  GetListHomePage: 'api/admin/HomePage/GetListHomePage',
  GetDetailHomePage: 'api/admin/HomePage/GetDetailHomePage',
  CreateHomePage: 'api/admin/HomePage/CreateHomePage',
  UpdateHomePage: 'api/admin/HomePage/UpdateHomePage',
  DeleteHomePage: 'api/admin/HomePage/DeleteHomePage',
  DeleteAvatar: 'api/admin/HomePage/DeleteAvatar',

  // User Management
  GetListUserManagement: 'api/admin/GUIUser/GetUserManagement',
  GetDetailUserManagement: 'api/admin/GUIUser/GetUserDetail',
  CreateUserManagement: 'api/admin/GUIUser/CreateUser',
  UpdateUserManagement: 'api/admin/GUIUser/UpdateUser',
  DeleteUserManagement: 'api/admin/HomePage/DeleteHomePage',
  ActiveUserManagement: 'api/admin/GUIUser/ActiveUser',
  ResetPasswordUserManagement: 'api/admin/GUIUser/ResetPassword',
  GetRoleLookupUserManagement: 'api/admin/Role/GetRoleLookup',
  ActiveUser: 'api/admin/GUIUser/ActiveUser',
  DeActiveUser: 'api/admin/GUIUser/DeActiveUser',
  KickOutUser: 'api/admin/GUIUser/KickOutUser',
  ListLimitAccessPermission: (value) =>
    `api/admin/User/GetDetailLimitAccessPermission?status=${value}`,
  SaveListAccessPermission: 'api/admin/User/UpdateLimitAccessPermission',

  // User Admin Management
  GetListUserAdminManagement: 'api/admin/User/GetUserManagement',
  GetDetailUserAdminManagement: 'api/admin/User/GetUserDetail',
  CreateUserAdminManagement: 'api/admin/User/CreateUser',
  UpdateUserAdminManagement: 'api/admin/User/UpdateUser',
  DeleteUserAdminManagement: 'api/admin/HomePage/DeleteHomePage',
  ActiveUserAdminManagement: 'api/admin/User/ActiveUser',
  ResetPasswordUserAdminManagement: 'api/admin/User/ResetPassword',
  GetRoleLookupUserAdminManagement: 'api/admin/Role/GetRoleLookup',
  ActiveAdminUser: 'api/admin/User/ActiveUser',
  DeActiveAdminUser: 'api/admin/User/DeActiveUser',

  //ApprovalAgencyLevel
  ApprovalAgencyLevel:
    'api/admin/ApprovalAgencyLevel/get-approval-agency-level',
  GetLookupDistrict: 'api/admin/Administrative/GetLookupDistrict',

  //Document
  GetAllDocumentByPlanning: 'api/admin/Document/GetAllDocumentByParentId',
  CreateFolder: 'api/admin/Document/CreateDocumentFolder',
  ReNameDocument: 'api/admin/Document/RenameDocument',
  CreateFile: 'api/admin/Document/UploadDocument',
  RemoveDocument: 'api/admin/Document/DeleteDocument',
  MoveDocument: 'api/admin/Document/MoveDocument',
  SearchDocument: 'api/admin/Document/SearchDocument',
  SetDocumentStatus: 'api/admin/Document/SetDocumentStatus',
  DownloadDocument: 'api/admin/Document/DownloadDocument',

  // CommuneManagement
  GetListCommune: 'api/admin/Administrative/GetListCommune',
  GetDetailCommune: 'api/admin/Administrative/GetDetailCommune',
  GetDetailCommune: 'api/admin/Administrative/GetDetailCommune',
  CreateCommune: 'api/admin/Administrative/CreateCommune',
  UpdateCommune: 'api/admin/Administrative/UpdateCommune',
  DeleteCommune: 'api/admin/Administrative/DeleteCommune',
  GetDistrictByProvinceId: 'api/admin/Administrative/GetLookupDistrict',
  GetLookupCommune: 'api/admin/Administrative/GetLookupCommune',
  GetLookupCommuneMultiDistrict: 'api/admin/Administrative/GetLookupCommuneMultiDistrict',
  GetLookupCommuneHome: 'api/cms/Administrative/GetLookupAutoComplete', 
  // DistrictManagement
  GetListDistrict: 'api/admin/Administrative/GetListDistrict',
  GetDetailDistrict: 'api/admin/Administrative/GetDetailDistrict',
  GetLookupDistrict: 'api/admin/Administrative/GetLookupDistrict',
  GetLookupDistrictMultiProvince: 'api/admin/Administrative/GetLookupDistrictMultiProvince',
  CreateDistrict: 'api/admin/Administrative/CreateDistrict',
  UpdateDistrict: 'api/admin/Administrative/UpdateDistrict',
  DeleteDistrict: 'api/admin/Administrative/DeleteDistrict',

  // ProvinceManagement
  GetListProvince: 'api/admin/Administrative/GetListProvince',
  GetDetailProvince: 'api/admin/Administrative/GetDetailProvince',
  CreateProvince: 'api/admin/Administrative/CreateProvince',
  UpdateProvince: 'api/admin/Administrative/UpdateProvince',
  DeleteProvince: 'api/admin/Administrative/DeleteProvince',
  GetLookupProvince: 'api/admin/Administrative/GetLookupProvince',

  //Link Group Management
  GetListLinkGroup: 'api/admin/Administrative/GetListLinkGroup',
  GetDetailLinkGroup: 'api/admin/Administrative/GetDetailLinkGroup',
  CreateLinkGroup: 'api/admin/Administrative/CreateLinkGroup',
  UpdateLinkGroup: 'api/admin/Administrative/UpdateLinkGroup',
  DeleteLinkGroup: 'api/admin/Administrative/DeleteLinkGroup',

  //Service Link Management
  GetListServiceLink: 'api/admin/Administrative/GetListLinkService',
  GetDetailServiceLink: 'api/admin/Administrative/GetDetailLinkService',
  CreateServiceLink: 'api/admin/Administrative/CreateLinkService',
  UpdateServiceLink: 'api/admin/Administrative/UpdateLinkService',
  DeleteServiceLink: 'api/admin/Administrative/DeleteLinkService',
  GetLookupLinkGroup: 'api/admin/Administrative/GetLookupLinkGroup',

  //Userlog
  GetAllLogHistory: 'api/admin/LogHistory/GetAllLogHistory',

  //Land Type
  GetListLandType: 'api/admin/Land/GetListLandType',
  LookupLandType: 'api/admin/Land/LookupLandType',
  GetLandTypeById: 'api/admin/Land/GetLandTypeById',
  CreateLandType: 'api/admin/Land/CreateLandType',
  UpdateLandType: 'api/admin/Land/UpdateLandType',
  DeleteLandType: 'api/admin/Land/DeleteLandType',

  //Land Type Detail
  GetListLandTypeDetail: 'api/admin/Land/GetListLandTypeDetail',
  GetLandTypeDetailById: 'api/admin/Land/GetLandTypeDetailById',
  CreateLandTypeDetail: 'api/admin/Land/CreateLandTypeDetail',
  UpdateLandTypeDetail: 'api/admin/Land/UpdateLandTypeDetail',
  DeleteLandTypeDetail: 'api/admin/Land/DeleteLandTypeDetail',

  //Opinion Form
  CreateFormTemplate: 'api/admin/ConsultantCommunity/CreateFormTemplate',
  UpdateFormTemplate: 'api/admin/ConsultantCommunity/UpdateFormTemplate',
  GetListFormTemplate: 'api/admin/ConsultantCommunity/GetListFormTemplate',
  GetDetailFormTemplate: 'api/admin/ConsultantCommunity/GetDetailFormTemplate',
  DeleteFormTemplate: 'api/admin/ConsultantCommunity/DeleteFormTemplate',
  GetLookupFormExample: 'api/admin/ConsultantCommunity/GetLookupFormExample',

  GetDetailFeedback: 'api/admin/ConsultantCommunityAdmin/GetDetailFeedback',
  SaveReply: 'api/admin/ConsultantCommunityAdmin/SaveReply',

  //utils-shape-file
  Shape_File_Step_1: 'api/admin/Utils/step-1-import-and-unzip-file',
  Shape_File_Step_2: 'api/admin/Utils/step-2-import-shape-file-to-postgis',
  Shape_File_Step_3: 'api/admin/Utils/step-3-created-sld-and-import-to-postgis',
  Shape_File_Step_4: 'api/admin/Utils/step-4-publish',
  // Shape_File_Publish_Finish: "api/admin/Utils/publish-again",

  //utils-tiff-file
  Import_Tiff_File: 'api/admin/Utils/import-tif',

  //News
  News_Create: 'api/admin/News/Create',
  News_Update: 'api/admin/News/Update',
  News_Delete: 'api/admin/News/Delete',
  News_Restore: 'api/admin/News/Restore/{id}',
  News_EmptyTrash: 'api/admin/News/EmptyTrash',
  News_ChangeActiveStatus: 'api/admin/News/ChangeActiveStatus',
  News_ChangeHotStatus: 'api/admin/News/ChangeHotStatus',
  News_ChangeFeatureStatus: 'api/admin/News/ChangeFeatureStatus',
  News_GetListAll: 'api/admin/News/GetListAll',
  News_GetListByCat: 'api/admin/News/GetListByCat',
  News_GetListHot: 'api/admin/News/GetListHot',
  News_GetListByStatus: 'api/admin/News/GetListByStatus',
  News_GetDetail: 'api/admin/News/GetDetail/{id}',

  DownloadFile: 'api/cms/Planning/DownloadFileBinary',

  //--- Document Repository
  DocumentRepositoryCreateFolder:
    'api/admin/DocumentUpload/CreateDocumentFolder',
  DocumentRepositoryUploadDocument: 'api/admin/DocumentUpload/UploadDocument',
  DocumentRepositoryDownloadDocument:
    'api/admin/DocumentUpload/DownloadDocument',
  DocumentRepositoryDeleteDocument: 'api/admin/DocumentUpload/DeleteDocument',
  DocumentRepositoryGetAllDocumentByParentId:
    'api/admin/DocumentUpload/GetAllDocumentByParentId',
  DocumentRepositoryMoveDocument: 'api/admin/DocumentUpload/MoveDocument',
  DocumentRepositorySearchDocument: 'api/admin/DocumentUpload/SearchDocument',
  DocumentRepositoryRenameDocument: 'api/admin/DocumentUpload/RenameDocument',
  DocumentRepositoryGetParentDocumentById:
    'api/admin/DocumentUpload/GetParentDocumentById',
  UrlUploadFromEditor: 'api/admin/DocumentUpload/UploadImage',

  // Security Matrix
  GetSecurityMatrixDetail: 'api/admin/Permission/GetSecurityMatrixDetail',
  GetActionLookup: 'api/admin/Permission/GetActionLookup',
  GetScreenLookup: 'api/admin/Permission/GetScreenLookup',
  UpdateSecurityMatrix: 'api/admin/Permission/UpdateSecurityMatrix',
  CheckUserPermission: 'api/admin/Permission/CheckUserPermission',

  GetDefaultCordinate: 'api/cms/Administrative/GetDefaultCordinate',
  UpdateDefaultCordinate: 'api/admin/Administrative/UpdateDefaultCordinate',
  GetClientSetting: 'api/cms/Administrative/GetClientSetting',

  //Category
  GetLookUpCategory: (langId) =>
    `api/admin/Category/GetLookup?langId=${langId}`,

  //Posts
  Posts_GetListAll: 'api/admin/Posts/GetListAll',
  Posts_Create: 'api/admin/Posts/Create',
  Posts_Update: 'api/admin/Posts/Update',
  Posts_Delete: (id) => `api/admin/Posts/Delete/${id}`,
  Posts_EmptyTrash: `api/admin/Posts/EmptyTrash`,
  Posts_GetDetail: (postId, langId) =>
    `api/admin/Posts/GetDetail/${langId}/${postId}`,
  Posts_CreateWithUpload: 'api/admin/Posts/CreateWithUpload',
  Posts_UpdateWithUpload: 'api/admin/Posts/UpdateWithUpload',

  //Address
  Address_Analysis: '/api/admin/Address/AnalysisAddress',
  Address_GetListAll: 'api/admin/Address/GetListAll',
  Address_Create: 'api/admin/Address/Create',
  Address_Update: 'api/admin/Address/Update',
  Address_Delete: (id) => `api/admin/Address/Delete/${id}`,
  Address_EmptyTrash: 'api/admin/Address/EmptyTrash',
  Address_GetDetail: (addressId, langId) =>
    `api/admin/Address/GetDetail/${langId}/${addressId}`,
  GetLookUpAddress: 'api/admin/Address/GetLookup',

  AnalysisAddressLongLat: 'api/admin/Address/AnalysisAddressLongLat',

  //faq
  Faq_GetListAll: 'api/admin/QuesAndAns/GetListAll',
  Faq_Create: 'api/admin/QuesAndAns/Create',
  Faq_Update: 'api/admin/QuesAndAns/Update',
  Faq_delete: (id) => `api/admin/QuesAndAns/Delete/${id}`,
  Faq_EmptyTrash: 'api/admin/QuesAndAns/EmptyTrash',
  Faq_GetDetail: (quesAndAnsId, langId) =>
    `api/admin/QuesAndAns/GetDetail/${langId}/${quesAndAnsId}`,

  //classified
  Classified_GetlistAll: 'api/admin/Classified/GetListAll',
  Classified_Create: 'api/admin/Classified/Create',
  Classified_CreateWithUpload: 'api/admin/Classified/CreateWithUpload',
  Classified_Update: 'api/admin/Classified/Update',
  Classified_UpdateWithUpload: 'api/admin/Classified/UpdateWithUpload',
  Classified_Delete: (id) => `api/admin/Classified/Delete/${id}`,
  Classified_Active: (id) => `api/admin/Classified/Active/${id}`,
  Classified_DeActive: (id) => `api/admin/Classified/DeActive/${id}`,
  Classified_ShowOnWeb: (id) => `api/admin/Classified/ShowOnWeb/${id}`,
  Classified_HideOnWeb: (id) => `api/admin/Classified/HideOnWeb/${id}`,
  Classified_SetSoldOut: (id) => `api/admin/Classified/SetSoldOut/${id}`,
  Classified_SetOnSale: (id) => `api/admin/Classified/SetOnSale/${id}`,
  Classified_ChangeRealEstateType: (id, realEstateTypeId) => `api/admin/Classified/ChangeRealEstateType/${id}/${realEstateTypeId}`,
  Classified_GetDetail: (id) => `api/admin/Classified/GetDetail/${id}`,
  Classified_EmptyTrash: 'api/admin/Classified/GetListAll',
  //Address
  //---> Project
  ProjectGetListAll: 'api/admin/ProjectInfo/GetListAll',
  ProjectCreate: 'api/admin/ProjectInfo/Create',
  ProjectCreateWithUpload: 'api/admin/ProjectInfo/CreateWithUpload',
  ProjectUpdate: 'api/admin/ProjectInfo/Update',
  ProjectUpdateWithUpload: 'api/admin/ProjectInfo/UpdateWithUpload',
  ProjectDelete: (id) => `api/admin/ProjectInfo/Delete/${id}`,
  ProjectEmptyTrash: `api/admin/ProjectInfo/EmptyTrash`,
  ProjectGetDetail: (id, langId) =>
    `api/admin/ProjectInfo/GetDetail/${langId}/${id}`,
  ProjectGetLookUp: (langId, limit) =>
    `api/admin/ProjectInfo/GetLookup?langId=${langId}&limit=${limit}`,

  ProjecInforGetLookUp: (langId, keyword, limit, pageIndex) =>
    `api/admin/ProjectInfo/GetLookup?langId=${langId}&limit=${limit}&pageIndex=${pageIndex}&keyWord=${keyword}`,

  //---> Master data
  GetMasterDataFromLangId: '/api/MasterData/GetMasterDataFromLangId',

  //---> Investor
  GetListAllInvestor: 'api/cms/Investor/GetListAll',
  Investor_GetListAll: 'api/admin/Investor/GetListAll',
  Investor_GetDetail: (langId, investorId) =>
    `api/admin/Investor/GetDetail/${langId}/${investorId}`,
  Investor_Create: 'api/admin/Investor/Create',
  Investor_Update: 'api/admin/Investor/Update',
  Investor_Delete: (investorId) => `api/admin/Investor/Delete/${investorId}`,
  Investor_EmptyTrash: 'api​/admin​/Investor​/EmptyTrash',
  GetLookupInvestor: 'api/admin/Investor/GetLookup',

  // LandPriceInfo
  LandPriceInfo_GetListAll: 'api/admin/LandPriceInfo/GetListAll',
  LandPriceInfo_Create: 'api/admin/LandPriceInfo/Create',
  LandPriceInfo_Update: 'api/admin/LandPriceInfo/Update',
  LandPriceInfo_Delete: (id) => `api/admin/LandPriceInfo/Delete/${id}`,
  LandPriceInfo_EmptyTrash: 'api/admin/LandPriceInfo/EmptyTrash',
  LandPriceInfo_GetDetail: (addressId, langId) =>
    `api/admin/LandPriceInfo/GetDetail/${langId}/${addressId}`,
  GetLookUpLandPriceInfo: 'api/admin/LandPriceInfo/GetLookup',

  // MetaTag

  PageMetaTag_GetListAll: 'api/admin/PageMetaTag/GetListAll',
  PageMetaTag_Create: 'api/admin/PageMetaTag/Create',
  PageMetaTag_Update: 'api/admin/PageMetaTag/Update',
  PageMetaTag_Delete: (metatagId) =>
    `api/admin/PageMetaTag/Delete/${metatagId}`,
  PageMetaTag_EmptyTrash: 'api/admin/PageMetaTag/EmptyTrash',
  PageMetaTag_Permanent: (metatagId) =>
    `api/admin/PageMetaTag/DeletePermanent/${metatagId}`,
  PageMetaTag_Detail: (langId, pageMetaTagId) =>
    `/api/admin/PageMetaTag/GetDetail/${langId}/${pageMetaTagId}`,

  // MobileAppIntroduce
  MobileAppIntroduce_GetListAll: 'api/admin/MobileAppIntroduce/GetListAll',
  MobileAppIntroduce_Create: 'api/admin/MobileAppIntroduce/Create',
  MobileAppIntroduce_Update: 'api/admin/MobileAppIntroduce/Update',
  MobileAppIntroduce_Delete: (id) =>
    `api/admin/MobileAppIntroduce/Delete/${id}`,

  // Introduce
  Introduce_GetListAll: 'api/admin/Introduce/GetListAll',
  Introduce_Create: 'api/admin/Introduce/Create',
  Introduce_Update: 'api/admin/Introduce/Update',
  Introduce_Delete: (id) => `api/admin/Introduce/Delete/${id}`,
  Introduce_Detail: (id) => `/api/admin/Introduce/GetDetail/${id}`,

  // ConsultantService
  ConsultantService_GetListAll: 'api/admin/ConsultantService/GetListAll',
  ConsultantService_GetDetail: (id) =>
    `api/admin/ConsultantService/GetDetail/${id}`,
  ConsultantService_Create: 'api/admin/ConsultantService/Create',
  ConsultantService_Update: 'api/admin/ConsultantService/Update',
  ConsultantService_Delete: (id) =>
    `api/admin/ConsultantService/Delete/${id}`,
  ConsultantService_EmptyTrash: 'api/admin/ConsultantService/EmptyTrash',
  ConsultantService_Permanent: (id) =>
    `api/admin/ConsultantService/DeletePermanent/${id}`,

  // GetLookupLanguage
  GetLookupLanguage: 'api/admin/Administrative/GetLookupLanguage',

  // Street
  StreetGetLookUp: 'api/admin/Street/GetLookup',

  //StatePriceFile
  StatePriceFile_GetListAll: 'api/admin/StatePriceFile/GetListAll',
  StatePriceFile_Create: 'api/admin/StatePriceFile/Create',
  StatePriceFile_Update: 'api/admin/StatePriceFile/Update',
  StatePriceFile_Delete: (id) => `api/admin/StatePriceFile/Delete/${id}`,
  StatePriceFile_GetDetail: (id) => `api/admin/StatePriceFile/GetDetail/${id}`,
  StatePriceFile_EmptyTrash: 'api/admin/StatePriceFile/EmptyTrash',



  UserRegisterSumary: 'api/admin/Report/UserRegisterSumary',
  UserRegisterReport: '/api/admin/Report/UserRegisterReport',
  UserExternalLoginType: 'api/admin/Report/UserExternalLoginType',
  reportGetListPlanning: 'api/admin/Planning/GetListPlanning',
  reportGetListPlanning: 'api/admin/Planning/GetListPlanning',
  reportClassifiedSumary: 'api/admin/Report/ClassifiedSumary',
  ClassifiedWithRealEstateType: 'api/admin/Report/ClassifiedWithRealEstateType',

  //Log
  Log_GetListAll: '/api/admin/Log/GetListAll',
  Log_Delete: (id) => `/api/admin/Log/Delete?${id}`,
  Log_Detail: (id) => `/api/admin/Log/GetById?id=${id}`,

  //---invoice
  Invoice_GetlistAll: 'api/admin/Invoice/GetListAll',
  Invoice_Update: 'api/admin/Invoice/UpdateInvoice',
  Invoice_GetDetail: (id) => `api/admin/Invoice/GetDetail?invoiceId=${id}`,
  Invoice_Delete: (id) => `api/admin/Invoice/Delete?invoiceId=${id}`,
  GetLookupInvoiceStatus: 'api/cms/Payment/get-look-up-invoice-status',
  GetLookupPaymentStatus: 'api/cms/Payment/get-look-up-payment_status',

  //---Edition
  Edition_GetlistAll: 'api/admin/Edition/GetList',
  Edition_Create: 'api/admin/Edition/Create',
  Edition_Update: 'api/admin/Edition/Update',
  Edition_GetDetail: (id) => `api/admin/Edition/GetDetail?id=${id}`,
  Edition_Delete: `api/admin/Edition/Delete`,

  //---Scene
  Scene_GetlistAll: 'api/admin/Scene/GetListAll',
  Scene_Create: 'api/admin/Scene/Create',
  Scene_Update: 'api/admin/Scene/Update',
  Scene_GetDetail: (id) => `api/admin/Scene/GetDetail?id=${id}`,
  Scene_Delete: (id) => `api/admin/Scene/Delete?id=${id}`,

  //---ConnectionScene
  ConnectionScene_GetlistAll: 'api/admin/ConnectionScene/GetListAll',
  ConnectionScene_Create: 'api/admin/ConnectionScene/Create',
  ConnectionScene_Update: 'api/admin/ConnectionScene/Update',
  ConnectionScene_GetDetail: (id) => `api/admin/ConnectionScene/GetDetail?id=${id}`,
  ConnectionScene_Delete: (id) => `api/admin/ConnectionScene/Delete?id=${id}`,

  //---Hotspot
  Hotspot_GetlistAll: 'api/admin/Hotspot/GetListAll',
  Hotspot_Create: 'api/admin/Hotspot/Create',
  Hotspot_Update: 'api/admin/Hotspot/Update',
  Hotspot_GetDetail: (id) => `api/admin/Hotspot/GetDetail?id=${id}`,
  Hotspot_Delete: (id) => `api/admin/Hotspot/Delete?id=${id}`,
  Hotspot_Active: (id) => `api/admin/Hotspot/Active?id=${id}`,
  Hotspot_DeActive: (id) => `api/admin/Hotspot/DeActive?id=${id}`,

  //---VrProject
  VRProject_GetLookup: 'api/cms/VRProject/GetLookup',
  VrProject_GetlistAll: 'api/admin/VRProject/GetListAll',
  VrProject_Create: 'api/admin/VRProject/Create',
  VrProject_Update: 'api/admin/VRProject/Update',
  VrProject_GetDetail: (id) => `api/admin/VRProject/GetDetail?id=${id}`,
  VrProject_Delete: (id) => `api/admin/VRProject/Delete?id=${id}`,

};
