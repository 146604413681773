import React, { useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { TextField, makeStyles } from "@material-ui/core";
import "suneditor/dist/css/suneditor.min.css";
import { UploadFile } from "../../components/upload-file/upload-file.view";
import { useAddOrEditMobileAppIntroduceDialog } from "./useAddOrEditMobileAppIntroduceDialog";
import { Autocomplete } from "@material-ui/lab";
import { AddOrEditDialog } from "../../components/addOrEditDialog/add-or-edit-dialog.view";

const useStyles = makeStyles((theme) => ({
  fullHeight: {
    height: "100%",
  },
}));

const LINK_PATTERN =
  // eslint-disable-next-line no-useless-escape
  /(ftp|http|https):\/\/(\w+:{0,1}\w*@)?(\S+)(:[0-9]+)?(\/|\/([\w#!:.?+=&%@!\-\/]))?/;

export const AddOrEditMobileAppDialog = (props) => {
  const { isOpen, onClose, onSuccess, data, languageOptions } = props;
  const classes = useStyles();

  const [files, setFiles] = useState(null);
  const [androidFiles, setAndroidFiles] = useState(null);
  const [iosFiles, setIOSFiles] = useState(null);

  const { onSubmit } = useAddOrEditMobileAppIntroduceDialog({
    data,
    onSuccess,
  });

  const { register, handleSubmit, errors, reset, control } = useForm({
    mode: "all",
    reValidateMode: "onBlur",
  });

  const onSubmitForm = (data) => {
    if (Object.keys(errors).length) return;
    onSubmit(data);
  };

  const handleExited = () => {
    setFiles(null);
    setAndroidFiles(null);
    setIOSFiles(null);
    reset();
  };

  return (
    <AddOrEditDialog
      title={
        data
          ? "Chỉnh sửa thông tin giới thiệu mobile app"
          : "Thêm thông tin giới thiệu mobile app"
      }
      isOpen={isOpen}
      onExited={handleExited}
      onClose={onClose}
      onSubmit={handleSubmit(onSubmitForm)}
    >
      <form>
        <div className="form-group">
          <div className="row">
            <div className="col-12">
              <label className="text-dark">
                Ngôn ngữ<span className="required"></span>
              </label>
              <Controller
                render={(props) => (
                  <Autocomplete
                    {...props}
                    disabled={!!data}
                    options={Object.keys(languageOptions)}
                    getOptionLabel={(option) => languageOptions[option]}
                    disableClearable
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        value={params.value}
                        error={!!errors["langId"]}
                        fullWidth
                        variant="outlined"
                        helperText={
                          <span className="error">
                            {errors["langId"]?.message || null}
                          </span>
                        }
                      />
                    )}
                    onChange={(_, data) => props.onChange(data)}
                  />
                )}
                control={control}
                name="langId"
                defaultValue={
                  data ? data.langId : Object.keys(languageOptions)[0]
                }
                rules={{ required: "Trường này là bắt buộc" }}
              />
            </div>
          </div>
        </div>
        <div className="form-group">
          <div className="row">
            <div className="col-12">
              <label className="text-dark">
                Tiêu đề giới thiệu<span className="required"></span>
              </label>
              <TextField
                name="title"
                error={!!errors["title"]}
                defaultValue={data ? data.title : ""}
                fullWidth
                type="text"
                className="form-control"
                inputRef={register({
                  required: {
                    value: true,
                    message: "Trường này là bắt buộc",
                  },
                })}
                helperText={
                  <span className="error">
                    {errors["title"]?.message || null}
                  </span>
                }
              />
            </div>
          </div>
        </div>
        <div className="form-group">
          <div className="row">
            <div className="col-12">
              <label className="text-dark">
                Nội dung giới thiệu<span className="required"></span>
              </label>
              <TextField
                name="content"
                error={!!errors["content"]}
                defaultValue={data ? data.content : ""}
                fullWidth
                multiline
                rows={3}
                variant="outlined"
                type="text"
                className={`form-control ${classes.fullHeight}`}
                inputRef={register({
                  required: {
                    value: true,
                    message: "Trường này là bắt buộc",
                  },
                })}
                helperText={
                  <span className="error">
                    {errors["content"]?.message || null}
                  </span>
                }
              />
            </div>
          </div>
        </div>

        <div className="form-group">
          <div className="row">
            <div className="col-12">
              <label className="text-dark">
                Link android app<span className="required"></span>
              </label>
              <TextField
                name="androidLink"
                error={!!errors["androidLink"]}
                defaultValue={data ? data.androidLink : ""}
                fullWidth
                type="text"
                className="form-control"
                inputRef={register({
                  required: {
                    value: true,
                    message: "Trường này là bắt buộc",
                  },
                  pattern: {
                    value: LINK_PATTERN,
                    message: "Link không đúng định dạng",
                  },
                })}
                helperText={
                  <span className="error">
                    {errors["androidLink"]?.message || null}
                  </span>
                }
              />
            </div>
          </div>
        </div>

        <div className="form-group">
          <div className="row">
            <div className="col-12">
              <label className="text-dark">
                Link ios app<span className="required"></span>
              </label>
              <TextField
                name="iosLink"
                error={!!errors["iosLink"]}
                defaultValue={data ? data.iosLink : ""}
                fullWidth
                type="text"
                className="form-control"
                inputRef={register({
                  required: {
                    value: true,
                    message: "Trường này là bắt buộc",
                  },
                  pattern: {
                    value: LINK_PATTERN,
                    message: "Link không đúng định dạng",
                  },
                })}
                helperText={
                  <span className="error">
                    {errors["iosLink"]?.message || null}
                  </span>
                }
              />
            </div>
          </div>
        </div>

        <div className="form-group">
          <div className="row">
            <div className="col-12 col-md-4 col-lg-4">
              <UploadFile
                title="Ảnh giới thiệu"
                files={files}
                onSetFiles={setFiles}
                defaultValue={data ? data.imageFile : null}
              />
              <TextField
                inputRef={register({
                  required: {
                    value: true,
                    message: "Trường này là bắt buộc",
                  },
                })}
                type="hidden"
                name="imageId"
                value={
                  files?.length > 0 ? files[0].fileId : data ? data.imageId : ""
                }
              />
              <p className="error">{errors["imageId"]?.message || null}</p>
            </div>
            <div className="col-12 col-md-4 col-lg-4">
              <UploadFile
                title="Ảnh andoird app"
                files={androidFiles}
                onSetFiles={setAndroidFiles}
                defaultValue={data ? data.androidImageFile : null}
              />
              <TextField
                inputRef={register({
                  required: {
                    value: true,
                    message: "Trường này là bắt buộc",
                  },
                })}
                type="hidden"
                name="androidImageId"
                value={
                  androidFiles?.length > 0
                    ? androidFiles[0].fileId
                    : data
                    ? data.androidImageId
                    : ""
                }
              />
              <p className="error">
                {errors["androidImageId"]?.message || null}
              </p>
            </div>
            <div className="col-12 col-md-4 col-lg-4">
              <UploadFile
                title="Ảnh ios app"
                files={iosFiles}
                onSetFiles={setIOSFiles}
                defaultValue={data ? data.iosImageFile : null}
              />
              <TextField
                inputRef={register({
                  required: {
                    value: true,
                    message: "Trường này là bắt buộc",
                  },
                })}
                type="hidden"
                name="iosImageId"
                value={
                  iosFiles?.length > 0
                    ? iosFiles[0].fileId
                    : data
                    ? data.androidImageId
                    : ""
                }
              />
              <p className="error">{errors["iosImageId"]?.message || null}</p>
            </div>
          </div>
        </div>
      </form>
    </AddOrEditDialog>
  );
};
