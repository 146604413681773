import {Button, TextField} from '@material-ui/core';
import React, {useEffect} from 'react';
import {useFormContext} from 'react-hook-form';
import { APIUrlDefault } from '../../../utils/configuration';
import { DropzoneArea } from 'material-ui-dropzone';

const ProjectImage = ({
  isShow = false,
  files = [],
    fileUpload=[],
    onOpenSelectFile = () => { },
  setFileUpload = () => {}
}) => {
  const {register, errors, clearErrors} = useFormContext();

  useEffect(() => {
    //if (files && files.length > 0) {
    if (fileUpload && fileUpload.length > 0) {
      clearErrors('imageId');
    }
  }, [fileUpload, clearErrors]);

  return (
    <>
          <div className="form-group row">
              <div className="col-12">
                  <label className="text-dark">
                      Ảnh đại diện<span className="required"></span>
                  </label>
                  {!isShow &&
                      files &&
                      files.length > 0 &&
                      files.map(item => (
                          <div key={item.fileName} style={{ width: '150px' }}>
                              <img
                                  src={APIUrlDefault + item.filePreview}
                                  alt={item.fileName}
                                  className="img-fluid mb-2"
                                  style={{
                                      width: 'auto',
                                      height: 'auto',
                                      maxWidth: '100%',
                                      maxHeight: '100%'
                                  }}
                              />
                          </div>
                      ))}
                  <div>
                      {
          //                <Button
          //                    variant="contained"
          //                    color="primary"
          //                    onClick={onOpenSelectFile}
          //                >
          //                    Chọn file
          //</Button>
                      }
                      <TextField
                          inputRef={register({ required: true })}
                          type="hidden"
                          name="imageId"
                          error={
                              errors.imageId &&
                              errors.imageId.type === "required"
                          }
                          value={(fileUpload && fileUpload.length && fileUpload[0]?.name) || (files && files.length>0 && files[0].fileName) || ''}
                      />
                      {errors.imageId && errors.imageId.type === 'required' && (
                          <p className="error">Trường này là bắt buộc</p>
                      )}
                  </div>
              </div>
          </div>
              <DropzoneArea
                  acceptedFiles={['image/png, image/gif, image/jpeg, image/jpg']}
                  filesLimit={1}
                  dropzoneText="Kéo thả ảnh đại diện vào đây"
                  onChange={(files) => {
                      setFileUpload(files);
                  }}
              />
    </>
  );
};

export default ProjectImage;
