import React from 'react';
import {
  Checkbox
} from '@material-ui/core';
import { Controller, useFormContext } from 'react-hook-form';
import './styles.scss';

const FormCheckbox = ({
  title,
  name,
  validate,
  placeholder = 'Chọn...',
  type,
  className = 'p-0 mt-0 ml-4',
  titleClassName = 'text-dark',
  InputProps = { 'aria-label': 'secondary checkbox' },
  color='primary',
  ...rest
}) => {
  const { control, errors, setValue } = useFormContext();
  return (
    <>
      <Controller
        control={control}
        name={name}
        rules={validate}
        placeholder={placeholder}
        render={({ name, ref, value }) => (
          <>
            <label className={titleClassName}>
              {title}{validate?.required ? <span className='required'></span> : null}
            </label>
            <Checkbox
              checked={value}
              name={name}
              ref={ref}
              onChange={(event) => {
                event.persist();
                setValue(name, event.target.checked);
              }}
              color={color}
              inputProps={InputProps}
              className={className}
              {...rest}
            />
            {validate && errors[name] ? (
              <span className='error'>{errors[name] && errors[name].message}</span>
            ) : null}
          </>
        )
        }
      />
    </>
  );
};

export default FormCheckbox;
