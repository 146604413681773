import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as config from "../../../common/config";
import * as appActions from "../../../core/app.store";
import * as sceneAction from "../../../redux/store/vr360/vr360.store";
//---mui
import AddCircle from "@material-ui/icons/AddCircle";
import {
    Button,
    TextField,
    IconButton,
    Tooltip,
    Popover,
    Select,
    MenuItem,
    Fab,
} from "@material-ui/core";
import ShowNotification from '../../../components/react-notifications/react-notifications';
import { NotificationMessageType, } from '../../../utils/configuration';
import * as viVN from '../../../language/vi-VN.json';
import { useLocation } from 'react-router-dom';

import ListEdition from "./list-edition/list-edition.view";
import AddEdition from "./add-edition/add-edition.view";
import EditEdition from "./edit-edition/edit-edition.view";
import VrFilter from "../../../components/vr-filter/vr-filter";

const ProjectManagement = (props) => {
    const { showLoading } = props;
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);

    const pageIndex = 1;
    const pageSize = 10;
    const [editionModel, setEditionModel] = useState([]);
    const [order, setOrder] = useState("desc");
    const [orderBy, setOrderBy] = useState("createdDate");

    const [openAddDialog, setOpenAddDialog] = useState(false);
    const [openEditDialog, setOpenEditDialog] = useState(false);
    const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
    const [itemSelected, setItemSelected] = useState();
    const [pagination, setPagination] = useState({});

    useEffect(() => {
        let params = getParamsFromQuery();
        GetListAll(params);
    }, [location.search])

    const refreshData = () => {
        let params = getParamsFromQuery();
        GetListAll(params);
    }

    const getParamsFromQuery = () => {
        return {
            keyword: queryParams.get("keyword"),
            pageSize: queryParams.get("pageSize") || pageSize,
            pageIndex: queryParams.get("pageIndex") || pageIndex,
            status: queryParams.get("status"),
            sorting: queryParams.get("sorting"),
        }
    }

    const GetListAll = (params) => {
        showLoading(true);
        sceneAction.VrProjectGetListAll(params).then(res => {
            if (res && res.content) {
                const { items, pageCount, pageIndex, pageSize, totalItemCount } = res.content || {};
                setEditionModel(items);
                setPagination({ ...pagination, pageCount, pageIndex, pageSize, totalItemCount });
            }
            showLoading(false);
        }).catch(err => {
            showLoading(false);
            console.log(err);
        })
    }

    const handleDelete = (id) => {
        sceneAction.VrProjectDelete(id).then(res => {
            if (res && res.content) {
                console.log('res', res)
                refreshData();
                ShowNotification(
                    'Xóa thành công',
                    NotificationMessageType.Success
                );
            }
        }).catch(err => {
            console.log(err)
        })
    }

    const handleEdit = (data) => {
        setOpenEditDialog(true);
        setItemSelected(data)
    }

    return (
        <div className="slider">
            <div className="d-sm-flex align-items-center justify-content-between mb-3">
                <h1 className="h3 mb-0 text-gray-800">
                    Quản lý Dự án vr360
                </h1>
                <Button
                    variant="contained"
                    color="primary"
                    onClick={() => setOpenAddDialog(true)}
                    startIcon={<AddCircle />}
                >
                    Thêm mới
                </Button>
            </div>
            <div>
                <VrFilter />
            </div>
            {editionModel && (
                <div>
                    <ListEdition
                        listModel={editionModel}
                        deleteAction={handleDelete}
                        editAction={handleEdit}
                        pagination={pagination}
                    />
                </div>
            )}
            {openAddDialog && (
                <AddEdition
                    isOpen={openAddDialog}
                    onClose={() => setOpenAddDialog(false)}
                    GetListAll={refreshData}
                />
            )}
            {openEditDialog && (
                <EditEdition
                    item={itemSelected}
                    isOpen={openEditDialog}
                    onClose={() => setOpenEditDialog(false)}
                    GetListAll={refreshData}
                />
            )}
        </div>
    )
}

const mapStateToProps = (state) => ({
    isLoading: state.app.loading,
});

const mapDispatchToProps = (dispatch) =>
    bindActionCreators(
        {
            showLoading: appActions.ShowLoading,
        },
        dispatch
    );

export default connect(mapStateToProps, mapDispatchToProps)(ProjectManagement);