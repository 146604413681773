import React, { useEffect, useState } from "react";
import {
  IconButton,
  makeStyles,
  Modal,
  Paper,
  TextField,
} from "@material-ui/core";
import SearchIcon from "@material-ui/icons/Search";
import "./styles.scss";
import { Controller, useFormContext } from "react-hook-form";
import MapGoogle from "../google-map/MapGoogle";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    alignItems: "center",
    boxShadow: "none",
  },
  input: {
    marginLeft: theme.spacing(1),
    flex: 1,
  },
  iconButton: {
    padding: 10,
  },
  divider: {
    height: 28,
    margin: 4,
  },
  modal: {
    padding: 50,
  },
}));

const FormInputCoordinate = ({
  title,
  name,
  validate,
  placeholder = "Chọn...",
  type,
  className = "form-control",
  titleClassName = "text-dark",
  InputProps = {
    readOnly: true,
  },
  ...rest
}) => {
  const classes = useStyles();
  const { control, errors, setValue } = useFormContext();
  const [open, setOpen] = useState(false);
  const [choosePlace, setChoosePlace] = useState(null);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setTimeout(() => {
      setOpen(false);
    }, 500);
  };

  useEffect(() => {
    if (choosePlace) {
      setValue(
        name,
        `${choosePlace.geometry.location.lng()}, ${choosePlace.geometry.location.lat()}`
      );
    }
  }, [choosePlace]);

  const getCurrentPosition = (position) => {
    if (!position) return null;
    const pos = position.split(", ");
    return {
      lng: parseFloat(pos.shift()),
      lat: parseFloat(pos.pop()),
    };
  };

  return (
    <>
      <Controller
        control={control}
        name={name}
        rules={validate}
        placeholder={placeholder}
        render={({ onChange, name, onBlur, ref, value }) => (
          <>
            <label className={titleClassName}>
              {title}
              {validate?.required ? <span className="required"></span> : null}
            </label>
            <Paper component="form" className={classes.root}>
              <TextField
                type={type}
                onChange={(e) => onChange(e)}
                placeholder={placeholder}
                value={value}
                ref={ref}
                onBlur={onBlur}
                name={name}
                fullWidth
                className={className}
                InputProps={InputProps}
                {...rest}
              />
              <IconButton
                type="button"
                className={classes.iconButton}
                onClick={handleOpen}
              >
                <SearchIcon />
              </IconButton>
            </Paper>
            {validate && errors[name] ? (
              <span className="error">
                {errors[name] && errors[name].message}
              </span>
            ) : null}
            <Modal
              open={open}
              onClose={handleClose}
              aria-labelledby="simple-modal-title"
              aria-describedby="simple-modal-description"
              className={classes.modal}
            >
              <MapGoogle
                setChoosePlace={setChoosePlace}
                isHideAfterClickMap={handleClose}
                currentPosition={getCurrentPosition(value)}
                containerStyle={{
                  width: "100%",
                  height: "600px",
                }}
              />
            </Modal>
          </>
        )}
      />
    </>
  );
};

export default FormInputCoordinate;
