import React, { useState } from "react";

import {
  Fab,
  IconButton,
  Tooltip,
  Popover,
  FormControlLabel,
  Switch,
  ButtonGroup,
  Button,
} from "@material-ui/core";

import {
  Search,
  Refresh,
  Close,
  ClearAll,
  DeleteForever,
  AddCircle,
} from "@material-ui/icons";
import { useForm } from "react-hook-form";
import { RenderElement } from "./render-element";

export const PageHeaderActions = (props) => {
  const {
    title,
    labelAddButton,
    onClickClearTrash,
    onClickAdd,
    popoverElements = [],
    onChangeRequest,
    onSubmit,
    onRefresh,
    showTrash,
  } = props;

  const { handleSubmit, register, reset } = useForm();

  const [filterSection, setFilterSection] = useState(null);

  const openFilter = Boolean(filterSection);
  const idFilter = openFilter ? "popoverSlider" : undefined;

  const handleClickFilter = (event) => {
    setFilterSection(event.currentTarget);
  };

  const handleChangeTrash = (event) => {
    const { checked } = event.target;
    onChangeRequest("isDelete", checked);
  };

  const handleCloseFilter = () => {
    setFilterSection(null);
  };

  const handleClearAllField = () => {
    reset();
    onRefresh();
  };

  return (
    <div className="d-sm-flex align-items-center justify-content-between mb-3">
      <h1 className="h3 mb-0 text-gray-800">
        {title}
        {onSubmit && onRefresh ? (
          <>
            <Tooltip title="Tìm kiếm">
              <Fab
                color="primary"
                aria-label="filter"
                size="small"
                className="ml-2"
                onClick={handleClickFilter}
                aria-describedby={idFilter}
              >
                <Search />
              </Fab>
            </Tooltip>
            <Tooltip title="Làm mới">
              <Fab
                color="primary"
                aria-label="filter"
                size="small"
                onClick={handleClearAllField}
                className="ml-2"
              >
                <Refresh />
              </Fab>
            </Tooltip>
          </>
        ) : null}
        <span>&nbsp;&nbsp;</span>
        <FormControlLabel
          control={
            <Switch
              checked={showTrash}
              onChange={handleChangeTrash}
              name="checkedB"
              color="primary"
            />
          }
          label="Thùng rác"
        />
        <Popover
          id={idFilter}
          open={openFilter}
          anchorEl={filterSection}
          style={{ backgroundColor: "rgba(0, 0, 0, 0.4)" }}
          onClose={handleCloseFilter}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "left",
          }}
        >
          <div className="p-3" style={{ width: "30rem" }}>
            <div className="text-right border-bottom mb-3 pb-2">
              <IconButton
                aria-label="close"
                size="small"
                onClick={handleCloseFilter}
              >
                <Close fontSize="small" />
              </IconButton>
            </div>
            <form
              onSubmit={handleSubmit((data) => {
                onSubmit(data);
                handleCloseFilter();
              })}
            >
              {popoverElements.length &&
                popoverElements.map((item, index) => (
                  <div key={index} className="form-group">
                    <label className="text-dark">{item.label}</label>
                    <RenderElement
                      type={item.type}
                      className="w-100"
                      name={item.name}
                      defaultValue={item.defaultValue}
                      inputRef={register}
                    />
                  </div>
                ))}
              <div className="border-top">
                <div className="row">
                  <div className="col-12 text-right mt-3">
                    <Button variant="contained" color="primary" type="submit">
                      <Search fontSize="small" /> Tìm kiếm
                    </Button>
                    <Button
                      variant="contained"
                      className="ml-2"
                      onClick={() => {
                        handleClearAllField();
                        handleCloseFilter();
                      }}
                    >
                      <ClearAll fontSize="small" /> Bỏ lọc
                    </Button>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </Popover>
      </h1>
      <ButtonGroup
        variant="contained"
        color="primary"
        aria-label="contained primary button group"
      >
        {showTrash && onClickClearTrash ? (
          <Button
            variant="contained"
            color="secondary"
            onClick={onClickClearTrash}
            startIcon={<DeleteForever />}
          >
            Xóa thùng rác
          </Button>
        ) : null}
        {!showTrash && onClickAdd ? (
          <Button
            variant="contained"
            color="primary"
            onClick={onClickAdd}
            startIcon={<AddCircle />}
          >
            {labelAddButton}
          </Button>
        ) : null}
      </ButtonGroup>
    </div>
  );
};
