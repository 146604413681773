import React from "react";

import * as metaTagAction from "../../redux/store/meta-tag/metaTag.store";

import { PageHeaderActions } from "../../components/pageHeaderActions/page-header-actions.view";
import * as config from "../../common/config";
import ListMetaTags from "./list-meta-tag.view";
import DeleteDialog from "../../components/dialog-delete/dialog-delete.view";
import { useAddOrEditMetaTagDialog } from "./useAddOrEditMetaTagDialog";
import { useScreenActions } from "../../common/useScreenActions";
import { useHistory } from "react-router-dom";
import { UrlCollection } from "../../common/url-collection";
import { useDispatch } from "react-redux";
import * as appActions from "../../core/app.store";
const initialRequestParams = {
  Keyword: null,
  isDelete: false,
  Sorting: config.Configs.DefaultSortExpression,
  PageIndex: 0,
  PageSize: config.Configs.DefaultPageSize,
};

export const MetaTag = () => {
  const history = useHistory();
  const {
    requestParams,
    datas,
    data,
    openAddOrEditDialog,
    dataWillDelete,
    setDataWillDelete,
    setOpenAddOrEditDialog,
    setDatas,
    handleChangeRequestParams,
    handleAddOrEditSuccess,
    onDeleteSuccess,
  } = useScreenActions({
    initialRequestParams,
    getDatas: (req) => getMetaTags(req),
  });
  const dispatch = useDispatch();
  const { onDeleteMetaTag, onClearTrash, onDeletePermanant } =
    useAddOrEditMetaTagDialog({
      onSuccess: onDeleteSuccess,
    });

  const getMetaTags = async (params) => {
    dispatch(appActions.ShowLoading(true))
    const res = await metaTagAction.GetListAll({
      ...params,
      PageIndex: params.PageIndex + 1,
    });
    if (!!res.content) {
      dispatch(appActions.ShowLoading(false))
      setDatas(res.content);
    }
  };
  const onClickAdd = () => {
    const param = getParamSearch()
    history.push(UrlCollection.AddMetaTag + param)
  }
  const onClickEdit = (items) => {
    const param = getParamSearch()
    history.push(UrlCollection.EditMetaTag + `${items.id}` + param)
  }
  const getParamSearch = () => {
    let param = `?PageSize=${requestParams.PageSize}`;
    if (requestParams?.PageIndex) {
      param = param + `&PageIndex=${requestParams?.PageIndex}`;
    }
    if (requestParams?.Keyword) {
      param = param + `&Keyword=${requestParams?.Keyword}`;
    }
    if (requestParams?.Sorting) {
      const sorting = requestParams.Sorting.split(" ");
      param = param + `&order=${sorting.pop()}`;
      param = param + `&orderBy=${sorting.shift()}`;
    }
    return param;
  };
  return (
    <div className="slider">
      <PageHeaderActions
        title="Meta tag"
        labelAddButton="Add meta tag"
        onClickClearTrash={onClearTrash}
        onClickAdd={onClickAdd}
        onChangeRequest={handleChangeRequestParams}
        showTrash={requestParams.isDelete}
        showSearchAndRefreshActions={false}
      />
      <ListMetaTags
        metaTags={datas}
        requestParams={requestParams}
        onChangeRequest={handleChangeRequestParams}
        onClickEdit={onClickEdit}
        onClickDelete={(data) => setDataWillDelete(data)}
      />
      <DeleteDialog
        isOpen={!!dataWillDelete?.id}
        onClose={() => setDataWillDelete(null)}
        onSuccess={() =>
          dataWillDelete.isDelete
            ? onDeletePermanant(dataWillDelete.id)
            : onDeleteMetaTag(dataWillDelete.id)
        }
        header={"Xác nhận xóa"}
        content={"Bạn có chắc chắn muốn xóa?"}
      />
    </div>
  );
};
