import Service from '../../../api/api-service';
import { ApiUrl } from '../../../api/api-url';
import * as config from '../../../common/config';

const service = new Service();

export const GetListAll = (
  keyword,
  langId,
  sortExpression = 'modifiedDate desc',
  pageIndex = 1,
  pageSize = config.Configs.DefaultPageSize,
  isDelete = false,
  isActive,
  isHot,
  isHomePage,
  user
) => {
  const params = new URLSearchParams();
  //params.append('categoryId', categoryId);
  if (keyword) {
    params.append('keyword', keyword);
  }

  params.append('isDelete', isDelete);

  if (isActive) {
    params.append('isActive', isActive);
  }
  if (isHot) {
    params.append('isHot', isHot);
  }
  if (isHomePage) {
    params.append('isHomePage', isHomePage);
  }
    if (user) {
        params.append('createdBy', user);
    }
  params.append('langId', langId);
  params.append('pageIndex', pageIndex);
  params.append('pageSize', pageSize);
  params.append('sorting', sortExpression);

  return service
    .get(ApiUrl.Classified_GetlistAll, params)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      throw err;
    });
};

export const ClassifiedCreate = (body) => {
  return service
    .post(ApiUrl.Classified_Create, body)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      throw err;
    });
};

export const ClassifiedCreateWithUpload = (body) => {
    return service
        .post(ApiUrl.Classified_CreateWithUpload, body)
        .then((res) => {
            return res;
        })
        .catch((err) => {
            throw err;
        });
};

export const ClassifiedUpdate = (body) => {
  return service
    .post(ApiUrl.Classified_Update, body)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      throw err;
    });
};

export const ClassifiedUpdateWithUpload = (body) => {
    return service
        .post(ApiUrl.Classified_UpdateWithUpload, body)
        .then((res) => {
            return res;
        })
        .catch((err) => {
            throw err;
        });
};

export const ClassifiedDelete = (id) => {
  return service
    .post(ApiUrl.Classified_Delete(id))
    .then((res) => {
      return res;
    })
    .catch((err) => {
      throw err;
    });
};

export const ClassifiedActive = (id) => {
    return service
        .post(ApiUrl.Classified_Active(id))
        .then((res) => {
            return res;
        })
        .catch((err) => {
            throw err;
        });
};

export const ClassifiedDeActive = (id) => {
    return service
        .post(ApiUrl.Classified_DeActive(id))
        .then((res) => {
            return res;
        })
        .catch((err) => {
            throw err;
        });
};

export const ClassifiedShowOnWeb = (id) => {
    return service
        .post(ApiUrl.Classified_ShowOnWeb(id))
        .then((res) => {
            return res;
        })
        .catch((err) => {
            throw err;
        });
};

export const ClassifiedSetOnSale = (id) => {
    return service
        .post(ApiUrl.Classified_SetOnSale(id))
        .then((res) => {
            return res;
        })
        .catch((err) => {
            throw err;
        });
};

export const ClassifiedSetSoldOut = (id) => {
    return service
        .post(ApiUrl.Classified_SetSoldOut(id))
        .then((res) => {
            return res;
        })
        .catch((err) => {
            throw err;
        });
};

export const ClassifiedHideOnWeb = (id) => {
    return service
        .post(ApiUrl.Classified_HideOnWeb(id))
        .then((res) => {
            return res;
        })
        .catch((err) => {
            throw err;
        });
};

export const ClassifiedChangeRealEstateType = (id, realEstateTypeId) => {
    return service
        .post(ApiUrl.Classified_ChangeRealEstateType(id, realEstateTypeId))
        .then((res) => {
            return res;
        })
        .catch((err) => {
            throw err;
        });
};


export const EmptyTrash = () => {
  return service
    .post(ApiUrl.Classified_EmptyTrash)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      throw err;
    });
};

export const ClassifiedDetail = (id) => {
  return service
    .get(ApiUrl.Classified_GetDetail(id))
    .then((res) => {
      return res;
    })
    .catch((err) => {
      throw err;
    });
};
