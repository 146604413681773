/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import ConfirmModalView from '../../../components/confirm-modal/confirm-modal';

//--- Action
import 'suneditor/dist/css/suneditor.min.css';

//--- Material Control
import {
  DialogActions,
  Button,
  DialogTitle,
  Dialog,
  makeStyles,
  Typography,
  IconButton,
} from '@material-ui/core';

//--- Material Icon
import CloseIcon from '@material-ui/icons/Close';
import SaveIcon from '@material-ui/icons/Save';
import RefreshIcon from '@material-ui/icons/Refresh';

import AddressFormItems from '../../../components/FormItem/FormInputAddress/components/components/FormItems';
import AddUpdateMethods from '../components/AddUpdateMethods';

//--- Styles
import '../styles.scss';

const useStyles = makeStyles((theme) => ({
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
}));

export default function EditAddress(props) {
  const [choosePlace, setChoosePlace] = useState(null);
  const [addressModel, setAddressModel] = useState(null);
  const [currentPosition, setCurrentPosition] = useState(null);
  const classes = useStyles();

  const {
    isOpen,
    onClose,
    onSuccess,
    setRequestParams,
    addressId,
    provinceSelect,
    langId,
  } = props;

  const methods = useForm({
    defaultValues: {
      commune: null,
      latitude: '',
      longitude: '',
      street: '',
      generalInfor: '',
      room: '',
      city: '',
      township: '',
      province: null,
      district: null,
    },
  });

  const { handleSubmit, setValue, watch } = methods;
  const [communes, setCommunes] = useState();
  const [districtByProvinceId, setDistrictByProvinceId] = useState();
  const [openConfirm, setOpenConfirm] = useState(false);

  const { onSubmit, handleAccept, refresh } = AddUpdateMethods({
    langId,
    addressId,
    onSuccess,
    setValue,
    watch,
    setCommunes,
    setDistrictByProvinceId,
    choosePlace,
    setRequestParams,
    provinceSelect,
    setAddressModel,
    setCurrentPosition,
    setOpenConfirm,
    openConfirm,
  });

  return (
    <div>
      <Dialog open={isOpen} onClose={onClose} fullWidth={true} maxWidth='lg'>
        <DialogTitle disableTypography className='border-bottom'>
          <Typography variant='h6'>Chỉnh sửa địa chỉ</Typography>
          <IconButton
            aria-label='close'
            className={classes.closeButton}
            onClick={onClose}>
            <CloseIcon />
          </IconButton>
        </DialogTitle>

        <FormProvider {...methods}>
          <form onSubmit={handleSubmit(onSubmit)} autoComplete='off'>
            {addressModel && currentPosition && (
              <AddressFormItems
                provinceSelect={provinceSelect}
                districtByProvinceId={districtByProvinceId}
                communes={communes}
                setChoosePlace={setChoosePlace}
                currentPosition={currentPosition}
              />
            )}

            <DialogActions className='border-top'>
              <Button
                type='button'
                onClick={refresh}
                variant='contained'
                color='primary'
                startIcon={<RefreshIcon />}>
                Refresh
              </Button>
              <Button
                type='button'
                onClick={onClose}
                variant='contained'
                startIcon={<CloseIcon />}>
                Hủy
              </Button>
              <Button
                type='submit'
                color='primary'
                variant='contained'
                startIcon={<SaveIcon />}>
                Lưu
              </Button>
            </DialogActions>
          </form>
        </FormProvider>
      </Dialog>
      <ConfirmModalView
        open={openConfirm}
        content='Thao tác dưới đây cập nhật dữ liệu chọn trên bản đồ vào ô thông tin. Tiếp tục?'
        handleClose={() => setOpenConfirm(false)}
        title='Cập nhật dữ liệu theo bản đồ'
        handleAccept={() => handleAccept()}
      />
    </div>
  );
}
