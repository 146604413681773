import React from "react";
import {
  DialogActions,
  Button,
  DialogContent,
  DialogTitle,
  Dialog,
  Typography,
  IconButton,
  makeStyles,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import SaveIcon from "@material-ui/icons/Save";

const useStyles = makeStyles((theme) => ({
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
}));

export const AddOrEditDialog = (props) => {
  const { isOpen, title, children, onExited, onClose, onSubmit } = props;
  const classes = useStyles();

  return (
    <div>
      <Dialog
        disableEscapeKeyDown
        onClose={(_, reason) => {
          if (reason !== "backdropClick") {
            onClose();
          }
        }}
        open={isOpen}
        TransitionProps={{
          onExited: onExited,
        }}
        fullWidth={true}
        maxWidth="lg"
      >
        <DialogTitle disableTypography className="border-bottom">
          <Typography variant="h6">{title}</Typography>
          <IconButton
            aria-label="close"
            className={classes.closeButton}
            onClick={onClose}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent className="pt-4 pb-2">{children}</DialogContent>
        <DialogActions className="border-top">
          <Button
            onClick={onClose}
            variant="contained"
            startIcon={<CloseIcon />}
          >
            Hủy
          </Button>
          <Button
            onClick={onSubmit}
            color="primary"
            variant="contained"
            startIcon={<SaveIcon />}
          >
            Lưu
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};
