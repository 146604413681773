import React, { useState, useEffect } from "react";
import "suneditor/dist/css/suneditor.min.css";
import * as metaTagAction from "../../redux/store/meta-tag/metaTag.store";
import { useSelector } from "react-redux";
import AddOrEditMetaTagDialog from './addOrEditMetaTagDialog'
export default function EditMeta (props) {
  const { idMetatag } = props?.match?.params;
  const { langId } = useSelector((state) => state.language);
  const [metaTag, setMetaTag] = useState();
  useEffect(() => {
    if(idMetatag) {
      getMeta()
    }
  }, []);
  const getMeta = async () => {
    const res = await metaTagAction.DetailMetaTag(langId, idMetatag)
    if (res.content) {
      setMetaTag(res.content)
    }
  };
  

  return metaTag ? (
    <AddOrEditMetaTagDialog {...props} langId={langId} metaTag={metaTag}/>
  ) : null;
};
