import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import { UserSidePathUrl } from '../../../common/config';
// import * as configuration from '../../../utils/configuration';
import dateformat from 'dateformat';
import { FormProvider, useForm } from 'react-hook-form';
//--- Material Table
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Paper from '@material-ui/core/Paper';

import FooterPagination from '../../../components/footer-pagination/footer-pagination';

import {
  APIUrlDefault,
  DomainUserSide,
} from '../../../utils/configuration';
import Autocomplete from '@material-ui/lab/Autocomplete';
import {
  TextField
} from '@material-ui/core';

//--- Material Icon
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import RestoreIcon from '@material-ui/icons/Restore';

// import AssignmentIcon from '@material-ui/icons/Assignment';
// import AddCommentIcon from '@material-ui/icons/AddComment';
//--- Material Control
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  paper: {
    width: '100%',
    marginBottom: theme.spacing(2),
  },
  tableContainer: {
    maxHeight: window.outerHeight - 365,
  },
  table: {
    minWidth: 750,
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
  pointer: { cursor: 'pointer' },
}));

const headCells = [
  { id: 'title', hideSortIcon: true, label: 'Tiêu đề' },
  { id: 'addressDetail', hideSortIcon: false, label: 'Địa chỉ' },
  { id: 'price', hideSortIcon: false, label: 'Giá' },
  { id: 'acreage', hideSortIcon: false, label: 'Diện tích(m2)' },
  { id: 'pricePerMettre', hideSortIcon: false, label: 'Giá/m2' },
  { id: 'realEstateTypeName', hideSortIcon: true, label: 'Loại hình BĐS' },
  { id: 'viewCount', hideSortIcon: false, label: 'Lượt xem' },
  { id: 'createdDate', hideSortIcon: true, label: 'Ngày tạo' },
  { id: 'createdBy', hideSortIcon: true, label: 'Người tạo' },
  { id: 'modifiedDate', hideSortIcon: true, label: 'Ngày sửa' },
  { id: 'modifiedBy', hideSortIcon: true, label: 'Người sửa' },
  { id: 'isSoldOut', hideSortIcon: true, label: 'Đã bán?' },
  { id: 'isActive', hideSortIcon: true, label: 'Kích hoạt?' },
  { id: 'isShowUI', hideSortIcon: true, label: 'Hiện web?' },
  { id: 'actions', hideSortIcon: true, label: '' },
];

function EnhancedTableHead(props) {
  const { classes, order, orderBy, onRequestSort, postTypeId } = props;

  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  const headerCellFilter = headCells.filter((item) => {
    if (postTypeId === '5') {
      return item.id !== 'isHomePage' && item.id !== 'isHot';
    }
    return item;
  });

  return (
    <TableHead>
      <TableRow>
        {headerCellFilter.map((headCell) => (
          <TableCell
            key={headCell.id}
            sortDirection={orderBy === headCell.id ? order : false}
            className={
              'pt-3 pb-3 text-nowrap' +
              (headCell.id === 'planningName' ? 'MuiTableCell-freeze' : '')
            }>
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
              hideSortIcon={headCell.hideSortIcon ? true : false}>
              {headCell.label}
              {orderBy === headCell.id ? (
                <span className={classes.visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </span>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  classes: PropTypes.object.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

export default function ListFAQs(props) {
  const {
    //viewFileAction,
    editAction,
    deleteAction,
    changeStatusAction,
    changeRealEstateType,
    restoreAction,
    optionRealEstate,
    newsModels,
    totalItemCount,
    setOrder,
    setOrderBy,
    setPage,
    setRowsPerPage,
    GetListAll,
    updatePermission,
    //title,
    categoryId,
    //isDelete,
    addAnsAction,
    editAnsAction,
    order,
    page,
    rowsPerPage,
    orderBy,
    postTypeId,
    langId,
  } = props;

  //--- Config table
  const classes = useStyles();
  const { register, handleSubmit, setError, errors, clearErrors, setValue } = useForm();
  const linkToView = DomainUserSide + UserSidePathUrl.landAdvertising;
  //--- Handle sort, change page, change row per page
  const handleRequestSort = (event, property) => {
    if (property !== 'avatar') {
      const isAsc = orderBy === property && order === 'asc';
      setOrder(isAsc ? 'desc' : 'asc');
      setOrderBy(property);
      let sort = isAsc ? 'desc' : 'asc';
      let sortExpression = property + ' ' + sort;

      GetListAll(
        undefined,
        langId,
        sortExpression,
        page + 1,
        rowsPerPage,
        false,
        undefined,
        undefined,
        undefined
      );
    }
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage - 1);
    console.log(newPage);
    let sortExpression = orderBy + ' ' + order;
    GetListAll(
      undefined,
      langId,
      sortExpression,
      newPage,
      rowsPerPage,
      false,
      undefined,
      undefined,
      undefined
    );
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
    let sortExpression = orderBy + ' ' + order;
    GetListAll(
      undefined,
      langId,
      sortExpression,
      1,
      parseInt(event.target.value, 10),
      false,
      undefined,
      undefined,
      undefined
    );
  };

  const onChangeEstate = (classifiedId, e, values) => {
    if (values) {
      changeRealEstateType(classifiedId, values.id);
    }
  };

  const emptyRows =
    rowsPerPage - Math.min(rowsPerPage, newsModels.length - page * rowsPerPage);

  const totalPage = Math.ceil(totalItemCount / rowsPerPage);

  return (
    <div className={classes.root}>
      <Paper className={classes.paper}>
        <TableContainer className={classes.tableContainer}>
          <Table className={classes.table} size='small' stickyHeader>
            <EnhancedTableHead
              classes={classes}
              order={order}
              orderBy={orderBy}
              onRequestSort={handleRequestSort}
              rowCount={newsModels.length}
              postTypeId={postTypeId}
            />
            <TableBody>
              {newsModels && newsModels.length > 0 ? (
                newsModels.map((row, index) => {
                  return (
                    <TableRow hover tabIndex={-1} key={index}>
                      <TableCell className='MuiTableCell-freeze text-truncate'>
                        <a href={linkToView + row.slug + '-' + row.id} target="review" tooltip="Click để xem trước">{row.title}</a>
                      </TableCell>
                      <TableCell className='text-truncate'>
                        {row.addressDetail}
                      </TableCell>
                      <TableCell className='text-center'>
                        {parseInt(row.price || 0, 10).toLocaleString()}
                      </TableCell>
                      <TableCell className='text-center'>
                        {row.acreage}
                      </TableCell>

                      <TableCell>
                        {parseInt(row.pricePerMettre, 10).toLocaleString()}
                      </TableCell>
                      <TableCell className='MuiTableCell-custom text-truncate'>
                        {
                          updatePermission ? (<Autocomplete
                            id='combo-box-demo'
                            options={optionRealEstate}
                            getOptionLabel={(option) => option.name}
                            onChange={(event, value) => onChangeEstate(row.id, event, value)}
                            style={{ width: '100%' }}
                            defaultValue={{
                              id: row.realEstateTypeId,
                              name: row.realEstateTypeName,
                            }}
                            disableClearable={true}
                            renderInput={(params) => (
                              <TextField
                                {...params}

                                variant='outlined'
                                name="realEstateTypeId"
                                inputRef={register({ required: true })}
                                error={
                                  errors.realEstateTypeId &&
                                  errors.realEstateTypeId.type === "required"
                                }
                                size="small"
                              />
                            )}
                          />) : (<span>{row.realEstateTypeName}</span>)
                        }

                        {
                          //row.realEstateTypeName
                        }
                      </TableCell>
                      <TableCell>
                        {row.viewCount || 0}
                      </TableCell>
                      <TableCell>
                        {dateformat(row.createdDate, 'dd/mm/yyyy')}
                      </TableCell>
                      <TableCell>
                        {row.created_by}
                      </TableCell>
                      <TableCell>
                        {dateformat(row.modifiedDate, 'dd/mm/yyyy')}
                      </TableCell>
                      <TableCell>
                        {row.modified_by}
                      </TableCell>
                      <TableCell>
                        {row.isSoldOut ? (
                          updatePermission ? (
                            <Tooltip title='Chuyển TT chưa bán'>
                              <img className={classes.pointer} onClick={() => changeStatusAction(row.id, 5, false)}
                                src={require('../../../assets/icon/tick.png')}
                                alt='Tick'
                              />
                            </Tooltip>
                          ) : (<img
                            src={require('../../../assets/icon/tick.png')}
                            alt='Tick'
                          />)
                        ) : (
                          updatePermission ? (<Tooltip title='Chuyển TT đã bán'>
                            <img className={classes.pointer} onClick={() => changeStatusAction(row.id, 6, false)}
                              src={require('../../../assets/icon/cancel.png')}
                              alt='Cancel'
                            />
                          </Tooltip>) :
                            (<img
                              src={require('../../../assets/icon/cancel.png')}
                              alt='Cancel'
                            />)

                        )}
                      </TableCell>
                      <TableCell>
                        {row.isActive ? (
                          updatePermission ? (
                            <Tooltip>
                              <img className={classes.pointer} onClick={() => changeStatusAction(row.id, 1, false)}
                                src={require('../../../assets/icon/tick.png')}
                                alt='Tick'
                              />
                            </Tooltip>
                          ) : (<img
                            src={require('../../../assets/icon/tick.png')}
                            alt='Tick'
                          />)
                        ) : (
                          updatePermission ? (<Tooltip title='Click để kích hoạt'>
                            <img className={classes.pointer} onClick={() => changeStatusAction(row.id, 2, false)}
                              src={require('../../../assets/icon/cancel.png')}
                              alt='Cancel'
                            />
                          </Tooltip>) :
                            (<img
                              src={require('../../../assets/icon/cancel.png')}
                              alt='Cancel'
                            />)

                        )}
                      </TableCell>
                      <TableCell>
                        {row.isShowUI ? (
                          updatePermission ? (<Tooltip title='Click để ẩn'>
                            <img className={classes.pointer} onClick={() => changeStatusAction(row.id, 3, false)}
                              src={require('../../../assets/icon/tick.png')}
                              alt='Tick'
                            />
                          </Tooltip>) : (<img
                            src={require('../../../assets/icon/tick.png')}
                            alt='Tick'
                          />)


                        ) : (
                          updatePermission ? (
                            <Tooltip title='Click cho hiện web'>
                              <img className={classes.pointer} onClick={() => changeStatusAction(row.id, 4, false)}
                                src={require('../../../assets/icon/cancel.png')}
                                alt='Cancel'
                              />
                            </Tooltip>
                          ) : (<img
                            src={require('../../../assets/icon/cancel.png')}
                            alt='Cancel'
                          />)
                        )}
                      </TableCell>
                      <TableCell align='right' className='text-nowrap'>
                        <Tooltip title='Sửa'>
                          <IconButton
                            aria-label='edit'
                            onClick={() => editAction(row)}>
                            <EditIcon className='text-primary' />
                          </IconButton>
                        </Tooltip>
                        <Tooltip title='Xóa'>
                          <IconButton
                            aria-label='delete'
                            onClick={() => deleteAction(row.id)}>
                            <DeleteIcon className='text-danger' />
                          </IconButton>
                        </Tooltip>
                        {row.isDelete ? (
                          <Tooltip title='Khôi phục'>
                            <IconButton
                              aria-label='restore'
                              onClick={() => restoreAction(row.id)}>
                              <RestoreIcon className='text-success' />
                            </IconButton>
                          </Tooltip>
                        ) : (
                          ''
                        )}
                      </TableCell>
                    </TableRow>
                  );
                })
              ) : (
                <TableRow hover tabIndex={-1}>
                  <TableCell colSpan={8} className='text-center'>
                    Không có dữ liệu
                  </TableCell>
                </TableRow>
              )}
              {emptyRows > 0 && (
                <TableRow style={{ height: 0 }}>
                  <TableCell
                    colSpan={8}
                    style={{ padding: 0, borderBottom: 0 }}
                  />
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>

        {totalItemCount && totalItemCount > 0 ? (
          <FooterPagination
            currentPage={page + 1}
            rowsPerPage={rowsPerPage}
            handleChangeRowsPerPage={handleChangeRowsPerPage}
            handleChangePage={handleChangePage}
            totalPage={totalPage}
            totalItemCount={totalItemCount}
          />
        ) : (
          ''
        )}
      </Paper>
    </div>
  );
}
