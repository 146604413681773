// Build-in Modules
import React, { useEffect, useState } from 'react';

// External Modules
import { withStyles } from '@material-ui/core/styles';
import { green } from '@material-ui/core/colors';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import Favorite from '@material-ui/icons/Favorite';
import FavoriteBorder from '@material-ui/icons/FavoriteBorder';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import { Controller, useForm, useFormContext } from 'react-hook-form';
import { TextField } from '@material-ui/core';
import Divider from '@material-ui/core/Divider';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    height: 140,
    width: 100,
  },
  control: {
    padding: theme.spacing(2),
  },
}));

const UtilitiesList = ({
  listUtilities = [],
  currentUtilities = [],
  isEdit,
  isValidUtility = false,
}) => {
  const classes = useStyles();
  const { register, setValue } = useFormContext();

  return (
    <>
      <div>
        <label className='text-dark'>
          Tiện ích<span className='required'></span>
        </label>
        {!isValidUtility && <p className='error'>Chọn ít nhất một tiện ích</p>}
        <FormGroup row>
          {[...listUtilities]?.map((item, idx) => {
            return (
              <UtilityItem
                key={`pr-${idx}`}
                item={item}
                idx={idx}
                currentUtilities={currentUtilities}
                isEdit={isEdit}
              />
            );
          })}
        </FormGroup>
        <Divider />
      </div>
    </>
  );
};

const UtilityItem = ({ item, idx, currentUtilities, isEdit }) => {
  const { control, setValue } = useFormContext();
  const [defaultChecked, setDefaultChecked] = useState(false);

  useEffect(() => {
    if (isEdit) {
      const foundItem = [...currentUtilities].find(
        (findItem) => findItem === item?.id
      );

      if (foundItem) {
        setValue(`utilities[${idx}]`, true);
      } else {
        setValue(`utilities[${idx}]`, false);
      }
    }
  }, [currentUtilities, idx, item, isEdit]);

  return (
    <Grid item sm={3}>
      <FormControlLabel
        control={
          <Controller
            control={control}
            name={`utilities[${idx}]`}
            defaultValue={''}
            render={(
              { onChange, onBlur, value, name, ref },
              { invalid, isTouched, isDirty }
            ) => (
              <Checkbox
                onBlur={onBlur}
                onChange={(e) => onChange(e.target.checked)}
                checked={value}
                inputRef={ref}
                name={name}
              />
            )}
          />
        }
        label={item?.name}
      />
    </Grid>
  );
};

export default UtilitiesList;
