import React from "react";

import * as consultingServicesAction from "../../redux/store/consulting-services/consultingServices.store";
import * as config from "../../common/config";
import { useScreenActions } from "../../common/useScreenActions";
import { useAddOrEditConlsultingServicesDialog } from "./useAddOrEditConlsultingServicesDialog";
import { PageHeaderActions } from "../../components/pageHeaderActions/page-header-actions.view";
import { AddOrEditConlsultingServicesDialog } from "./addOrEditConlsultingServicesDialog";
import DeleteDialog from "../../components/dialog-delete/dialog-delete.view";
import { ListConsultingServices } from "./listConsultingServices.view";
import * as appActions from "../../core/app.store";
import { useDispatch } from "react-redux";
const initialRequestParams = {
  Keyword: null,
  isDelete: false,
  Sorting: config.Configs.DefaultSortExpression,
  PageIndex: 0,
  PageSize: config.Configs.DefaultPageSize,
  LangId: "vi",
};

export const ConsultingServices = () => {
  const {
    requestParams,
    datas,
    data,
    openAddOrEditDialog,
    dataWillDelete,
    setDataWillDelete,
    setOpenAddOrEditDialog,
    setDatas,
    onClickAdd,
    onClickEdit,
    handleChangeRequestParams,
    handleAddOrEditSuccess,
    onDeleteSuccess,
  } = useScreenActions({
    initialRequestParams,
    getDatas: (req) => getConsultingServices(req),
  });
  const dispatch = useDispatch();
  const { onDelete, onClearTrash, onDeletePermanant, getDetail } =
    useAddOrEditConlsultingServicesDialog({
      onSuccess: onDeleteSuccess,
    });

  const getConsultingServices = async (params) => {
    dispatch(appActions.ShowLoading(true))
    const res = await consultingServicesAction.GetListAll({
      ...params,
      PageIndex: params.PageIndex + 1,
    });
    if (!!res.content) {
      setDatas(res.content);
      dispatch(appActions.ShowLoading(false))
    }
  };

  const handleClickEdit = async (id) => {
    const res = await getDetail(id);
    res && onClickEdit(res);
  };

  return (
    <div className="slider">
      <PageHeaderActions
        title="Dịch vụ tư vấn"
        labelAddButton="Thêm dịch vụ tư vấn"
        onClickClearTrash={onClearTrash}
        onClickAdd={onClickAdd}
        onChangeRequest={handleChangeRequestParams}
        showTrash={requestParams.isDelete}
        showSearchAndRefreshActions={false}
      />
      <ListConsultingServices
        datas={datas}
        requestParams={requestParams}
        onChangeRequest={handleChangeRequestParams}
        onClickEdit={handleClickEdit}
        onClickDelete={(data) => setDataWillDelete(data)}
      />
      <AddOrEditConlsultingServicesDialog
        isOpen={openAddOrEditDialog}
        onClose={() => setOpenAddOrEditDialog(false)}
        onSuccess={handleAddOrEditSuccess}
        data={data}
      />
      <DeleteDialog
        isOpen={!!dataWillDelete?.id}
        onClose={() => setDataWillDelete(null)}
        onSuccess={() =>
          requestParams.isDelete
            ? onDeletePermanant(dataWillDelete.id)
            : onDelete(dataWillDelete.id)
        }
        header={"Xác nhận xóa"}
        content={"Bạn có chắc chắn muốn xóa?"}
      />
    </div>
  );
};
