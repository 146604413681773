export const pageName = {
  HOME: "Trang chủ",
  PLANNING: "Trang tra cứu bản đồ quy hoạch ",
  GENARALPLANNING: "Trang tổng hợp quy hoạch Việt Nam",
  PLANNINGNEWS: "Tin tức tổng hợp về dự án",
  PROJECTINFO: "Trang thông tin bản đồ dự án",
  PROJECTNEWS: "Tin tức tổng hợp về dự án",
  REALSTATEPRICE: "Tra cứu thông tin bản đồ giá đất Việt Nam",
  BUYNSALE: "Trang mua bán",
  MARKETNEWS: "Tin tức thông tin thị trường giá đất",
  INTRODUCE: "Giới thiệu về Đất Vàng Việt Nam",
  NEWS: "Thông tin tổng hợp bất động sản hữu ích",
  NEEDYOUKNOW: "Quy trình đầu tư dự án cần biết",
  LEGALDOCUMENTS: "Văn bản pháp lý",
  FAQ: "Hỏi đáp - Hướng dẫn sử dụng",
  CONTACTUS: "Trang liên hệ",
  HELPING: "Trang hướng dẫn sử dụng",
};
