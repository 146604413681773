const HANDLEOUTSIDEFUNCTION = "OPENLAYER/HANDLEOUTESIDEFUNCTION";
const SAVE_PRICE_MAP_CONTROLLER = 'PRICE_MAP/SAVE_PRICE_MAP_CONTROLLER';

const creatActionHandleOutsideFunction = (data) => ({ type: HANDLEOUTSIDEFUNCTION, data: data })
const CreateSavePriceMapControllerAction = (data) => ({ type: SAVE_PRICE_MAP_CONTROLLER, data: data })

export const SetHandleOutsideFunction = (data) => {
    return (dispatch) => {
        dispatch(creatActionHandleOutsideFunction(data))
    }
}

export const CreateMapController = (data) => {
    return (dispatch) => {
        dispatch(CreateSavePriceMapControllerAction(data))
    }
  }  

const DefaultState = {
    handleOutSideFunction: () => { },
    mapController: {},
}

export default function OpenlayerReducer(state = DefaultState, action) {
    switch (action.type) {
        case HANDLEOUTSIDEFUNCTION: return { ...state, handleOutSideFunction: action.data }
        case SAVE_PRICE_MAP_CONTROLLER: return { ...state, mapController: action.data }
        default: return state
    }
};