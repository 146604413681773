import React from "react";
import Highcharts from "highcharts/highstock";
import PieChart from "highcharts-react-official";


const ChartPie = (props) => {
    const { userLoginType } = props;
    const options = {
        chart: {
            plotBackgroundColor: null,
            plotBorderWidth: null,
            plotShadow: false,
            type: 'pie',
            height: 300,
            width: 400,
        },
        title: {
            text: ''
        },
        tooltip: {
            pointFormat: 'SL User: <b>{point.y}</b> <br/> Tỷ lệ: {point.percentage:.1f} %'
        },
        accessibility: {
            point: {
                valueSuffix: '%'
            }
        },
        plotOptions: {
            pie: {
                allowPointSelect: true,
                cursor: 'pointer',
                dataLabels: {
                    enabled: true,
                    distance: -50,
                    format: '<b>{point.name}</b>: {point.percentage:.1f} %',
                    connectorColor: 'silver'
                }
            }
        },
      series: userLoginType.series
    };
    
  return (
    <div style={{ width: '100%'}}>
      <PieChart height={300} width={300} highcharts={Highcharts} options={options} />
    </div>
  );
};

export default ChartPie;
