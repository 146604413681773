// Build-in modules
import React, {useEffect} from 'react';
import PropTypes from 'prop-types';

// External Modules
import Button from '@material-ui/core/Button';
import CloseIcon from '@material-ui/icons/Close';
import TextField from '@material-ui/core/TextField';
import { useFormContext } from 'react-hook-form';
import { DropzoneArea } from 'material-ui-dropzone';

// Internal Modules
import {APIUrlDefault} from '../../../utils/configuration';

const ProjectGroundImage = ({
  groundImages = [],
  onOpenSelectFileLib = () => {},
    deleteImageSingle = () => { },
    setFileUploadGround = () => {},
  classes
}) => {
  const {register, errors, clearErrors} = useFormContext();

  useEffect(() => {
    if (groundImages && groundImages.length > 0) {
      clearErrors('groundImageFake');
    }
  }, [groundImages, clearErrors]);

  return (
      <>
          <div className="form-group row">
              <div className="col-12">
                  <label className="text-dark">
                      Ảnh mặt bằng <span className="required"></span>
                  </label>
                  <div className="row">
                      {groundImages?.map((item, idx) => (
                          <div key={`pr-${idx}`} className="col-12 col-md-6 col-lg-2">
                              <div style={{ width: '150px', position: 'relative' }}>
                                  <div
                                      className={classes.closeButtonImage}
                                      onClick={e => deleteImageSingle(e, item.fileId)}
                                  >
                                      <CloseIcon />
                                  </div>
                                  <img
                                      src={APIUrlDefault + item.filePreview}
                                      alt={item.fileName}
                                      className="img-fluid mb-2"
                                      style={{
                                          width: 'auto',
                                          height: 'auto',
                                          maxWidth: '100%',
                                          maxHeight: '100%'
                                      }}
                                  />
                              </div>
                          </div>
                      ))}
                  </div>

                  {
                      //        <div>
                      //            <Button
                      //                variant="contained"
                      //                color="primary"
                      //                onClick={onOpenSelectFileLib}
                      //            >
                      //                Chọn file
                      //</Button>
                      //            <TextField
                      //                type="hidden"
                      //                name="groundImageFake"
                      //                inputRef={register({ required: true })}
                      //                value={
                      //                    (groundImages &&
                      //                        groundImages.length > 0 &&
                      //                        groundImages[0].fileName) ||
                      //                    ''
                      //                }
                      //            />
                      //            {errors.groundImageFake &&
                      //                errors.groundImageFake.type === 'required' && (
                      //                    <p className="error">Trường này là bắt buộc</p>
                      //                )}
                      //        </div>
                  }
              </div>
          </div>
          <div className="row">
              <div className="col-12">
                  <DropzoneArea
                      acceptedFiles={['image/png, image/gif, image/jpeg, image/jpg']}
                      filesLimit={20}
                      dropzoneText="Chọn và upload ảnh mặt bằng"
                      onChange={(files) => {
                          setFileUploadGround(files);
                      }}
                  />
              </div>
          </div>
      </>
  );
};

ProjectGroundImage.propTypes = {
  groundImages: PropTypes.array,
  onOpenSelectFileLib: PropTypes.func,
  deleteImageSingle: PropTypes.func
};

export default ProjectGroundImage;
