import React, { useState, useEffect } from "react";
import { FormProvider, useForm } from "react-hook-form";
import SunEditor from "suneditor-react";
import { ApiUrl } from "../../../api/api-url";
//--- Styles
import { DropzoneArea } from "material-ui-dropzone";
//--- Material Control
import {
  DialogActions,
  Button,
  TextField,
  DialogContent,
  DialogTitle,
  Dialog,
  Typography,
  IconButton,
  makeStyles,
  Checkbox,
} from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";

//--- Material Icon
import CloseIcon from "@material-ui/icons/Close";
import SaveIcon from "@material-ui/icons/Save";
import ShowNotification from "../../../components/react-notifications/react-notifications";
import {
  NotificationMessageType,
  APIUrlDefault,
  MaxSizeImageUpload,
} from "../../../utils/configuration";

import NumberFormatCustom from "../../../components/NumberFormatCustom/NumberFormat";
import * as viVN from "../../../language/vi-VN.json";

//--- Action
//import * as newsAction from '../../../redux/store/news/news.store';
import * as ProjectActions from "../../../redux/store/project-management/project-store";
import * as MasterActions from "../../../redux/store/master-data/master-data.store";
import * as ClassifiedActions from "../../../redux/store/classified/classified.store";
import FileManagement from "../../../components/file_management/file_management";
import FormInputAddress from "../../../components/FormItem/FormInputAddress";
import FormInputCurrency from "../../../components/FormItem/FormInputCurrency";
import FormAsyncSelect from "../../../components/FormItem/FormAsyncSelect";
import FormAsyncSelecPagination from "../../../components/FormItem/FormAsynceSelectPaginationProject";
import FormAsyncPlanning from "../../../components/FormItem/FormAsynceSelectPaginationPlanning";
import { useHistory } from "react-router-dom";
import { UrlCollection } from "../../../common/url-collection";
import { ArrowBackIos } from "@material-ui/icons";
import { useSelector } from "react-redux";
import CircularProgress from '@material-ui/core/CircularProgress';
const useStyles = makeStyles((theme) => ({
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  formControl: {
    margin: theme.spacing(1),
  },
  closeButtonImage: {
    position: "absolute",
    right: "2px",
    top: "3px",
    color: theme.palette.grey[500],
    opacity: "0.7",
    background: "white",
    borderRadius: "50%",
    cursor: "pointer",
  },
}));

export default function AddClassified(props) {
  const classes = useStyles();
  const history = useHistory();
  const { langId } = useSelector((state) => state.language);
  //const [image_Url, setImage_Url] = useState('');
  const [content, setContent] = useState();
  const [isShow, setShow] = useState(false);
  const [isShowFileLib, setShowFileLib] = useState(false);

  const [files, setFiles] = useState([]);
  const [filesTemp, setFilesTemp] = useState([]);

  const [fileLib, setFileLib] = useState([]);
  const [fileTempLib, setFileTempLib] = useState([]);

  const [arrayOfLib, setArrayOfLib] = useState([]);
  const [isActive, setActive] = useState(false);
  const [isShowUi, setShowUi] = useState(false);
  const [isSoldOut, setSoldOut] = useState(false);
  const [expire, setExpire] = useState("");

  const [optionProject, setOptionProject] = useState([]);
  const [optionRealEstate, setOptionRealEstate] = useState([]);
  const [optionDirection, setOptionDirection] = useState([]);
  const [direction, setDirection] = useState({});
  const [estate, setEstate] = useState({});
  const [project, setProject] = useState({});
  const [fileUpload, setFileUpload] = useState([]);
  const [fileUploadGalary, setFileUploadGalary] = useState([]);

  const urlUploadImage = `${APIUrlDefault}${ApiUrl.UrlUploadFromEditor}`;

  const methods = useForm({
    mode: "all",
    reValidateMode: "onBlur",
    defaultValues: {
      price: 0,
      pricePerMettre: 0,
    },
  });

  const { register, handleSubmit, setError, errors, clearErrors, setValue, formState } =
    methods;
  const { isSubmitting } = formState;
  useEffect(() => {
    ProjectActions.ProjectGetLookUp(langId, 20)
      .then((res) => {
        if (res.content) {
          setOptionProject(res.content.items);
        }
      })
      .catch((err) => console.log(err));
    MasterActions.GetMasterDataFromLangId({ langId })
      .then((res) => {
        if (res.realEstateTypeCache) {
          setOptionRealEstate(res.realEstateTypeCache);
        }
        if (res.directionCache) {
          setOptionDirection(res.directionCache);
        }
      })
      .catch((err) => console.log(err));
  }, []);

  const onChangeDirection = (e, values) => {
    setDirection(values);
  };

  const onChangeEstate = (e, values) => {
    setEstate(values);
  };

  const onChangeProject = (e, values) => {
    setProject(values);
  };

  const handleChangeActive = (e) => {
    setActive(e.target.checked);
  };

  const handleChangeShowUi = (e) => {
    setShowUi(e.target.checked);
  };
  const handleChangeSold = (e) => {
    setSoldOut(e.target.checked);
  };

  const handleChangeExpire = (e) => {
    setExpire(e.target.value);
  };

  const onChangeContent = (editorContent) => {
    clearErrors(["editorContent"]);
    if (editorContent === "<p><br></p>") {
      setError("editorContent", { type: "required" });
      setContent("");
    } else {
      clearErrors("editorContent");
      setContent(editorContent);
    }
  };

  const onSubmit = async (data) => {
    const formData = new FormData();
    formData.append("title", data.title);
    data.code && formData.append("code", data.code);
    formData.append("description", content);
    data.project && formData.append("projectId", data.project.value);
    data.planning && formData.append("planningId", data.planning.value);
    data.address && formData.append("addressId", data.address?.value);
    formData.append("telephoneNumber", data.telephoneNumber);
    formData.append("pricePerMettre", data.pricePerMettre);
    formData.append("realEstateTypeId", estate?.id);
    formData.append("pricePerMettre", data.pricePerMettre);
    formData.append("priceFrom", data.price);
    formData.append("priceTo", data.price);
    formData.append("price", data.price);
    formData.append("acreage", parseInt(data.acreage));
    data.floorCount && formData.append("floorCount", parseInt(data.floorCount));
    data.bedroomCount &&
      formData.append("bedroomCount", parseInt(data.bedroomCount));
    data.bathroomCount &&
      formData.append("bathroomCount", parseInt(data.bathroomCount));
    direction && direction.id && formData.append("directionId", direction?.id);
    formData.append("isActive", isActive);
    formData.append("isShowUI", isShowUi);
    formData.append("isSoldOut", isSoldOut);
    expire && formData.append("expiredDate", expire);
    formData.append("linkYoutube", data.linkYoutube);
    formData.append("link3D", data.link3D);
    fileUpload &&
      fileUpload.length > 0 &&
      formData.append("image", fileUpload[0]);
    fileUploadGalary &&
      fileUploadGalary.length > 0 &&
      fileUploadGalary.map((item) => formData.append("galaryImages", item));

    await ClassifiedActions.ClassifiedCreateWithUpload(formData)
      .then((res) => {
        history.push(UrlCollection.Classified);
        ShowNotification(
          viVN.Success.ClassifiedAddSuccess,
          NotificationMessageType.Success
        );
      })
      .catch(
        (err) => viVN.Errors[err.errorType],
        NotificationMessageType.Error
      );
  };

  const onOpenSelectFileLib = () => {
    setShowFileLib(true);
  };
  const onOpenSelectFile = () => {
    setShow(true);
  };

  const onCloseSelectFileLib = () => {
    setShowFileLib(false);
    setFileLib(setFileTempLib);
  };

  const onSaveSelectFileLib = () => {
    console.log(fileLib);
    setArrayOfLib([...arrayOfLib, ...fileLib]);
    setShowFileLib(false);
  };

  const onCloseSelectFile = () => {
    setShow(false);
    setFiles(filesTemp);
  };

  const onSaveSelectFile = () => {
    console.log(files);
    setShow(false);
  };

  const deleteImageSingle = (e, fileId) => {
    const arr = arrayOfLib.filter((item) => item?.fileId !== fileId);
    setArrayOfLib(arr);
  };
  const onGoBack = () => {
    const queryParams = window.location.search
    history.push(UrlCollection.Classified + queryParams)
  };
  return (
    <div>
      <FormProvider {...methods}>
        <form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
          <div className="d-sm-flex align-items-center mb-2" onClick={onGoBack} style={{ cursor: "pointer", width: 100 }}>
            <ArrowBackIos fontSize="small"></ArrowBackIos> Quay lại
          </div>
          <div className="d-sm-flex align-items-center justify-content-between mb-3">
            <h1 className="h3 mb-0 text-gray-800">
              Thêm tin mua bán
            </h1>
            <Button
              type="submit"
              color="primary"
              variant="contained"
              startIcon={!isSubmitting ? <SaveIcon></SaveIcon> : <CircularProgress size={20} color="white" />}
              disabled={isSubmitting}
            >
              Lưu
            </Button>
          </div>
          <DialogContent className="pt-4 pb-2">
            <div className="form-group row">
              <div className="col-9 col-md-9 col-lg-9">
                <label className="text-dark">
                  Tiêu đề<span className="required"></span>
                </label>
                <TextField
                  name="title"
                  error={
                    errors.title &&
                    (errors.title.type === "required" ||
                      errors.title.type === "maxLength")
                  }
                  fullWidth
                  type="text"
                  className="form-control"
                  inputRef={register({ required: true, maxLength: 150 })}
                  inputProps={{ maxLength: 150 }}
                  onChange={(e) => setValue("title", e.target.value)}
                />
                {errors.title && errors.title.type === "required" && (
                  <span className="error">Trường này là bắt buộc</span>
                )}
                {errors.title && errors.title.type === "maxLength" && (
                  <span className="error">
                    Trường này không quá 150 ký tự
                  </span>
                )}
              </div>
              <div className="col-3 col-md-3 col-lg-3">
                <label className="text-dark">
                  Code
                </label>
                <TextField
                  name="code"
                  error={
                    errors.title &&
                    (errors.title.type === "maxLength")
                  }
                  fullWidth
                  type="text"
                  className="form-control"
                  inputRef={register({ maxLength: 150 })}
                  inputProps={{ maxLength: 150 }}
                  onChange={(e) => setValue("code", e.target.value)}
                />
              </div>
            </div>
            <div className="form-group row">
              <div className="col-12 col-md-3 col-lg-3">
                <label className="text-dark">Số điện thoại:</label>
                <TextField
                  name="telephoneNumber"
                  error={
                    errors.telephoneNumber &&
                    errors.telephoneNumber.type === "maxLength"
                  }
                  fullWidth
                  type="number"
                  className="form-control"
                  inputProps={{ maxLength: 12 }}
                  inputRef={register({
                    minLength: 6,
                    maxLength: 12,
                  })}
                  onChange={(e) =>
                    setValue("telephoneNumber", e.target.value)
                  }
                />
                {errors.phoneNumber &&
                  errors.phoneNumber.type === "maxLength" && (
                    <span className="error">Trường này không quá 12 số</span>
                  )}
              </div>
              <div className="col-12 col-md-3 col-lg-3">
                <label className="text-dark">
                  Diện tích:<span className="required"></span>
                </label>
                <TextField
                  name="acreage"
                  error={
                    errors.acreage &&
                    (errors.acreage.type === "required" ||
                      errors.acreage.type === "maxLength")
                  }
                  fullWidth
                  type="number"
                  className="form-control"
                  inputRef={register({ required: true, maxLength: 150 })}
                  inputProps={{ maxLength: 150 }}
                  onChange={(e) => setValue("acreage", e.target.value)}
                />
                {errors.acreage && errors.acreage.type === "required" && (
                  <span className="error">Trường này là bắt buộc(số)</span>
                )}
              </div>
              <div className="col-12 col-md-3 col-lg-3">
                <label className="text-dark">Số lượng phòng ngủ:</label>
                <TextField
                  name="bedroomCount"
                  // error={
                  //   errors.bedroomCount &&
                  //   (errors.bedroomCount.type === 'required' ||
                  //     errors.bedroomCount.type === 'maxLength')
                  // }
                  fullWidth
                  type="number"
                  className="form-control"
                  inputRef={register({ maxLength: 150 })}
                  // inputProps={{ maxLength: 150 }}
                  onChange={(e) => setValue("bedroomCount", e.target.value)}
                />
                {/* {errors.bedroomCount &&
                  errors.bedroomCount.type === 'required' && (
                    <span className='error'>Trường này là bắt buộc</span>
                  )} */}
              </div>
              <div className="col-12 col-md-3 col-lg-3">
                <label className="text-dark">Số lượng nhà WC:</label>
                <TextField
                  name="bathroomCount"
                  // error={
                  //   errors.bathroomCount &&
                  //   (errors.bathroomCount.type === 'required' ||
                  //     errors.bathroomCount.type === 'maxLength')
                  // }
                  fullWidth
                  type="text"
                  className="form-control"
                  inputRef={register({ maxLength: 150 })}
                  inputProps={{ maxLength: 150 }}
                  onChange={(e) => setValue("bathroomCount", e.target.value)}
                />
                {/* {errors.bathroomCount &&
                  errors.bathroomCount.type === 'required' && (
                    <span className='error'>Trường này là bắt buộc</span>
                  )} */}
              </div>
            </div>

            <div className="form-group row">
              <div className="col-12 col-md-3 col-lg-3">
                <FormInputCurrency
                  title="Giá bán"
                  name="price"
                  placeholder="Giá bán"
                  validate={{
                    required: "Giá bán là dữ liệu bắt buộc",
                  }}
                />
              </div>
              <div className="col-12 col-md-3 col-lg-3">
                <FormInputCurrency
                  title="Giá/m2"
                  name="pricePerMettre"
                  placeholder="Giá/m2"
                  validate={{
                    required: "Giá/m2 là dữ liệu bắt buộc",
                  }}
                />
              </div>
              <div className="col-12 col-md-3 col-lg-3">
                <label className="text-dark">Số tầng:</label>
                <TextField
                  name="floorCount"
                  // error={
                  //   errors.floorCount &&
                  //   (errors.floorCount.type === 'required' ||
                  //     errors.floorCount.type === 'maxLength')
                  // }
                  fullWidth
                  type="number"
                  className="form-control"
                  inputRef={register({ maxLength: 150 })}
                  // inputProps={{ maxLength: 150 }}
                  onChange={(e) => setValue("floorCount", e.target.value)}
                />
                {/* {errors.floorCount && errors.floorCount.type === 'required' && (
                  <span className='error'>Trường này là bắt buộc</span>
                )} */}
              </div>
              <div className="col-12 col-md-3 col-lg-3">
                <label className="text-dark">Ngày hết hạn</label>
                <TextField
                  type="date"
                  onChange={handleChangeExpire}
                  color="primary"
                  className="form-control p-0 mt-0"
                />
              </div>
            </div>

            <div className="form-group row">
              {optionRealEstate.length > 0 && (
                <div className="col-12 col-md-4 col-lg-4">
                  <Autocomplete
                    id="combo-box-demo"
                    options={optionRealEstate}
                    getOptionLabel={(option) => option.name}
                    onChange={onChangeEstate}
                    style={{ width: "100%" }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Loại hình BĐS"
                        variant="outlined"
                        name="realEstateTypeId"
                        inputRef={register({ required: true })}
                        error={
                          errors.realEstateTypeId &&
                          errors.realEstateTypeId.type === "required"
                        }
                        size="small"
                      />
                    )}
                  />

                  {errors.realEstateTypeId &&
                    errors.realEstateTypeId.type === "required" && (
                      <span className="error">Trường này là bắt buộc</span>
                    )}
                </div>
              )}

              <div className="col-12 col-md-4 col-lg-4">
                {optionProject.length > 0 && (
                  <FormAsyncSelecPagination
                    name="project"
                    placeholder="Dự án"
                  />
                )}
              </div>
              <div className="col-12 col-md-4 col-lg-4">
                {optionDirection.length > 0 && (
                  <Autocomplete
                    id="combo-box-demo"
                    name="direction"
                    options={optionDirection}
                    getOptionLabel={(option) => option.name}
                    onChange={onChangeDirection}
                    style={{ width: "100%" }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Hướng"
                        variant="outlined"
                        size="small"
                      />
                    )}
                  />
                )}
              </div>
            </div>
            <div className="form-group row">
              <div className="col-12 col-md-12 col-lg-12">
                {langId && (
                  <FormInputAddress
                    name="address"
                    placeholder="Địa chỉ"
                    langId={langId}
                  />
                )}
              </div>

            </div>
            <div className="form-group row">
              <div className="col-12 col-md-12 col-lg-12">
                <FormAsyncPlanning
                  name="planning"
                  options={[]}
                  placeholder="Chọn quy hoạch..."
                />
              </div>
            </div>
            <div className="form-group">
              <div class="row">
                <div className="col-12 col-md-3 col-lg-3">
                  <label className="text-dark">Hoạt động</label>
                  <Checkbox
                    checked={isActive}
                    onChange={handleChangeActive}
                    color="primary"
                    inputProps={{ "aria-label": "secondary checkbox" }}
                    className="p-0 mt-0 ml-4"
                  />
                </div>
                <div className="col-12 col-md-3 col-lg-3">
                  <label className="text-dark">Hiển thị ở trang web</label>
                  <Checkbox
                    checked={isShowUi}
                    onChange={handleChangeShowUi}
                    color="primary"
                    inputProps={{ "aria-label": "secondary checkbox" }}
                    className="p-0 mt-0 ml-4"
                  />
                </div>
                <div className="col-12 col-md-2 col-lg-2">
                  <label className="text-dark">Đã bán</label>
                  <Checkbox
                    checked={isSoldOut}
                    onChange={handleChangeSold}
                    color="primary"
                    inputProps={{ "aria-label": "secondary checkbox" }}
                    className="p-0 mt-0 ml-4"
                  />
                </div>

              </div>
            </div>
            <div className="form-group">
              <label className="text-dark">Mô tả</label>
              <SunEditor
                enableToolbar={true}
                showToolbar={true}
                videoFileInput={false}
                setOptions={{
                  height: 500,
                  imageUploadUrl: urlUploadImage,
                  imageUploadSizeLimit: MaxSizeImageUpload,
                  imageAccept: ".jpg,.jpeg,.png,.gift,.svg,.tif",
                  buttonList: [
                    [
                      "undo",
                      "redo",
                      "font",
                      "fontSize",
                      "formatBlock",
                      "paragraphStyle",
                      "blockquote",
                      "bold",
                      "underline",
                      "italic",
                      "strike",
                      "subscript",
                      "superscript",
                      "fontColor",
                      "hiliteColor",
                      "textStyle",
                      "removeFormat",
                      "outdent",
                      "indent",
                      "align",
                      "horizontalRule",
                      "list",
                      "lineHeight",
                      "table",
                      "link",
                      "image",
                      "video",
                      "audio",
                      "fullScreen",
                      "showBlocks",
                      "codeView",
                    ],
                  ],
                }}
                onChange={onChangeContent}
                onBlur={(event, editorContents) =>
                  onChangeContent(editorContents)
                }
              />
            </div>

            <div className="form-group row">
              <div className="col-12">
                <label className="text-dark">Thêm video từ Youtube</label>
                <TextField
                  name={"linkYoutube"}
                  placeholder={"https://www.youtube.com/watch?v=uEP92B9zB5c"}
                  error={
                    errors.linkYoutube &&
                    errors.linkYoutube.type === "pattern"
                  }
                  //defaultValue={currentProject?.linkYoutube}
                  fullWidth
                  type="text"
                  className="form-control"
                  inputRef={register({
                    pattern:
                      /(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g,
                  })}
                />
                {errors.linkYoutube &&
                  errors.linkYoutube.type === "pattern" && (
                    <span className="error">
                      Trường này chưa phải là liên kết youtube
                    </span>
                  )}
              </div>
            </div>
            <div className="form-group row">
              <div className="col-12">
                <label className="text-dark">Thêm link 3D</label>
                <TextField
                  name={"link3D"}
                  placeholder={"https://www.3d-link.com/"}
                  error={
                    errors.link3D &&
                    errors.link3D.type === "pattern"
                  }
                  //defaultValue={currentProject?.linkYoutube}
                  fullWidth
                  type="text"
                  className="form-control"
                  inputRef={register({
                    pattern:
                      /(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g,
                  })}
                />
                {errors.link3D &&
                  errors.link3D.type === "pattern" && (
                    <span className="error">
                      Trường này chưa phải là liên kết
                    </span>
                  )}
              </div>
            </div>
            <div className="form-group row">
              <div className="col-12">
                <label className="text-dark">Ảnh đại diện</label>
                <span className="required"></span>
                {
                  //                  !isShow &&
                  //files &&
                  //files.length > 0 &&
                  //files.map((item) => (
                  //  <div key={item.fileName} style={{ width: '150px' }}>
                  //    <img
                  //      src={APIUrlDefault + item.filePreview}
                  //      alt={item.fileName}
                  //      className='img-fluid mb-2'
                  //      style={{
                  //        width: 'auto',
                  //        height: 'auto',
                  //        maxWidth: '100%',
                  //        maxHeight: '100%',
                  //      }}
                  //    />
                  //  </div>
                  //))
                }
                <div>
                  {
                    //  <Button
                    //      variant='contained'
                    //      color='primary'
                    //      onClick={onOpenSelectFile}>
                    //      Chọn file
                    //</Button>
                  }
                  <TextField
                    inputRef={register({ required: true })}
                    type="hidden"
                    name="image"
                    value={
                      (fileUpload &&
                        fileUpload.length > 0 &&
                        fileUpload[0].name) ||
                      ""
                    }
                  />
                  {errors.image && errors.image.type === "required" && (
                    <p className="error">Trường này là bắt buộc</p>
                  )}
                </div>
              </div>
              <div className="col-12">
                <DropzoneArea
                  acceptedFiles={["image/png, image/gif, image/jpeg, image/jpg"]}
                  filesLimit={1}
                  dropzoneText="Kéo thả ảnh đại diện vào đây"
                  onChange={(files) => {
                    files &&
                      files.length &&
                      console.log("Files:", files[0].name);
                    setFileUpload(files);
                  }}
                />
              </div>
            </div>

            <div className="form-group row">
              <div className="col-12">
                <label className="text-dark">Thư viện ảnh:</label>
                <div className="row">
                  {
                    //                    arrayOfLib &&
                    //arrayOfLib.length > 0 &&
                    //arrayOfLib.map((item) => (
                    //  <div className='col-12 col-md-6 col-lg-2'>
                    //    <div
                    //      key={item.fileName}
                    //      style={{ width: '150px', position: 'relative' }}>
                    //      <div
                    //        className={classes.closeButtonImage}
                    //        onClick={(e) =>
                    //          deleteImageSingle(e, item.fileId)
                    //        }>
                    //        <CloseIcon />
                    //      </div>
                    //      <img
                    //        src={APIUrlDefault + item.filePreview}
                    //        alt={item.fileName}
                    //        className='img-fluid mb-2'
                    //        style={{
                    //          width: 'auto',
                    //          height: 'auto',
                    //          maxWidth: '100%',
                    //          maxHeight: '100%',
                    //        }}
                    //      />
                    //    </div>
                    //  </div>
                    //))
                  }
                </div>

                <div>
                  {
                    //                      <Button
                    //                          variant='contained'
                    //                          color='primary'
                    //                          onClick={onOpenSelectFileLib}>
                    //                          Chọn file
                    //</Button>
                  }
                </div>
              </div>
              <div className="col-12">
                <DropzoneArea
                  acceptedFiles={["image/png, image/gif, image/jpeg, image/jpg"]}
                  filesLimit={20}
                  dropzoneText="Kéo thả ảnh thư viện vào đây"
                  onChange={(files) => {
                    console.log("Files:", files);
                    setFileUploadGalary(files);
                  }}
                />
              </div>
            </div>
          </DialogContent>
        </form>
      </FormProvider>

      {isShow && (
        <Dialog
          onClose={onCloseSelectFile}
          open={isShow}
          fullWidth={true}
          maxWidth="md"
          className="dialog-preview-form"
        >
          <DialogTitle disableTypography>
            <Typography variant="h6">Quản lý file</Typography>
            <IconButton
              aria-label="close"
              className={classes.closeButton}
              onClick={onCloseSelectFile}
            >
              <CloseIcon />
            </IconButton>
          </DialogTitle>
          <DialogContent dividers>
            <FileManagement
              files={files}
              setFiles={setFiles}
              acceptedFiles={["jpeg", "png", "jpg", "gif"]}
            />
          </DialogContent>

          <DialogActions>
            <Button
              type="button"
              onClick={onCloseSelectFile}
              variant="contained"
              startIcon={<CloseIcon />}
            >
              Hủy
            </Button>
            {files && files.length > 0 && (
              <Button
                type="button"
                color="primary"
                variant="contained"
                startIcon={<SaveIcon />}
                onClick={onSaveSelectFile}
              >
                Lưu
              </Button>
            )}
          </DialogActions>
        </Dialog>
      )}

      {isShowFileLib && (
        <Dialog
          onClose={onCloseSelectFileLib}
          open={isShowFileLib}
          fullWidth={true}
          maxWidth="md"
          className="dialog-preview-form"
        >
          <DialogTitle disableTypography>
            <Typography variant="h6">Quản lý file</Typography>
            <IconButton
              aria-label="close"
              className={classes.closeButton}
              onClick={onCloseSelectFileLib}
            >
              <CloseIcon />
            </IconButton>
          </DialogTitle>
          <DialogContent dividers>
            <FileManagement
              files={fileLib}
              setFiles={setFileLib}
              acceptedFiles={["jpeg", "png", "jpg", "gif"]}
            />
          </DialogContent>

          <DialogActions>
            <Button
              type="button"
              onClick={onCloseSelectFileLib}
              variant="contained"
              startIcon={<CloseIcon />}
            >
              Hủy
            </Button>
            {fileLib && fileLib.length > 0 && (
              <Button
                type="button"
                color="primary"
                variant="contained"
                startIcon={<SaveIcon />}
                onClick={onSaveSelectFileLib}
              >
                Lưu
              </Button>
            )}
          </DialogActions>
        </Dialog>
      )}
    </div>
  );
}
