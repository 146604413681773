import React, {useEffect} from 'react';
import {useFormContext, Controller} from 'react-hook-form';
import Select from 'react-select';
import './_main.scss';

const CustomFormSelect = ({
  name,
  label,
  options,
  isRequired,
  isMulti = false,
  defaultValue,
  optionLabel = 'label',
  optionValue = 'value',
  currentData = null,
  isEdit = false,
  placeholderText = 'Chọn...'
}) => {
  const {control, setValue, errors} = useFormContext();

  useEffect(() => {
    if (isEdit) {
      let currentValue = isMulti ? [] : null;
      if (currentData && currentData[name]) {
        currentValue = currentData[name];
      }
      setValue(name, currentValue);
    }
  }, [setValue, currentData, name, isMulti]);

  return (
    <div className="form-group">
      <label htmlFor="exampleEmail">
        {label}
        {isRequired && <span className="required"></span>}
      </label>
      <Controller
        name={name}
        as={Select}
        options={options}
        control={control}
        rules={{required: true}}
        className={`react-select ${errors[name] ? 'in-valid' : ''}`}
        invalid={true}
        isMulti={isMulti}
        defaultValue={defaultValue}
        getOptionLabel={opt => opt[optionLabel]}
        getOptionValue={opt => opt[optionValue]}
        placeholder={placeholderText}
      />
    </div>
  );
};

export default CustomFormSelect;
