/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { connect, useSelector } from "react-redux";
import { bindActionCreators } from "redux";
import { ApiUrl } from "../../../api/api-url";
import { DropzoneArea } from "material-ui-dropzone";
import {
  Col,
  Nav,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane,
} from "reactstrap";
//--- Action
import * as postActions from "../../../redux/store/posts/posts.store";
import SunEditor from "suneditor-react";
import "suneditor/dist/css/suneditor.min.css";
import * as viVN from "../../../language/vi-VN.json";
import CircularProgress from '@material-ui/core/CircularProgress';
import InputAdornment from '@material-ui/core/InputAdornment';
//--- Material Control
import {
  DialogActions,
  //TextareaAutosize,
  Button,
  TextField,
  DialogContent,
  DialogTitle,
  Dialog,
  makeStyles,
  Typography,
  IconButton,
  //  Select,
  //  MenuItem,
  Checkbox,
  Radio,
  RadioGroup,
  FormControlLabel,
  Tabs,
  Tab,
  Box,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import TabPanel from "../../../components/tabpanel/tabpanel";
import a11yProps from "../../../utils/helpers/a11yProps";

//--- Material Icon
import CloseIcon from "@material-ui/icons/Close";
import SaveIcon from "@material-ui/icons/Save";

//--- Notifications
//import FileInputComponent from '../../../components/file-input/file-input.view';
import ShowNotification from "../../../components/react-notifications/react-notifications";
import {
  NotificationMessageType,
  APIUrlDefault,
  MaxSizeImageUpload,
} from "../../../utils/configuration";
import * as appActions from "../../../core/app.store";
import FileManagement from "../../../components/file_management/file_management";
import FormAsyncPlanning from "../../../components/FormItem/FormAsynceSelectPaginationPlanning";
import buildFormData from "../../../utils/helpers/formDataBuild";
//--- Styles
import "../posts.scss";
import { useHistory } from "react-router-dom";
import { ArrowBackIos } from "@material-ui/icons";
const urlUploadImage = APIUrlDefault + ApiUrl.UrlUploadFromEditor;
const useStyles = makeStyles((theme) => ({
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightBold,
  },
}));

function EditSlider(props) {
  const classes = useStyles();
  const history = useHistory();
  const { langId } = useSelector((state) => state.language);
  const { id, postTypeId, postId } = props.match.params;
  const categoryId = id;
  const [tab, setTab] = useState(1);
  const [newsModel, setNewsModel] = useState();
  const [responseModel, setResponseModel] = useState();
  const [translate, setTranslate] = useState({});
  const [core, setCore] = useState({});
  const [status, setStatus] = useState(false);
  const [isHot, setIsHot] = useState(false);
  const [isHomePage, setHomePage] = useState(false);

  const [content, setContent] = useState();
  const [isShow, setShow] = useState(false);
  const [isShowFb, setShowFb] = useState(false);
  const [isShowTw, setShowTw] = useState(false);
  const [isShowDocs, setShowDocs] = useState(false);
  const [files, setFiles] = useState([]);
  const [filesTemp, setFilesTemp] = useState([]);
  const [fileDocs, setFileDocs] = useState([]);
  const [fileTempDocs, setFileTempDocs] = useState([]);
  const [fileFb, setFileFb] = useState([]);
  const [fileTempFb, setFileTempFb] = useState([]);
  const [fileTw, setFileTw] = useState([]);
  const [fileTempTw, setFileTempTw] = useState([]);
  const [tabIndex, setTabIndex] = React.useState(0);
  const [defaultPlanning, setDefaultPlanning] = useState(null);
  const handleTabIndexChange = (event, newValue) => {
    setTabIndex(newValue);
  };
  const [isOwnerNews, setIsOwnerNews] = useState("true");
  const [fileImage, setFileImage] = useState([]);
  const [fileImageFb, setFileImageFb] = useState([]);
  const [fileImageTw, setFileImageTw] = useState([]);
  const [fileDoc, setFileDoc] = useState([]);
  const [sourceNews, setSourceNews] = useState("");
  const [sourceLink, setSourceLink] = useState("");

  useEffect(() => {
    postActions
      .PostDetail(postId, langId)
      .then((res) => {
        if (res && res.content) {
          if (res.content.image_Url && res.content.image_Url !== "null")
            res.content.image_Url = APIUrlDefault + res.content.image_Url;
          setNewsModel(res.content.translations[0]);
          setDefaultPlanning({
            value: res.content.planningId,
            label: res.content.planningName,
          });
          setTranslate(res.content.translations[0]);
          setStatus(res.content.isActive);
          setIsHot(res.content.isHot);
          setContent(res.content.content);
          setIsOwnerNews(res.content.isOwnerNews);
          setSourceNews(res.content.sourceNews);
          setSourceLink(res.content.sourceLink);
          setResponseModel(res.content);
          setFiles(res.content.imageFile ? [res.content.imageFile] : []);
          setFileFb(res.content.fbImageFile ? [res.content.fbImageFile] : []);
          setFileTw(
            res.content.twitterImageFile ? [res.content.twitterImageFile] : []
          );
          setFileDocs(
            res.content.translations[0].files
              ? [res.content.translations[0].files]
              : []
          );
          setHomePage(res.content.isHomePage);
          delete res.content.translations;
          setCore(res.content);
        }
      })
      .catch((err) => console.log(err));
  }, []);

  const methods = useForm({
    mode: "all",
    reValidateMode: "onBlur",
  });

  const { register, handleSubmit, setError, errors, clearErrors, setValue, formState } =
    methods;
  const { isSubmitting } = formState;
  const onSubmit = async(data) => {
    let object = {
      ...translate,
      core: {
        ...core,
        categoryId: parseInt(categoryId),
        isActive: status,
        isHot: isHot,
        isHomePage: isHomePage,
        isOwnerNews: isOwnerNews == "true" ? true : false,
        sourceNews: data.sourceNews,
        sourceLink: data.sourceLink,
      },
      slug: data.slug,
      langId: langId,
      title: data.title,
      description: data.description,
      metaTitle: data.metaTitle,
      metaDescription: data.metaDescription,
      metaKeyword: data.metaKeyword,
      fbDescription: data.fbDescription,
      twitterDescription: data.twitterDescription,
      content: content,
      isActive: status,
    };

    if (data.planning)
      object = {
        ...object,
        core: { ...core, planningId: data.planning.value },
      };

    if (fileImage && fileImage.length > 0) {
      object = {
        ...object,
        image: fileImage[0],
      };
    }

    if (fileImageFb && fileImageFb.length > 0) {
      object = {
        ...object,
        imageFb: fileImageFb[0],
      };
    }

    if (fileImageTw && fileImageTw.length > 0) {
      object = {
        ...object,
        imageTw: fileImageTw[0],
      };
    }

    if (fileDoc && fileDoc.length > 0) {
      object = {
        ...object,
        docFile: fileDoc[0],
      };
    }

    //console.log(object);
    if (files && files.length > 0) {
      object = {
        ...object,
        core: {
          ...object.core,
          imageId: files[0].fileId,
        },
      };
    }
    if (fileTw && fileTw.length > 0) {
      object = {
        ...object,
        core: {
          ...object.core,
          twitterImageId: fileTw[0].fileId,
        },
      };
    }
    if (fileFb && fileFb.length > 0) {
      object = {
        ...object,
        core: {
          ...object.core,
          fbImageId: fileFb[0].fileId,
        },
      };
    }

    if (fileDocs && fileDocs.length > 0) {
      object = {
        ...object,
        fileId: fileDocs[0].fileId,
      };
    }

    const formData = new FormData();
    buildFormData(formData, object);

    await postActions
      .PostsUpdateWithUpload(formData)
      .then((res) => {
        if (res) {
          ShowNotification(
            viVN.Success.EditPostsSuccess,
            NotificationMessageType.Success
          );
        }
      })
      .catch((err) => {
        ShowNotification(
          viVN.Errors[err.errorType],
          NotificationMessageType.Error
        );
      });
  };

  const handleChangeContent = (editorContent) => {
    clearErrors(["editorContent"]);
    if (editorContent === "<p><br></p>") {
      setError("editorContent", { type: "required" });
      setContent("");
    } else {
      clearErrors("editorContent");
      setContent(editorContent);
    }
  };

  const handleChangeStatus = (event) => {
    event.persist();
    setStatus(event.target.checked);
  };

  const handleChangeHot = (event) => {
    event.persist();
    setIsHot(event.target.checked);
  };
  const handleChangeHomePage = (event) => {
    event.persist();
    setHomePage(event.target.checked);
  };
  const onOpenSelectFile = () => {
    setShow(true);
    setFilesTemp(files);
  };

  const onCloseSelectFile = () => {
    setShow(false);
    setFiles(filesTemp);
  };

  const onOpenSelectFileDoc = () => {
    setShowDocs(true);
    setFileTempDocs(fileDocs);
  };

  const onCloseSelectFileDocs = () => {
    setShowDocs(false);
    setFileDocs(fileTempDocs);
  };

  const onSaveSelectFileDocs = () => {
    console.log(fileDocs);
    setShowDocs(false);
  };

  const onSaveSelectFile = () => {
    console.log(files);
    setShow(false);
  };

  const onOpenSelectFileFb = () => {
    setShowFb(true);
    setFileTempFb(fileFb);
  };

  const onOpenSelectFileTw = () => {
    setShowTw(true);
    setFileTempTw(fileTw);
  };

  const onCloseSelectFileFb = () => {
    setShowDocs(false);
    setFileFb(fileTempFb);
  };

  const onSaveSelectFileFb = () => {
    console.log(fileFb);
    setShowFb(false);
  };

  const onCloseSelectFileTw = () => {
    setShowTw(false);
    setFileTw(fileTempTw);
  };

  const onSaveSelectFileTw = () => {
    console.log(fileDocs);
    setShowTw(false);
  };

  const handleRadioChange = (event) => {
    setIsOwnerNews(event.target.value);
    //setError(false);
  };
  const onGoBack = () => {
    const queryParams = window.location.search
    history.push(`/tin-tuc/${id}/${postTypeId}` + queryParams)
  };
  const renderTitle = () => {
    if(id == 3) {
      return "Sửa tin tức quy trình đầu tư dự án"
    }
    if(id == 4) {
      return "Sửa tin tức văn bản pháp lý"
    }
    if(id == 6) {
      return "Sửa tin tức quy hoạch"
    }
    if(id == 7) {
      return "Sửa tin tức dự án"
    }
    if(id == 9) {
      return "Sửa tin tức chung"
    } 
  }
  return (
    <div>
      <FormProvider {...methods}>
        <form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
          <div
            className="d-sm-flex align-items-center mb-2"
            onClick={onGoBack}
            style={{ cursor: "pointer", width: 100 }}
          >
            <ArrowBackIos fontSize="small"></ArrowBackIos> Quay lại
          </div>
          <div className="d-sm-flex align-items-center justify-content-between mb-3">
            <h1 className="h3 mb-0 text-gray-800">{renderTitle()}</h1>
            <Button
              type="submit"
              color="primary"
              variant="contained"
              startIcon={!isSubmitting ? <SaveIcon></SaveIcon> : <CircularProgress size={20} color="white"/>}
              disabled={isSubmitting}
            >
              Lưu
            </Button>
          </div>
          {responseModel && (
            <>
              <Nav tabs>
                <NavItem>
                  <NavLink
                    className={`${tab === 1 ? "active" : ""}`}
                    onClick={() => setTab(1)}
                  >
                    Thông tin dự án
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    className={`${tab === 2 ? "active" : ""}`}
                    onClick={() => setTab(2)}
                  >
                    Thông tin SEO
                  </NavLink>
                </NavItem>
              </Nav>
              <TabContent activeTab={tab}>
                <TabPane tabId={1}>
                  <div className="row pt-4 pb-2">
                    <div className="col-9">
                      <div className="form-group">
                        <label className="text-dark">
                          Tiêu đề<span className="required"></span>
                        </label>
                        <TextField
                          inputRef={register({
                            required: true,
                            maxLength: 150,
                          })}
                          name="title"
                          error={
                            errors.title && errors.title.type === "required"
                          }
                          fullWidth
                          type="text"
                          className="form-control"
                          inputProps={{ maxLength: 150 }}
                          defaultValue={newsModel.title}
                          onChange={(e) => setValue("title", e.target.value)}
                        />
                        {errors.title && errors.title.type === "required" && (
                          <span className="error">Trường này là bắt buộc</span>
                        )}
                        {errors.title && errors.title.type === "maxLength" && (
                          <span className="error">
                            Trường này không quá 150 ký tự
                          </span>
                        )}
                      </div>
                      <div className="form-group">
                        <label className="text-dark">
                          Liên kết
                        </label>
                        <TextField
                          inputRef={register({
                            required: false,
                          })}
                          name="slug"
                          fullWidth
                          type="text"
                          className="form-control"
                          defaultValue={newsModel.slug}
                          onChange={(e) => setValue("slug", e.target.value)}
                          InputProps={{
                            endAdornment: (
                              <InputAdornment 
                                style={{  color: "#ccc"}}
                                >
                                -{newsModel.id}
                              </InputAdornment>
                            ),
                            style: {
                              paddingRight: "0"
                           }
                          }}
                        />
                      </div>
                      <div className="form-group">
                        <label className="text-dark">
                          Tóm tắt<span className="required"></span>
                        </label>
                        <textarea
                          name="description"
                          rows="5"
                          ref={register({ required: true, maxLength: 550 })}
                          className={
                            "form-control" +
                            (errors.description &&
                            errors.description.type === "required"
                              ? " is-invalid"
                              : "")
                          }
                          maxLength="500"
                          defaultValue={newsModel.description}
                          onChange={(e) =>
                            setValue("description", e.target.value)
                          }
                        ></textarea>
                        {errors.description &&
                          errors.description.type === "required" && (
                            <span className="error">
                              Trường này là bắt buộc
                            </span>
                          )}
                        {errors.description &&
                          errors.description.type === "maxLength" && (
                            <span className="error">
                              Trường này không quá 550 ký tự
                            </span>
                          )}
                      </div>
                      <div className="form-group">
                        <label className="text-dark">Nội dung</label>
                        <SunEditor
                          enableToolbar={true}
                          showToolbar={true}
                          setContents={newsModel.content}
                          videoFileInput={false}
                          setOptions={{
                            height: 500,
                            imageUploadUrl: urlUploadImage,
                            imageUploadSizeLimit: MaxSizeImageUpload,
                            imageAccept: ".jpg,.jpeg,.png,.gift,.svg,.tif",
                            buttonList: [
                              [
                                "undo",
                                "redo",
                                "font",
                                "fontSize",
                                "formatBlock",
                                "paragraphStyle",
                                "blockquote",
                                "bold",
                                "underline",
                                "italic",
                                "strike",
                                "subscript",
                                "superscript",
                                "fontColor",
                                "hiliteColor",
                                "textStyle",
                                "removeFormat",
                                "outdent",
                                "indent",
                                "align",
                                "horizontalRule",
                                "list",
                                "lineHeight",
                                "table",
                                "link",
                                "image",
                                "video",
                                "audio",
                                "fullScreen",
                                "showBlocks",
                                "codeView",
                              ],
                            ],
                          }}
                          onChange={handleChangeContent}
                          onBlur={(event, editorContents) =>
                            handleChangeContent(editorContents)
                          }
                        />
                      </div>
                    </div>
                    <div className="col-3">
                      <div className="form-group row">
                        <div className="col-12">
                          <label className="text-dark">
                            Ảnh đại diện<span className="required"></span>
                          </label>
                          {files &&
                            files.length > 0 &&
                            files.map((item) => (
                              <div
                                key={item.fileName}
                                style={{ width: "150px" }}
                              >
                                <img
                                  src={APIUrlDefault + item.filePreview}
                                  alt={item.fileName}
                                  className="img-fluid mb-2"
                                  style={{
                                    width: "auto",
                                    height: "auto",
                                    maxWidth: "100%",
                                    maxHeight: "100%",
                                  }}
                                />
                              </div>
                            ))}
                          <div>
                            {
                              //<Button
                              //    variant='contained'
                              //    color='primary'
                              //    onClick={onOpenSelectFile}>
                              //    Chọn file
                              //</Button>
                            }
                            <TextField
                              inputRef={register({ required: true })}
                              type="hidden"
                              name="image"
                              value={
                                (files &&
                                  files.length > 0 &&
                                  files[0].fileName) ||
                                ""
                              }
                            />
                            {errors.image &&
                              errors.image.type === "required" && (
                                <p className="error">Trường này là bắt buộc</p>
                              )}
                          </div>
                          <DropzoneArea
                            acceptedFiles={["image/png, image/gif, image/jpeg, image/jpg"]}
                            filesLimit={1}
                            dropzoneText="Thay ảnh đại diện"
                            onChange={(files) => {
                              setFileImage(files);
                            }}
                          />
                        </div>
                        {postTypeId === "3" && (
                          <div className="col-12">
                            <label className="text-dark">Tài liệu</label>
                            {!isShowDocs &&
                              fileDocs &&
                              fileDocs.length > 0 &&
                              fileDocs.map((item) => (
                                <div
                                  key={item.fileName}
                                  style={{ width: "150px" }}
                                >
                                  <div>{item.fileName}</div>
                                </div>
                              ))}
                            <DropzoneArea
                              acceptedFiles={[
                                "image/*",
                                "application/pdf",
                                ".doc",
                                ".docx",
                                ".xls",
                                ".xlsx",
                                ".csv",
                                ".ppt",
                                ".pptx",
                              ]}
                              filesLimit={1}
                              dropzoneText="Kéo thả tệp tài liệu vào đây"
                              maxFileSize={10485760}
                              onChange={(files) => {
                                setFileDoc(files);
                              }}
                            />
                          </div>
                        )}
                        {postTypeId === "1" && defaultPlanning && (
                          <>
                            <div className="col-12 col-md-12 col-lg-12">
                              <hr />
                              <FormAsyncPlanning
                                name="planning"
                                placeholder="Chọn quy hoạch..."
                                defaultValue={defaultPlanning}
                              />
                            </div>
                          </>
                        )}
                      </div>
                      <hr />
                      <div className="form-group">
                        <div className="row">
                          <div className="col-12">
                            <label className="text-dark">Kích hoạt</label>
                            <Checkbox
                              checked={status}
                              onChange={handleChangeStatus}
                              color="primary"
                              inputProps={{
                                "aria-label": "secondary checkbox",
                              }}
                              className="p-0 mt-0 ml-4"
                            />
                          </div>
                        </div>
                        {postTypeId !== "5" && (
                          <>
                            <div className="row">
                              <div className="col-12">
                                <label className="text-dark">Tin nóng</label>
                                <Checkbox
                                  checked={isHot}
                                  onChange={handleChangeHot}
                                  color="primary"
                                  inputProps={{
                                    "aria-label": "secondary checkbox",
                                  }}
                                  className="p-0 mt-0 ml-4"
                                />
                              </div>
                            </div>
                            <div className="row">
                              <div className="col-12">
                                <label className="text-dark">
                                  Hiển thị ở trang chủ
                                </label>
                                <Checkbox
                                  checked={isHomePage}
                                  onChange={handleChangeHomePage}
                                  color="primary"
                                  inputProps={{
                                    "aria-label": "secondary checkbox",
                                  }}
                                  className="p-0 mt-0 ml-4"
                                />
                              </div>
                            </div>
                          </>
                        )}
                        <div className="row">
                          <div className="col-12">
                            <RadioGroup
                              aria-label="isOwnerNews"
                              name="isOwnerNews"
                              defaultValue={
                                responseModel && responseModel.isOwnerNews
                                  ? "true"
                                  : "false"
                              }
                              onChange={handleRadioChange}
                            >
                              <FormControlLabel
                                value="true"
                                control={<Radio />}
                                label="Tin tự viết"
                              />
                              <FormControlLabel
                                value="false"
                                control={<Radio />}
                                label="Tin trích dẫn"
                              />
                            </RadioGroup>
                          </div>
                        </div>
                        <div className="row">
                          <div className="form-group col-12">
                            <label className="text-dark">Nguồn trích dẫn</label>
                            <TextField
                              name="sourceNews"
                              disabled={
                                (isOwnerNews && isOwnerNews == "true") ||
                                (responseModel && responseModel.isOwnerNews)
                              }
                              error={
                                errors.sourceNews &&
                                errors.sourceNews.type === "maxLength"
                              }
                              fullWidth
                              defaultValue={responseModel.sourceNews}
                              type="text"
                              className="form-control"
                              inputRef={register({ maxLength: 150 })}
                              inputProps={{ maxLength: 150 }}
                              onChange={(e) =>
                                setValue("sourceNews", e.target.value)
                              }
                            />
                            {errors.sourceNews &&
                              errors.sourceNews.type === "maxLength" && (
                                <span className="error">
                                  Trường này không quá 150 ký tự
                                </span>
                              )}
                          </div>
                        </div>
                        <div className="row">
                          <div className="form-group col-12">
                            <label className="text-dark">Link trích dẫn</label>

                            <TextField
                              name="sourceLink"
                              disabled={
                                (isOwnerNews && isOwnerNews == "true") ||
                                (responseModel && responseModel.isOwnerNews)
                              }
                              error={
                                errors.sourceLink &&
                                errors.sourceLink.type === "maxLength"
                              }
                              fullWidth
                              defaultValue={responseModel.sourceLink}
                              type="text"
                              className="form-control"
                              inputRef={register({
                                maxLength: 150,
                                pattern:
                                  /(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b/g,
                              })}
                              inputProps={{ maxLength: 150 }}
                              onChange={(e) =>
                                setValue("sourceLink", e.target.value)
                              }
                            />
                            {errors.sourceLink &&
                              errors.sourceLink.type === "maxLength" && (
                                <span className="error">
                                  Trường này không quá 150 ký tự
                                </span>
                              )}
                            {errors.sourceLink &&
                              errors.sourceLink.type === "pattern" && (
                                <span className="error">
                                  Trường này chưa phải là liên kết
                                </span>
                              )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-8 col-sm-12"></div>
                    <div className="col-4 col-sm-12"></div>
                  </div>
                </TabPane>
                <TabPane tabId={2}>
                  <div className="row pt-4 pb-2">
                    <div className="col-6">
                      <div className="form-group">
                        <label className="text-dark">Mô tả ngắn SEO</label>
                        <textarea
                          name="metaDescription"
                          rows="5"
                          ref={register({ maxLength: 550 })}
                          maxLength="500"
                          defaultValue={newsModel.metaDescription}
                          className={
                            "form-control" +
                            (errors.metaDescription &&
                            errors.metaDescription.type === "required"
                              ? " is-invalid"
                              : "")
                          }
                        ></textarea>
                        {errors.metaDescription &&
                          errors.metaDescription.type === "required" && (
                            <span className="error">
                              Trường này là bắt buộc
                            </span>
                          )}
                        {errors.metaDescription &&
                          errors.metaDescription.type === "maxLength" && (
                            <span className="error">
                              Trường này không quá 550 ký tự
                            </span>
                          )}
                      </div>
                      <div className="form-group">
                        <label className="text-dark">Tiêu đề SEO</label>
                        <TextField
                          name="metaTitle"
                          error={
                            errors.metaTitle &&
                            (errors.metaTitle.type === "required" ||
                              errors.metaTitle.type === "maxLength")
                          }
                          fullWidth
                          type="text"
                          className="form-control"
                          defaultValue={newsModel.metaTitle}
                          inputRef={register({ maxLength: 150 })}
                          inputProps={{ maxLength: 150 }}
                          onChange={(e) =>
                            setValue("metaTitle", e.target.value)
                          }
                        />
                        {errors.metaTitle &&
                          errors.metaTitle.type === "required" && (
                            <span className="error">
                              Trường này là bắt buộc
                            </span>
                          )}
                        {errors.metaTitle &&
                          errors.metaTitle.type === "maxLength" && (
                            <span className="error">
                              Trường này không quá 150 ký tự
                            </span>
                          )}
                      </div>
                      <div className="form-group">
                        <label className="text-dark">Từ khoá SEO</label>
                        <TextField
                          name="metaKeyword"
                          error={
                            errors.metaKeyword &&
                            (errors.metaKeyword.type === "required" ||
                              errors.metaKeyword.type === "maxLength")
                          }
                          fullWidth
                          type="text"
                          className="form-control"
                          defaultValue={newsModel.metaKeyword}
                          inputRef={register({ maxLength: 550 })}
                          inputProps={{ maxLength: 550 }}
                          onChange={(e) =>
                            setValue("metaKeyword", e.target.value)
                          }
                        />
                        {errors.metaKeyword &&
                          errors.metaKeyword.type === "required" && (
                            <span className="error">
                              Trường này là bắt buộc
                            </span>
                          )}
                        {errors.metaKeyword &&
                          errors.metaKeyword.type === "maxLength" && (
                            <span className="error">
                              Trường này không quá 150 ký tự
                            </span>
                          )}
                      </div>
                    </div>
                    <div className="col-6">
                      <div className="form-group">
                        <label className="text-dark">
                          Mô tả share Facebook
                        </label>
                        <textarea
                          name="fbDescription"
                          rows="5"
                          ref={register({ maxLength: 550 })}
                          maxLength="550"
                          defaultValue={newsModel.fbDescription}
                          className={
                            "form-control" +
                            (errors.fbDescription &&
                            errors.fbDescription.type === "required"
                              ? " is-invalid"
                              : "")
                          }
                        ></textarea>
                        {errors.fbDescription &&
                          errors.fbDescription.type === "required" && (
                            <span className="error">
                              Trường này là bắt buộc
                            </span>
                          )}
                        {errors.fbDescription &&
                          errors.fbDescription.type === "maxLength" && (
                            <span className="error">
                              Trường này không quá 550 ký tự
                            </span>
                          )}
                      </div>

                      <div className="form-group">
                        <label className="text-dark">Mô tả share Twitter</label>
                        <textarea
                          name="twitterDescription"
                          rows="5"
                          ref={register({ maxLength: 550 })}
                          maxLength="550"
                          defaultValue={newsModel.twitterDescription}
                          className={
                            "form-control" +
                            (errors.twitterDescription &&
                            errors.twitterDescription.type === "required"
                              ? " is-invalid"
                              : "")
                          }
                        ></textarea>
                        {errors.twitterDescription &&
                          errors.twitterDescription.type === "required" && (
                            <span className="error">
                              Trường này là bắt buộc
                            </span>
                          )}
                        {errors.twitterDescription &&
                          errors.twitterDescription.type === "maxLength" && (
                            <span className="error">
                              Trường này không quá 550 ký tự
                            </span>
                          )}
                      </div>
                    </div>
                  </div>
                  <div className="form-group row">
                    <div className="col-6">
                      <label className="text-dark">Ảnh Twitter</label>
                      {!isShowTw &&
                        fileTw &&
                        fileTw.length > 0 &&
                        fileTw.map((item) => (
                          <div key={item.fileName} style={{ width: "150px" }}>
                            <img
                              src={APIUrlDefault + item.filePreview}
                              alt={item.fileName}
                              className="img-fluid mb-2"
                              style={{
                                width: "auto",
                                height: "auto",
                                maxWidth: "100%",
                                maxHeight: "100%",
                              }}
                            />
                          </div>
                        ))}
                      {
                        //<div>
                        //    <Button
                        //        variant='contained'
                        //        color='primary'
                        //        onClick={onOpenSelectFileTw}>
                        //        Chọn file
                        //    </Button>
                        //    <TextField
                        //        type='hidden'
                        //        name='image'
                        //        value={
                        //            (fileTw && fileTw.length > 0 && fileTw[0].fileName) ||
                        //            ''
                        //        }
                        //    />
                        //</div>
                      }
                      <DropzoneArea
                        acceptedFiles={["image/png, image/gif, image/jpeg, image/jpg"]}
                        filesLimit={1}
                        dropzoneText="Kéo thả ảnh share twitter vào đây"
                        onChange={(files) => {
                          setFileImageTw(files);
                        }}
                      />
                    </div>
                    <div className="col-6">
                      <label className="text-dark">Ảnh Facebook</label>
                      {!isShowFb &&
                        fileFb &&
                        fileFb.length > 0 &&
                        fileFb.map((item) => (
                          <div key={item.fileName} style={{ width: "150px" }}>
                            <img
                              src={APIUrlDefault + item.filePreview}
                              alt={item.fileName}
                              className="img-fluid mb-2"
                              style={{
                                width: "auto",
                                height: "auto",
                                maxWidth: "100%",
                                maxHeight: "100%",
                              }}
                            />
                          </div>
                        ))}
                      {
                        //<div>
                        //    <Button
                        //        variant='contained'
                        //        color='primary'
                        //        onClick={onOpenSelectFileFb}>
                        //        Chọn file
                        //    </Button>
                        //    <TextField
                        //        type='hidden'
                        //        name='image'
                        //        value={
                        //            (fileFb && fileFb.length > 0 && fileFb[0].fileName) ||
                        //            ''
                        //        }
                        //    />
                        //</div>
                      }
                      <DropzoneArea
                        acceptedFiles={["image/png, image/gif, image/jpeg, image/jpg"]}
                        filesLimit={1}
                        dropzoneText="Kéo thả ảnh share facebook vào đây"
                        onChange={(files) => {
                          setFileImageFb(files);
                        }}
                      />
                    </div>
                  </div>
                </TabPane>
              </TabContent>
            </>
          )}
        </form>
      </FormProvider>

      {isShow && (
        <Dialog
          onClose={onCloseSelectFile}
          open={isShow}
          fullWidth={true}
          maxWidth="md"
          className="dialog-preview-form"
        >
          <DialogTitle disableTypography>
            <Typography variant="h6">Quản lý file</Typography>
            <IconButton
              aria-label="close"
              className={classes.closeButton}
              onClick={onCloseSelectFile}
            >
              <CloseIcon />
            </IconButton>
          </DialogTitle>
          <DialogContent dividers>
            <FileManagement
              files={files}
              setFiles={setFiles}
              acceptedFiles={["jpeg", "png", "jpg", "gif"]}
            />
          </DialogContent>

          <DialogActions>
            <Button
              type="button"
              onClick={onCloseSelectFile}
              variant="contained"
              startIcon={<CloseIcon />}
            >
              Hủy
            </Button>
            {files && files.length > 0 && (
              <Button
                type="button"
                color="primary"
                variant="contained"
                startIcon={<SaveIcon />}
                onClick={onSaveSelectFile}
              >
                Lưu
              </Button>
            )}
          </DialogActions>
        </Dialog>
      )}

      {isShowDocs && (
        <Dialog
          onClose={onCloseSelectFileDocs}
          open={isShowDocs}
          fullWidth={true}
          maxWidth="md"
          className="dialog-preview-form"
        >
          <DialogTitle disableTypography>
            <Typography variant="h6">Quản lý file</Typography>
            <IconButton
              aria-label="close"
              className={classes.closeButton}
              onClick={onCloseSelectFileDocs}
            >
              <CloseIcon />
            </IconButton>
          </DialogTitle>
          <DialogContent dividers>
            <FileManagement
              files={fileDocs}
              setFiles={setFileDocs}
              acceptedFiles={["doc", "docx", "xls", "xlsx", "pdf"]}
            />
          </DialogContent>

          <DialogActions>
            <Button
              type="button"
              onClick={onCloseSelectFileDocs}
              variant="contained"
              startIcon={<CloseIcon />}
            >
              Hủy
            </Button>
            {fileDocs && fileDocs.length > 0 && (
              <Button
                type="button"
                color="primary"
                variant="contained"
                startIcon={<SaveIcon />}
                onClick={onSaveSelectFileDocs}
              >
                Lưu
              </Button>
            )}
          </DialogActions>
        </Dialog>
      )}

      {isShowFb && (
        <Dialog
          onClose={onCloseSelectFileFb}
          open={isShowFb}
          fullWidth={true}
          maxWidth="md"
          className="dialog-preview-form"
        >
          <DialogTitle disableTypography>
            <Typography variant="h6">Quản lý file</Typography>
            <IconButton
              aria-label="close"
              className={classes.closeButton}
              onClick={onCloseSelectFileFb}
            >
              <CloseIcon />
            </IconButton>
          </DialogTitle>
          <DialogContent dividers>
            <FileManagement
              files={fileFb}
              setFiles={setFileFb}
              acceptedFiles={["jpeg", "png", "jpg", "gif"]}
            />
          </DialogContent>

          <DialogActions>
            <Button
              type="button"
              onClick={onCloseSelectFileFb}
              variant="contained"
              startIcon={<CloseIcon />}
            >
              Hủy
            </Button>
            {fileFb && fileFb.length > 0 && (
              <Button
                type="button"
                color="primary"
                variant="contained"
                startIcon={<SaveIcon />}
                onClick={onSaveSelectFileFb}
              >
                Lưu
              </Button>
            )}
          </DialogActions>
        </Dialog>
      )}

      {isShowTw && (
        <Dialog
          onClose={onCloseSelectFileTw}
          open={isShowTw}
          fullWidth={true}
          maxWidth="md"
          className="dialog-preview-form"
        >
          <DialogTitle disableTypography>
            <Typography variant="h6">Quản lý file</Typography>
            <IconButton
              aria-label="close"
              className={classes.closeButton}
              onClick={onCloseSelectFileTw}
            >
              <CloseIcon />
            </IconButton>
          </DialogTitle>
          <DialogContent dividers>
            <FileManagement
              files={fileTw}
              setFiles={setFileTw}
              acceptedFiles={["jpeg", "png", "jpg", "gif"]}
            />
          </DialogContent>

          <DialogActions>
            <Button
              type="button"
              onClick={onCloseSelectFileTw}
              variant="contained"
              startIcon={<CloseIcon />}
            >
              Hủy
            </Button>
            {fileTw && fileTw.length > 0 && (
              <Button
                type="button"
                color="primary"
                variant="contained"
                startIcon={<SaveIcon />}
                onClick={onSaveSelectFileTw}
              >
                Lưu
              </Button>
            )}
          </DialogActions>
        </Dialog>
      )}
    </div>
  );
}
const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      showLoading: appActions.ShowLoading,
    },
    dispatch
  );

export default connect(null, mapDispatchToProps)(EditSlider);
