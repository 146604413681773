import React from 'react';
import {useDestructureListToOptions} from '../hooks';
import CustomFormSelect from './form-select';

const SelectProvince = ({listProvinces = []}) => {
  const provinceOptions = useDestructureListToOptions({
    listData: listProvinces
  });

  return (
    <>
      <CustomFormSelect
        name="provinceId"
        label="Tỉnh"
        options={provinceOptions}
        isRequired
        defaultValue={null}
        placeholderText="Chọn tỉnh"
      />
    </>
  );
};

export default SelectProvince;
