import axios from "axios";
import * as apiConfig from "./api-config";
import * as configuration from "../utils/configuration";
import * as Page500ErrorTypes from "../common/error-types-500";

//--- Notifications
import ShowNotification from '../components/react-notifications/react-notifications';
import { NotificationMessageType } from '../utils/configuration';
import * as viVN from '../language/vi-VN.json';
import platform from 'platform';
import { history } from "../utils/helpers/history";
import { UrlCollection } from "../common/url-collection";

configuration.setConfiguration(
  configuration.ApiServerKey.APP_API_ROOT,
  apiConfig.api
);
const apiRoot = configuration.getConfiguration(
  configuration.ApiServerKey.APP_API_ROOT
);

const isHandlerEnabled = true;

const requestHandler = (request) => {
  if (isHandlerEnabled) {
    // request.headers.common["Accept"] = "application/json";
    request.headers.common["Content-Type"] = "application/json; charset=utf-8";
    request.headers.common["Accept"] =
      "application/json, text/javascript, */*; q=0.01";
    request.headers.common["Access-Control-Allow-Origin"] = "*";
  }

  let userInfo = configuration.getCookies(configuration.TokenKey.accessToken);
  if (userInfo) {
    request.headers.common[
      "Authorization"
    ] = `${configuration.TokenPrefix} ${userInfo}`;
  }

  return request;
};

const successHandler = (response, isHandlerEnabled) => {
  if (isHandlerEnabled) {
    //TODO: Do Success Handler
    if(response.status === 403) {
      ShowNotification(
        viVN.Errors["AccessDenied"],
        NotificationMessageType.Error
      );
    }
  }

  return response;
};

const errorHandler = (error, isHandlerEnabled) => {
  if (isHandlerEnabled) {
    //TODO: Do Error Handler
    if(error.response.status === 403) {
      ShowNotification(
        viVN.Errors["AccessDenied"],
        NotificationMessageType.Error
      );
    }

    if(error.response.status === 401) {
      ShowNotification(
        viVN.Errors["UnAuthenticate"],
        NotificationMessageType.Error
      );

      // Remove cookies
      configuration.removeJWTCookies();
      configuration.setCookies(configuration.TokenKey.returnUrl, UrlCollection.Home);
      history.push(UrlCollection.Login);
      window.location.reload(true);
    }
  }

  return Promise.reject({
    ...(error.response
      ? error.response.data
      : {
        errorType: Page500ErrorTypes.UnhandleException,
        errorMessage: "UnhandleException",
      }),
  });
};

export default class Service {
  constructor(namespace) {
    this.namespace = namespace;
    this.axios = axios.create({
      baseURL: apiRoot,
      responseType: "json",
      headers: {
        device: 'Desktop',
        browser: platform.name,
        os: platform.os
      }
    });

    //Enable request interceptor
    this.axios.interceptors.request.use(
      (request) => requestHandler(request, isHandlerEnabled),
      (error) => errorHandler(error, isHandlerEnabled)
    );

    //Response and Error handler
    this.axios.interceptors.response.use(
      (response) => successHandler(response, isHandlerEnabled),
      (error) => errorHandler(error, isHandlerEnabled)
    );
  }

  /**
   * Get Http Request
   * @param {any} action
   */
  get(action, params) {
    return new Promise((resolve, reject) => {
      this.axios
        .request(params ? action + "?" + params : action, {
          method: "GET",
        })
        .then((response) => {
          if (response.data) {
            resolve(response.data);
          } else {
            reject(response);
          }
        })
        .catch((error) => {
          if (
            error.response &&
            error.response.data &&
            error.response.data.error
          ) {
            console.error("REST request error!", error.response.data.error);
            reject(error.response.data.error);
          } else reject(error);
        });
    });
  }

  /**
   * Post Http Request
   * @param {any} action
   * @param {any} params
   */
  postParams(action, params, body) {
    return new Promise((resolve, reject) => {
      this.axios
        .request(params ? action + "?" + params : action, {
          method: "POST",
          data: body,
        })
        .then((response) => {
          if (response.data) {
            resolve(response.data);
          } else {
            reject(response);
          }
        })
        .catch((error) => {
          if (
            error.response &&
            error.response.data &&
            error.response.data.error
          ) {
            console.error("REST request error!", error.response.data.error);
            reject(error.response.data.error);
          } else reject(error);
        });
    });
  }

  /**
   * Get Http Request
   * @param {any} action
   */
  getBinary(action, params) {
    return new Promise((resolve, reject) => {
      axios
        .create({
          baseURL: apiRoot,
          responseType: "blob",
        })
        .request(params ? action + "?" + params : action, {
          method: "GET",
        })
        .then((response) => {
          if (response.data) {
            resolve(response.data);
          } else {
            reject(response);
          }
        })
        .catch((error) => {
          if (
            error.response &&
            error.response.data &&
            error.response.data.error
          ) {
            console.error("REST request error!", error.response.data.error);
            reject(error.response.data.error);
          } else reject(error);
        });
    });
  }
  /**
   * Get Http Request
   * @param {any} action
   */
  postBinary(action, body, params, callback = () => { }) {
    callback(0);
    let userInfo = configuration.getCookies(configuration.TokenKey.accessToken);
    let headers = {};
    if (userInfo) {
      headers = {
        "Authorization": `${configuration.TokenPrefix} ${userInfo}`
      }
    }
    return new Promise((resolve, reject) => {
      axios
        .create({
          baseURL: apiRoot,
          responseType: "blob",
          headers: headers,
          onDownloadProgress: progressEvent => {
            const { loaded, total } = progressEvent;
            let percentCompleted = Math.floor((loaded * 100) / total);
            callback(percentCompleted);
          }
        })
        .request(params ? action + "?" + params : action, {
          data: body,
          method: "POST",
        })
        .then((response) => {
          if (response.data) {
            resolve(response.data);
          } else {
            reject(response);
          }
        })
        .catch((error) => {
          if (
            error.response &&
            error.response.data &&
            error.response.data.error
          ) {
            console.error("REST request error!", error.response.data.error);
            reject(error.response.data.error);
          } else reject(error);
        });
    });
  }

  /**
   * Post Http Request
   * @param {any} action
   * @param {any} params
   */
  post(action, params) {
    return new Promise((resolve, reject) => {
      this.axios
        .request(action, {
          method: "POST",
          data: params,
        })
        .then((response) => {
          if (response.data) {
            resolve(response.data);
          } else {
            reject(response);
          }
        })
        .catch((error) => {
          if (
            error.response &&
            error.response.data &&
            error.response.data.error
          ) {
            console.error("REST request error!", error.response.data.error);
            reject(error.response.data.error);
          } else reject(error);
        });
    });
  }

  /**
   * Put Http Request
   * @param {any} action
   * @param {any} params
   */
  put(action, requestBody, params = null) {
    return new Promise((resolve, reject) => {
      this.axios
        .request(params ? action + "?" + params : action, {
          method: "PUT",
          data: requestBody,
        })
        .then((response) => {
          if (response.data) {
            resolve(response.data);
          } else {
            reject(response);
          }
        })
        .catch((error) => {
          if (
            error.response &&
            error.response.data &&
            error.response.data.error
          ) {
            console.error("REST request error!", error.response.data.error);
            reject(error.response.data.error);
          } else reject(error);
        });
    });
  }
  /**
   *Delete Http Request
   * @param {any} action
   * @param {any} params
   */
  delete(action, params = null) {
    return new Promise((resolve, reject) => {
      this.axios
        .request(params ? action + "?" + params : action, {
          method: "DELETE",
        })
        .then((response) => {
          if (response.data) {
            resolve(response.data);
          } else {
            reject(response);
          }
        })
        .catch((error) => {
          if (
            error.response &&
            error.response.data &&
            error.response.data.error
          ) {
            console.error("REST request error!", error.response.data.error);
            reject(error.response.data.error);
          } else reject(error);
        });
    });
  }
}
