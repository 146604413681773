import React, { useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';

//--- Styles
import '../styles.scss';

//--- Material Control
import {
  DialogActions,
  Button,
  DialogContent,
  DialogTitle,
  Dialog,
  Typography,
  IconButton,
  makeStyles,
  Grid,
} from '@material-ui/core';

//--- Material Icon
import CloseIcon from '@material-ui/icons/Close';
import SaveIcon from '@material-ui/icons/Save';
import 'suneditor/dist/css/suneditor.min.css';

import * as viVN from "../../../language/vi-VN.json";
import ShowNotification from "../../../components/react-notifications/react-notifications";
import { NotificationMessageType } from "../../../utils/configuration";

//--- Action
import * as landPriceInfoActions from "../../../redux/store/land-price-info/land-price-info.store";

import LandPriceInfoFormItems from '../components/FormItems';
import AddUpdateMethods from '../components/AddUpdateMethods';

const useStyles = makeStyles((theme) => ({
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  formControl: {
    margin: theme.spacing(1),
  },
}));

export default function AddLandPriceInfo(props) {
  const {
    isOpen,
    onClose,
    onSuccess,
    setRequestParams,
    soilTypes,
    realEstateTypes,
    langId,
    provinceSelect,
  } = props;
  const classes = useStyles();

  const methods = useForm({
    defaultValues: {
      statePrice: 0,
      soilType: 0,
      stateVT1: 0,
      stateVT2: 0,
      stateVT3: 0,
      stateVT4: 0,
      stateVT5: 0,
      isActive: '',
      description: '',
      fromToAddr: '',
      fromCoordinate: '',
      toCoordinate: '',
      commune: null,
      district: null,
      province: null,
      dateApply: ''
    }
  });

  const { handleSubmit, setValue, watch } = methods;

  const [communes, setCommunes] = useState();
  const [districtByProvinceId, setDistrictByProvinceId] = useState();

  const {
    onSubmit
  } = AddUpdateMethods({
    onSuccess,
    setValue,
    watch,
    setCommunes,
    setDistrictByProvinceId,
    setRequestParams,
    provinceSelect,
    langId,
  });

  return (
    <div>
      <Dialog open={isOpen} onClose={onClose} fullWidth={true} maxWidth='lg'>
        <DialogTitle disableTypography className='border-bottom'>
          <Typography variant='h6'>Thêm thông tin giá đất</Typography>
          <IconButton
            aria-label='close'
            className={classes.closeButton}
            onClick={onClose}>
            <CloseIcon />
          </IconButton>
        </DialogTitle>

        <FormProvider {...methods}>
          <form onSubmit={handleSubmit(onSubmit)} autoComplete='off'>

            <LandPriceInfoFormItems
              soilTypes={soilTypes}
              realEstateTypes={realEstateTypes}
              langId={langId}
              provinceSelect={provinceSelect}
              districtByProvinceId={districtByProvinceId}
              communes={communes}
            />

            <DialogActions className='border-top'>
              <Button
                type='button'
                onClick={onClose}
                variant='contained'
                startIcon={<CloseIcon />}>
                Hủy
              </Button>
              <Button
                type='submit'
                color='primary'
                variant='contained'
                startIcon={<SaveIcon />}>
                Lưu
              </Button>
            </DialogActions>
          </form>
        </FormProvider>
      </Dialog>
    </div>
  );
}
