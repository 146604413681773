import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { DropzoneArea } from "material-ui-dropzone";
import Autocomplete from "@material-ui/lab/Autocomplete";
import AsyncSelect from 'react-select/async';
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as appActions from "../../../../core/app.store";


//--- Styles
// import '../news.scss';

//--- Material Control
import {
    DialogActions,
    Button,
    TextField,
    DialogContent,
    DialogTitle,
    Dialog,
    Typography,
    IconButton,
    makeStyles,
    Checkbox,
} from '@material-ui/core';

//--- Material Icon
import CloseIcon from '@material-ui/icons/Close';
import SaveIcon from '@material-ui/icons/Save';
import ShowNotification from '../../../../components/react-notifications/react-notifications';
import {
    NotificationMessageType,
    APIUrlDefault,
    MaxSizeImageUpload,
} from '../../../../utils/configuration';
import * as viVN from '../../../../language/vi-VN.json';
import * as sceneAction from "../../../../redux/store/vr360/vr360.store"

const useStyles = makeStyles((theme) => ({
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500],
    },
    formControl: {
        margin: theme.spacing(1),
    },
}));

var query = null;

const EditEdition = (props) => {
    const {
        item,
        isOpen,
        onClose,
        onSuccess,
        GetListAll,
        rowsPerPage,
        setOrder,
        setOrderBy,
        showLoading,
    } = props;
    const classes = useStyles();
    const [fileUpload, setFileUpload] = useState([]);
    const [viewDefault, setViewDefault] = useState(item.isViewDefault);
    const [defaultOptions, setDefaultOptions] = useState([]);
    const [connection, setConnection] = useState(
        item.connectionName.length > 0 ? item.connectionName.map(x => {
            return {...x, value: x.id, label: x.name}
        }): []
    );
    const [curVrProject, setCurVrProject] = useState({ id: item.vrProjectId, name: item.vrProjectName });
    const [options, setOptions] = useState([]);

    useEffect(() => {
        VrProjectGetLookup();
        sceneAction.ConnectionSceneGetListAll({ pageIndex: 1, pageSize: 100 }).then(res => {
            if (res && res.content) setDefaultOptions(res.content.items.map(item => {
                return { ...item, label: item.name, }
            }))
        })
    }, [])

    const VrProjectGetLookup = () => {
        showLoading(true);
        sceneAction
            .VrProjectGetLookup()
            .then((res) => {
                if (res && res.content) {
                    setOptions(res.content);
                } else setOptions([])
                showLoading(false);
            })
            .catch((err) => {
                err &&
                    err.errorType &&
                    ShowNotification(
                        viVN.Errors[err.errorType],
                        NotificationMessageType.Error
                    );
                showLoading(false);
            });
    };

    const handleChangeViewDefault = () => {
        setViewDefault(!viewDefault);
    }

    const loadOptions = (inputValue, callback) => {
        if (query) {
            clearTimeout(query);
        }

        if (inputValue.length > 2) {
            query = setTimeout(() => {
                if (inputValue) {
                    const getListAddress = async () => {
                        let results = [];
                        const res = await sceneAction.ConnectionSceneGetListAll({keyword: inputValue});
                        if (res.content.items && res.content.items.length > 0) {
                            results = res.content.items.map(item => {
                                return {
                                    ...item,
                                    value: item.id,
                                    label: item.name,
                                };
                            })
                        }
                        if (results.length == 0) {
                            ShowNotification(
                                'Vui lòng nhập địa chỉ khác, hoặc thêm địa chỉ mới',
                                NotificationMessageType.Warning,
                                'center'
                            );
                        }
                        callback(results);
                    };
                    getListAddress();
                }
            }, 800);
        } else {
            callback([]);
        }
    };

    const handleChoose = (data) => {
        console.log('data', data);
        setConnection(data)
    }

    const deleteConnection = id => {
        setConnection(connection.filter(x => x.id !== id))
    }
    useEffect(() => {
        console.log('connection', connection)
    }, [connection])

    const { register, handleSubmit, setError, errors, clearErrors, setValue } =
        useForm({
            mode: 'all',
            reValidateMode: 'onBlur',
        });

    const onSubmit = (data) => {
        console.log('data', data)
        const formData = new FormData();
        formData.append('Id', item.id);
        formData.append('Name', data.name);
        formData.append('Code', data.code);
        curVrProject?.id && formData.append('VRProjectId', curVrProject?.id);
        formData.append('IsViewDefault', viewDefault);
        data.title && formData.append('Title', data.title);
        connection.length > 0 && connection.map(item => {
            formData.append('ConnectionIds', item.id);
        })
        if (fileUpload && fileUpload.length > 0) {
            formData.append('file', fileUpload[0]);
        }
        sceneAction.SceneUpdate(formData).then(res => {
            if (res && res.content) {
                onClose();
                GetListAll();
                ShowNotification(
                    'Thêm mới thành công',
                    NotificationMessageType.Success
                );
            }
        }).catch(err => {
            console.log(err)
        })
    }

    return (
        <Dialog open={isOpen} onClose={onClose} fullWidth={true} maxWidth='md'>
            <DialogTitle disableTypography className='border-bottom'>
                <Typography variant='h6'>Chỉnh sửa khung hình</Typography>
                <IconButton
                    aria-label='close'
                    className={classes.closeButton}
                    onClick={onClose}>
                    <CloseIcon />
                </IconButton>
            </DialogTitle>
            <form onSubmit={handleSubmit(onSubmit)} autoComplete='off'>
                <DialogContent className='pt-4 pb-2'>
                    <div className='row form-group'>
                        <div className="col-lg-6">
                            <label className='text-dark'>
                                Tên khung hình<span className='required'></span>
                            </label>
                            <TextField
                                name='name'
                                error={
                                    errors.name &&
                                    (errors.name.type === 'required' ||
                                        errors.name.type === 'maxLength')
                                }
                                fullWidth
                                type='text'
                                className='form-control'
                                inputProps={{ maxLength: 150 }}
                                defaultValue={item.name}
                                inputRef={register({ required: true, maxLength: 150 })}
                                onChange={(e) => setValue('name', e.target.value)}
                            />
                            {errors.name && errors.name.type === 'required' && (
                                <span className='error'>Trường này là bắt buộc</span>
                            )}
                            {errors.name && errors.name.type === 'maxLength' && (
                                <span className='error'>Trường này không quá 150 ký tự</span>
                            )}
                        </div>
                        <div className="col-lg-6">
                            <label className='text-dark'>
                                Code<span className='required'></span>
                            </label>
                            <TextField
                                name='code'
                                error={
                                    errors.code &&
                                    (errors.code.type === 'required' ||
                                        errors.code.type === 'maxLength')
                                }
                                fullWidth
                                type='text'
                                className='form-control'
                                inputProps={{ maxLength: 150 }}
                                defaultValue={item.code}
                                inputRef={register({ required: true, maxLength: 150 })}
                                onChange={(e) => setValue('code', e.target.value)}
                            />
                            {errors.code && errors.code.type === 'required' && (
                                <span className='error'>Trường này là bắt buộc</span>
                            )}
                            {errors.code && errors.code.type === 'maxLength' && (
                                <span className='error'>Trường này không quá 150 ký tự</span>
                            )}
                        </div>
                    </div>
                    <div className='row form-group'>
                        <div className="col-lg-6">
                            <label className='text-dark'>
                                Tiêu đề hiển thị
                            </label>
                            <TextField
                                name='title'
                                error={
                                    errors.title &&
                                    errors.title.type === 'maxLength'
                                }
                                fullWidth
                                type='text'
                                className='form-control'
                                defaultValue={item.title}
                                inputProps={{ maxLength: 150 }}
                                inputRef={register({ maxLength: 150 })}
                                onChange={(e) => setValue('title', e.target.value)}
                            />
                            {errors.title && errors.title.type === 'maxLength' && (
                                <span className='error'>Trường này không quá 150 ký tự</span>
                            )}
                        </div>
                        <div className="col-lg-6">
                            <label className="text-dark">Xem mặc định</label>
                            <Checkbox
                                checked={viewDefault}
                                onChange={handleChangeViewDefault}
                                color="primary"
                                inputProps={{ "aria-label": "secondary checkbox" }}
                                className="p-0 mt-0 ml-4"
                            />
                        </div>
                    </div>
                    <div className='row form-group'>
                        <div className="col-lg-6">
                            <label className='text-dark'>
                                Dự án vr360<span className='required'></span>
                            </label>
                            <Autocomplete
                                options={options}
                                getOptionLabel={(option) =>
                                    typeof option === "string" ? option : option.name
                                }
                                value={curVrProject}
                                onChange={(event, newValue) => {
                                    setCurVrProject(newValue);
                                }}
                                disableClearable={true}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        name="pid"
                                        variant="outlined"
                                        size="small"
                                    />
                                )}
                            />
                        </div>
                        <div className="col-lg-6">
                            <label className='text-dark'>
                                Liên kết
                            </label>
                            <AsyncSelect
                                defaultOptions={defaultOptions}
                                className='search_input_select'
                                placeholder='Nhập để tìm kiếm'
                                isLoading={false}
                                loadOptions={loadOptions}
                                onChange={data => {
                                    handleChoose(data);
                                }}
                                isMulti
                                value={connection}
                                components={{ DropdownIndicator: null, LoadingIndicator: null, ClearIndicator: null }}
                            />
                            {/* {connection && connection.length > 0 && connection.map((item, index) => (
                                <div key={index} className='d-flex justify-content-between'>
                                    {item.name + ' - ' + item.id}
                                    <div onClick={() => deleteConnection(item.id)}>x</div>
                                </div>
                            ))} */}
                        </div>
                    </div>
                    <div className='row form-group'>
                        <div className="col-12">
                            <DropzoneArea
                                acceptedFiles={["image/png, image/gif, image/jpeg, image/jpg"]}
                                filesLimit={1}
                                dropzoneText="Kéo thả ảnh đại diện vào đây"
                                onChange={(files) => {
                                    files &&
                                        files.length &&
                                        console.log("Files:", files[0].name);
                                    setFileUpload(files);
                                }}
                            />
                        </div>
                    </div>
                </DialogContent>
                <DialogActions>
                    <Button
                        type="submit"
                        color="primary"
                        variant="contained"
                    //   startIcon={!isSubmitting ? <SaveIcon></SaveIcon> : <CircularProgress size={20} color="white"/>}
                    //   disabled={isSubmitting}
                    >
                        Lưu
                    </Button>

                </DialogActions>
            </form>
        </Dialog>
    )
}

const mapStateToProps = (state) => ({
    isLoading: state.app.loading,
});

const mapDispatchToProps = (dispatch) =>
    bindActionCreators(
        {
            showLoading: appActions.ShowLoading,
        },
        dispatch
    );

export default connect(mapStateToProps, mapDispatchToProps)(EditEdition);