//---> Build-in Modules
import React, { useEffect } from 'react';

//---> External Modules
import { Checkbox } from '@material-ui/core';
import { useFormContext } from 'react-hook-form';

const ProjectActive = ({ defaultValue = false }) => {
  const { register } = useFormContext();

  return (
    <div className='form-group'>
      <div className='row'>
        <div className='col-12 '>
          <label className='text-dark' style={{ marginRight: '5px' }}>
            Kích hoạt
          </label>
          <Checkbox
            color='primary'
            inputProps={{ 'aria-label': 'secondary checkbox' }}
            className='p-0 mt-0 '
            name='isActive'
            inputRef={register({ required: true })}
            defaultChecked={defaultValue}
          />
        </div>
      </div>
    </div>
  );
};

export default ProjectActive;
