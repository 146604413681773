import React, { useEffect, useState } from "react";

import * as lookupLanguageAction from "../../redux/store/lookup-language/lookupLanguage.store";
import * as introduceAction from "../../redux/store/introduce/introduce.store";
import { PageHeaderActions } from "../../components/pageHeaderActions/page-header-actions.view";
import * as config from "../../common/config";
import DeleteDialog from "../../components/dialog-delete/dialog-delete.view";
import { useScreenActions } from "../../common/useScreenActions";
import { useAddOrEditIntroduceDialog } from "./useAddOrEditIntroduceDialog";
import { ListIntroduceView } from "./list-introduce";
import AddOrEditIntroduceDialog from "./addOrEditIntroduceDialog";
import { UrlCollection } from "../../common/url-collection";
import { useHistory } from "react-router-dom";
import * as appActions from "../../core/app.store";
import { useDispatch } from "react-redux";
const initialRequestParams = {
  isDelete: false,
  PageIndex: 0,
  PageSize: config.Configs.DefaultPageSize,
};

export const Introduce = () => {
  const [language, setLanguage] = useState([]);
  const history = useHistory();
  const {
    requestParams,
    datas,
    data,
    openAddOrEditDialog,
    dataWillDelete,
    setDataWillDelete,
    setOpenAddOrEditDialog,
    setDatas,
    handleChangeRequestParams,
    handleAddOrEditSuccess,
    onDeleteSuccess,
  } = useScreenActions({
    initialRequestParams,
    getDatas: (req) => getIntroduce(req),
  });
  const dispatch = useDispatch();
  useEffect(() => {
    getLookupLanguageAction();
  }, []);

  const { onDelete } = useAddOrEditIntroduceDialog({
    onSuccess: onDeleteSuccess,
  });

  const getIntroduce = async (params) => {
    dispatch(appActions.ShowLoading(true))
    const res = await introduceAction.GetListAll(params);
    if (res.content) {
      dispatch(appActions.ShowLoading(false))
      setDatas(res.content);
    }
  };

  const getLookupLanguageAction = async () => {
    const res = await lookupLanguageAction.GetLookupLanguage();
    if (res.content?.length) {
      setLanguage(res.content);
    }
  };

  const convertLanguageOptions = () => {
    const itemsFilter = language.filter(
      (itemLanguage) =>
        !datas.map((itemDatas) => itemDatas.langId).includes(itemLanguage.id)
    );

    return convertArrToObj(itemsFilter);
  };

  const convertOptionsForEdit = () => {
    const itemFilter = language.find(
      (itemLanguage) => itemLanguage.id === data.langId
    );

    return convertArrToObj([itemFilter]);
  };

  const convertArrToObj = (arr) => {
    return arr.reduce((obj, item) => {
      return {
        ...obj,
        [item["id"]]: item["name"],
      };
    }, {});
  };
  const onClickAdd = () => {
    const param = getParamSearch()
    history.push(UrlCollection.AddIntroduce + param)
  }
  const onClickEdit = (items) => {
    const param = getParamSearch()
    history.push(UrlCollection.EditIntroduce + `${items.id}` + param)
  }
  const getParamSearch = () => {
    let param = `?PageSize=${requestParams.PageSize}`;
    if (requestParams?.PageIndex) {
      param = param + `&PageIndex=${requestParams?.PageIndex}`;
    }
    return param;
  };
  return (
    <div className="slider">
      <PageHeaderActions
        title="Thông tin giới thiệu"
        labelAddButton="Thêm thông tin"
        onClickAdd={onClickAdd}
        onChangeRequest={handleChangeRequestParams}
        showTrash={requestParams.isDelete}
        showSearchAndRefreshActions={false}
      />
      <ListIntroduceView
        datas={datas}
        requestParams={requestParams}
        onChangeRequest={handleChangeRequestParams}
        onClickEdit={onClickEdit}
        onClickDelete={(data) => setDataWillDelete(data)}
        languageOptions={convertArrToObj(language)}
      />
      <DeleteDialog
        isOpen={!!dataWillDelete?.id}
        onClose={() => setDataWillDelete(null)}
        onSuccess={() => onDelete(dataWillDelete.id)}
        header={"Xác nhận xóa"}
        content={"Bạn có chắc chắn muốn xóa?"}
      />
    </div>
  );
};
