import React from 'react';
import {
  TextField
} from '@material-ui/core';
import { Controller, useFormContext } from 'react-hook-form';
import Autocomplete from "@material-ui/lab/Autocomplete";
import './styles.scss';

const FormAutoComplete = ({
  isHideIfEmpty = true,
  title,
  name,
  validate,
  placeholder = 'Chọn...',
  type,
  className = 'form-control',
  titleClassName = 'text-dark',
  InputProps = {
    readOnly: false,
    autoComplete: 'off'
  },
  options = [],
  variant = "outlined",
  size = "small",
  ...rest
}) => {
  const { control, errors, setValue } = useFormContext();
  return (
    <>
      {(!isHideIfEmpty || options.length > 0) ? <Controller
        control={control}
        name={name}
        rules={validate}
        placeholder={placeholder}
        render={({ name, value }) => (
          <>
            <label className={titleClassName}>
              {title}{validate?.required ? <span className='required'></span> : null}
            </label>

            <Autocomplete
              options={options}
              getOptionLabel={(option) =>
                typeof option === "string" ? option : option.name
              }
              value={value}
              onChange={(event, newValue) => {
                setValue(name, newValue);
              }}
              disableClearable={true}
              renderInput={(params) => (
                <TextField
                  {...params}
                  placeholder={placeholder}
                  name={name}
                  variant={variant}
                  size={size}
                  inputProps={{
                    ...params.inputProps,
                    autoComplete: 'new-password',
                  }}
                />
              )}
              {...rest}
            />
            {validate && errors[name] ? (
              <span className='error'>{errors[name] && errors[name].message}</span>
            ) : null}
          </>
        )
        }
      /> : null}
    </>
  );
};

export default FormAutoComplete;
