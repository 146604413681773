import React, { useEffect, useRef, useState } from 'react';
import Highcharts from 'highcharts';

function ChartColumn(props) {
  const {userRegisterReport , typeChart} = props;
  const refContainer = useRef(null);

  useEffect(() => {
    const chart = Highcharts.chart(refContainer.current, {
      chart: {
        type: typeChart
      }, // type of the chart
      title: {
        text: ''
      }, // title of the chart
     
      xAxis: {
        categories: userRegisterReport.category,
        crosshair: true
      },
      yAxis: {
        min: 0, // minimum value of the Y Axis
        title: {
          text: ''
        } // the title of the Y Axis
      },
      tooltip: {
        headerFormat: '<span style="font-size:10px">{point.key}</span><table>',
        pointFormat: '<tr><td style="color:{series.color};padding:0">{series.name}: </td>' +
          '<td style="padding:0"><b>{point.y}</b></td></tr>',
        footerFormat: '</table>',
        shared: true,
        useHTML: true
      }, // tooltip appears when hovering over a point
      credits: {
        enabled: false
      },
      series: userRegisterReport.series // set of the data
    });

    if (userRegisterReport?.series?.length > 0) {
      chart.hideLoading();
    }
    else {
      chart.showLoading();
    }
    
  }, [userRegisterReport,typeChart]);

  return (
    <div className="App">
      <div ref={refContainer} />
    </div>
  );
}

export default ChartColumn;