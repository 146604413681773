import Service from '../../../api/api-service';
import { ApiUrl } from '../../../api/api-url';

const service = new Service();

export const getCategoryGetLookUp = (langId) =>
  service
    .get(ApiUrl.GetLookUpCategory(langId))
    .then((res) => res)
    .catch((err) => err);
