import * as mobileAppIntroduceAction from "../../redux/store/mobile-app-introduce/mobileAppIntroduce.store";
import * as viVN from "../../language/vi-VN.json";
import { NotificationMessageType } from "../../utils/configuration";
import ShowNotification from "../../components/react-notifications/react-notifications";

export const useAddOrEditMobileAppIntroduceDialog = (props) => {
  const { data, onSuccess } = props;

  const onSubmit = (formData) => {
    if (data) {
      handleEdit(formData);
    } else {
      handleAdd(formData);
    }
  };

  const handleAdd = async (formData) => {
    const res = await mobileAppIntroduceAction.Create(formData);
    handleShowNotification(res, viVN.Success.CreateSuccess);
  };

  const handleEdit = async (formData) => {
    const res = await mobileAppIntroduceAction.Update({
      ...formData,
      id: data.id,
    });
    handleShowNotification(res, viVN.Success.UpdateSuccess);
  };

  const onDelete = async (id) => {
    const res = await mobileAppIntroduceAction.Delete(id);
    handleShowNotification(res, viVN.Success.DeleteSuccess);
  };

  const handleShowNotification = (res, mess) => {
    if (res.content?.status) {
      ShowNotification(mess, NotificationMessageType.Success);

      onSuccess();
    } else {
      ShowNotification(
        viVN.Errors[res.errorType],
        NotificationMessageType.Error
      );
    }
  };

  return {
    onSubmit,
    onDelete,
  };
};
