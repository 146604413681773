import React, { useEffect, useState } from 'react';
import {
  GoogleMap,
  Marker,
  StandaloneSearchBox,
  useLoadScript,
} from '@react-google-maps/api';
import { TextField } from '@material-ui/core';
import './style.scss';
import {
  GOOGLE_API_KEY,
  GOOGLE_MAPS_DEFAULT_CENTER_LATLNG,
  GOOGLE_MAPS_DEFAULT_LIBRARIES,
} from '../../common/constants';

export default function MapGoole(props) {
  const {
    currentPosition,
    setChoosePlace,
    containerStyle = {
      width: '100%',
      height: '400px',
    },
    libraries = GOOGLE_MAPS_DEFAULT_LIBRARIES,
    initCenter = GOOGLE_MAPS_DEFAULT_CENTER_LATLNG,
    isHideAfterClickMap = null,
  } = props;
  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: GOOGLE_API_KEY,
    libraries: libraries,
  });
  const [searchBox, setSearchBox] = useState();
  const [maps, setMaps] = useState();
  const [center, setCenter] = useState(initCenter);
  const [marker, setMarker] = useState(null);
  const [chooseLatLng, setChooseLatLng] = useState();
  const [load, setLoad] = useState(false);

  const reRenderObjects = () => {
    setMarker(chooseLatLng);
  };

  const setPlaceByChooseLatLng = () => {
    var geocoder = new window.google.maps.Geocoder();
    geocoder.geocode(
      {
        location: chooseLatLng.position,
      },
      function (results, status) {
        if (status === window.google.maps.GeocoderStatus.OK) {
          if (results[0]) {
            setChoosePlace(results[0]);
          } else {
            console.log('No results found');
          }
        } else {
          console.log('Geocoder failed due to: ' + status);
        }
      }
    );
  };

  useEffect(() => {
    if (chooseLatLng) {
      reRenderObjects();
      setPlaceByChooseLatLng();
    }
  }, [chooseLatLng]);

  const onPlacesChanged = () => {
    if (searchBox != null) {
      setChooseLatLng({ position: searchBox.getPlaces()[0].geometry.location });
      setCenter(searchBox.getPlaces()[0].geometry.location);
    }
  };

  const onClickMap = async (e) => {
    if (e.latLng) {
      setChooseLatLng({ position: e.latLng.toJSON() });
      if (isHideAfterClickMap) {
        isHideAfterClickMap();
      }
    }
  };

  const onDragEnd = async (e) => {
    if (e.latLng) {
      setChooseLatLng({ position: e.latLng.toJSON() });
    }
  };

  const onLoadMap = async (ref) => {
    setMaps(ref);
    if (currentPosition != null) {
      console.log(
        '🚀 ~ file: MapGoogle.js ~ line 82 ~ onLoadMap ~ currentPosition',
        currentPosition
      );
      setCenter(currentPosition);
      setChooseLatLng({ position: currentPosition });
      setMarker(true);
    }
  };

  return (
    isLoaded && (
      <>
        <StandaloneSearchBox
          onPlacesChanged={() => onPlacesChanged()}
          onLoad={(ref) => setSearchBox(ref)}
          controlPosition={window.google.maps.ControlPosition.TOP_LEFT}>
          <TextField
            className='field-search'
            variant='outlined'
            placeholder='Tìm kiếm địa chỉ'
            InputProps={{
              style: {
                height: '40px',
              },
            }}
          />
        </StandaloneSearchBox>

        <GoogleMap
          center={center}
          onClick={(e) => onClickMap(e)}
          mapContainerStyle={containerStyle}
          onLoad={onLoadMap}
          zoom={10}
          id='g-map'>
          <div>
            {marker ? (
              <Marker
                key={JSON.stringify(marker.position)}
                position={marker.position}
                draggable={true}
                onDragEnd={onDragEnd}
              />
            ) : null}
          </div>
        </GoogleMap>
      </>
    )
  );
}
