import Service from "../../../api/api-service";
import { ApiUrl } from "../../../api/api-url";

const service = new Service();

export const GetListNotifications = (
  pageIndex = 1,
  pageSize = 10,
  content = "",
  type = "",
  isPushed = undefined,
  isActive = undefined,
  isDelete = undefined
) => {
  const params = new URLSearchParams();
  params.append("pageIndex", pageIndex);
  params.append("pageSize", pageSize);
  content && params.append("content", content);
  type && params.append("type", type);
  isPushed && params.append("isPushed", isPushed === 2 ? true : false );
  isActive && params.append("isActive", isActive === 2 ? true : false);
  isDelete && params.append("isDelete", isDelete);
  return service
    .get(ApiUrl.GetListNotifications, params)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      throw err;
    });
};

export const GetDetailNotification = (code) => {
  return service
    .get(ApiUrl.GetDetailNotification + code)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      throw err;
    });
};

export const CreateNotification = (data) => {
  return service
    .post(ApiUrl.CreateNotification, data)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      throw err;
    });
};

export const UpdateNotification = (data) => {
  return service
    .post(ApiUrl.UpdateNotification, data)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      throw err;
    });
};

export const DeleteNotification = (data) => {
  return service
    .post(ApiUrl.DeleteNotification + data)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      throw err;
    });
};
export const ActiveNotification = (data) => {
  return service
    .post(ApiUrl.ActiveNotification + data)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      throw err;
    });
};
export const DeActiveNotification = (data) => {
  return service
    .post(ApiUrl.DeActiveNotification + data)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      throw err;
    });
};
export const PushFirebaseNotification = (data) => {
  return service
    .post(ApiUrl.PushFirebaseNotification + data)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      throw err;
    });
};