import React, { useState } from "react";
import "date-fns";

//--- Validation
import { useForm } from "react-hook-form";

//--- Material Control
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import Fab from "@material-ui/core/Fab";
import Popover from "@material-ui/core/Popover";
import ButtonGroup from "@material-ui/core/ButtonGroup";
//--- Material Icon
import CloseIcon from "@material-ui/icons/Close";
import FilterListIcon from "@material-ui/icons/FilterList";
import SearchIcon from "@material-ui/icons/Search";
import ClearAllIcon from "@material-ui/icons/ClearAll";
import RefreshIcon from "@material-ui/icons/Refresh";
//--- Material Icon
import DeleteCircle from "@material-ui/icons/DeleteForever";
import { Select, MenuItem, FormControlLabel, Switch } from "@material-ui/core";
import AddCircle from "@material-ui/icons/AddCircle";

function SearchNotification(props) {
  const {
    getListNotificationModels,
    rowsPerPageCommon,
    handleOpenAddDialog,
    dataSearch,
    setDataSearch,
    onChangeFormSearch,
    setIsDelete,
    isDelete,
  } = props;

  const { register, handleSubmit } = useForm({
    mode: "all",
    reValidateMode: "onBlur",
  });
  const [isShowTrash, setIsShowTrash] = useState(false);
  //--- Filter
  const [filterSection, setFilterSection] = React.useState(null);

  const handleClickFilter = (event) => {
    setFilterSection(event.currentTarget);
  };

  const handleCloseFilter = () => {
    setFilterSection(null);
  };

  const onSubmit = async (data) => {
    await getListNotificationModels(
      1,
      rowsPerPageCommon,
      dataSearch.content,
      dataSearch.type,
      dataSearch.isPushed,
      dataSearch.isPushed,
      ""
    );
    handleCloseFilter();
  };

  const refresh = async () => {
    setDataSearch({
      content: "",
      type: "",
      isPushed: "",
      isActive: "",
    })
    await getListNotificationModels(
      1,
      rowsPerPageCommon,
      "",
      "",
      "",
      "",
      "",
    );
  };

  const handleClearAllField = () => {
    setDataSearch({
      content: "",
      type: "",
      isPushed: "",
      isActive: "",
    })
    handleCloseFilter();
  };

  const handleChangeTrash = async(event) => {
    setIsShowTrash(event.target.checked);
    setIsDelete(event.target.checked);
    await getListNotificationModels(
      1,
      rowsPerPageCommon,
      dataSearch.content,
      dataSearch.type,
      dataSearch.isPushed,
      dataSearch.isPushed,
      event.target.checked,
    );
  };

  const handleEmptyTrash = async() => {
    setIsDelete(false);
    setIsShowTrash(false);
    await getListNotificationModels(
      1,
      rowsPerPageCommon,
      dataSearch.content,
      dataSearch.type,
      dataSearch.isPushed,
      dataSearch.isPushed,
      false,
    );
  };
  const openFilter = Boolean(filterSection);
  const idFilter = openFilter ? "popoverSlider" : undefined;

  return (
    <div className="d-flex align-items-center justify-content-between mb-3">
      <div className="position-relative">
        <span className="h3 mb-0 text-gray-800">
          Quản lý thông báo
          <Tooltip title="Tìm kiếm">
            <Fab
              color="primary"
              aria-label="filter"
              size="small"
              onClick={handleClickFilter}
              className="ml-2"
            >
              <FilterListIcon />
            </Fab>
          </Tooltip>
          <Tooltip title="Refresh">
            <Fab
              color="primary"
              aria-label="filter"
              size="small"
              onClick={refresh}
              className="ml-2"
            >
              <RefreshIcon />
            </Fab>
          </Tooltip>
          <span>&nbsp;&nbsp;</span>
          <FormControlLabel
            control={
              <Switch
                checked={isShowTrash}
                onChange={handleChangeTrash}
                name="checkedB"
                color="primary"
              />
            }
            label="Thùng rác"
          />
          <Popover
            id={idFilter}
            open={openFilter}
            anchorEl={filterSection}
            onClose={handleCloseFilter}
            style={{ backgroundColor: "rgba(0, 0, 0, 0.4)" }}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "center",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "center",
            }}
          >
            <div className="p-3 mt-2" style={{ width: "30rem" }}>
              <div className="text-right border-bottom mb-3 pb-2">
                <IconButton
                  aria-label="close"
                  size="small"
                  onClick={handleCloseFilter}
                >
                  <CloseIcon fontSize="small" />
                </IconButton>
              </div>
              <form id="formSearch" onSubmit={handleSubmit(onSubmit)}>
                <div className="form-group">
                  <label className="text-dark">Nội dung</label>
                  <TextField
                    className="w-100"
                    name="content"
                    value={dataSearch.content}
                    onChange={(e) => {
                      onChangeFormSearch(e);
                    }}
                    inputRef={register}
                  />
                </div>
                <div className="form-group">
                  <label className="text-dark">Loại</label>
                  <Select
                    name="type"
                    className="w-100 ml-2"
                    placeholder="Trạng thái"
                    value={dataSearch.type}
                    inputRef={register}
                    onChange={(e) => {
                      onChangeFormSearch(e);
                    }}
                  >
                    <MenuItem value="">---Tất cả---</MenuItem>
                    <MenuItem value="PLANNING">Quy hoạch </MenuItem>
                    <MenuItem value="PROJECT">Dự án</MenuItem>
                    <MenuItem value="CLASSIFIED">Giá đất</MenuItem>
                  </Select>
                </div>
                <div className="form-group">
                  <label className="text-dark">Trạng thái thông báo</label>
                  <Select
                    name="isPushed"
                    className="w-100 ml-2"
                    placeholder="Trạng thái"
                    inputRef={register}
                    value={dataSearch.isPushed}
                    onChange={(e) => {
                      onChangeFormSearch(e);
                    }}
                  >
                    <MenuItem value={0}>---Tất cả---</MenuItem>
                    <MenuItem value={2}>Đã gửi thông báo</MenuItem>
                    <MenuItem value={1}>Chưa gửi thông báo</MenuItem>
                  </Select>
                </div>
                <div className="form-group">
                  <label className="text-dark">Trạng thái kích hoạt</label>
                  <Select
                    name="isActive"
                    className="w-100 ml-2"
                    placeholder="Trạng thái"
                    inputRef={register}
                    value={dataSearch.isActive}
                    onChange={(e) => {
                      onChangeFormSearch(e);
                    }}
                  >
                    <MenuItem value={0}>---------</MenuItem>
                    <MenuItem value={2}>Đã kích hoạt</MenuItem>
                    <MenuItem value={1}>Chưa kích hoạt</MenuItem>
                  </Select>
                </div>
                <div className="border-top">
                  <div className="row">
                    <div className="col-12 text-right mt-3">
                      <Button variant="contained" color="primary" type="submit">
                        <SearchIcon fontSize="small" /> Tìm kiếm
                      </Button>
                      <Button
                        variant="contained"
                        className="ml-2"
                        onClick={handleClearAllField}
                      >
                        <ClearAllIcon fontSize="small" /> Bỏ lọc
                      </Button>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </Popover>
        </span>
      </div>
     <div className="d-flex align-items-center">
     <ButtonGroup
          variant="contained"
          color="primary"
          className="mr-1"
          aria-label="contained primary button group"
        >
          {isShowTrash && (
            <Button
              variant="contained"
              color="secondary"
              onClick={handleEmptyTrash}
              startIcon={<DeleteCircle />}
            >
              Xóa thùng rác
            </Button>
          )}
       
        </ButtonGroup>
     <Button
        variant="contained"
        color="primary"
        onClick={() => handleOpenAddDialog()}
        startIcon={<AddCircle />}
      >
        Thêm thông báo
      </Button>
     </div>
    </div>
  );
}

export default SearchNotification;
