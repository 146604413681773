import Service from '../../../api/api-service';
import { ApiUrl } from '../../../api/api-url';
import * as config from '../../../common/config';

const service = new Service();

export const GetListAllInvestor = ({ langId }) => {
  return service
    .get(`${ApiUrl.GetListAllInvestor}?langId=${langId}`)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      throw err;
    });
};

export const GetLookupInvestor = ({ langId, pageSize, pageIndex, keyword }) => {
  return service
    .get(
      `${ApiUrl.GetLookupInvestor}?langId=${langId}&pageIndex=${pageIndex}&pageSize=${pageSize}&keyword=${keyword}`
    )
    .then((res) => {
      return res;
    })
    .catch((err) => {
      throw err;
    });
};

export const Investor_GetListAll = (
  keyword,
  langId,
  isFeaTure,
  isDelete,
  sortExpression = 'modifiedDate desc',
  pageIndex = 1,
  pageSize = config.Configs.DefaultPageSize
) => {
  const params = new URLSearchParams();
  if (keyword) {
    params.append('keyword', keyword);
  }

  params.append('isDelete', isDelete);
  if (isFeaTure) params.append('isFeaTure', isFeaTure);

  params.append('langId', langId);
  params.append('pageIndex', pageIndex);
  params.append('pageSize', pageSize);
  params.append('sorting', sortExpression);

  return service
    .get(ApiUrl.Investor_GetListAll, params)
    .then((res) => res)
    .catch((err) => {
      throw err;
    });
};

export const Investor_Create = (body) => {
  return service
    .post(ApiUrl.Investor_Create, body)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      throw err;
    });
};

export const Investor_Update = (body) => {
  return service
    .post(ApiUrl.Investor_Update, body)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      throw err;
    });
};

export const Investor_Delete = (id) => {
  return service
    .post(ApiUrl.Investor_Delete(id))
    .then((res) => {
      return res;
    })
    .catch((err) => {
      throw err;
    });
};

export const Investor_GetDetail = (langId, id) => {
  return service
    .get(ApiUrl.Investor_GetDetail(langId, id))
    .then((res) => {
      return res;
    })
    .catch((err) => {
      throw err;
    });
};

export const EmptyTrash = () => {
  return service
    .post(ApiUrl.Investor_EmptyTrash)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      throw err;
    });
};
