import { Button } from "@material-ui/core";
import React, { useState } from "react";
import { APIUrlDefault } from "../../utils/configuration";
import { FileManagementDialog } from "../file-management-dialog/file-management-dialog.view";

export const UploadFile = (props) => {
  const {
    title,
    className,
    required = true,
    files,
    onSetFiles,
    defaultValue,
    backgroundColor = "#fff",
    width = '150px'
  } = props;
  const [openDialog, setOpenDialog] = useState(false);
  const [filesTemp, setFilesTemp] = useState([]);

  const handleOpen = () => {
    setOpenDialog(true);
    setFilesTemp(files);
  };

  const handleClose = () => {
    setOpenDialog(false);
    onSetFiles(filesTemp);
  };

  const handleSave = () => {
    setOpenDialog(false);
  };

  return (
    <div className={className}>
      <label className="text-dark">
        {title}
        {required && <span className="required"></span>}
      </label>
      {!openDialog &&
        files &&
        files.length > 0 &&
        files.map((item) => (
          <div
            key={item.fileName}
            style={{ width: width, backgroundColor: backgroundColor }}
          >
            <img
              src={APIUrlDefault + item.filePreview}
              alt={item.fileName}
              className="img-fluid mb-2"
              style={{
                width: "auto",
                height: "auto",
                maxWidth: "100%",
                maxHeight: "100%",
              }}
            />
          </div>
        ))}
      {!openDialog && !files?.length && defaultValue ? (
        <div style={{ width: width, backgroundColor: backgroundColor }}>
          <img
            src={APIUrlDefault + defaultValue}
            alt={""}
            className="img-fluid mb-2"
            style={{
              width: "auto",
              height: "auto",
              maxWidth: "100%",
              maxHeight: "100%",
            }}
          />
        </div>
      ) : null}
      <div>
        <Button variant="contained" color="primary" onClick={handleOpen}>
          Chọn file
        </Button>
      </div>
      <FileManagementDialog
        open={openDialog}
        onClose={handleClose}
        onSave={handleSave}
        files={files}
        onSetFiles={(file) => onSetFiles(file)}
      />
    </div>
  );
};
