import React from "react";
import {
  TableCell,
  TableHead,
  TableRow,
  TableSortLabel,
} from "@material-ui/core";

export const EnhancedTableHead = (props) => {
  const { headCells, order, orderBy, onChangeRequest } = props;

  const handleRequestSort = (property) => {
    const isAsc = orderBy === property && order === "asc";
    let sort = isAsc ? "desc" : "asc";

    onChangeRequest("Sorting", [property, sort].join(" "));
  };

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            sortDirection={orderBy === headCell.id ? order : false}
            className="pt-3 pb-3 text-nowrap"
            align="center"
          >
            {headCell.hideSortIcon ? (
              headCell.label
            ) : (
              <TableSortLabel
                active={orderBy === headCell.id}
                direction={orderBy === headCell.id ? order : "asc"}
                onClick={() => handleRequestSort(headCell.id)}
                hideSortIcon={headCell.hideSortIcon ? true : false}
              >
                {headCell.label}
              </TableSortLabel>
            )}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
};
