import React from 'react';
import {useDestructureListToOptions} from '../hooks';
import CustomFormSelect from './form-select';

const SelectProjectProgress = ({listOptions = []}) => {
  const projectProgressOptions = useDestructureListToOptions({
    listData: listOptions
  });

  return (
    <>
      <CustomFormSelect
        name="progressId"
        label="Tiến độ"
        options={projectProgressOptions}
        isRequired
        defaultValue={null}
        placeholderText="Chọn tiến độ"
      />
    </>
  );
};

export default SelectProjectProgress;
