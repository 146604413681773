import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as config from "../../../common/config";
import * as appActions from "../../../core/app.store";
import * as sceneAction from "../../../redux/store/vr360/vr360.store";
//---mui
import AddCircle from "@material-ui/icons/AddCircle";
import {
    Button,
    TextField,
    IconButton,
    Tooltip,
    Popover,
    Select,
    MenuItem,
    Fab,
} from "@material-ui/core";
import ShowNotification from '../../../components/react-notifications/react-notifications';
import { NotificationMessageType, } from '../../../utils/configuration';
import * as viVN from '../../../language/vi-VN.json';
import { useLocation } from 'react-router-dom';

import ListEdition from "./list-edition/list-edition.view";
import AddEdition from "./add-edition/add-edition.view";
import EditEdition from "./edit-edition/edit-edition.view";
import VrFilter from "../../../components/vr-filter/vr-filter";

const HotspotManagement = (props) => {
    const { showLoading } = props;
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);

    const pageIndex = 1;
    const pageSize = 10;
    const [editionModel, setEditionModel] = useState([]);
    const [order, setOrder] = useState("desc");
    const [orderBy, setOrderBy] = useState("createdDate");

    const [openAddDialog, setOpenAddDialog] = useState(false);
    const [openEditDialog, setOpenEditDialog] = useState(false);
    const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
    const [itemSelected, setItemSelected] = useState();
    const [pagination, setPagination] = useState({});

    useEffect(() => {
        let params = getParamsFromQuery();
        GetListAll(params);
    }, [location.search])

    const getParamsFromQuery = () => {
        return {
            pageSize: queryParams.get("pageSize") || pageSize,
            pageIndex: queryParams.get("pageIndex") || pageIndex,
            keyword: queryParams.get("keyword"),
            pid: queryParams.get("pid"),
            sceneId: queryParams.get("sceneId"),
            status: queryParams.get("status"),
            sorting: queryParams.get("sorting"),
        }
    }

    const onRefreshData = () => {
        let params = getParamsFromQuery();
        GetListAll(params);
    }

    const GetListAll = (params) => {
        showLoading(true);
        sceneAction.HotspotGetListAll(params).then(res => {
            if (res && res.content) {
                const { items, pageCount, pageIndex, pageSize, totalItemCount } = res.content || {};
                setEditionModel(items);
                setPagination({ ...pagination, pageCount, pageIndex, pageSize, totalItemCount });
            }
            showLoading(false);
        }).catch(err => {
            showLoading(false);
            console.log(err);
        })
    }

    const handleDelete = (id) => {
        sceneAction.HotspotDelete(id).then(res => {
            if (res && res.content) {
                console.log('res', res)
                onRefreshData();
                ShowNotification(
                    'Xóa thành công',
                    NotificationMessageType.Success
                );
            }
        }).catch(err => {
            console.log(err)
        })
    }

    const handleEdit = (data) => {
        setOpenEditDialog(true);
        setItemSelected(data)
    }

    const handleChangeStatus = async (id, status) => {
        try {
            const res = status
                ? await sceneAction.HotspotActive(id)
                : await sceneAction.HotspotDeActive(id)
            if (res) {
                onRefreshData();
                 ShowNotification(
                    'Cập nhật thành công',
                    NotificationMessageType.Success
                );
            }
        }
        catch (e) {
            ShowNotification(
                'Vui lòng liên hệ với Admin',
                NotificationMessageType.Error
            );
        }
        // if (!data) return;
        // const formData = new FormData();
        // formData.append('Id', data.id);
        // formData.append('SceneId', data.sceneId);
        // formData.append('IsActive', !data.isActive);
        // data.polygon.map((item, index) => {
        //     formData.append(`Polygon[${index}].Atv`, item.atv);
        //     formData.append(`Polygon[${index}].Ath`, item.ath);
        // })
        // sceneAction.HotspotUpdate(formData).then(res => {
        //     if (res) {
        //         onRefreshData();
        //         ShowNotification(
        //             'Cập nhật thành công',
        //             NotificationMessageType.Success
        //         );
        //     }
        // }).catch(err => {
        //     ShowNotification(
        //         'Vui lòng liên hệ với Admin',
        //         NotificationMessageType.Error
        //     );
        // })
    }

    return (
        <div className="slider">
            <div className="d-sm-flex align-items-center justify-content-between mb-3">
                <h1 className="h3 mb-0 text-gray-800">
                    Quản lý Điểm nóng
                </h1>
                {/* <Button
                    variant="contained"
                    color="primary"
                    onClick={() => setOpenAddDialog(true)}
                    startIcon={<AddCircle />}
                >
                    Thêm gói
                </Button> */}
            </div>
            <div>
                <VrFilter hasVrProject hasScene />
            </div>
            <div>
                <ListEdition
                    listModel={editionModel}
                    deleteAction={handleDelete}
                    editAction={handleEdit}
                    changeStatusAction={handleChangeStatus}
                    pagination={pagination}
                />
            </div>
            <AddEdition
                isOpen={openAddDialog}
                onClose={() => setOpenAddDialog(false)}
                GetListAll={onRefreshData}
            />
            {openEditDialog && (
                <EditEdition
                    item={itemSelected}
                    isOpen={openEditDialog}
                    onClose={() => setOpenEditDialog(false)}
                    GetListAll={onRefreshData}
                />
            )}
        </div>
    )
}

const mapStateToProps = (state) => ({
    isLoading: state.app.loading,
});

const mapDispatchToProps = (dispatch) =>
    bindActionCreators(
        {
            showLoading: appActions.ShowLoading,
        },
        dispatch
    );

export default connect(mapStateToProps, mapDispatchToProps)(HotspotManagement);