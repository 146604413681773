import React, { useEffect, useState } from 'react';
import { AsyncPaginate } from 'react-select-async-paginate';
import { Controller, useFormContext } from 'react-hook-form';
import * as PlanningActions from '../../redux/store/planning/planning.store';
import './styles.scss';

const SelectAsyncPaginate = ({
  promiseOptions,
  title,
  name,
  validate,
  placeholder = 'Chọn...',
  options = [],
  className = 'form-control',
  titleClassName = 'text-dark',
  langId = 'vi',
  InputProps = {
    readOnly: false,
  },
  ...rest
}) => {
    const { control, errors } = useFormContext();
    console.log(rest)
  //  const [regionName, setRegionName] = useState(null);

  const loadOptions = async (searchQuery, loadedOptions, { page }) => {
    const res = await PlanningActions.GetLookUpPlanningPaging(
      searchQuery,
      20,
      page
    );

    const newArr = res?.content?.items.map((item) => {
      return {
        value: item.id,
        label: item.name,
      };
    });
    return {
      options: newArr,
      hasMore: newArr.length >= 1,
      additional: {
        page: page + 1,
      },
    };
  };
  return (
    <>
      <Controller
        control={control}
        name={name}
        rules={validate}
        placeholder={placeholder}
        render={({ onChange, name, value }) => (
          <>
            {title && (
              <label className={titleClassName}>
                {title}
                {validate?.required ? <span className='required'></span> : null}
              </label>
            )}

            <div className='input-project-group'>
              <AsyncPaginate
                className='input-field'
                loadOptions={loadOptions}
                isClearable
                defaultOptions={options}
                value={value}
                onChange={onChange}
                placeholder={placeholder}
                closeMenuOnSelect={true}
                additional={{
                  page: 1,
                }}
                {...rest}
              />
            </div>

            {validate && errors[name] ? (
              <span className='error'>
                {errors[name] && errors[name].message}
              </span>
            ) : null}
          </>
        )}></Controller>
    </>
  );
};

export default SelectAsyncPaginate;
