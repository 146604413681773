import * as introduceAction from "../../redux/store/introduce/introduce.store";
import * as viVN from "../../language/vi-VN.json";
import { NotificationMessageType } from "../../utils/configuration";
import ShowNotification from "../../components/react-notifications/react-notifications";
import { useHistory } from "react-router-dom";
import { UrlCollection } from "../../common/url-collection";
export const useAddOrEditIntroduceDialog = (props) => {
  const { data } = props;
  const history = useHistory();
  const onSubmit = async (formData) => {
    if (data) {
     await handleEdit(formData);
    } else {
      await handleAdd(formData);
    }
  };

  const handleAdd = async (formData) => {
    const res = await introduceAction.Create(formData);
    history.push(UrlCollection.Introduce)
    handleShowNotification(res, viVN.Success.CreateSuccess);
  };

  const handleEdit = async (formData) => {
    const res = await introduceAction.Update({
      ...formData,
        id: data.id,
      staticPage: data.staticPage,
    });
    handleShowNotification(res, viVN.Success.UpdateSuccess);
  };

  const onDelete = async (id) => {
    const res = await introduceAction.Delete(id);
    handleShowNotification(res, viVN.Success.DeleteSuccess);
  };

  const handleShowNotification = (res, mess) => {
    if (res.content?.status) {
      ShowNotification(mess, NotificationMessageType.Success);
    } else {
      ShowNotification(
        viVN.Errors[res.errorType],
        NotificationMessageType.Error
      );
    }
  };

  return {
    onSubmit,
    onDelete,
  };
};
