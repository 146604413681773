import React, { useState } from 'react';
import { useForm, FormProvider } from 'react-hook-form';

//--- Material Control
import {
  DialogActions,
  Button,
  TextField,
  DialogContent,
  DialogTitle,
  Dialog,
  Typography,
  IconButton,
  makeStyles,
  Checkbox,
} from '@material-ui/core';

import './style.scss';
//--- Material Icon
import CloseIcon from '@material-ui/icons/Close';
import SaveIcon from '@material-ui/icons/Save';
import ShowNotification from '../../../components/react-notifications/react-notifications';
import FormInputAddress from '../../../components/FormItem/FormInputAddress';
import {
  NotificationMessageType,
  APIUrlDefault,
} from '../../../utils/configuration';
import * as viVN from '../../../language/vi-VN.json';
import FileManagement from '../../../components/file_management/file_management';
//--- Action
import * as FaqActions from '../../../redux/store/faq/faq.store';
import * as InvestorActions from '../../../redux/store/investor/investor.store';

const useStyles = makeStyles((theme) => ({
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  formControl: {
    margin: theme.spacing(1),
  },
}));

export default function AddNews(props) {
  const classes = useStyles();

  const {
    isOpen,
    onClose,
    onSuccess,
    GetListAll,
    rowsPerPage,
    setOrder,
    setOrderBy,
    langId,
  } = props;
  const [isFeature, setFeature] = useState(false);
  const [isShow, setShow] = useState(false);

  const [files, setFiles] = useState([]);
  const [filesTemp, setFilesTemp] = useState([]);

  const methods = useForm({
    mode: 'all',
    reValidateMode: 'onBlur',
  });
  const { register, handleSubmit, setError, errors, clearErrors, setValue } =
    methods;
  const handleChangeIsFeature = (e) => {
    e.persist();
    setFeature(e.target.checked);
  };

  const onCloseSelectFile = () => {
    setShow(false);
    setFiles(filesTemp);
  };

  const onSaveSelectFile = () => {
    console.log(files);
    setShow(false);
  };

  const onOpenSelectFile = () => {
    setShow(true);
  };

  const onSubmit = (data) => {
    let object = {
      translations: [
        {
          langId: langId,
          name: data.name,
        },
      ],
      taxCode: data.taxCode,
      fax: data.fax,
      facebookUrl: data.facebookUrl,
      addressId: data.address?.value,

      phoneNumber: data.phoneNumber,
      email: data.email,
      isFeature: isFeature,
    };

    if (files && files.length > 0) {
      object = {
        ...object,
        logoId: files[0].fileId,
      };
    }
    InvestorActions.Investor_Create(object)
      .then((res) => {
        setOrder('desc');
        setOrderBy('modifiedDate');
        GetListAll(
          undefined,
          langId,
          undefined,
          false,
          'modifiedDate desc',
          1,
          rowsPerPage
        );
        onSuccess();
        ShowNotification(
          viVN.Success.NewsAddSuccess,
          NotificationMessageType.Success
        );
      })
      .catch(
        (err) => viVN.Errors[err.errorType],
        NotificationMessageType.Error
      );
    console.log(data);
  };

  return (
    <div>
      <Dialog open={isOpen} onClose={onClose} fullWidth={true} maxWidth='lg'>
        <DialogTitle disableTypography className='border-bottom'>
          <Typography variant='h6'>Thêm mới chủ đầu tư</Typography>
          <IconButton
            aria-label='close'
            className={classes.closeButton}
            onClick={onClose}>
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <FormProvider {...methods}>
          <form onSubmit={handleSubmit(onSubmit)} autoComplete='off'>
            <DialogContent className='pt-4 pb-2'>
              <div className='form-group'>
                <div className='row'>
                  <div className='col-12 col-md-4 col-lg-4'>
                    <label className='text-dark'>
                      Họ và tên<span className='required'></span>
                    </label>
                    <TextField
                      name='name'
                      error={
                        errors.name &&
                        (errors.name.type === 'required' ||
                          errors.name.type === 'maxLength')
                      }
                      fullWidth
                      type='text'
                      className='form-control'
                      inputProps={{ maxLength: 150 }}
                      inputRef={register({ required: true, maxLength: 150 })}
                      inputProps={{ maxLength: 150 }}
                      onChange={(e) => setValue('name', e.target.value)}
                    />
                    {errors.name && errors.name.type === 'required' && (
                      <span className='error'>Trường này là bắt buộc</span>
                    )}
                  </div>
                  <div className='col-12 col-md-4 col-lg-4'>
                    <label className='text-dark'>
                      Mã số thuế
                    </label>
                    <TextField
                      name='taxCode'
                      error={
                        errors.taxCode &&
                        (
                          errors.taxCode.type === 'maxLength')
                      }
                      fullWidth
                      type='text'
                      className='form-control'
                      inputProps={{ maxLength: 150 }}
                      inputRef={register({maxLength: 150 })}
                      onChange={(e) => setValue('taxCode', e.target.value)}
                    />
                    {errors.taxCode && errors.taxCode.type === 'maxLength' && (
                      <span className='error'>
                        Trường này không quá 150 ký tự
                      </span>
                    )}
                  </div>
                  <div className='col-12 col-md-4 col-lg-4'>
                    <label className='text-dark'>
                      Điện thoại:
                    </label>
                    <TextField
                      name='phoneNumber'
                      error={
                        errors.phoneNumber &&
                        (errors.phoneNumber.type === 'maxLength')
                      }
                      fullWidth
                      type='text'
                      className='form-control'
                      inputProps={{ maxLength: 30 }}
                      inputRef={register({ maxLength: 30})}
                      onChange={(e) => setValue('phoneNumber', e.target.value)}
                    />
                    
                    {errors.phoneNumber &&
                      errors.phoneNumber.type === 'maxLength' && (
                        <span className='error'>
                          Trường này không quá 30 ký tự
                        </span>
                      )}
                  </div>
                </div>
              </div>
              <div className='form-group'>
                <div className='row'>
                  <div className='col-12 col-md-4 col-lg-4'>
                    <label className='text-dark'>
                      Số Fax:
                    </label>
                    <TextField
                      name='fax'
                      error={
                        errors.fax &&
                        (errors.fax.type === 'maxLength')
                      }
                      fullWidth
                      type='text'
                      className='form-control'
                      inputRef={register({ maxLength: 30 })}
                      inputProps={{ maxLength: 30 }}
                      onChange={(e) => setValue('fax', e.target.value)}
                    />
                    {errors.fax && errors.fax.type === 'maxLength' && (
                      <span className='error'>
                        Trường này không quá 150 ký tự
                      </span>
                    )}
                  </div>
                  <div className='col-12 col-md-4 col-lg-4'>
                    <label className='text-dark'>
                      Email:<span className='required'></span>
                    </label>
                    <TextField
                      name='email'
                      error={
                        errors.email &&
                        (errors.email.type === 'required' ||
                          errors.email.type === 'maxLength' ||
                          errors.email.type === 'pattern')
                      }
                      fullWidth
                      type='text'
                      className='form-control'
                      inputProps={{ maxLength: 150 }}
                      inputRef={register({
                        required: true,
                        maxLength: 150,
                        pattern: /^\S+@\S+$/i,
                      })}
                      inputProps={{ maxLength: 150 }}
                      onChange={(e) =>
                        setValue('questionAsker', e.target.value)
                      }
                    />
                    {errors.email && errors.email.type === 'required' && (
                      <span className='error'>
                        Trường này là bắt buộc (abc@mail.com)
                      </span>
                    )}
                    {errors.email && errors.email.type === 'maxLength' && (
                      <span className='error'>
                        Trường này không quá 150 ký tự
                      </span>
                    )}
                    {errors.email && errors.email.type === 'pattern' && (
                      <span className='error'>
                        Sai định dạng (abc@mail.com)
                      </span>
                    )}
                  </div>
                  <div className='col-12 col-md-4 col-lg-4'>
                    {langId && (
                      <FormInputAddress
                        title='Địa chỉ'
                        name='address'
                        placeholder='Địa chỉ'
                        langId={langId}
                        variant='variant'
                      />
                    )}
                  </div>
                </div>
              </div>

              <div className='form-group'>
                <div className='row'>
                  <div className='col-12 col-md-6 col-lg-6'>
                    <label className='text-dark'>
                      Facebook Url
                    </label>
                    <TextField
                      name='facebookUrl'
                      fullWidth
                      type='facebookUrl'
                      className='form-control'
                      onChange={(e) => setValue('facebookUrl', e.target.value)}
                    />
                  </div>
                  <div className='col-12 col-md-6 col-lg-6'>
                    <div className='mg-top'>
                      <label className='text-dark'>Tiêu biểu</label>
                      <Checkbox
                        checked={isFeature}
                        onChange={handleChangeIsFeature}
                        color='primary'
                        inputProps={{ 'aria-label': 'secondary checkbox' }}
                        className='p-0 mt-0 ml-4'
                      />
                    </div>
                  </div>
                </div>
              </div>

              <div className='form-group'>
                <div className='col-12'>
                  <label className='text-dark'>Ảnh logo</label>
                  {!isShow &&
                    files &&
                    files.length > 0 &&
                    files.map((item) => (
                      <div key={item.fileName} style={{ width: '150px' }}>
                        <img
                          src={APIUrlDefault + item.filePreview}
                          alt={item.fileName}
                          className='img-fluid mb-2'
                          style={{
                            width: 'auto',
                            height: 'auto',
                            maxWidth: '100%',
                            maxHeight: '100%',
                          }}
                        />
                      </div>
                    ))}
                  <div>
                    <Button
                      variant='contained'
                      color='primary'
                      onClick={onOpenSelectFile}>
                      Chọn file
                    </Button>
                    <TextField
                      inputRef={register({ required: true })}
                      type='hidden'
                      name='image'
                      value={
                        (files && files.length > 0 && files[0].fileName) || ''
                      }
                    />
                    {errors.image && errors.image.type === 'required' && (
                      <p className='error'>Trường này là bắt buộc</p>
                    )}
                  </div>
                </div>
              </div>
            </DialogContent>

            <DialogActions className='border-top'>
              <Button
                type='submit'
                onClick={onClose}
                variant='contained'
                startIcon={<CloseIcon />}>
                Hủy
              </Button>
              <Button
                type='submit'
                color='primary'
                variant='contained'
                startIcon={<SaveIcon />}>
                Lưu
              </Button>
            </DialogActions>
          </form>
        </FormProvider>
      </Dialog>

      {isShow && (
        <Dialog
          onClose={onCloseSelectFile}
          open={isShow}
          fullWidth={true}
          maxWidth='md'
          className='dialog-preview-form'>
          <DialogTitle disableTypography>
            <Typography variant='h6'>Quản lý file</Typography>
            <IconButton
              aria-label='close'
              className={classes.closeButton}
              onClick={onCloseSelectFile}>
              <CloseIcon />
            </IconButton>
          </DialogTitle>
          <DialogContent dividers>
            <FileManagement
              files={files}
              setFiles={setFiles}
              acceptedFiles={['jpeg', 'png', 'jpg', 'gif']}
            />
          </DialogContent>

          <DialogActions>
            <Button
              type='button'
              onClick={onCloseSelectFile}
              variant='contained'
              startIcon={<CloseIcon />}>
              Hủy
            </Button>
            {files && files.length > 0 && (
              <Button
                type='button'
                color='primary'
                variant='contained'
                startIcon={<SaveIcon />}
                onClick={onSaveSelectFile}>
                Lưu
              </Button>
            )}
          </DialogActions>
        </Dialog>
      )}
    </div>
  );
}
