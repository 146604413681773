import { stringify } from "querystring";
import Service from "../../../api/api-service";
import { ApiUrl } from "../../../api/api-url";

const service = new Service();

export const GetListAll = async (params) => {
  try {
    return await service.get(
      ApiUrl.MobileAppIntroduce_GetListAll,
      stringify(params)
    );
  } catch (error) {
    return error;
  }
};

export const Create = async (body) => {
  try {
    return await service.post(ApiUrl.MobileAppIntroduce_Create, body);
  } catch (error) {
    return error;
  }
};

export const Update = async (body) => {
  try {
    return await service.post(ApiUrl.MobileAppIntroduce_Update, body);
  } catch (error) {
    return error;
  }
};

export const Delete = async (id) => {
  try {
    return await service.post(ApiUrl.MobileAppIntroduce_Delete(id));
  } catch (error) {
    return error;
  }
};
