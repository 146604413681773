import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import dateformat from 'dateformat';
import * as config from '../../../common/config';
import Button from "@material-ui/core/Button";
//--- Material Table
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Paper from '@material-ui/core/Paper';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import FooterPagination from '../../../components/footer-pagination/footer-pagination';
//--- Material Icon
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import { FormControlLabel, Switch } from '@material-ui/core';

const headCells = [
  { id: 'content', hideSortIcon: false, label: 'Nội dung' },
  { id: 'type', hideSortIcon: false, label: 'Loại' },
  { id: 'resouceId', hideSortIcon: false, label: 'Mã nguồn' },
  { id: 'user', hideSortIcon: false, label: 'Người sử dụng' },
  { id: 'isActive', hideSortIcon: false, label: 'Kích hoạt' },
  { id: 'isPushed', hideSortIcon: false, label: 'Trang thái' },
  //{ id: 'error', hideSortIcon: false, label: 'Lỗi' },
  { id: 'noti', hideSortIcon: false, label: '' },
  { id: 'action', hideSortIcon: false, label: '' },
];

function EnhancedTableHead(props) {
  const { order, orderBy, onRequestSort } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell key={headCell.id} className='pt-3 pb-3 text-nowrap'>
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
              hideSortIcon={headCell.hideSortIcon ? true : false}>
              {headCell.label}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  classes: PropTypes.object.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  paper: {
    width: '100%',
    marginBottom: theme.spacing(2),
  },
  tableContainer: {
    maxHeight: window.outerHeight - 365,
  },
  table: {
    minWidth: 750,
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
  buttonCustom: {
    boxShadow: 'none',
  },
  buttonCustomsuccess: {
    boxShadow: 'none',
    backgroundColor: "#28a745 !important",
    color:"#fff !important",
  }
}));

function ListNotification(props) {
  const {
    getListNotificationModels,
    notificationModels,
    handleOpenEditDialog,
    handleOpenDeleteDialog,
    contentModel,
    code,
    title,
    setRowsPerPageCommon,
    page,
    setPage,
    order,
    orderBy,
    setOrder,
    setOrderBy,
    onChangeActive,
    onChangePushFirebaseNotification,
    onChangeDeActive,
    dataSearch
  } = props;

  const classes = useStyles();

  const [rowsPerPage, setRowsPerPage] = useState(
    config.Configs.DefaultPageSize
  );

  const emptyRows =
    rowsPerPage -
    Math.min(rowsPerPage, notificationModels.length - page * rowsPerPage);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
    let sort = isAsc ? 'desc' : 'asc';
    let sortExpression = property + ' ' + sort;
    getListNotificationModels(page + 1, rowsPerPage, dataSearch.content, dataSearch.type, dataSearch.isPushed, dataSearch.isActive, '');
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage - 1);
    let sortExpression = orderBy + ' ' + order;
    getListNotificationModels(newPage, rowsPerPage, dataSearch.content, dataSearch.type, dataSearch.isPushed, dataSearch.isActive, '');
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setRowsPerPageCommon(event.target.value);
    setPage(0);
    let sortExpression = orderBy + ' ' + order;
    getListNotificationModels(1, event.target.value, dataSearch.content, dataSearch.type, dataSearch.isPushed, dataSearch.isActive, '');
  };
  const totalPage = Math.ceil(contentModel.totalItemCount / rowsPerPage);
  return (
    <div className={classes.root}>
      <Paper className={classes.paper}>
        <TableContainer className={classes.tableContainer}>
          <Table className={classes.table} size='small' stickyHeader>
            <EnhancedTableHead
              classes={classes}
              order={order}
              orderBy={orderBy}
              onRequestSort={handleRequestSort}
              rowCount={notificationModels.length}
            />
            <TableBody>
              {notificationModels && notificationModels.length > 0 ? (
                notificationModels.map((row, index) => {
                  const labelId = `enhanced-table-checkbox-${index}`;

                  return (
                    <TableRow hover tabIndex={-1} key={index}>
                      <TableCell component='th' id={labelId} scope='row'>
                        {row.content}
                      </TableCell>
                      <TableCell>{row.type}</TableCell>
                      <TableCell>{row.resourceId}</TableCell>
                      <TableCell>{row.user}</TableCell>
                      <TableCell>
                      <FormControlLabel
                        control={
                          <Switch
                            checked={row.isActive}
                            onChange={()=> {
                              row.isActive ? onChangeDeActive(row.id) : onChangeActive(row.id)
                            }}
                            name="checkedB"
                            color="primary"
                          />
                        }
                      />
                      </TableCell>
                      <TableCell className='text-center' style={{ minWidth: 120}}>
                        {row.isPushed ? (
                           <Button
                           size="small"
                           variant="contained"
                           className={classes.buttonCustomsuccess}
                         >
                           Đã gửi
                         </Button>
                        ) : (
                          <Button
                          size="small"
                          color="secondary"
                          className={classes.buttonCustom}
                          variant="contained"
                        >
                          Chưa gửi
                        </Button>
                        )}
                      </TableCell>
                          {
                              /*
                               <TableCell>
                        {row.error}
                      </TableCell>
                               */
                          }
                      <TableCell style={{ minWidth: 200}}>
                        <Button
                          size="small"
                          variant="contained"
                          onClick={()=>onChangePushFirebaseNotification(row.id)}
                        >
                          Gửi thông báo
                        </Button>
                      </TableCell>
                      <TableCell align='right' className='text-nowrap'>
                        <Tooltip title='Sửa'>
                          <IconButton
                            aria-label='edit'
                            onClick={() => handleOpenEditDialog(row.id)}>
                            <EditIcon />
                          </IconButton>
                        </Tooltip>
                        <Tooltip title='Xóa'>
                          <IconButton
                            aria-label='delete'
                            onClick={() => handleOpenDeleteDialog(row.id)}>
                            <DeleteIcon />
                          </IconButton>
                        </Tooltip>
                      </TableCell>
                    </TableRow>
                  );
                })
              ) : (
                <TableRow hover tabIndex={-1}>
                  <TableCell colSpan={9} className='text-center'>
                    Không có dữ liệu
                  </TableCell>
                </TableRow>
              )}
              {emptyRows > 0 && (
                <TableRow style={{ height: 0 }}>
                  <TableCell
                    colSpan={7}
                    style={{ padding: 0, borderBottom: 0 }}
                  />
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>

        {contentModel && contentModel.totalItemCount ? (
          <FooterPagination
            currentPage={page + 1}
            rowsPerPage={rowsPerPage}
            handleChangeRowsPerPage={handleChangeRowsPerPage}
            handleChangePage={handleChangePage}
            totalPage={totalPage}
          />
        ) : (
          ''
        )}
      </Paper>
    </div>
  );
}
export default ListNotification;
