import React, { useState, useEffect } from "react";
import { Controller, FormProvider, useForm } from "react-hook-form";
import { TextField, makeStyles, Button } from "@material-ui/core";
import "suneditor/dist/css/suneditor.min.css";
import { UploadFile } from "../../components/upload-file/upload-file.view";
import { Autocomplete } from "@material-ui/lab";
import { AddOrEditDialog } from "../../components/addOrEditDialog/add-or-edit-dialog.view";
import { useAddOrEditIntroduceDialog } from "./useAddOrEditIntroduceDialog";
import FormSunEditor from "../../components/FormItem/FormSunEditor";
import { UrlCollection } from "../../common/url-collection";
import { useHistory } from "react-router-dom";
import { ArrowBackIos } from "@material-ui/icons";
import SaveIcon from "@material-ui/icons/Save";
import * as introduceAction from "../../redux/store/introduce/introduce.store";
import * as lookupLanguageAction from "../../redux/store/lookup-language/lookupLanguage.store";
import { useSelector } from "react-redux";
import CircularProgress from '@material-ui/core/CircularProgress';
const useStyles = makeStyles((theme) => ({
  fullHeight: {
    height: "100%",
  },
}));

const LINK_PATTERN =
  // eslint-disable-next-line no-useless-escape
  /(ftp|http|https):\/\/(\w+:{0,1}\w*@)?(\S+)(:[0-9]+)?(\/|\/([\w#!:.?+=&%@!\-\/]))?/;

const EMAIL_PATTERN = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;

export default function AddOrEditIntroduceDialog (props) {
  const classes = useStyles();
  const history = useHistory();
  const { data, languageOptionsEdit } = props;
  const { idIntroduce } = props?.match?.params;
  const { langId } = useSelector((state) => state.language);
  const [files, setFiles] = useState(null);
  const [facebookFiles, setFacebookFiles] = useState(null);
  const [twitterFiles, setTwitterFiles] = useState(null);
  const [language, setLanguage] = useState([]);

  useEffect(() => {
    getLookupLanguageAction();
 }, []);
  const getLookupLanguageAction = async () => {
    const res = await lookupLanguageAction.GetLookupLanguage();
    if (res.content?.length) {
      setLanguage(res.content);
    }
  };
  const convertArrToObj = (arr) => {
    return arr.reduce((obj, item) => {
      return {
        ...obj,
        [item["id"]]: item["name"],
      };
    }, {});
  };
  const { onSubmit } = useAddOrEditIntroduceDialog({
    data,
  });

  const methods = useForm({
    mode: "all",
    reValidateMode: "onBlur",
  });

  const { register, handleSubmit, errors, reset, control, formState } = methods;
  const { isSubmitting } = formState;
  const onSubmitForm = async(data) => {
    if (Object.keys(errors).length) return;
    await onSubmit(data);
  };

  const handleExited = () => {
    setFiles(null);
    setFacebookFiles(null);
    setTwitterFiles(null);
    reset();
  };
  const onGoBack = () => {
    handleExited()
    const queryParams = window.location.search
    history.push(UrlCollection.Introduce + queryParams)
  };
  const languageOptions = data ? languageOptionsEdit : convertArrToObj(language)
  return (
      <FormProvider {...methods}>
        <form onSubmit={handleSubmit(onSubmitForm)} autoComplete="off">
          <div className="d-sm-flex align-items-center mb-2" onClick={onGoBack} style={{ cursor: "pointer", width: 100}}>
            <ArrowBackIos fontSize="small"></ArrowBackIos> Quay lại
          </div>
          <div className="d-sm-flex align-items-center justify-content-between mb-3">
            <h1 className="h3 mb-0 text-gray-800">
              { data ? `Chỉnh sửa ${data.title}` : "Thêm thông tin giới thiệu"}
            </h1>
            <Button
              type="submit"
              color="primary"
              variant="contained"
              startIcon={!isSubmitting ? <SaveIcon></SaveIcon> : <CircularProgress size={20} color="white"/>}
              disabled={isSubmitting}
            >
              Lưu
            </Button>
          </div>
          <div className="form-group">
            <div className="row">
              <div className="col-12">
                <label className="text-dark">Tên công ty</label>
                <TextField
                  name="companyName"
                  error={!!errors["companyName"]}
                  defaultValue={data ? data.companyName : ""}
                  fullWidth
                  type="text"
                  className="form-control"
                  inputRef={register({
                    maxLength: {
                      value: 150,
                      message: "Không vượt quá 150 ký tự",
                    },
                  })}
                  helperText={
                    <span className="error">
                      {errors["companyName"]?.message || null}
                    </span>
                  }
                />
              </div>
            </div>
          </div>
          <div className="form-group">
            <div className="row">
              <div className="col-12">
                <label className="text-dark">
                  Ngôn ngữ<span className="required"></span>
                </label>
                <Controller
                  render={(props) => (
                    <Autocomplete
                      {...props}
                      disabled={!!data}
                      options={Object.keys(languageOptions)}
                      getOptionLabel={(option) => languageOptions[option]}
                      disableClearable
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          value={params.value}
                          error={!!errors["langId"]}
                          fullWidth
                          variant="outlined"
                          helperText={
                            <span className="error">
                              {errors["langId"]?.message || null}
                            </span>
                          }
                        />
                      )}
                      onChange={(_, data) => props.onChange(data)}
                    />
                  )}
                  control={control}
                  name="langId"
                  defaultValue={
                    data?.langId ? data.langId : Object.keys(languageOptions)[0]
                  }
                  rules={{ required: "Trường này là bắt buộc" }}
                />
              </div>
            </div>
          </div>
          <div className="form-group">
            <div className="row">
              <div className="col-12">
                <label className="text-dark">
                  Tiêu đề<span className="required"></span>
                </label>
                <TextField
                  name="title"
                  error={!!errors["title"]}
                  defaultValue={data ? data.title : ""}
                  fullWidth
                  type="text"
                  className="form-control"
                  inputRef={register({
                    required: {
                      value: true,
                      message: "Trường này là bắt buộc",
                    },
                    maxLength: {
                      value: 150,
                      message: "Không vượt quá 150 ký tự",
                    },
                  })}
                  helperText={
                    <span className="error">
                      {errors["title"]?.message || null}
                    </span>
                  }
                />
              </div>
            </div>
          </div>
          <div className="form-group">
            <div className="row">
              <div className="col-12">
                <label className="text-dark">
                  Mô tả
                </label>
                <TextField
                  name="seoDescription"
                  error={!!errors["seoDescription"]}
                  defaultValue={data ? data.seoDescription : ""}
                  fullWidth
                  multiline
                  rows={3}
                  variant="outlined"
                  type="text"
                  className={`form-control ${classes.fullHeight}`}
                  inputRef={register({
                    
                    maxLength: {
                      value: 550,
                      message: "Không vượt quá 550 ký tự",
                    },
                  })}
                  helperText={
                    <span className="error">
                      {errors["seoDescription"]?.message || null}
                    </span>
                  }
                />
              </div>
            </div>
          </div>
          <div className="form-group">
            <div className="row">
              <div className="col-12">
                <label className="text-dark">
                  Địa chỉ
                </label>
                <TextField
                  name="address"
                  error={!!errors["address"]}
                  defaultValue={data ? data.address : ""}
                  fullWidth
                  type="text"
                  className={`form-control ${classes.fullHeight}`}
                  inputRef={register({
                    
                    maxLength: {
                      value: 150,
                      message: "Không vượt quá 150 ký tự",
                    },
                  })}
                  helperText={
                    <span className="error">
                      {errors["address"]?.message || null}
                    </span>
                  }
                />
              </div>
            </div>
          </div>
          <div className="form-group">
            <div className="row">
              <div className="col-12">
                <label className="text-dark">
                  Email
                </label>
                <TextField
                  name="email"
                  error={!!errors["email"]}
                  defaultValue={data ? data.email : ""}
                  fullWidth
                  type="text"
                  className={`form-control ${classes.fullHeight}`}
                  inputRef={register({
                    
                    pattern: {
                      value: EMAIL_PATTERN,
                      message: "Email không đúng định dạng",
                    },
                  })}
                  helperText={
                    <span className="error">
                      {errors["email"]?.message || null}
                    </span>
                  }
                />
              </div>
            </div>
          </div>
          <div className="form-group">
            <div className="row">
              <div className="col-12">
                <label className="text-dark">
                  Hotline
                </label>
                <TextField
                  name="hotline"
                  error={!!errors["hotline"]}
                  defaultValue={data ? data.hotline : ""}
                  fullWidth
                  type="text"
                  className={`form-control ${classes.fullHeight}`}
                  inputRef={register({
                    
                    maxLength: {
                      value: 30,
                      message: "Không vượt quá 30 ký tự",
                    },
                  })}
                  helperText={
                    <span className="error">
                      {errors["hotline"]?.message || null}
                    </span>
                  }
                />
              </div>
            </div>
          </div>
          <div className="form-group">
            <div className="row">
              <div className="col-12">
                <FormSunEditor
                  title="Nội dung"
                  name="content"
                  validate={{ required: "Trường này là bắt buộc" }}
                  defaultValue={data ? data.content : ""}
                />
              </div>
            </div>
          </div>
          <div className="form-group">
            <div className="row">
              <div className="col-12">
                <UploadFile
                  title="Hình ảnh"
                  files={files}
                  onSetFiles={setFiles}
                  defaultValue={data ? data.imageFile : null}
                />
                <TextField
                  inputRef={register({
                    
                  })}
                  type="hidden"
                  name="imageId"
                  value={
                    files?.length > 0
                      ? files[0].fileId
                      : data
                      ? data.imageId
                      : ""
                  }
                />
                <p className="error">{errors["imageId"]?.message || null}</p>
              </div>
            </div>
          </div>
          <div className="form-group">
            <div className="row">
              <div className="col-12">
                <label className="text-dark">Link facebook</label>
                <TextField
                  name="fbLink"
                  error={!!errors["fbLink"]}
                  defaultValue={data ? data.fbLink : ""}
                  fullWidth
                  type="text"
                  className="form-control"
                  inputRef={register({
                    pattern: {
                      value: LINK_PATTERN,
                      message: "Link không đúng định dạng",
                    },
                    maxLength: {
                      value: 150,
                      message: "Không vượt quá 150 ký tự",
                    },
                  })}
                  helperText={
                    <span className="error">
                      {errors["fbLink"]?.message || null}
                    </span>
                  }
                />
              </div>
            </div>
          </div>
          <div className="form-group">
            <div className="row">
              <div className="col-12">
                <label className="text-dark">Link tiktok</label>
                <TextField
                  name="tiktokLink"
                  error={!!errors["tiktokLink"]}
                  defaultValue={data ? data.tiktokLink : ""}
                  fullWidth
                  type="text"
                  className="form-control"
                  inputRef={register({
                    pattern: {
                      value: LINK_PATTERN,
                      message: "Link không đúng định dạng",
                    },
                    maxLength: {
                      value: 150,
                      message: "Không vượt quá 150 ký tự",
                    },
                  })}
                  helperText={
                    <span className="error">
                      {errors["tiktokLink"]?.message || null}
                    </span>
                  }
                />
              </div>
            </div>
          </div>
          <div className="form-group">
            <div className="row">
              <div className="col-12">
                <label className="text-dark">Link instagram</label>
                <TextField
                  name="instagramLink"
                  error={!!errors["instagramLink"]}
                  defaultValue={data ? data.instagramLink : ""}
                  fullWidth
                  type="text"
                  className="form-control"
                  inputRef={register({
                    pattern: {
                      value: LINK_PATTERN,
                      message: "Link không đúng định dạng",
                    },
                    maxLength: {
                      value: 150,
                      message: "Không vượt quá 150 ký tự",
                    },
                  })}
                  helperText={
                    <span className="error">
                      {errors["instagramLink"]?.message || null}
                    </span>
                  }
                />
              </div>
            </div>
          </div>
          <div className="form-group">
            <div className="row">
              <div className="col-12">
                <label className="text-dark">Link linkedIn</label>
                <TextField
                  name="linkedinLink"
                  error={!!errors["linkedinLink"]}
                  defaultValue={data ? data.linkedinLink : ""}
                  fullWidth
                  type="text"
                  className="form-control"
                  inputRef={register({
                    pattern: {
                      value: LINK_PATTERN,
                      message: "Link không đúng định dạng",
                    },
                    maxLength: {
                      value: 150,
                      message: "Không vượt quá 150 ký tự",
                    },
                  })}
                  helperText={
                    <span className="error">
                      {errors["linkedinLink"]?.message || null}
                    </span>
                  }
                />
              </div>
            </div>
          </div>
          <div className="form-group">
            <div className="row">
              <div className="col-12">
                <label className="text-dark">Link youtube</label>
                <TextField
                  name="youtubeLink"
                  error={!!errors["youtubeLink"]}
                  defaultValue={data ? data.youtubeLink : ""}
                  fullWidth
                  type="text"
                  className="form-control"
                  inputRef={register({
                    pattern: {
                      value: LINK_PATTERN,
                      message: "Link không đúng định dạng",
                    },
                    maxLength: {
                      value: 150,
                      message: "Không vượt quá 150 ký tự",
                    },
                  })}
                  helperText={
                    <span className="error">
                      {errors["youtubeLink"]?.message || null}
                    </span>
                  }
                />
              </div>
            </div>
          </div>

          <div className="form-group">
            <div className="row">
              <div className="col-12">
                <label className="text-dark">Tiêu đề facebook</label>
                <TextField
                  name="fbTitle"
                  error={!!errors["fbTitle"]}
                  defaultValue={data ? data.fbTitle : ""}
                  fullWidth
                  type="text"
                  className="form-control"
                  inputRef={register({
                    maxLength: {
                      value: 150,
                      message: "Không vượt quá 150 ký tự",
                    },
                  })}
                  helperText={
                    <span className="error">
                      {errors["fbTitle"]?.message || null}
                    </span>
                  }
                />
              </div>
            </div>
          </div>
          <div className="form-group">
            <div className="row">
              <div className="col-12">
                <label className="text-dark">Chi tiết facebook</label>
                <TextField
                  name="fbDescription"
                  error={!!errors["fbDescription"]}
                  defaultValue={data ? data.fbDescription : ""}
                  fullWidth
                  multiline
                  rows={3}
                  variant="outlined"
                  type="text"
                  className={`form-control ${classes.fullHeight}`}
                  inputRef={register({
                    maxLength: {
                      value: 550,
                      message: "Không vượt quá 550 ký tự",
                    },
                  })}
                  helperText={
                    <span className="error">
                      {errors["fbDescription"]?.message || null}
                    </span>
                  }
                />
              </div>
            </div>
          </div>
          <div className="form-group">
            <div className="row">
              <div className="col-12">
                <UploadFile
                  title="Ảnh facebook"
                  files={facebookFiles}
                  onSetFiles={setFacebookFiles}
                  defaultValue={data ? data.fbImageFile : null}
                  required={false}
                />
                <TextField
                  inputRef={register}
                  type="hidden"
                  name="fbImageId"
                  value={
                    facebookFiles?.length > 0
                      ? facebookFiles[0].fileId
                      : data
                      ? data.fbImageId
                      : ""
                  }
                />
              </div>
            </div>
          </div>

          <div className="form-group">
            <div className="row">
              <div className="col-12">
                <label className="text-dark">Tiêu đề twitter</label>
                <TextField
                  name="twitterTitle"
                  error={!!errors["twitterTitle"]}
                  defaultValue={data ? data.twitterTitle : ""}
                  fullWidth
                  type="text"
                  className="form-control"
                  inputRef={register({
                    maxLength: {
                      value: 150,
                      message: "Không vượt quá 150 ký tự",
                    },
                  })}
                  helperText={
                    <span className="error">
                      {errors["twitterTitle"]?.message || null}
                    </span>
                  }
                />
              </div>
            </div>
          </div>
          <div className="form-group">
            <div className="row">
              <div className="col-12">
                <label className="text-dark">Chi tiết twitter</label>
                <TextField
                  name="twitterDescription"
                  error={!!errors["twitterDescription"]}
                  defaultValue={data ? data.twitterDescription : ""}
                  fullWidth
                  type="text"
                  multiline
                  rows={3}
                  variant="outlined"
                  className={`form-control ${classes.fullHeight}`}
                  inputRef={register({
                    maxLength: {
                      value: 550,
                      message: "Không vượt quá 550 ký tự",
                    },
                  })}
                  helperText={
                    <span className="error">
                      {errors["twitterDescription"]?.message || null}
                    </span>
                  }
                />
              </div>
            </div>
          </div>
          <div className="form-group">
            <div className="row">
              <div className="col-12">
                <UploadFile
                  title="Ảnh twitter"
                  files={twitterFiles}
                  onSetFiles={setTwitterFiles}
                  defaultValue={data ? data.twitterImageFile : null}
                  required={false}
                />
                <TextField
                  inputRef={register}
                  type="hidden"
                  name="twitterImageId"
                  value={
                    twitterFiles?.length > 0
                      ? twitterFiles[0].fileId
                      : data
                      ? data.twitterImageId
                      : ""
                  }
                />
              </div>
            </div>
          </div>
        </form>
      </FormProvider>
  );
};
