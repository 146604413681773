import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  makeStyles,
  Typography,
} from "@material-ui/core";
import Close from "@material-ui/icons/Close";
import Save from "@material-ui/icons/Save";
import React from "react";
import FileManagement from "../file_management/file_management";

const useStyles = makeStyles((theme) => ({
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
}));

export const FileManagementDialog = (props) => {
  const { open, onClose, files, onSetFiles, onSave } = props;
  const classes = useStyles();

  return (
    <Dialog
      onClose={onClose}
      open={open}
      fullWidth={true}
      maxWidth="md"
      className="dialog-preview-form"
    >
      <DialogTitle disableTypography>
        <Typography variant="h6">Quản lý file</Typography>
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <Close />
        </IconButton>
      </DialogTitle>
      <DialogContent dividers>
        <FileManagement
          files={files}
          setFiles={onSetFiles}
          acceptedFiles={["jpeg", "png", "jpg", "gif"]}
        />
      </DialogContent>

      <DialogActions>
        <Button
          type="button"
          onClick={onClose}
          variant="contained"
          startIcon={<Close />}
        >
          Hủy
        </Button>
        {files && files.length > 0 && (
          <Button
            type="button"
            color="primary"
            variant="contained"
            startIcon={<Save />}
            onClick={onSave}
          >
            Lưu
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
};
