import { Map } from "immutable";

const APP_LOADING = "APP_LOADING";
const APP_CHANGE_TITLE = "APP_CHANGE_TITLE";

export const onLoading = (isLoading) => ({ type: APP_LOADING, isLoading });
export const onChangeTitlePage = (payload) => ({ type: APP_CHANGE_TITLE, payload });

export const ShowLoading = (isLoading) => {
  return (dispatch) => {
    dispatch(onLoading(isLoading));
  };
};

export const initialState = Map({
  loading: false,
  titlePage: '',
}).toJS();

export default function AppReducer(state = initialState, action) {
  switch (action.type) {
    case APP_LOADING:
      return {
        ...state,
        loading: action.isLoading,
      };
    case APP_CHANGE_TITLE:
      return {
        ...state,
        titlePage: action.payload,
      };
    default:
      return { ...state };
  }
}
