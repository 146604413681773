import { useState, useEffect } from 'react';

import * as viVN from '../../../language/vi-VN.json';
import ShowNotification from '../../../components/react-notifications/react-notifications';
import { NotificationMessageType } from '../../../utils/configuration';

//--- Action
import * as addressAction from '../../../redux/store/address/address.store';
import * as districtAction from '../../../redux/store/district-management/district.store';
import * as communeAction from '../../../redux/store/commune-management/commune.store';

const AddUpdateMethods = (props) => {
  const {
    addressId,
    onSuccess,
    setValue,
    watch,
    setCommunes,
    setDistrictByProvinceId,
    choosePlace,
    setRequestParams,
    provinceSelect,
    setAddressModel,
    setCurrentPosition,
    langId,
    setOpenConfirm,
    openConfirm,
  } = props;
  const [id, setId] = useState('');
  const [coreId, setCoreId] = useState('');
  const [currentDistrictId, setCurrentDistrictId] = useState();
  const [currentCommuneId, setCurrentCommuneId] = useState();
  const [reChooseSelectedOptions, setReChooseSelectedOptions] = useState(0);
  const [lat, setLat] = useState('');
  const [long, setLong] = useState('');
  const selectedProvince = watch('province');
  const selectedDistrict = watch('district');

  useEffect(() => {
    if (!selectedProvince) return;
    GetDistrictByProvinceId(selectedProvince.id);
  }, [selectedProvince, reChooseSelectedOptions]);

  useEffect(() => {
    if (!selectedDistrict) return;
    Promise.all([GetLookupCommune(selectedDistrict.id)])
      .then((res) => {
        const [communeLookupModels] = res;
        setCommunes(
          communeLookupModels && communeLookupModels.content
            ? communeLookupModels.content
            : []
        );
        setValue(
          'commune',
          currentCommuneId
            ? communeLookupModels.content?.find(
                (item) => item.id === currentCommuneId
              )
            : null
        );
      })
      .catch((err) => {});
  }, [selectedDistrict]);

  useEffect(() => {
    if (choosePlace) {
      setOpenConfirm(true);
      setLat(choosePlace.geometry.location.lat());
      setLong(choosePlace.geometry.location.lng());
    }
  }, [choosePlace]);

  useEffect(() => {
    if (!openConfirm) {
    }
  }, [openConfirm]);

  const GetDistrictByProvinceId = (provinceId) => {
    if (!provinceId) return;
    return districtAction
      .GetDistrictByProvinceId(provinceId)
      .then((res) => {
        setDistrictByProvinceId(res && res.content ? res.content : []);
        setValue(
          'district',
          currentDistrictId
            ? res.content?.find((item) => item.id === currentDistrictId)
            : null
        );
      })
      .catch((err) => {});
  };

  const GetLookupCommune = (districtId) => {
    return new Promise((resolve, reject) => {
      communeAction.GetCommunesByDistrictId(districtId).then(
        (res) => {
          resolve(res);
        },
        (err) => {
          reject(err);
          err &&
            err.errorType &&
            ShowNotification(
              viVN.Errors[err.errorType],
              NotificationMessageType.Error
            );
        }
      );
    });
  };

  const GetAnalysisAddress = (longitude, latitude) => {
    addressAction
      .AnalysisAddressLongLat(longitude, latitude)
      .then((res) => {
        if (res.districtId) {
          setCurrentDistrictId(res.districtId ? res.districtId : null);
        }

        if (res.communeId) {
          setCurrentCommuneId(res.communeId ? res.communeId : null);
        }

        setValue(
          'province',
          res.provinceId
            ? provinceSelect?.find((item) => item.id === res.provinceId)
            : null
        );
        setReChooseSelectedOptions((x) => x + 1);

        if (res.street && res.streetId) {
          setValue('street', {
            value: res.streetId,
            label: res.street,
          });
        }
        setValue('generalInfor', res.streetNumber ? res.streetNumber : '');
      })
      .catch((err) => console.log(err));
  };

  useEffect(() => {
    if (addressId) {
      addressAction
        .AddressDetail(addressId, langId)
        .then((res) => {
          if (res && res.content) {
            setAddressModel(res.content);
            setId(res.content.translations[0].id);
            setCoreId(res.content.id);
            setValue('latitude', res.content.latitude);
            setValue('longitude', res.content.longitude);
            setValue('street', res.content.translations[0].street);
            setValue('generalInfor', res.content.generalInfor);
            setValue('room', res.content.room);
            setValue('city', res.content.translations[0].city);
            setValue('township', res.content.translations[0].township);
            if (res.content.districtId) {
              setCurrentDistrictId(
                res.content.districtId ? res.content.districtId : null
              );
            }
            if (res.content.communeId) {
              setCurrentCommuneId(
                res.content.communeId ? res.content.communeId : null
              );
            }
            setValue(
              'province',
              res.content.provinceId
                ? provinceSelect?.find(
                    (item) => item.id === res.content.provinceId
                  )
                : null
            );
            setCurrentPosition({
              lat: res.content.latitude,
              lng: res.content.longitude,
            });
          }
        })
        .catch((err) => console.log(err));
    }
  }, [addressId]);

  const onSubmit = (formData) => {
    if (addressId) {
      onSubmitEdit(formData);
    } else {
      onSubmitAdd(formData);
    }
  };

  const onSubmitAdd = (formData) => {
    if (long !== '' && lat !== '') {
      let object = {
        translations: [
          {
            langId: langId,
            generalInfor: formData.generalInfor,
            street: formData?.street?.label ? formData.street.label : '',
            city: '',
            township: '',
          },
        ],
        room: formData.room,
        landPriceInforId: 0,
        communeId: formData.commune.id,
        longitude: long,
        latitude: lat,
        street: {
          id: formData?.street?.value ? formData.street.value : 0,
          name: formData?.street?.label ? formData.street.label : '',
        },
      };
      addressAction
        .AddressCreate(object)
        .then((res) => {
          setRequestParams((x) => ({
            ...x,
            page: 1,
            order: 'desc',
            orderBy: 'modifiedDate',
            keyword: '',
          }));
          onSuccess();
          ShowNotification(
            viVN.Success.NewsAddSuccess,
            NotificationMessageType.Success
          );
        })
        .catch(
          (err) => viVN.Errors[err.errorType],
          NotificationMessageType.Error
        );
    } else {
      ShowNotification(
        viVN.Errors['LatLongValid'],
        NotificationMessageType.Error
      );
    }
  };

  const onSubmitEdit = (formData) => {
    if (long !== '' && lat !== '') {
      let object = {
        core: {
          id: coreId,
          room: formData?.room ? formData?.room : '',
          streetId: formData?.street?.value,
          communeId: formData.commune.id,
          longitude: long,
          latitude: lat,
        },
        langId: langId,
        generalInfor: formData.generalInfor,
        street: formData?.street?.label ? formData.street.label : '',
        city: '',
        township: '',
        id: id,
        coreId: coreId,
      };
      addressAction
        .AddressUpdate(object)
        .then((res) => {
          setRequestParams((x) => ({
            ...x,
            page: 1,
            order: 'desc',
            orderBy: 'modifiedDate',
            keyword: '',
          }));
          onSuccess();
          ShowNotification(
            viVN.Success.NewsAddSuccess,
            NotificationMessageType.Success
          );
        })
        .catch(
          (err) => viVN.Errors[err.errorType],
          NotificationMessageType.Error
        );
    } else {
      ShowNotification(
        viVN.Errors['LatLongValid'],
        NotificationMessageType.Error
      );
    }
  };

  const handleAccept = () => {
    if (choosePlace) {
      setOpenConfirm(false);
      GetAnalysisAddress(
        choosePlace.geometry.location.lng(),
        choosePlace.geometry.location.lat()
      );
    }
  };

  const refresh = () => {
    GetAnalysisAddress(0, 0);
    setValue('province', null);
    setValue('district', null);
    setValue('street', null);
    setValue('commune', null);
  };

  return {
    onSubmit,
    handleAccept,
    refresh,
  };
};

export default AddUpdateMethods;
