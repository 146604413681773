import moment from 'moment';

export const convertStringToNumber = value =>
  parseInt(value.replace(/\./g, ''), 10);
export const formatCurrency = value =>
  value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.');

export const destructureFormData = ({objData, listUitilies}) => {
  let {
    acreage,
    apartmentNumber,
    buildingDensity,
    endDate,
    investorId,
    phoneNumber,
    priceFrom,
    priceTo,
    progressId,
    provinceId,
    realEstateTypeId,
    startDate,
    statusId,
    translations,
    utilities,
    isActive,
    isFeature
  } = objData;
  // Number
  acreage = parseInt(acreage, 10) || 0;
  apartmentNumber = parseInt(apartmentNumber, 10) || 0;
  buildingDensity = parseInt(buildingDensity, 10) || 0;
  priceFrom = 0;
  priceTo = 0;
  buildingDensity = parseInt(buildingDensity, 10) || 0;
  investorId = investorId?.value || 0;
  progressId = progressId?.value || 0;
  provinceId = provinceId?.value || 0;
  realEstateTypeId = realEstateTypeId?.value || 0;
  statusId = statusId?.value || 0;

  // Date
  startDate = moment(startDate).format();
  endDate = moment(endDate).format();

  const utilitiesFiltered = [...listUitilies].reduce((acc, item, idx) => {
    if (utilities[idx]) {
      acc.push(item?.id);
    }
    return acc;
  }, []);

  return {
    translations,
    acreage,
    apartmentNumber,
    buildingDensity,
    investorId,
    progressId,
    provinceId,
    realEstateTypeId,
    statusId,
    startDate,
    endDate,
    phoneNumber,
    priceFrom,
    priceTo,
    utilities: [...utilitiesFiltered],
    isActive,
    isFeature
  };
};
