import React from "react";
import "./layout.scss";
import Sidebar from "../sidebar/sidebar.view.jsx";
import TopBar from "../topbar/topbar.view.jsx";
import Footer from "../footer/footer.view.jsx";
import LoadingScreen from '../loading-with-queue/loading-with-queue';

function LayoutView(props :any) {
    console.log("render");
    return (
      <div id="wrapper">
        <LoadingScreen />
        <Sidebar />
        <div id="content-wrapper" className="d-flex flex-column">
          <div id="content">
            <TopBar />
            <div className="container-fluid">{props.children}</div>
          </div>

          <Footer />
        </div>
      </div>
    );
}
export default React.memo(LayoutView);
