import { useState, useEffect } from 'react';

import * as viVN from "../../../language/vi-VN.json";
import ShowNotification from "../../../components/react-notifications/react-notifications";
import { NotificationMessageType } from "../../../utils/configuration";

//--- Action
import * as districtAction from "../../../redux/store/district-management/district.store";
import * as communeAction from "../../../redux/store/commune-management/commune.store";
import * as landPriceInfoActions from "../../../redux/store/land-price-info/land-price-info.store";
import { convertNumberToCurrency } from '../../../utils/helpers';

const AddUpdateMethods = (props) => {
  const {
    landPriceInfoId,
    onSuccess,
    setValue,
    watch,
    setCommunes,
    setDistrictByProvinceId,
    setRequestParams,
    provinceSelect,
    langId,
    soilTypes,
  } = props;
  const [id, setId] = useState('');
  const [coreId, setCoreId] = useState('');
  const [currentDistrictId, setCurrentDistrictId] = useState();
  const [currentCommuneId, setCurrentCommuneId] = useState();
  const [reChooseSelectedOptions, setReChooseSelectedOptions] = useState(0);
  const selectedProvince = watch('province');
  const selectedDistrict = watch('district');

  useEffect(() => {
    if (!selectedProvince) return;
    GetDistrictByProvinceId(selectedProvince.id);
  }, [selectedProvince, reChooseSelectedOptions]);

  useEffect(() => {
    if (!selectedDistrict) return;
    Promise.all([GetLookupCommune(selectedDistrict.id)])
      .then((res) => {
        const [communeLookupModels] = res;
        setCommunes(
          communeLookupModels && communeLookupModels.content
            ? communeLookupModels.content
            : []
        );
        setValue('commune', currentCommuneId ? communeLookupModels.content?.find(item => item.id === currentCommuneId) : null);
      })
      .catch((err) => {
      });
  }, [selectedDistrict]);

  const GetDistrictByProvinceId = (provinceId) => {
    if (!provinceId) return;
    return districtAction
      .GetDistrictByProvinceId(provinceId)
      .then((res) => {
        setDistrictByProvinceId(res && res.content ? res.content : []);
        setValue('district', currentDistrictId ? res.content?.find(item => item.id === currentDistrictId) : null);
      })
      .catch((err) => {
      });
  };

  const GetLookupCommune = (districtId) => {
    return new Promise((resolve, reject) => {
      communeAction.GetCommunesByDistrictId(districtId).then(
        (res) => {
          resolve(res);
        },
        (err) => {
          reject(err);
          err &&
            err.errorType &&
            ShowNotification(
              viVN.Errors[err.errorType],
              NotificationMessageType.Error
            );
        }
      );
    });
  };

  useEffect(() => {
    landPriceInfoActions
      .LandPriceInfoDetail(landPriceInfoId, langId)
      .then((res) => {
        if (res && res.content) {
          setId(res.content.translations[0].id);
          setCoreId(res.content.id);
          setValue('toCoordinate', res.content.toCoordinate);
          setValue('fromCoordinate', res.content.fromCoordinate);
          setValue('statePrice', convertNumberToCurrency(res.content.statePrice));
          setValue('stateVT1', convertNumberToCurrency(res.content.stateVT1));
          setValue('stateVT2', convertNumberToCurrency(res.content.stateVT2));
          setValue('stateVT3', convertNumberToCurrency(res.content.stateVT3));
          setValue('stateVT4', convertNumberToCurrency(res.content.stateVT4));
          setValue('stateVT5', convertNumberToCurrency(res.content.stateVT5));
          setValue('isActive', res.content.isActive);
          setValue('dateApply', res.content.dateApply);
          setValue('description', res.content.translations[0].description);
          setValue('fromToAddr', res.content.translations[0].fromToAddr);
          setValue('soilType', res.content.soilTypeId ? soilTypes?.find(item => item.id === res.content.soilTypeId) : null);
          setValue('landPriceInfo', res.content.landPriceInfoId ? {"label": res.content.landPriceInfoDetail, "value": res.content.landPriceInfoId} : null);
          if (res.content.districtId) {
            setCurrentDistrictId(res.content.districtId ? res.content.districtId : null);
          }
          if (res.content.communeId) {
            setCurrentCommuneId(res.content.communeId ? res.content.communeId : null);
          }
          setValue('province', res.content.provinceId ? provinceSelect?.find(item => item.id === res.content.provinceId) : null);
          if(res.content.streetId) {
            setValue('street', {"value": res.content.streetId, "label": res.content.streetName});
          }
        }
      })
      .catch((err) => console.log(err));
  }, [landPriceInfoId]);

  const onSubmit = (formData) => {
    if (landPriceInfoId) {
      onSubmitEdit(formData);
    } else {
      onSubmitAdd(formData);
    }
  };

  const onSubmitAdd = (formData) => {
    let object = {
      translations: [
        {
          langId: langId,
          description: formData.description,
          fromToAddr: formData.fromToAddr
        }
      ],
      statePrice: parseInt(formData?.statePrice.toString().replace(/[",]/g, '')),
      stateVT1: parseInt(formData?.stateVT1.toString().replace(/[",]/g, '')),
      stateVT2: parseInt(formData?.stateVT2.toString().replace(/[",]/g, '')),
      stateVT3: parseInt(formData?.stateVT3.toString().replace(/[",]/g, '')),
      stateVT4: parseInt(formData?.stateVT4.toString().replace(/[",]/g, '')),
      stateVT5: parseInt(formData?.stateVT5.toString().replace(/[",]/g, '')),
      fromCoordinate: formData.fromCoordinate,
      toCoordinate: formData.toCoordinate,
      dateApply: formData.dateApply,
      isActive: formData.isActive,
      street: {
        id: formData?.street?.value ? formData.street.value : 0,
        name: formData?.street?.label ? formData.street.label : ''
      },
      communeId: formData.commune.id,
      soilTypeId: formData.soilType.id,
    }
    landPriceInfoActions
      .LandPriceInfoCreate(object)
      .then((res) => {
        setRequestParams(x => ({
          ...x,
          page: 1,
          order: 'desc',
          orderBy: 'modifiedDate',
          keyword: ''
        }));
        onSuccess();
        ShowNotification(
          viVN.Success.NewsAddSuccess,
          NotificationMessageType.Success
        );
      })
      .catch(
        (err) => viVN.Errors[err.errorType],
        NotificationMessageType.Error
      );
  };

  const onSubmitEdit = (formData) => {
    let object = {
      "id": id,
      "coreId": coreId,
      "core": {
        "id": coreId,
        "statePrice": parseInt(formData?.statePrice.toString().replace(/[",]/g, '')),
        "stateVT1": parseInt(formData?.stateVT1.toString().replace(/[",]/g, '')),
        "stateVT2": parseInt(formData?.stateVT2.toString().replace(/[",]/g, '')),
        "stateVT3": parseInt(formData?.stateVT3.toString().replace(/[",]/g, '')),
        "stateVT4": parseInt(formData?.stateVT4.toString().replace(/[",]/g, '')),
        "stateVT5": parseInt(formData?.stateVT5.toString().replace(/[",]/g, '')),
        "fromCoordinate": formData.fromCoordinate,
        "toCoordinate": formData.toCoordinate,
        "dateApply": formData.dateApply,
        "isActive": formData.isActive,
        "isDelete": false,
        "soilTypeId": formData.soilType?.id,
        "streetId": formData?.street?.value ? formData.street.value : 0,
      },
      "langId": langId,
      "description": formData.description,
      "fromToAddr": formData.fromToAddr
    }
    landPriceInfoActions
      .LandPriceInfoUpdate(object)
      .then((res) => {
        setRequestParams(x => ({
          ...x,
          page: 1,
          order: 'desc',
          orderBy: 'modifiedDate',
          keyword: ''
        }));
        onSuccess();
        ShowNotification(
          viVN.Success.NewsAddSuccess,
          NotificationMessageType.Success
        );
      })
      .catch(
        (err) => viVN.Errors[err.errorType],
        NotificationMessageType.Error
      );
  };

  return {
    onSubmit,
  };
};

export default AddUpdateMethods;