import React from "react";
import { useSelector } from 'react-redux';
import { FormProvider, useForm } from 'react-hook-form';

import {
    Grid
} from '@material-ui/core';
import FormInput from './FormInput';
import FormInputAddress from "./FormInputAddress";

export default function DemoFormInputAddress() {
    const { langId } = useSelector((state) => state.language);
    const methods = useForm({
        defaultValues: {
            name: ''
        }
    });

    const { handleSubmit } = methods;

    const onSubmit = (formData) => {
        console.log("🚀 ~ file: DemoFormInputAddress.js ~ line 20 ~ onSubmit ~ formData", formData)
    };

    return (
        <FormProvider {...methods}>
            <form onSubmit={handleSubmit(onSubmit)} autoComplete='off'>
                <Grid container
                    direction="column"
                    spacing={2}>
                    <Grid item>
                        <div className='form-group'>
                            <FormInput
                                title="Tên"
                                name="name"
                                placeholder="Tên"
                            />
                        </div>
                    </Grid>
                    <Grid item>
                        <div className='form-group'>
                            {langId && <FormInputAddress
                                title="Địa chỉ"
                                name="address"
                                placeholder="Địa chỉ"
                                langId={langId}
                            />}
                        </div>
                    </Grid>
                </Grid>
            </form>
        </FormProvider>
    );
}