/* eslint-disable no-script-url */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/alt-text */
import React, { Component, useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBars,
  faSignOutAlt,
  faUser
} from "@fortawesome/free-solid-svg-icons";

import {
  APIUrlDefault, setCookies, TokenKey,
} from "../../utils/configuration";
import { UrlCollection } from "../../common/url-collection";
import useAuth from "../../hook/useAuth";

export default function TopBar(props) {
  const { logout, user } = useAuth();
  const { pathname } = useLocation();
  const [avatarUri, setAvatarUri] = useState(null);

  useEffect(() => {
    if(user) {
      const tmpAvatarUri = !user?.avatar ? `${process.env.PUBLIC_URL}/user-default.png` : `${APIUrlDefault}${user.avatar}`;
      setAvatarUri(tmpAvatarUri);
    }
  }, [user]);

  const handleLogout = async () => {
    try {
      setCookies(TokenKey.returnUrl, pathname);
      await logout();
    } catch (err) {
      console.error(err);
    }
  };

  return (
    <nav className="navbar navbar-expand navbar-light bg-white topbar mb-4 static-top shadow">
      <button
        id="sidebarToggleTop"
        className="btn btn-link d-md-none rounded-circle mr-3"
      >
        <FontAwesomeIcon icon={faBars} />
      </button>
      <ul className="navbar-nav ml-auto">
        <li className="nav-item dropdown no-arrow">
          <a
            className="nav-link dropdown-toggle"
            href="#"
            id="userDropdown"
            role="button"
            data-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false"
          >
            <span className="mr-2 d-none d-lg-inline text-gray-600 small">
              {user && user.fullName}
            </span>
            <img
              className="img-profile rounded-circle"
              src={avatarUri}
            />
          </a>

          <div
            className="dropdown-menu dropdown-menu-right shadow animated--grow-in"
            aria-labelledby="userDropdown"
          >
            {/* <a className="dropdown-item" href="#">
                <FontAwesomeIcon icon={faUser} className="fa-sm fa-fw mr-2 text-gray-400" />Profile
              </a> */}
            {/* <div className="dropdown-divider"></div> */}
            <Link className="dropdown-item" to={UrlCollection.MyAccount} >
              <FontAwesomeIcon
                icon={faUser}
                className="fa-sm fa-fw mr-2 text-gray-400"
              />
              My Account
            </Link>
            <Link
              className="dropdown-item"
              to="#"
              onClick={handleLogout}
            >
              <FontAwesomeIcon
                icon={faSignOutAlt}
                className="fa-sm fa-fw mr-2 text-gray-400"
              />
              Logout
            </Link>
          </div>
        </li>
      </ul>
    </nav>
  );
}
