import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Configs } from "../../common/config";
import { useForm } from "react-hook-form";

//--- Material Control
import {
  Button,
  TextField,
  IconButton,
  Tooltip,
  Popover,
  Select,
  MenuItem,
  Checkbox,
  Fab,
} from "@material-ui/core";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";
import ButtonGroup from "@material-ui/core/ButtonGroup";

//--- Styles
import "./posts.scss";

//--- Material Icon
import DeleteCircle from "@material-ui/icons/DeleteForever";
import AddCircle from "@material-ui/icons/AddCircle";
import CloseIcon from "@material-ui/icons/Close";
//import FilterListIcon from '@material-ui/icons/FilterList';
import SearchIcon from "@material-ui/icons/Search";
import ClearAllIcon from "@material-ui/icons/ClearAll";
import RefreshIcon from "@material-ui/icons/Refresh";

//--- Component
import ListPosts from "./list-posts/list-posts.view";
import AddPosts from "./add-posts/add-posts.view";
import EditPosts from "./edit-posts/edit-posts.view";
import DeleteDialog from "../../components/dialog-delete/dialog-delete.view";
import ViewFile from "./view-posts/view-post.view";

//--- Notifications
import ShowNotification from "../../components/react-notifications/react-notifications";
import {
  NotificationMessageType,
  DomainUserSide,
} from "../../utils/configuration";
import * as viVN from "../../language/vi-VN.json";
import { UserSidePathUrl } from "../../common/config";
import * as appActions from "../../core/app.store";

//--- Redux store
import * as postActions from "../../redux/store/posts/posts.store";
import { useHistory } from "react-router-dom";
import { UrlCollection } from "../../common/url-collection";
//import { makeStyles } from '@material-ui/core/styles';

// const useStyles = makeStyles((theme) => ({
//   formControl: {
//     margin: theme.spacing(1),
//     minWidth: 120,
//   },
//   selectEmpty: {
//     marginTop: theme.spacing(2),
//   },
// }));

export default function News(props) {
  //const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();
  const { langId } = useSelector((state) => state.language);
  const { titlePage } = useSelector((state) => state.app);
  const { id, postTypeId } = props.match.params;
  const { pathname } = props.location;
  const [newsModels, setNewsModels] = useState();
  const [totalItemCount, setTotalItemCount] = useState();
  const [searchData, setSearchData] = useState({
    isHot: undefined,
    isHomePage: undefined,
    keyword: "",
    status: 2,
    title: "",
  });
  const [postId, setPostId] = useState();
  //const [newsxmin, setNewsxmin] = useState();
  const [order, setOrder] = useState("desc");
  const [orderBy, setOrderBy] = useState("modifiedDate");
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(Configs.DefaultPageSize);
  const [filePreview, setFilePreview] = useState([]);

  const [isDelete, setIsDelete] = useState(false);
  const [isShowTrash, setIsShowTrash] = useState(false);
  const [linkToView, setLinkToView] = useState("");
  const { register, handleSubmit } = useForm({
    mode: "all",
    reValidateMode: "onBlur",
  });

  useEffect(() => {
    const queryParams = new URLSearchParams(window.location.search);
    let _langId = langId;
    let _page = page;
    let _rowsPerPage = rowsPerPage;
    let _order = order;
    let _orderBy = orderBy;
    let _isDelete = isDelete;
    let _keyword = undefined;
    let _isHot = undefined;
    let _isHomepage = undefined;
    let _status = undefined;
    let _title = undefined;
    if (queryParams.get("langId")) {
      _langId = queryParams.get("langId");
    }
    if (queryParams.get("pageIndex")) {
      _page = queryParams.get("pageIndex");
      setPage(Number(_page));
    }
    if (queryParams.get("pageSize")) {
      _rowsPerPage = queryParams.get("pageSize");
      setRowsPerPage(Number(_rowsPerPage));
    }
    if (queryParams.get("keyword")) {
      _keyword = queryParams.get("keyword");
      setSearchData({ ...searchData, keyword: _keyword });
    }
    if (queryParams.get("isHot")) {
      _isHot = queryParams.get("isHot");
      setSearchData({ ...searchData, isHot: _isHot });
    }
    if (queryParams.get("isHomepage")) {
      _isHomepage = queryParams.get("isHomepage");
      setSearchData({ ...searchData, isHomepage: _isHomepage });
    }
    if (queryParams.get("title")) {
      _title = queryParams.get("title");
      setSearchData({ ...searchData, title: _title });
    }
    if (queryParams.get("order")) {
      _order = queryParams.get("order");
      setOrder(_order);
    }
    if (queryParams.get("orderBy")) {
      _orderBy = queryParams.get("orderBy");
      setOrderBy(_orderBy);
    }
    if (queryParams.get("isDelete")) {
      _isDelete = queryParams.get("isDelete");
      setIsDelete(_isDelete);
    }
    if (queryParams.get("status")) {
      _status = Number(queryParams.get("status"));
      setSearchData({ ...searchData, status: queryParams.get("status") });
    }
    GetListAll(
      postTypeId,
      _langId,
      Number(_page) + 1,
      Number(_rowsPerPage),
      _orderBy + " " + _order,
      id,
      _status,
      _isHot,
      _isHomepage,
      _keyword,
      _isDelete,
      _title
    );

    switch (postTypeId) {
      case 1:
        setLinkToView(DomainUserSide + UserSidePathUrl.planningNews);
        break;
      case 2:
        setLinkToView(DomainUserSide + UserSidePathUrl.projectNews);
        break;
      case 3:
        if (id === 3)
          setLinkToView(DomainUserSide + UserSidePathUrl.flowInvestment);
        else if (id === 4)
          setLinkToView(DomainUserSide + UserSidePathUrl.lawDocument);
        break;
      case 4:
        setLinkToView(DomainUserSide + UserSidePathUrl.News);
        break;
      default:
        break;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname, id, postTypeId]);

  const GetListAll = (
    postTypeId,
    langId,
    pageIndex,
    pageSize,
    sortExpression,
    id,
    status = undefined,
    isHot = undefined,
    isHomepage = undefined,
    keyword = undefined,
    deleteStatus,
    title = undefined
  ) => {
    setPage(pageIndex - 1);
    dispatch(appActions.ShowLoading(true))
    postActions
      .GetListAll(
        postTypeId,
        langId,
        pageIndex,
        pageSize,
        sortExpression,
        id,
        status ? status : searchData.status ? searchData.status : undefined,
        isHot != undefined ? isHot : searchData.isHot,
        isHomepage != undefined ? isHomepage : searchData.isHomePage,
        keyword ? keyword : searchData.keyword,
        deleteStatus,
        title ? title : searchData.title
      )
      .then((res) => {
        dispatch(appActions.ShowLoading(false))
        setNewsModels(res.content.items);
        setTotalItemCount(res.content.totalItemCount);
      })
      .catch((err) => {
        dispatch(appActions.ShowLoading(false))
        console.log(err)
      });
  };

  //--- Dialog
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [openViewFileDialog, setOpenViewFileDialog] = useState(false);

  const handleOpenAddDialog = () => {
    const param = getParamSearch();
    history.push(`/tin-tuc/${id}/${postTypeId}/them-moi` + param);
  };

  const handleOpenEditDialog = (postId) => {
    setPostId(postId);
    const param = getParamSearch();
    history.push(`/tin-tuc/${id}/${postTypeId}/${postId}/chinh-sua` + param);
  };

  const getParamSearch = () => {
    let param = `?langId=${langId}`;
    if (page) {
      param = param + `&pageIndex=${page}`;
    }
    if (rowsPerPage) {
      param = param + `&pageSize=${rowsPerPage}`;
    }
    if (searchData?.keyword) {
      param = param + `&keyword=${searchData.keyword}`;
    }
    if (searchData?.title) {
      param = param + `&title=${searchData.title}`;
    }
    if (searchData?.status) {
      param = param + `&status=${searchData.status}`;
    }
    if (searchData?.isHot) {
      param = param + `&isHot=${searchData.isHot}`;
    }
    if (searchData?.isHomePage) {
      param = param + `&isHomePage=${searchData.isHomePage}`;
    }
    param = param + `&orderBy=${orderBy}`;
    param = param + `&order=${order}`;
    param = param + `&isDelete=${isDelete}`;
    return param;
  };
  const handleOpenViewFileDialog = (event, id) => {
    const newArr = newsModels.filter((item) => item.id === id);
    setOpenViewFileDialog(true);
    setFilePreview(newArr);
  };
  const handleCloseViewFileDialog = (event) => {
    setOpenViewFileDialog(false);
    setFilePreview([]);
  };

  const handleOpenDeleteDialog = (postId, newsxmin) => {
    setPostId(postId);
    //setNewsxmin(newsxmin);
    setOpenDeleteDialog(true);
  };

  const handleCloseDeleteDialog = () => {
    setOpenDeleteDialog(false);
  };

  //--- Filter
  const [filterSection, setFilterSection] = useState(null);

  const handleClickFilter = (event) => {
    setFilterSection(event.currentTarget);
  };

  const handleCloseFilter = () => {
    setFilterSection(null);
  };

  const ShowNotificationError = (messages) => {
    ShowNotification(messages, NotificationMessageType.Error);
  };

  const openFilter = Boolean(filterSection);
  const idFilter = openFilter ? "popoverSlider" : undefined;
  const handleRestore = (id) => {
    // newsAction.RestoreNews(id).then(
    //   (res) => {
    //     if (res && res.content && res.content.status) {
    //       GetListAll(
    //         searchData.categoryId,
    //         searchData.title,
    //         undefined,
    //         undefined,
    //         isDelete,
    //         searchData.status,
    //         1,
    //         rowsPerPage,
    //         orderBy + ' ' + order
    //       );
    //       ShowNotification(
    //         viVN.Success.NewsRestoreSuccess,
    //         NotificationMessageType.Success
    //       );
    //     }
    //   },
    //   (err) => {
    //     err &&
    //       err.errorType &&
    //       ShowNotification(
    //         viVN.Errors[err.errorType],
    //         NotificationMessageType.Error
    //       );
    //   }
    // );
  };
  const handleDelete = () => {
    postActions.PostsDelete(postId).then(
      (res) => {
        if (res && res.content && res.content.status) {
          GetListAll(
            postTypeId,
            langId,
            1,
            rowsPerPage,
            orderBy + " " + order,
            id,
            searchData?.status,
            searchData?.isHots,
            searchData?.isHomePage,
            searchData?.keyword,
            isDelete,
            searchData?.title
          );
          handleCloseDeleteDialog();
          ShowNotification(
            viVN.Success.NewsDeleteSuccess,
            NotificationMessageType.Success
          );
        }
      },
      (err) => {
        err &&
          err.errorType &&
          ShowNotification(
            viVN.Errors[err.errorType],
            NotificationMessageType.Error
          );
      }
    );
  };

  const handelChangeFeature = (postId, value) => {
    alert("change " + postId + " feature to" + value);
  };
  const handleClearAllField = () => {
    refresh();
    setFilterSection(null);
  };

  const onSubmit = async (data) => {
    await GetListAll(
      postTypeId,
      langId,
      1,
      rowsPerPage,
      orderBy + " " + order,
      id,
      searchData?.status,
      searchData?.isHot,
      searchData?.isHomePage,
      searchData?.keyword !== "" ? searchData.keyword : undefined,
      isDelete,
      searchData?.title !== "" ? searchData.title : undefined
    );
    handleCloseFilter();
  };

  const refresh = () => {
    setSearchData({
      isHot: undefined,
      keyword: "",
      isHomePage: undefined,
      status: 2,
      title: "",
    });
    setOrderBy("modifiedDate");
    setOrder("desc");
    GetListAll(
      postTypeId,
      langId,
      1,
      rowsPerPage,
      orderBy + " " + order,
      id,
      undefined,
      undefined,
      undefined,
      undefined
    );
  };

  const handleChangeTrash = (event) => {
    setIsShowTrash(event.target.checked);
    setIsDelete(event.target.checked);
    GetListAll(
      postTypeId,
      langId,
      1,
      rowsPerPage,
      orderBy + " " + order,
      id,
      searchData?.status,
      searchData?.isHots,
      searchData?.isHomePage,
      undefined,
      event.target.checked,
      undefined
    );
  };

  const handleChangeSearchForm = (event) => {
    setSearchData({ ...searchData, [event.target.name]: event.target.value });
  };

  const handleChangeChecked = (event) => {
    event.persist();
    setSearchData({ ...searchData, [event.target.name]: event.target.checked });
  };

  const handleEmptyTrash = () => {
    postActions.EmptyTrash().then(
      (res) => {
        if (res && res.content && res.content.status) {
          GetListAll(
            postTypeId,
            langId,
            1,
            rowsPerPage,
            orderBy + " " + order,
            id,
            searchData?.status,
            searchData?.isHots,
            searchData?.isHomePage,
            undefined,
            isDelete,
            undefined
          );
          //handleCloseDeleteDialog();
          ShowNotification(
            viVN.Success.NewsDeleteSuccess,
            NotificationMessageType.Success
          );
        }
      },
      (err) => {
        err &&
          err.errorType &&
          ShowNotification(
            viVN.Errors[err.errorType],
            NotificationMessageType.Error
          );
      }
    );
  };
  return (
    <div className="slider">
      <div className="d-sm-flex align-items-center justify-content-between mb-3">
        <h1 className="h3 mb-0 text-gray-800">
          {titlePage ? titlePage : "Tin tức"}
          <Tooltip title="Làm mới">
            <Fab
              color="primary"
              aria-label="filter"
              size="small"
              onClick={refresh}
              className="ml-2"
            >
              <RefreshIcon />
            </Fab>
          </Tooltip>
          <span>&nbsp;&nbsp;</span>
          <FormControlLabel
            control={
              <Switch
                checked={isShowTrash}
                onChange={handleChangeTrash}
                name="checkedB"
                color="primary"
              />
            }
            label="Thùng rác"
          />
        </h1>
        <ButtonGroup
          variant="contained"
          color="primary"
          aria-label="contained primary button group"
        >
          {isShowTrash && (
            <Button
              variant="contained"
              color="secondary"
              onClick={handleEmptyTrash}
              startIcon={<DeleteCircle />}
            >
              Xóa thùng rác
            </Button>
          )}
         
        </ButtonGroup>
        <Button
            variant="contained"
            color="primary"
            onClick={handleOpenAddDialog}
            startIcon={<AddCircle />}
          >
            Thêm Tin
          </Button>
      </div>
      <div className="d-flex justify-content-between align-items-end">
        <form id="formSearch" onSubmit={handleSubmit(onSubmit)}>
          <div className="d-flex align-items-end">
            <div className="form-group">
              <TextField
                className="w-80"
                name="title"
                placeholder="Tiêu đề"
                variant="outlined"
                value={searchData.title}
                onChange={handleChangeSearchForm}
                inputRef={register}
              />
            </div>
            <div className="form-group">
              <TextField
                className="w-80 ml-2"
                name="keyword"
                placeholder="Từ khóa"
                variant="outlined"
                value={searchData.keyword}
                onChange={handleChangeSearchForm}
                inputRef={register}
              />
            </div>
            <div className="form-group ml-2">
              <Select
                name="status"
                className="w-100 ml-2"
                variant="outlined"
                value={searchData.status}
                onChange={handleChangeSearchForm}
              >
                <MenuItem value={2}>-------</MenuItem>
                <MenuItem value={1}>Kích hoạt</MenuItem>
                <MenuItem value={0}>Không kích hoạt</MenuItem>
              </Select>
            </div>
            <div className="form-group ml-4">
              
             <Checkbox
                checked={searchData.isHot}
                onChange={handleChangeChecked}
                name="isHot"
                color="primary"
                inputProps={{ "aria-label": "secondary checkbox" }}
                className="p-0 mt-0 ml-4"
              />
              <label className="text-dark">Tin nóng</label>
            </div>
            <div className="form-group ml-4">
             
              <Checkbox
                checked={searchData.isHomePage}
                name="isHomePage"
                onChange={handleChangeChecked}
                color="primary"
                inputProps={{ "aria-label": "secondary checkbox" }}
                className="p-0 mt-0 ml-4"
              />
               <label className="text-dark">Hiển thị ở trang chủ</label>
            </div>
            <div className="form-group ml-2">
              <Button variant="contained" color="primary" onClick={onSubmit} className="ml-2">
                <SearchIcon fontSize="small" /> Tìm kiếm
              </Button>
              <Button
                variant="contained"
                className="ml-2"
                onClick={handleClearAllField}
              >
                <ClearAllIcon fontSize="small" /> Bỏ lọc
              </Button>
            </div>
          </div>
        </form>
        <div className="form-group">
      
        </div>
      </div>
      {newsModels ? (
        <ListPosts
          ctid={id}
          totalItemCount={totalItemCount}
          newsModels={newsModels}
          GetListAll={GetListAll}
          title={searchData.title}
          categoryId={searchData.categoryId}
          isDelete={isDelete}
          editAction={handleOpenEditDialog}
          viewFileAction={handleOpenViewFileDialog}
          deleteAction={handleOpenDeleteDialog}
          restoreAction={handleRestore}
          changeFeatureAction={handelChangeFeature}
          setOrder={setOrder}
          setOrderBy={setOrderBy}
          setPage={setPage}
          setRowsPerPage={setRowsPerPage}
          order={order}
          orderBy={orderBy}
          page={page}
          rowsPerPage={rowsPerPage}
          postTypeId={postTypeId}
          langId={langId}
        />
      ) : (
        ""
      )}

      {openDeleteDialog && (
        <DeleteDialog
          isOpen={openDeleteDialog}
          onClose={handleCloseDeleteDialog}
          onSuccess={handleDelete}
          header={"Xác nhận xóa"}
          content={"Bạn có chắc chắn muốn xóa?"}
        />
      )}
      {openViewFileDialog && (
        <ViewFile
          isOpen={openViewFileDialog}
          onClose={handleCloseViewFileDialog}
          filePreview={filePreview}
        />
      )}
    </div>
  );
}
