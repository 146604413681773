import {Button, TextField} from '@material-ui/core';
import React from 'react';
import {useFormContext} from 'react-hook-form';
import {APIUrlDefault} from '../../../utils/configuration';
import { DropzoneArea } from 'material-ui-dropzone';

const ProjectImageFb = ({
  isShowFb = false,
  fileFb = [],
    onOpenSelectFileFb = () => { },
    fileUploadFb = [],
    setFileUploadFb = () => { }
}) => {
  const {register, errors} = useFormContext();

  return (
      <>
          <div className="form-group row">
              <div className="col-12">
                  <label className="text-dark">Ảnh Facebook</label>
                  {!isShowFb &&
                      fileFb &&
                      fileFb.length > 0 &&
                      fileFb.map(item => (
                          <div key={item.fileName} style={{ width: '150px' }}>
                              <img
                                  src={APIUrlDefault + item.filePreview}
                                  alt={item.fileName}
                                  className="img-fluid mb-2"
                                  style={{
                                      width: 'auto',
                                      height: 'auto',
                                      maxWidth: '100%',
                                      maxHeight: '100%'
                                  }}
                              />
                          </div>
                      ))}
                  <div>
                      {

                          //            <Button
                          //                variant="contained"
                          //                color="primary"
                          //                onClick={onOpenSelectFileFb}
                          //            >
                          //                Chọn file
                          //</Button>
                      }
                      <TextField
                          type="hidden"
                          name="imageFb"
                          value={(fileFb && fileFb.length > 0 && fileFb[0].fileName) || ''}
                      />
                  </div>
              </div>
          </div>
          <div className="col-12">
              <DropzoneArea
                  acceptedFiles={['image/png, image/gif, image/jpeg, image/jpg']}
                  filesLimit={1}
                  dropzoneText="Kéo thả ảnh share Facebook vào đây"
                  onChange={(files) => {
                      setFileUploadFb(files);
                  }}
              />
          </div>
      </>
  );
};

export default ProjectImageFb;
