import { stringify } from "querystring";
import Service from "../../../api/api-service";
import { ApiUrl } from "../../../api/api-url";

const service = new Service();

export const GetListAll = async (params) => {
  try {
    return await service.get(
      ApiUrl.ConsultantService_GetListAll,
      stringify(params)
    );
  } catch (error) {
    return error;
  }
};

export const Create = async (body) => {
  try {
    return await service.post(ApiUrl.ConsultantService_Create, body);
  } catch (error) {
    return error;
  }
};

export const GetDetail = async (id, params) => {
  try {
    return await service.get(ApiUrl.ConsultantService_GetDetail(id), stringify(params));
  } catch (error) {
    return error;
  }
};

export const Update = async (body) => {
  try {
    return await service.post(ApiUrl.ConsultantService_Update, body);
  } catch (error) {
    return error;
  }
};

export const Delete = async (id) => {
  try {
    return await service.post(ApiUrl.ConsultantService_Delete(id));
  } catch (error) {
    return error;
  }
};

export const DeletePermanent = async (id) => {
  try {
    return await service.post(ApiUrl.ConsultantService_Permanent(id));
  } catch (error) {
    return error;
  }
};

export const EmptyTrash = async () => {
  try {
    return await service.post(ApiUrl.ConsultantService_EmptyTrash);
  } catch (error) {
    return error;
  }
};
