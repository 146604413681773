const UPDATE_LANGUAGE = 'LANGUAGE_UPDATE';

export const updateAppLanguageAction = (lang) => ({
  type: UPDATE_LANGUAGE,
  payload: lang,
});

const InitState = {
  langId: 'vi',
};

export default function LanguageReducer(state = InitState, action) {
  switch (action.type) {
    case UPDATE_LANGUAGE: {
      return { ...state, langId: action.payload };
    }
    default:
      return state;
  }
}
