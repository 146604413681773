import React, { useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';

//--- Material Control
import {
  DialogActions,
  Button,
  DialogContent,
  DialogTitle,
  Dialog,
  Typography,
  IconButton,
  makeStyles,
} from '@material-ui/core';

import SunEditor from 'suneditor-react';
import 'suneditor/dist/css/suneditor.min.css';

//--- Material Icon
import CloseIcon from '@material-ui/icons/Close';
import SaveIcon from '@material-ui/icons/Save';
import ShowNotification from '../../../components/react-notifications/react-notifications';
import {
  NotificationMessageType,
  MaxSizeImageUpload,
} from '../../../utils/configuration';
import * as viVN from '../../../language/vi-VN.json';
//--- Action
import * as FaqActions from '../../../redux/store/faq/faq.store';

const useStyles = makeStyles((theme) => ({
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  formControl: {
    margin: theme.spacing(1),
  },
}));

export default function AddNews(props) {
  const classes = useStyles();
  //const [ques, setQues] = useState({});
  const [trans, setTrans] = useState({});
  const [core, setCore] = useState({});
  const {
    isOpen,
    onClose,
    onSuccess,
    GetListAll,
    rowsPerPage,
    setOrder,
    setOrderBy,
    langId,
    singleQues,
  } = props;

  const [content, setContent] = useState();

  const { register, handleSubmit, setError, errors, clearErrors, setValue } =
    useForm({
      mode: 'all',
      reValidateMode: 'onBlur',
    });

  useEffect(() => {
    FaqActions.FaqDetail(singleQues?.childrens[0].id, langId)
      .then((res) => {
        if (res && res.content) {
          //setQues(res.content);
          setTrans(res.content.translations[0]);
          delete res.content.translations;
          setCore(res.content);
        }
      })
      .catch((err) => err);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onSubmit = (data) => {
    const object = {
      ...trans,
      core: {
        ...core,
        quesAndAnsTypeId: 2,
        parentId: singleQues.id,
        isActive: true,
      },
      langId: langId,
      content: content,
    };

    FaqActions.FaqUpdate(object)
      .then((res) => {
        setOrder('desc');
        setOrderBy('modifiedDate');
        GetListAll(undefined, langId, 'modifiedDate desc', 1, rowsPerPage);
        onSuccess();
        ShowNotification(
          viVN.Success.NewsAddSuccess,
          NotificationMessageType.Success
        );
      })
      .catch(
        (err) => viVN.Errors[err.errorType],
        NotificationMessageType.Error
      );
  };

  const onChangeContent = (editorContent) => {
    clearErrors(['editorContent']);
    if (editorContent === '<p><br></p>') {
      setError('editorContent', { type: 'required' });
      setContent('');
    } else {
      clearErrors('editorContent');
      setContent(editorContent);
    }
  };

  return (
    <div>
      <Dialog open={isOpen} onClose={onClose} fullWidth={true} maxWidth='lg'>
        <DialogTitle disableTypography className='border-bottom'>
          <Typography variant='h6'>Trả lời FAQ</Typography>
          <IconButton
            aria-label='close'
            className={classes.closeButton}
            onClick={onClose}>
            <CloseIcon />
          </IconButton>
        </DialogTitle>

        <form onSubmit={handleSubmit(onSubmit)} autoComplete='off'>
          <DialogContent className='pt-4 pb-2'>
            <div className='form-group'>
              <label className='text-dark'>Câu hỏi: {singleQues.title}</label>
            </div>
            <div className='form-group'>
              <label className='text-dark'>
                Nội dung: {singleQues.content}
              </label>
            </div>

            <div className='form-group'>
              <label className='text-dark'>Nội dung</label>
              <SunEditor
                enableToolbar={true}
                showToolbar={true}
                imageAccept='.jpg,.jpeg,.png,.gift,.svg,.tif'
                imageUploadSizeLimit={MaxSizeImageUpload}
                setContents={singleQues?.childrens[0].content}
                imageUploadUrl={
                  'https://localhost:5001/api/admin/DocumentUpload/UploadDocument'
                }
                videoFileInput={false}
                setOptions={{
                  height: 350,
                  buttonList: [
                    [
                      'undo',
                      'redo',
                      'font',
                      'fontSize',
                      'formatBlock',
                      'paragraphStyle',
                      'blockquote',
                      'bold',
                      'underline',
                      'italic',
                      'strike',
                      'subscript',
                      'superscript',
                      'fontColor',
                      'hiliteColor',
                      'textStyle',
                      'removeFormat',
                      'outdent',
                      'indent',
                      'align',
                      'horizontalRule',
                      'list',
                      'lineHeight',
                      'table',
                      'link',
                      'image',
                      'video',
                      'audio',
                      'fullScreen',
                      'showBlocks',
                      'codeView',
                    ],
                  ],
                }}
                onChange={onChangeContent}
                onBlur={(event, editorContents) =>
                  onChangeContent(editorContents)
                }
              />
            </div>
          </DialogContent>

          <DialogActions className='border-top'>
            <Button
              type='submit'
              onClick={onClose}
              variant='contained'
              startIcon={<CloseIcon />}>
              Hủy
            </Button>
            <Button
              type='submit'
              color='primary'
              variant='contained'
              startIcon={<SaveIcon />}>
              Lưu
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    </div>
  );
}
