import React from "react";
import SunEditor from "suneditor-react";
import { Controller, useFormContext } from "react-hook-form";
import "./styles.scss";
import { APIUrlDefault, MaxSizeImageUpload } from "../../utils/configuration";
import { ApiUrl } from "../../api/api-url";
import "suneditor/dist/css/suneditor.min.css";
import { difference } from "lodash";

const defaultOptions = [
  "undo",
  "redo",
  "font",
  "fontSize",
  "formatBlock",
  "paragraphStyle",
  "blockquote",
  "bold",
  "underline",
  "italic",
  "strike",
  "subscript",
  "superscript",
  "fontColor",
  "hiliteColor",
  "textStyle",
  "removeFormat",
  "outdent",
  "indent",
  "align",
  "horizontalRule",
  "list",
  "lineHeight",
  "table",
  "link",
  "image",
  "video",
  "audio",
  "fullScreen",
  "showBlocks",
  "codeView",
];

const FormSunEditor = ({
  title,
  name,
  validate,
  className = "",
  titleClassName = "text-dark",
  exceptOptions = [],
  defaultValue,
  InputProps = {
    readOnly: false,
  },
  ...rest
}) => {
  const { control, errors } = useFormContext();

  return (
    <>
      <Controller
        control={control}
        name={name}
        rules={validate}
        defaultValue={defaultValue}
        render={({ name, value, onChange }) => (
          <>
            <label className={titleClassName}>
              {title}
              {validate?.required ? <span className="required"></span> : null}
            </label>
            <SunEditor
              enableToolbar={true}
              showToolbar={true}
              videoFileInput={false}
              setOptions={{
                height: 500,
                imageAccept: ".jpg,.jpeg,.png,.gift,.svg,.tif",
                imageUploadSizeLimit: { MaxSizeImageUpload },
                imageUploadUrl: `${APIUrlDefault}${ApiUrl.UrlUploadFromEditor}`,
                buttonList: [difference(defaultOptions, exceptOptions)],
              }}
              setContents={value}
              className={className}
              onChange={(value) =>
                value === "<p><br></p>" ? onChange("") : onChange(value)
              }
              {...rest}
            />
            {validate && errors[name] ? (
              <span className="error">
                {errors[name] && errors[name].message}
              </span>
            ) : null}
          </>
        )}
      />
    </>
  );
};

export default FormSunEditor;
