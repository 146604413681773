//---> Build-in modules
import React from 'react';
import PropTypes from 'prop-types';

//---> External modules
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import Button from '@material-ui/core/Button';
import CloseIcon from '@material-ui/icons/Close';
import SaveIcon from '@material-ui/icons/Save';

//---> Internal modules
import FileManagement from '../../../components/file_management/file_management';

const SelectImageDialog = ({
  onClose = () => {},
  openDialog = false,
  setFiles = () => {},
  files = [],
  tmpFiles = [],
  onSave = () => {},
  classes
}) => {
  return (
    <Dialog
      onClose={onClose}
      open={openDialog}
      fullWidth={true}
      maxWidth="md"
      className="dialog-preview-form"
    >
      <DialogTitle disableTypography>
        <Typography variant="h6">Quản lý file</Typography>
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent dividers>
        <FileManagement
          files={files}
          setFiles={setFiles}
          acceptedFiles={['jpeg', 'png', 'jpg', 'gif']}
        />
      </DialogContent>

      <DialogActions>
        <Button
          type="button"
          onClick={onClose}
          variant="contained"
          startIcon={<CloseIcon />}
        >
          Hủy
        </Button>
        {tmpFiles && Array.isArray(tmpFiles) && tmpFiles.length > 0 && (
          <Button
            type="button"
            color="primary"
            variant="contained"
            startIcon={<SaveIcon />}
            onClick={onSave}
          >
            Lưu
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
};

SelectImageDialog.propTypes = {
  onClose: PropTypes.func,
  openDialog: PropTypes.bool,
  setFiles: PropTypes.func,
  files: PropTypes.array,
  tmpFiles: PropTypes.array,
  onSave: PropTypes.func
};

export default SelectImageDialog;
