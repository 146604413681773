import Service from '../../../api/api-service';
import { ApiUrl } from '../../../api/api-url';
import * as config from '../../../common/config';

const service = new Service();

export const GetMasterDataFromLangId = ({ langId }) => {
  return service
    .post(`${ApiUrl.GetMasterDataFromLangId}?langId=${langId}`)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      throw err;
    });
};
