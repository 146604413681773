import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as appActions from "../../core/app.store";
import ShowNotification from "../../components/react-notifications/react-notifications";
import { NotificationMessageType } from "../../utils/configuration";
import * as viVN from "../../language/vi-VN.json";

import Autocomplete from "@material-ui/lab/Autocomplete";
import RefreshIcon from "@material-ui/icons/Refresh";
import history from "../../common/history";
import { useLocation } from 'react-router-dom';
import { DomainAdminSide } from "../../utils/configuration";
import * as sceneAction from "../../redux/store/vr360/vr360.store";

import {
    TextField,
    Fab,
    Tooltip,
} from "@material-ui/core";

var query = null;

function VrFilter(props) {
    const { showLoading, hasFromToDate, hasVrProject, hasScene } = props;
    const location = useLocation();
    const url = location.pathname;
    const queryParams = new URLSearchParams(location.search);
    const [keyword, setKeyword] = useState('')
    const [sceneModels, setSceneModels] = useState([]);
    const [curScene, setCurScene] = useState({ id: 0, name: 'Chọn Scene' });
    const [vrProjectModels, setVrProjectModels] = useState([]);
    const [curVrProject, setCurVrProject] = useState({ id: 0, name: 'Chọn Vr project' });

    useEffect(() => {
        if (hasVrProject) {
            VrProjectGetLookup();
        }
    }, []);

    useEffect(() => {
        if (curVrProject.id) {
            // VrProjectGetLookup();
            setCurScene({ id: 0, name: 'Chọn Scene' });
            queryParams.set('pid', curVrProject.id);
            queryParams.delete('pageIndex');
            queryParams.delete('sceneId');
            pushParams();
            getSceneByVrProjectId(curVrProject.id)
        }
    }, [curVrProject]);

    useEffect(() => {
        if (curScene.id) {
            queryParams.set('sceneId', curScene.id);
            queryParams.delete('pageIndex');
            pushParams();
        }
    }, [curScene]);

    useEffect(() => {

    }, [location.search])


    const getSceneByVrProjectId = (id) => {
        let params = {
            pid: id,
            pageIndex: 1,
            pageSize: 1000,
        }
        showLoading(true);
        sceneAction
            .SceneGetListAll(params)
            .then((res) => {
                if (res && res.content && res.content.items) {
                    setSceneModels(res.content.items);
                    if (queryParams.get('sceneId')) {
                        res.content.items.find(item => {
                            if (item.id === parseInt(queryParams.get('sceneId'))) {
                                setCurScene(item)
                            }
                        })
                    }
                    showLoading(false);
                }
            })
            .catch((err) => {
                err &&
                    err.errorType &&
                    ShowNotification(
                        viVN.Errors[err.errorType],
                        NotificationMessageType.Error
                    );
                showLoading(false);
            });
    };

    const VrProjectGetLookup = () => {
        showLoading(true);
        sceneAction
            .VrProjectGetLookup()
            .then((res) => {
                if (res && res.content) {
                    setVrProjectModels(res.content);
                    if (queryParams.get('pid')) {
                        res.content.find(item => {
                            if (item.id === parseInt(queryParams.get('pid'))) {
                                setCurVrProject(item)
                            }
                        })
                    }
                    showLoading(false);
                }
            })
            .catch((err) => {
                err &&
                    err.errorType &&
                    ShowNotification(
                        viVN.Errors[err.errorType],
                        NotificationMessageType.Error
                    );
                showLoading(false);
            });
    };

    const handleChangeName = (event) => {
        if (event.key === "Enter") {
            const value = event.target.value.trim();
            queryParams.set('keyword', value);
            pushParams();
        }
    }

    const handleRefresh = () => {
        queryParams.delete('pid');
        queryParams.delete('sceneId');
        queryParams.delete('keyword');
        setCurVrProject({ id: 0, name: 'Chọn Vr project' });
        setCurScene({ id: 0, name: 'Chọn Scene' });
        setKeyword('');
        pushParams();
    }

    const pushParams = () => {
        history.push({
            search: queryParams.toString(),
        })
    }

    return (
        <div>
            <div className="form_search_rs">
                <div className="row">
                    <div className="col-md-2">
                        <TextField
                            fullWidth
                            id="standard-basic"
                            // label="Từ khóa"
                            value={keyword}
                            onChange={(e) => setKeyword(e.target.value)}
                            placeholder="Nhập tên"
                            defaultValue={queryParams.get('keyword')}
                            // type="search"
                            variant="outlined"
                            InputProps={{ maxLength: 200 }}
                            onKeyPress={handleChangeName}
                        />
                    </div>
                    {hasVrProject && vrProjectModels && vrProjectModels.length > 0 && (
                        <div className={`col-md-2 form-item`}>
                            <Autocomplete
                                options={vrProjectModels}
                                getOptionLabel={(option) =>
                                    typeof option === "string" ? option : option.name
                                }
                                value={curVrProject}
                                onChange={(event, newValue) => {
                                    setCurVrProject(newValue);
                                }}
                                disableClearable={true}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        name="pid"
                                        variant="outlined"
                                        size="small"
                                    />
                                )}
                            />
                        </div>
                    )}
                    {hasScene && sceneModels && sceneModels.length > 0 && (
                        <div className={`col-md-2 form-item`}>
                            <Autocomplete
                                disabled={!curVrProject.id}
                                options={sceneModels}
                                getOptionLabel={(option) =>
                                    typeof option === "string" ? option : option.name
                                }
                                value={curScene}
                                onChange={(event, newValue) => {
                                    setCurScene(newValue);

                                }}
                                disableClearable={true}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        name="sceneId"
                                        variant="outlined"
                                        size="small"
                                    />
                                )}
                            />
                        </div>
                    )}
                    <div className={`col-md-2 form-item`}>
                        <div className="">
                            <Tooltip title="Làm mới">
                                <Fab
                                    color="primary"
                                    aria-label="filter"
                                    size="small"
                                    onClick={handleRefresh}
                                    className={`mr-3 ${!hasFromToDate ? 'mb-3' : ''}`}
                                >
                                    <RefreshIcon />
                                </Fab>
                            </Tooltip>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

const mapStateToProps = (state) => ({
    isLoading: state.app.loading,
});

const mapDispatchToProps = (dispatch) =>
    bindActionCreators(
        {
            showLoading: appActions.ShowLoading,
        },
        dispatch
    );

export default connect(mapStateToProps, mapDispatchToProps)(VrFilter);