import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { DropzoneArea } from "material-ui-dropzone";
import AsyncSelect from 'react-select/async';

//--- Styles
// import '../news.scss';

//--- Material Control
import {
    DialogActions,
    Button,
    TextField,
    DialogContent,
    DialogTitle,
    Dialog,
    Typography,
    IconButton,
    makeStyles,
    Checkbox,
} from '@material-ui/core';

//--- Material Icon
import CloseIcon from '@material-ui/icons/Close';
import SaveIcon from '@material-ui/icons/Save';
import ShowNotification from '../../../../components/react-notifications/react-notifications';
import {
    NotificationMessageType,
    APIUrlDefault,
    MaxSizeImageUpload,
} from '../../../../utils/configuration';
import * as viVN from '../../../../language/vi-VN.json';
import * as sceneAction from "../../../../redux/store/vr360/vr360.store"

const useStyles = makeStyles((theme) => ({
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500],
    },
    formControl: {
        margin: theme.spacing(1),
    },
}));
const AddEdition = (props) => {
    const {
        isOpen,
        onClose,
        onSuccess,
        GetListAll,
        rowsPerPage,
        setOrder,
        setOrderBy,
    } = props;
    const classes = useStyles();

    const [fileUpload, setFileUpload] = useState([]);

    const { register, handleSubmit, setError, errors, clearErrors, setValue } =
        useForm({
            mode: 'all',
            reValidateMode: 'onBlur',
        });

    const onSubmit = (data) => {
        console.log('data', data)
        const formData = new FormData();
        formData.append('VRProjectName', data.name);
        formData.append('Description', data.description);
        formData.append('Lat', data.lat);
        formData.append('Lng', data.lng);
        if (fileUpload && fileUpload.length > 0) {
            formData.append('galaryImages', fileUpload[0]);
        }
        sceneAction.VrProjectCreate(formData).then(res => {
            if (res && res.content) {
                onClose();
                GetListAll();
                ShowNotification(
                    'Thêm mới thành công',
                    NotificationMessageType.Success
                );
            }
        }).catch(err => {
            console.log(err)
        })
    }

    return (
        <Dialog open={isOpen} onClose={onClose} fullWidth={true} maxWidth='md'>
            <DialogTitle disableTypography className='border-bottom'>
                <Typography variant='h6'>Thêm khung hình</Typography>
                <IconButton
                    aria-label='close'
                    className={classes.closeButton}
                    onClick={onClose}>
                    <CloseIcon />
                </IconButton>
            </DialogTitle>
            <form onSubmit={handleSubmit(onSubmit)} autoComplete='off'>
                <DialogContent className='pt-4 pb-2'>
                    <div className='row form-group'>
                        <div className="col-lg-6">
                            <label className='text-dark'>
                                Tên dự án<span className='required'></span>
                            </label>
                            <TextField
                                name='name'
                                error={
                                    errors.name &&
                                    (errors.name.type === 'required' ||
                                        errors.name.type === 'maxLength')
                                }
                                fullWidth
                                type='text'
                                className='form-control'
                                inputProps={{ maxLength: 150 }}
                                inputRef={register({ required: true, maxLength: 150 })}
                                onChange={(e) => setValue('name', e.target.value)}
                            />
                            {errors.name && errors.name.type === 'required' && (
                                <span className='error'>Trường này là bắt buộc</span>
                            )}
                            {errors.name && errors.name.type === 'maxLength' && (
                                <span className='error'>Trường này không quá 150 ký tự</span>
                            )}
                        </div>
                        <div className="col-lg-6">
                            <label className='text-dark'>
                                Mô tả<span className='required'></span>
                            </label>
                            <TextField
                                name='description'
                                error={
                                    errors.description &&
                                    (errors.description.type === 'required' ||
                                        errors.description.type === 'maxLength')
                                }
                                fullWidth
                                type='text'
                                className='form-control'
                                inputProps={{ maxLength: 150 }}
                                inputRef={register({ required: true, maxLength: 150 })}
                                onChange={(e) => setValue('description', e.target.value)}
                            />
                            {errors.description && errors.description.type === 'required' && (
                                <span className='error'>Trường này là bắt buộc</span>
                            )}
                            {errors.description && errors.description.type === 'maxLength' && (
                                <span className='error'>Trường này không quá 150 ký tự</span>
                            )}
                        </div>
                    </div>
                    <div className='row form-group'>
                        <div className="col-lg-6">
                            <label className='text-dark'>
                                Kinh độ<span className='required'></span>
                            </label>
                            <TextField
                                name='lng'
                                error={
                                    errors.lng &&
                                    (errors.lng.type === 'required' ||
                                        errors.lng.type === 'maxLength')
                                }
                                fullWidth
                                type='text'
                                className='form-control'
                                inputProps={{ maxLength: 150 }}
                                inputRef={register({ required: true, maxLength: 150 })}
                                onChange={(e) => setValue('lng', e.target.value)}
                            />
                            {errors.lng && errors.lng.type === 'required' && (
                                <span className='error'>Trường này là bắt buộc</span>
                            )}
                            {errors.lng && errors.lng.type === 'maxLength' && (
                                <span className='error'>Trường này không quá 150 ký tự</span>
                            )}
                        </div>
                        <div className="col-lg-6">
                            <label className='text-dark'>
                                Vĩ độ<span className='required'></span>
                            </label>
                            <TextField
                                name='lat'
                                error={
                                    errors.lat &&
                                    (errors.lat.type === 'required' ||
                                        errors.lat.type === 'maxLength')
                                }
                                fullWidth
                                type='text'
                                className='form-control'
                                inputProps={{ maxLength: 150 }}
                                inputRef={register({ required: true, maxLength: 150 })}
                                onChange={(e) => setValue('lat', e.target.value)}
                            />
                            {errors.lat && errors.lat.type === 'required' && (
                                <span className='error'>Trường này là bắt buộc</span>
                            )}
                            {errors.lat && errors.lat.type === 'maxLength' && (
                                <span className='error'>Trường này không quá 150 ký tự</span>
                            )}
                        </div>
                    </div>
                    <div className='row form-group'>
                        <div className="col-12">
                            <DropzoneArea
                                acceptedFiles={["image/png, image/gif, image/jpeg, image/jpg"]}
                                filesLimit={1}
                                dropzoneText="Kéo thả ảnh đại diện vào đây"
                                onChange={(files) => {
                                    files &&
                                        files.length &&
                                        console.log("Files:", files[0].name);
                                    setFileUpload(files);
                                }}
                            />
                        </div>
                    </div>

                </DialogContent>
                <DialogActions>
                    <Button
                        type="submit"
                        color="primary"
                        variant="contained"
                    //   startIcon={!isSubmitting ? <SaveIcon></SaveIcon> : <CircularProgress size={20} color="white"/>}
                    //   disabled={isSubmitting}
                    >
                        Lưu
                    </Button>

                </DialogActions>
            </form>
        </Dialog>
    )
}

export default AddEdition;