import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as config from "../../common/config";
import * as appActions from "../../core/app.store";
import * as editionAction from "../../redux/store/edition/edition.store";
//---mui
import AddCircle from "@material-ui/icons/AddCircle";
import {
    Button,
    TextField,
    IconButton,
    Tooltip,
    Popover,
    Select,
    MenuItem,
    Fab,
} from "@material-ui/core";
import ShowNotification from '../../components/react-notifications/react-notifications';
import { NotificationMessageType, } from '../../utils/configuration';
import * as viVN from '../../language/vi-VN.json';

import ListEdition from "./list-edition/list-edition.view";
import AddEdition from "./add-edition/add-edition.view";
import EditEdition from "./edit-edition/edit-edition.view";

const Edition = (props) => {
    const { showLoading } = props;
    const pageIndex = 1;
    const pageSize = 20;
    const [editionModel, setEditionModel] = useState([]);
    const [order, setOrder] = useState("desc");
    const [orderBy, setOrderBy] = useState("createdDate");

    const [openAddDialog, setOpenAddDialog] = useState(false);
    const [openEditDialog, setOpenEditDialog] = useState(false);
    const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
    const [itemSelected, setItemSelected] = useState();

    const GetListAll = () => {
        showLoading(true);
        editionAction.GetListAll().then(res => {
            if (res && res.content) {
                console.log(res)
                setEditionModel(res.content.items)
            }
            showLoading(false);
        }).catch(err => {
            showLoading(false);
            console.log(err);
        })
    }

    const handleDelete = (id) => {
        editionAction.EditionDelete(id).then(res => {
            if (res && res.content) {
                console.log('res', res)
                GetListAll();
                ShowNotification(
                    'Xóa thành công',
                    NotificationMessageType.Success
                );
            }
        }).catch(err => {
            console.log(err)
        })
    }

    const handleEdit = (data) => {
        setOpenEditDialog(true);
        setItemSelected(data)
    }

    useEffect(() => {
        GetListAll();
    }, [])

    return (
        <div className="slider">
            <div className="d-sm-flex align-items-center justify-content-between mb-3">
                <h1 className="h3 mb-0 text-gray-800">
                    Quản lý gói thành viên
                </h1>
                <Button
                    variant="contained"
                    color="primary"
                    onClick={() => setOpenAddDialog(true)}
                    startIcon={<AddCircle />}
                >
                    Thêm gói
                </Button>
            </div>
            <div>
                <ListEdition
                    listModel={editionModel}
                    deleteAction={handleDelete}
                    editAction={handleEdit}
                />
            </div>
            <AddEdition
                isOpen={openAddDialog}
                onClose={() => setOpenAddDialog(false)}
                GetListAll={GetListAll}
            />
            {openEditDialog && (
                <EditEdition
                    item={itemSelected}
                    isOpen={openEditDialog}
                    onClose={() => setOpenEditDialog(false)}
                    GetListAll={GetListAll}
                />
            )}
        </div>
    )
}

const mapStateToProps = (state) => ({
    isLoading: state.app.loading,
});

const mapDispatchToProps = (dispatch) =>
    bindActionCreators(
        {
            showLoading: appActions.ShowLoading,
        },
        dispatch
    );

export default connect(mapStateToProps, mapDispatchToProps)(Edition);