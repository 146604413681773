import React, { useState, useEffect } from 'react';
import { Configs } from '../../common/config';
import { useForm } from 'react-hook-form';
import InputMask from 'react-input-mask';

//--- Material Control
import {
  Button,
  TextField,
  IconButton,
  Tooltip,
  Popover,
  Select,
  MenuItem,
  Checkbox,
  Fab,
} from '@material-ui/core';

//--- Styles
//import './news.scss';

//--- Material Icon

import AddIcon from '@material-ui/icons/Add';
import SaveAltIcon from '@material-ui/icons/SaveAlt';

//--- Component
import ListAccess from './list-access/list-access.view';
// import AddNews from './add-news/add-news.view';
// import EditNews from './edit-news/edit-news.view';
import DeleteDialog from '../../components/dialog-delete/dialog-delete.view';

//--- Notifications
import ShowNotification from '../../components/react-notifications/react-notifications';
import { NotificationMessageType } from '../../utils/configuration';
import * as viVN from '../../language/vi-VN.json';

//--- Redux store

import * as LimitAccessActions from '../../redux/store/limit-access-manament/limit-access.store';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));

export default function News(props) {
  const [data, setData] = useState();
  const [error, setError] = useState('');

  const [value, setValue] = useState('ALLOW');
  const [ipAdress, setIpAdress] = useState('');

  useEffect(() => {
    GetListAll(value);
  }, [value]);

  const GetListAll = (select) => {
    LimitAccessActions.getListAccess(select)
      .then((res) => {
        if (res && res.content) {
          setData(res.content.status);
        }
      })
      .catch((err) => console.log(err));
  };

  //--- Dialog
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [ipIndex, setIpIndex] = useState();

  const handleOpenDeleteDialog = (id) => {
    setOpenDeleteDialog(true);
    setIpIndex(id);
  };

  const handleCloseDeleteDialog = () => {
    setOpenDeleteDialog(false);
  };

  const handleChangeIpAddress = (e) => {
    setIpAdress(e.target.value);
  };

  const handleChangeSelect = (event) => {
    setValue(event.target.value);
  };

  const handleDelete = () => {
    const arr = data.ipAddresses.filter((item, index) => index !== ipIndex);
    LimitAccessActions.saveAccessIp({ ...data, ipAddresses: arr }).then(
      (res) => {
        if (res) {
          setData({
            ...data,
            ipAddresses: arr,
          });
          setOpenDeleteDialog(false);
        }
      }
    );
  };

  const onClickAddIp = () => {
    if (
      /^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/.test(
        ipAdress
      )
    ) {
      data.ipAddresses.push(ipAdress);
      setData({
        ...data,
      });
      setIpAdress('');
    } else {
      alert('Sai kiểu địa chỉ IP');
      setError('Sai kiểu địa chỉ IP');
    }
  };
  const onPressAddIp = (e) => {
    if (e.keyCode === 13) {
      if (
        /^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/.test(
          ipAdress
        )
      ) {
        data.ipAddresses.push(ipAdress);
        setData({
          ...data,
        });
        setIpAdress('');
      } else {
        alert('Sai kiểu địa chỉ IP');
        setError('Sai kiểu địa chỉ IP');
      }
    }
  };
  const onClickSaveIp = () => {
    LimitAccessActions.saveAccessIp(data)
      .then((res) => {
        if (res) {
          ShowNotification(
            viVN.Success.UpdateSuccess,
            NotificationMessageType.Success
          );
        }
      })
      .catch((err) => {
        err &&
          ShowNotification(
            viVN.Errors[err.errorType],
            NotificationMessageType.Error
          );
      });
  };

  return (
    <div className='slider'>
      <div className='d-sm-flex align-items-center mb-3'>
        <h1 className='h3 mb-0 text-gray-800'>Whitelist/Blacklist IP</h1>
      </div>
      <div className='row'>
        <div className='col-12 col-md-3 col-lg-3'>
          <div className='form-group'>
            <Select
              className='w-100'
              name='status'
              value={value}
              onChange={handleChangeSelect}>
              <MenuItem value={''}>Lựa chọn danh sách</MenuItem>
              <MenuItem value={'DENIED'}>Blacklist</MenuItem>
              <MenuItem value={'ALLOW'}>Whitelist</MenuItem>
            </Select>
          </div>
        </div>
        <div className='col-12 col-md-3 col-lg-3'>
          <div className='form-group'>
            <InputMask
              mask='999.999.999.999'
              value={ipAdress}
              onChange={handleChangeIpAddress}
              name='ipAdress'>
              {() => (
                <TextField
                  className='w-100'
                  placeholder='Thêm địa chỉ..'
                  name='ipAdress'
                  onKeyDown={onPressAddIp}
                />
              )}
            </InputMask>
          </div>
        </div>
        <div className='col-12 col-md-3 col-lg-3'>
          <div className='form-group'>
            <Button variant='contained' color='primary' onClick={onClickAddIp}>
              <AddIcon fontSize='small' /> Thêm
            </Button>
            <Button
              variant='contained'
              className='ml-2'
              color='primary'
              onClick={onClickSaveIp}>
              <SaveAltIcon fontSize='small' /> Lưu
            </Button>
          </div>
        </div>
      </div>

      {data ? (
        <ListAccess data={data} deleteAction={handleOpenDeleteDialog} />
      ) : (
        ''
      )}

      {openDeleteDialog && (
        <DeleteDialog
          isOpen={openDeleteDialog}
          onClose={handleCloseDeleteDialog}
          onSuccess={handleDelete}
          header={'Xác nhận xóa'}
          content={'Bạn có chắc chắn muốn xóa?'}
        />
      )}
    </div>
  );
}
