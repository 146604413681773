import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { InputAdornment, TextField } from "@material-ui/core";
import { UploadFile } from "../../components/upload-file/upload-file.view";
import { useAddOrEditConlsultingServicesDialog } from "./useAddOrEditConlsultingServicesDialog";
import { AddOrEditDialog } from "../../components/addOrEditDialog/add-or-edit-dialog.view";

const LINK_PATTERN =
  // eslint-disable-next-line no-useless-escape
  /(ftp|http|https):\/\/(\w+:{0,1}\w*@)?(\S+)(:[0-9]+)?(\/|\/([\w#!:.?+=&%@!\-\/]))?/;

export const AddOrEditConlsultingServicesDialog = (props) => {
  const { isOpen, onClose, onSuccess, data } = props;

  const [icon1, setIcon1] = useState(null);
  const [icon2, setIcon2] = useState(null);

  const { onSubmit } = useAddOrEditConlsultingServicesDialog({
    data,
    onSuccess,
  });

  const { register, handleSubmit, errors, reset, watch } = useForm({
    mode: "all",
    reValidateMode: "onBlur",
  });

  const watchColor = watch("colorCode");

  const onSubmitForm = (data) => {
    if (Object.keys(errors).length) return;
    onSubmit(data);
  };

  const handleExited = () => {
    setIcon1(null);
    setIcon2(null);
    reset();
  };

  return (
    <AddOrEditDialog
      title={data ? "Chỉnh sửa dịch vụ tư vấn" : "Thêm dịch vụ tư vấn"}
      isOpen={isOpen}
      onExited={handleExited}
      onClose={onClose}
      onSubmit={handleSubmit(onSubmitForm)}
    >
      <form>
        <div className="form-group">
          <div className="row">
            <div className="col-12">
              <label className="text-dark">
                Nội dung<span className="required"></span>
              </label>
              <TextField
                name="content"
                error={!!errors["content"]}
                fullWidth
                type="text"
                defaultValue={data ? data.translations[0].content : ""}
                className="form-control"
                inputRef={register({
                  required: {
                    value: true,
                    message: "Trường này là bắt buộc",
                  },
                  maxLength: {
                    value: 200,
                    message: "Không được quá 200 ký tự",
                  },
                })}
                helperText={
                  <span className="error">
                    {errors["content"]?.message || null}
                  </span>
                }
              />
            </div>
          </div>
        </div>
        <div className="form-group">
          <div className="row">
            <div className="col-12 col-md-2 col-lg-2">
              <label className="text-dark">
                Mã màu<span className="required"></span>
              </label>
              <TextField
                name="colorCode"
                error={!!errors["colorCode"]}
                defaultValue={
                  data ? data.colorCode.substr(1, data.colorCode.length) : ""
                }
                fullWidth
                type="text"
                className="form-control"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">#</InputAdornment>
                  ),
                }}
                inputRef={register({
                  required: {
                    value: true,
                    message: "Trường này là bắt buộc",
                  },
                  pattern: {
                    value: /^(?:[0-9a-f]{3}){1,2}$/i,
                    message: "Không đúng định dạng",
                  },
                })}
                helperText={
                  <span className="error">
                    {errors["colorCode"]?.message || null}
                  </span>
                }
              />
            </div>
            <div className="col-12 col-md-10 col-lg-10">
              <div
                style={{
                  height: "50px",
                  width: "50px",
                  backgroundColor: watchColor
                    ? `#${watchColor}`
                    : data
                    ? data.colorCode
                    : "",
                }}
              ></div>
            </div>
          </div>
        </div>
        <div className="form-group">
          <div className="row">
            <div className="col-12">
              <label className="text-dark">Link</label>
              <TextField
                name="link"
                error={!!errors["link"]}
                defaultValue={data ? data.link : ""}
                fullWidth
                type="text"
                className="form-control"
                inputRef={register({
                  pattern: {
                    value: LINK_PATTERN,
                    message: "Link không đúng định dạng",
                  },
                  maxLength: {
                    value: 150,
                    message: "Không vượt quá 150 ký tự",
                  },
                })}
                helperText={
                  <span className="error">
                    {errors["link"]?.message || null}
                  </span>
                }
              />
            </div>
          </div>
        </div>
        <div className="form-group">
          <div className="row">
            <div className="col-12 col-md-6 col-lg-6">
              <UploadFile
                title="Icon 1"
                files={icon1}
                onSetFiles={setIcon1}
                defaultValue={data ? data.icon1File.filePreview : null}
                backgroundColor={"#2196f3"}
                width="50px"
              />
              <TextField
                inputRef={register({
                  required: {
                    value: true,
                    message: "Trường này là bắt buộc",
                  },
                })}
                type="hidden"
                name="icon1Id"
                value={
                  icon1?.length > 0 ? icon1[0].fileId : data ? data.icon1Id : ""
                }
              />
              <p className="error">{errors["icon1Id"]?.message || null}</p>
            </div>
            <div className="col-12 col-md-6 col-lg-6">
              <UploadFile
                title="Icon 2"
                files={icon2}
                onSetFiles={setIcon2}
                defaultValue={data ? data.icon2File.filePreview : null}
              />
              <TextField
                inputRef={register({
                  required: {
                    value: true,
                    message: "Trường này là bắt buộc",
                  },
                })}
                type="hidden"
                name="icon2Id"
                value={
                  icon2?.length > 0 ? icon2[0].fileId : data ? data.icon2Id : ""
                }
              />
              <p className="error">{errors["icon2Id"]?.message || null}</p>
            </div>
          </div>
        </div>
      </form>
    </AddOrEditDialog>
  );
};
