import React from 'react';

//--- Material Control
import { DialogContent, Grid } from '@material-ui/core';

import MapGoogle from '../../../../google-map/MapGoogle';
import Map4dMap from '../../../../map4dMap/index';
import FormInput from '../../../FormInput';
import FormAutoComplete from '../../../FormAutoComplete';
import FormAsyncSelect from '../../../FormAsyncSelect';
import * as communeAction from '../../../../../redux/store/commune-management/commune.store';
import FormInputCommune from './formInputCommune';
import OpenlayerMapView from "../../../../openlayers-map/open-layer"

var query = null;

export default function AddressFormItems(props) {
  const {
    setCoordinate,
    handleChoose,
  } = props;

  const loadOptions = (inputValue, callback) => {
    if (query) {
      clearTimeout(query);
    }
    if (inputValue.length > 2) {
      query = setTimeout(() => {
        const params = {
          keyword: inputValue,
        };
        return communeAction.getLookupAutoCompleteHome(inputValue).then(({ content }) => {
          let results = [];
          if (content && content.length > 0) {
            let data = [];
            content.map((item) => {
              if (item.communeId)
                data.push({ ...item, value: item.communeId, label: item.name })
            });
            results = data;
          }
          if (results.length == 0) {
            // showToast({
            //     type: 'warning',
            //     icon: 'warning',
            //     title: 'Vui lòng nhập Xã/Phường'
            // });
          }
          callback(results);
        });
      }, 800);
    } else {
      callback([]);
    }
  };

  return (
    <DialogContent className='pt-4 pb-2'>
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <OpenlayerMapView setCoordinate={setCoordinate}/>
        </Grid>
        <Grid item xs={6}>
          <div className='form-group'>
            <FormInputCommune
              title='Xã/Phường'
              name='commune'
              placeholder='Nhập tên Xã/Phường'
              promiseOptions={loadOptions}
              onChange={handleChoose}
            />
          </div>
          <div className='form-group'>
            <FormInput
              title='Số nhà'
              name='generalInfor'
              placeholder='Số nhà'
            />
          </div>
        </Grid>
      </Grid>
    </DialogContent>
  );
}
