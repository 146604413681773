import React, { useState, useCallback } from 'react';
import { debounce } from 'lodash';

//import useTrans from 'hooks/useTrans';
import { searchAutoSuggest } from '../map4d.service';

const SearchPlace = ({
	inputStyle = {},
	placeholder,
	onPlacesChanged
}) => {

	
	const [address, setAddress] = useState('');
	const [suggestions, setSuggestion] = useState([]);

	const handleSelectAddress = (data) => {
		setAddress(data.address);
		setSuggestion([]);
		onPlacesChanged(data);
	}

	const handleChange = e => {
		setAddress(e.target.value);
		getSuggestion(e.target.value);
	};

	const getSuggestion = useCallback(
		debounce(async searchValue => {
			try {
				if (searchValue) {
					const _suggest = await searchAutoSuggest(searchValue);
					setSuggestion(_suggest || []);
				} else {
					setSuggestion([]);
				}
			} catch (err) {
				setSuggestion([]);
			}
		}, 300),
		[]
	);

	return (
		<div className="input-autocomplete-container">
			<input
				type="text"
				style={{
					width: '100%',
					border: '1px solid rgb(206, 212, 218)',
					borderRadius: '0.25rem',
					boxShadow: 'rgb(0 0 0 / 30%) 0px 2px 6px',
					fontSize: 14,
					height: 50,
					boxSizing: 'border-box',
					padding: '0 12px',
					...inputStyle
				}}
				value={address}
				onChange={handleChange}
				placeholder={placeholder}
			/>
			{suggestions.length ? (
				<div
					className="suggestion-container"
					style={{ zIndex: 2 }}>
					{suggestions.map(item => (
						<div
							key={item?.id}
							className="suggestion-item"
							onClick={() => handleSelectAddress(item)}
						>
							{item?.address}
						</div>
					))}
				</div>
			) : null}
		</div>
	);
};

export default SearchPlace;