import React from 'react';
import { Router, Switch } from 'react-router-dom';
import RouteComponent from './route-config.jsx';
import history from './common/history';

//--- Loading
import AppLoading from './components/loading/loading.view';

import { JWTProvider } from './context/JWTContext.js';

import Routes from './routes';

function App() {
  return (
    <div>
      <AppLoading />
      <Router history={history}>
        <JWTProvider>
          <Switch>
            {Routes.map((item, index) => (
              <RouteComponent
                key={index}
                exact
                layout={item.layout}
                component={item.component}
                path={item.path}
              />
            ))}
          </Switch>
        </JWTProvider>
      </Router>
    </div>
  );
}

export default App;
