import Service from '../../../api/api-service';
import { ApiUrl } from '../../../api/api-url';
import * as config from '../../../common/config';
const service = new Service();

export const getListAccess = (value) => {
  return service
    .post(ApiUrl.ListLimitAccessPermission(value))
    .then((res) => {
      return res;
    })
    .catch((err) => {
      throw err;
    });
};

export const saveAccessIp = (body) => {
  return service
    .post(ApiUrl.SaveListAccessPermission, body)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      throw err;
    });
};
