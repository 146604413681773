import React from 'react';
import {useDestructureListToOptions} from '../hooks';
import CustomFormSelect from './form-select';

const SelectRealType = ({listOptions = []}) => {
  const realEstateOptions = useDestructureListToOptions({
    listData: listOptions
  });

  return (
    <>
      <CustomFormSelect
        name="realEstateTypeId"
        label="Loại bất động sản"
        options={realEstateOptions}
        isRequired
        defaultValue={null}
        placeholderText="Chọn loại bất động sản"
      />
    </>
  );
};

export default SelectRealType;
