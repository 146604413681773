import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import { UserSidePathUrl } from '../../../common/config';
// import * as configuration from '../../../utils/configuration';
import dateformat from 'dateformat';
import { FormProvider, useForm } from 'react-hook-form';
//--- Material Table
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Paper from '@material-ui/core/Paper';

import FooterPagination from '../../../components/footer-pagination/footer-pagination';

import {
    APIUrlDefault,
    DomainUserSide,
} from '../../../utils/configuration';
import Autocomplete from '@material-ui/lab/Autocomplete';
import {
    TextField
} from '@material-ui/core';

//--- Material Icon
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import RestoreIcon from '@material-ui/icons/Restore';

// import AssignmentIcon from '@material-ui/icons/Assignment';
// import AddCommentIcon from '@material-ui/icons/AddComment';
//--- Material Control
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import { convertWallet } from '../../../utils/helpers/convertWallet';

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
    },
    paper: {
        width: '100%',
        marginBottom: theme.spacing(2),
    },
    tableContainer: {
        maxHeight: window.outerHeight - 365,
    },
    table: {
        minWidth: 750,
    },
    visuallyHidden: {
        border: 0,
        clip: 'rect(0 0 0 0)',
        height: 1,
        margin: -1,
        overflow: 'hidden',
        padding: 0,
        position: 'absolute',
        top: 20,
        width: 1,
    },
    pointer: { cursor: 'pointer' },
}));

const headCells = [
    { id: 'name', hideSortIcon: true, label: 'Tên gói' },
    { id: 'description', hideSortIcon: false, label: 'Chú thích' },
    { id: 'price', hideSortIcon: false, label: 'Giá(VNĐ)' },
    { id: 'numberOfDay', hideSortIcon: false, label: 'Số ngày sử dụng' },
    { id: 'createdDate', hideSortIcon: true, label: 'Ngày tạo' },
    { id: 'createdBy', hideSortIcon: true, label: 'Người tạo' },
    { id: 'modifiedDate', hideSortIcon: true, label: 'Ngày sửa' },
    { id: 'modifiedBy', hideSortIcon: true, label: 'Người sửa' },
    { id: 'actions', hideSortIcon: true, label: '' },
];

const ListEdition = (props) => {
    const { listModel, deleteAction, editAction } = props;
    return (
        <>
            {listModel.length > 0 ? (
                <TableContainer component={Paper}>
                    <Table>
                        <TableHead>
                            <TableRow>
                                {headCells.map(item => (
                                    <TableCell key={item.id}>
                                        {item.label}
                                    </TableCell>
                                ))}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {listModel.map(item => (
                                <TableRow key={item.id}>
                                    <TableCell>
                                        {item.name[0].value}
                                    </TableCell>
                                    <TableCell>
                                        {item.description[0].value}
                                    </TableCell>
                                    <TableCell>
                                        {convertWallet(item.price)}
                                    </TableCell>
                                    <TableCell>
                                        {item.numberOfDay}
                                    </TableCell>
                                    <TableCell>
                                        {dateformat(item.createdDate, 'dd/mm/yyyy')}
                                    </TableCell>
                                    <TableCell>
                                        {item.createdBy}
                                    </TableCell>
                                    <TableCell>
                                        {dateformat(item.modifiedDate, 'dd/mm/yyyy')}
                                    </TableCell>
                                    <TableCell>
                                        {item.modifiedBy}
                                    </TableCell>
                                    <TableCell align='right' className='text-nowrap'>
                                        <Tooltip title='Sửa'>
                                            <IconButton
                                                aria-label='edit'
                                                onClick={() => editAction(item)}>
                                                <EditIcon className='text-primary' />
                                            </IconButton>
                                        </Tooltip>
                                        <Tooltip title='Xóa'>
                                            <IconButton
                                                aria-label='delete'
                                                onClick={() => deleteAction(item.id)}>
                                                <DeleteIcon className='text-danger' />
                                            </IconButton>
                                        </Tooltip>
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            ) : (
                <>chưa tạo</>
            )}
        </>
    )
}

export default ListEdition;