import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import dateformat from 'dateformat';

//--- Material Table
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Paper from '@material-ui/core/Paper';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
//--- Material Icon
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import * as configuration from '../../../utils/configuration';
import { UserSidePathUrl } from '../../../common/config';
const headCells = [
  { id: 'code', hideSortIcon: false, label: 'STT' },
  { id: 'title', hideSortIcon: false, label: 'Tiêu đề' },
  { id: 'sendTo', hideSortIcon: false, label: 'Ngày đăng' },
  { id: 'cc', hideSortIcon: false, label: 'Số lượng xem' },
];

function EnhancedTableHead(props) {
  const { order, orderBy, onRequestSort } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell key={headCell.id} className='pt-3 pb-3 text-nowrap'>
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
              hideSortIcon={headCell.hideSortIcon ? true : false}>
              {headCell.label}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  classes: PropTypes.object.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  paper: {
    width: '100%',
    marginBottom: theme.spacing(2),
  },
  tableContainer: {
    maxHeight: 500,
  },
  table: {
    minWidth: 750,
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
}));

function TableNews(props) {
  const {
    plannings,
    handleOpenEditDialog,
    handleOpenDeleteDialog,
  } = props;

  const classes = useStyles();

 
  const renderLink  = (postTypeId) => {
    let link = configuration.DomainUserSide + UserSidePathUrl.planningNews
    switch (postTypeId) {
        case 1:
            link=configuration.DomainUserSide + UserSidePathUrl.planningNews
            break;
        case 2:
            link=configuration.DomainUserSide + UserSidePathUrl.projectNews
            break;
        case 3:
            link=configuration.DomainUserSide + UserSidePathUrl.flowInvestment
            break;
        case 4:
            link=configuration.DomainUserSide + UserSidePathUrl.News
            break;
        default:
            break;
    }
    return link
  }

  return (
    <div className={classes.root}>
      <Paper className={classes.paper}>
        <TableContainer className={classes.tableContainer}>
          <Table className={classes.table} size='small' stickyHeader>
            <EnhancedTableHead
              classes={classes}
              rowCount={plannings?.length}
            />
            <TableBody>
              {plannings && plannings?.length > 0 ? (
                plannings.map((row, index) => {
                  const labelId = `enhanced-table-checkbox-${index}`;

                  return (
                    <TableRow hover tabIndex={-1} key={index}>
                      <TableCell component='th' id={labelId} scope='row'>
                        {index+1}
                      </TableCell>
                      <TableCell><a href={renderLink(row.postTypeId) + row.slug + '-' + row.id} target="review" tooltip="Click để xem trước">{row.title}</a></TableCell>
                      <TableCell>{dateformat(row.created_date, 'dd/mm/yyyy')}</TableCell>
                      <TableCell>{row.viewCount}</TableCell>
                     
                    </TableRow>
                  );
                })
              ) : (
                <TableRow hover tabIndex={-1}>
                  <TableCell colSpan={9} className='text-center'>
                    Không có dữ liệu
                  </TableCell>
                </TableRow>
              )}
             
            </TableBody>
          </Table>
        </TableContainer>

        
      </Paper>
    </div>
  );
}
export default TableNews;
