import React, {
	useEffect,
	useState,
	useMemo,
	useRef
} from 'react';

import {
	GOOGLE_MAPS_DEFAULT_CENTER_LATLNG
} from '../../common/constants';
import SearchPlace from './lib/searchPlace';

const Map4dMap = ({
	containerStyle = {
		width: '100%',
		height: '400px',
		marginTop: '10px'
	},
	initCenter = GOOGLE_MAPS_DEFAULT_CENTER_LATLNG,
	setChoosePlace,
	//setIsSearchedAddress,
	...props
}) => {

	const [map, setMap] = useState(null);
	const [center, setCenter] = useState(initCenter);
	const [chooseLatLng, setChooseLatLng] = useState(null);
	const [marker, setMarker] = useState(null);

	const listEventListened = useRef([]);

	const mapListeners = useMemo(() => {
		const onClickMap = (e) => {
			//setIsSearchedAddress(false);
			setChooseLatLng({ position: e.location });
			setCenter(e.location);
			setChoosePlace({
				...e.location
			});
		};

		return [
			{
				eventName: 'click',
				callback: onClickMap,
				config: {
					location: true
				}
			}
		]
	}, [map]);

	useEffect(() => {
		initMap();
	}, []);

	useEffect(() => {
		addEventListener(map);
		return () => {
			cleanEventListened();
		}
	}, [map]);

	useEffect(() => {
		panToPosition(center, map);
	}, [center]);

	useEffect(() => {
		createMarker(chooseLatLng);
	}, [chooseLatLng]);

	useEffect(() => {
		renderMarker(marker);
		return () => {
			removeMarker(marker);
		}
	}, [marker])

	const initMap = () => {
		const options = {
			center,
			zoom: 10,
			controls: false
		}
		const mapObject = new window.map4d.Map(document.getElementById('map4d-map'), options);
		setMap(mapObject);
	}

	const addEventListener = (_map) => {
		if (!_map) {
			return;
		}
		mapListeners.forEach(listener => {
			const event = _map.addListener(
				listener.eventName,
				listener.callback,
				listener.config
			);
			listEventListened.current.push(event);
		})
	}

	const cleanEventListened = (_listEventListened = []) => {
		_listEventListened.forEach(eventListerned => {
			eventListerned.remove();
		})
	}

	const createMarker = (_chooseLatLng) => {
		if (!_chooseLatLng) {
			return;
		}
		const markerEl = new window.map4d.Marker({
			position: _chooseLatLng.position
		})
		setMarker(markerEl);
	}

	const renderMarker = (_marker) => {
		if (!_marker) {
			return;
		}
		_marker.setMap(map);
	}

	const removeMarker = (_marker) => {
		if (!_marker) {
			return;
		}
		_marker.setMap(null);
	}

	const panToPosition = (_position) => {
		if (!map?.mapView || !_position) {
			return;
		}
		map.mapView.panTo(_position);
	}

	const onPlacesChanged = (data) => {
		//setIsSearchedAddress(true);
		setChooseLatLng({ position: data.location });
		setCenter(data.location);
		setChoosePlace(data.location);
	};

	return (
		<>
			<SearchPlace onPlacesChanged={onPlacesChanged} />
			<div id="map4d-map" style={containerStyle}></div>
		</>
	);
};

export default Map4dMap;