import React, { useState, useEffect } from "react";
import { Controller, FormProvider, useForm } from "react-hook-form";
import { TextField, makeStyles, Button } from "@material-ui/core";
import "suneditor/dist/css/suneditor.min.css";
import { pageName } from "./types/pageName";
import { UploadFile } from "../../components/upload-file/upload-file.view";
import { useAddOrEditMetaTagDialog } from "./useAddOrEditMetaTagDialog";
import { Autocomplete } from "@material-ui/lab";
import { AddOrEditDialog } from "../../components/addOrEditDialog/add-or-edit-dialog.view";
import { UrlCollection } from "../../common/url-collection";
import { useHistory } from "react-router-dom";
import { ArrowBackIos } from "@material-ui/icons";
import SaveIcon from "@material-ui/icons/Save";
import * as metaTagAction from "../../redux/store/meta-tag/metaTag.store";
import { useSelector } from "react-redux";
import CircularProgress from '@material-ui/core/CircularProgress';
const useStyles = makeStyles((theme) => ({
  fullHeight: {
    height: "100%",
  },
}));

export default function AddOrEditMetaTagDialog (props) {
  const { metaTag } = props;
  const { langId } = useSelector((state) => state.language);
  const classes = useStyles();
  const history = useHistory();
  const [files, setFiles] = useState([]);
  const [filesTwitter, setFilesTwitter] = useState([]);
  const [filesFacebook, setFilesFacebook] = useState([]);
  const { onSubmit } = useAddOrEditMetaTagDialog({
    metaTag,
  });
  const { register, handleSubmit, errors, reset, control, formState } = useForm({
    mode: "all",
    reValidateMode: "onBlur",
  });
  const { isSubmitting } = formState;
  const onSubmitForm = async(data) => {
    if (Object.keys(errors).length) return;
   await onSubmit(data);
  };
  const handleExited = () => {
    setFiles(null);
    setFilesTwitter(null);
    setFilesFacebook(null);
    reset();
  };
  const onGoBack = () => {
    handleExited()
    const queryParams = window.location.search
    history.push(UrlCollection.MetaTag + queryParams)
  };
  const methods = useForm({
    mode: "all",
    reValidateMode: "onBlur",
  });
  const pageNameTmp = metaTag?.pageName || Object.keys(pageName)[0]
  return (
    <FormProvider {...methods}>
      <form onSubmit={handleSubmit(onSubmitForm)} autoComplete="off">
          <div className="d-sm-flex align-items-center mb-2" onClick={onGoBack} style={{ cursor: "pointer", width: 100}}>
            <ArrowBackIos fontSize="small"></ArrowBackIos> Quay lại
          </div>
          <div className="d-sm-flex align-items-center justify-content-between mb-3">
            <h1 className="h3 mb-0 text-gray-800">
              {metaTag ? "Chỉnh sửa meta tag" : "Thêm meta tag"}
            </h1>
            <Button
              type="submit"
              color="primary"
              variant="contained"
              startIcon={!isSubmitting ? <SaveIcon></SaveIcon> : <CircularProgress size={20} color="white"/>}
              disabled={isSubmitting}
            >
              Lưu
            </Button>
          </div>
        <div className="form-group">
          <div className="row">
            <div className="col-12">
              <label className="text-dark">
                Trang<span className="required"></span>
              </label>
              <Controller
                render={(props) => (
                  <Autocomplete
                    options={Object.keys(pageName)}
                    getOptionLabel={(option) => pageName[option]}
                    disableClearable
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        value={pageName[params.value]}
                        error={!!errors["pageName"]}
                        fullWidth
                        variant="outlined"
                        helperText={
                          <span className="error">
                            {errors["pageName"]?.message || null}
                          </span>
                        }
                      />
                    )}
                    {...props}
                    onChange={(_, data) => props.onChange(data)}
                  />
                )}
                control={control}
                name="pageName"
                defaultValue={pageNameTmp}
                rules={{ required: "Trường này là bắt buộc" }}
              />
            </div>
          </div>
        </div>
        <div className="form-group">
          <div className="row">
            <div className="col-12">
              <label className="text-dark">
                Tiêu đề<span className="required"></span>
              </label>
              <TextField
                name="title"
                error={!!errors["title"]}
                fullWidth
                type="text"
                defaultValue={metaTag ? metaTag.translations[0].title : ""}
                className="form-control"
                inputRef={register({
                  required: {
                    value: true,
                    message: "Trường này là bắt buộc",
                  },
                  maxLength: {
                    value: 150,
                    message: "Không được quá 150 ký tự",
                  },
                })}
                helperText={
                  <span className="error">
                    {errors["title"]?.message || null}
                  </span>
                }
              />
            </div>
          </div>
        </div>
        <div className="form-group">
          <div className="row">
            <div className="col-12">
              <label className="text-dark">
                Từ khóa<span className="required"></span>
              </label>
              <TextField
                name="metaKeywords"
                error={!!errors["metaKeywords"]}
                defaultValue={metaTag ? metaTag.translations[0].metaKeywords : ""}
                fullWidth
                type="text"
                className="form-control"
                inputRef={register({
                  required: {
                    value: true,
                    message: "Trường này là bắt buộc",
                  },
                  maxLength: {
                    value: 150,
                    message: "Không được quá 150 ký tự",
                  },
                })}
                helperText={
                  <span className="error">
                    {errors["metaKeywords"]?.message || null}
                  </span>
                }
              />
            </div>
          </div>
        </div>
        <div className="form-group">
          <div className="row">
            <div className="col-12">
              <label className="text-dark">
                Chi tiết trang<span className="required"></span>
              </label>
              <TextField
                name="pageDescription"
                error={!!errors["pageDescription"]}
                defaultValue={metaTag ? metaTag.translations[0].pageDescription : ""}
                fullWidth
                multiline
                rows={3}
                variant="outlined"
                type="text"
                className={`form-control ${classes.fullHeight}`}
                inputRef={register({
                  required: {
                    value: true,
                    message: "Trường này là bắt buộc",
                  },
                  maxLength: {
                    value: 200,
                    message: "Không được quá 200 ký tự",
                  },
                })}
                helperText={
                  <span className="error">
                    {errors["pageDescription"]?.message || null}
                  </span>
                }
              />
            </div>
          </div>
        </div>
        <div className="form-group">
          <div className="row">
            <div className="col-12">
              <label className="text-dark">Mô tả meta</label>
              <TextField
                name="metaDescription"
                error={!!errors["metaDescription"]}
                defaultValue={metaTag ? metaTag.translations[0].metaDescription : ""}
                fullWidth
                multiline
                rows={3}
                variant="outlined"
                type="text"
                className={`form-control ${classes.fullHeight}`}
                inputRef={register({
                  maxLength: {
                    value: 200,
                    message: "Không được quá 200 ký tự",
                  },
                })}
                helperText={
                  <span className="error">
                    {errors["metaDescription"]?.message || null}
                  </span>
                }
              />
            </div>
          </div>
        </div>
        <div className="form-group">
          <div className="row">
            <div className="col-12">
              <label className="text-dark">Mô tả twitter</label>
              <TextField
                name="twitterDescription"
                error={!!errors["twitterDescription"]}
                defaultValue={metaTag ? metaTag.translations[0].twitterDescription : ""}
                fullWidth
                multiline
                rows={3}
                variant="outlined"
                type="text"
                className={`form-control ${classes.fullHeight}`}
                inputRef={register({
                  maxLength: {
                    value: 200,
                    message: "Không được quá 200 ký tự",
                  },
                })}
                helperText={
                  <span className="error">
                    {errors["twitterDescription"]?.message || null}
                  </span>
                }
              />
            </div>
          </div>
        </div>
        <div className="form-group">
          <div className="row">
            <div className="col-12">
              <label className="text-dark">Mô tả facebook</label>
              <TextField
                name="fbDescription"
                error={!!errors["fbDescription"]}
                defaultValue={metaTag ? metaTag.translations[0].fbDescription : ""}
                fullWidth
                multiline
                rows={3}
                variant="outlined"
                type="text"
                className={`form-control ${classes.fullHeight}`}
                inputRef={register({
                  maxLength: {
                    value: 200,
                    message: "Không được quá 200 ký tự",
                  },
                })}
                helperText={
                  <span className="error">
                    {errors["fbDescription"]?.message || null}
                  </span>
                }
              />
            </div>
          </div>
        </div>

        <div className="form-group">
          <div className="row">
            <div className="col-12 col-md-4 col-lg-4">
              <UploadFile
                title="Ảnh"
                files={files}
                onSetFiles={setFiles}
                className="col-12 col-md-4 col-lg-4"
                defaultValue={metaTag ? metaTag?.imageFile?.filePreview : null}
              />
              <TextField
                inputRef={register({
                  required: {
                    value: true,
                    message: "Trường này là bắt buộc",
                  },
                })}
                type="hidden"
                name="imageId"
                value={
                  files?.length > 0
                    ? files[0].fileId
                    : metaTag
                    ? metaTag.imageId
                    : ""
                }
              />
              <p className="error">{errors["imageId"]?.message || null}</p>
            </div>
            <div className="col-12 col-md-4 col-lg-4">
              <UploadFile
                title="Ảnh twitter"
                files={filesTwitter}
                onSetFiles={setFilesTwitter}
                className="col-12 col-md-4 col-lg-4"
                defaultValue={metaTag ? metaTag?.twitterImageFile?.filePreview : null}
                required={false}
              />
              <TextField
                inputRef={register}
                type="hidden"
                name="twitterImageId"
                value={
                  filesTwitter?.length > 0
                    ? filesTwitter[0].fileId
                    : metaTag
                    ? metaTag.twitterImageId
                    : ""
                }
              />
            </div>
            <div className="col-12 col-md-4 col-lg-4">
              <UploadFile
                title="Ảnh facebook"
                files={filesFacebook}
                onSetFiles={setFilesFacebook}
                className="col-12 col-md-4 col-lg-4"
                defaultValue={metaTag ? metaTag.fbImageFile?.filePreview : null}
                required={false}
              />
              <TextField
                inputRef={register}
                type="hidden"
                name="fbImageId"
                value={
                  filesFacebook?.length > 0
                    ? filesFacebook[0].fileId
                    : metaTag
                    ? metaTag.fbImageId
                    : ""
                }
              />
            </div>
          </div>
        </div>
      </form>
      </FormProvider>
  );
};
