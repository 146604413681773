import React from 'react';

//--- Styles
import '../styles.scss';

//--- Material Control
import {
    DialogContent,
    Grid,
} from '@material-ui/core';

import FormInput from '../../../components/FormItem/FormInput';
import FormInputCurrency from '../../../components/FormItem/FormInputCurrency';
import FormCheckbox from '../../../components/FormItem/FormCheckbox';
import FormAutoComplete from '../../../components/FormItem/FormAutoComplete';
import FormTextArea from '../../../components/FormItem/FormTextArea';
import FormAsyncSelect from '../../../components/FormItem/FormAsyncSelect';

import * as streetActions from '../../../redux/store/street/street.store';
import FormInputCoordinate from '../../../components/FormItem/FormInputCoordinate';

export default function LandPriceInfoFormItems(props) {
    const {
        provinceSelect,
        districtByProvinceId,
        communes,
        soilTypes,
    } = props;

    const filterStreetes = (inputValue) => {
        return streetActions.GetLookUpStreet(inputValue)
            .then((res) => {
                if (res?.content && res?.content.length > 0) {
                    res.content.forEach((element) => {
                        element.value = element.id;
                        element.label = element.name;
                    });
                } else {
                    return [
                      {
                        value: 0,
                        label: inputValue,
                      },
                    ];
                }
                return res.content;
            })
            .catch((err) => console.log(err));
    };

    const promiseStreetOptions = (inputValue) => {
        if (inputValue.length >= 2) {
            return new Promise((resolve) => {
                setTimeout(() => {
                    resolve(filterStreetes(inputValue));
                }, 1000);
            });
        }
    };

    return (
        <DialogContent className='pt-4 pb-2'>
            <Grid container spacing={2}>
                <Grid item xs={6}>
                    <div className="form-group">
                        <FormAutoComplete
                            title="Tỉnh/ Thành phố"
                            placeholder="Tỉnh/ Thành phố"
                            name="province"
                            options={provinceSelect}
                            validate={{
                                required: 'Tỉnh/ Thành phố là dữ liệu bắt buộc'
                            }}
                        />
                    </div>
                </Grid>
                <Grid item xs={6}>
                    <div className="form-group">
                        <FormAutoComplete
                            title="Quận/ Huyện"
                            placeholder="Quận/ Huyện"
                            name="district"
                            options={districtByProvinceId}
                            validate={{
                                required: 'Quận/ Huyện là dữ liệu bắt buộc'
                            }}
                            isHideIfEmpty={false}
                        />
                    </div>
                </Grid>
                <Grid item xs={6}>
                    <div className="form-group">
                        <FormAutoComplete
                            title="Xã/ Phường"
                            placeholder="Xã/ Phường"
                            name="commune"
                            options={communes}
                            validate={{
                                required: 'Xã/ Phường là dữ liệu bắt buộc'
                            }}
                            isHideIfEmpty={false}
                        />
                    </div>
                </Grid>
                <Grid item xs={6}>
                    <div className='form-group'>
                        <FormAsyncSelect
                            title="Đường"
                            name="street"
                            placeholder="Đường"
                            promiseOptions={promiseStreetOptions}
                        />
                    </div>
                </Grid>
                <Grid item xs={6}>
                    <div className='form-group'>
                        <FormInput
                            title="Đoạn đường"
                            name="fromToAddr"
                            placeholder="Đoạn đường"
                        />
                    </div>
                </Grid>
                <Grid item xs={6}>
                    <div className='form-group'>
                        <FormAutoComplete
                            title="Loại đất"
                            placeholder="Loại đất"
                            name="soilType"
                            options={soilTypes}
                        />
                    </div>
                </Grid>
                <Grid item xs={6}>
                    <div className='form-group'>
                        <FormInputCoordinate
                            title="Tọa độ bắt đầu"
                            name="fromCoordinate"
                            placeholder="Tọa độ bắt đầu"
                        />
                    </div>
                </Grid>
                <Grid item xs={6}>
                    <div className='form-group'>
                        <FormInputCoordinate
                            title="Tọa độ kết thúc"
                            name="toCoordinate"
                            placeholder="Tọa độ kết thúc"
                        />
                    </div>
                </Grid>
                <Grid item xs={6}>
                    <div className='form-group'>
                        <FormInputCurrency
                            title="Giá nhà nước vị trí 1"
                            name="stateVT1"
                            placeholder="Giá nhà nước vị trí 1"
                        />
                    </div>
                </Grid>
                <Grid item xs={6}>
                    <div className='form-group'>
                        <FormInputCurrency
                            title="Giá trung bình nhà nước"
                            name="statePrice"
                            placeholder="Giá trung bình nhà nước"
                            validate={{
                                required: 'Giá trung bình nhà nước là dữ liệu bắt buộc'
                            }}
                        />
                    </div>
                </Grid>
                <Grid item xs={6}>
                    <div className='form-group'>
                        <FormInputCurrency
                            title="Giá nhà nước vị trí 2"
                            name="stateVT2"
                            placeholder="Giá nhà nước vị trí 2"
                        />
                    </div>
                </Grid>
                <Grid item xs={6}>
                    <div className='form-group'>
                        <FormInputCurrency
                            title="Giá nhà nước vị trí 3"
                            name="stateVT3"
                            placeholder="Giá nhà nước vị trí 3"
                        />
                    </div>
                </Grid>
                <Grid item xs={6}>
                    <div className='form-group'>
                        <FormInputCurrency
                            title="Giá nhà nước vị trí 4"
                            name="stateVT4"
                            placeholder="Giá nhà nước vị trí 4"
                        />
                    </div>
                </Grid>
                <Grid item xs={6}>
                    <div className='form-group'>
                        <FormInputCurrency
                            title="Giá nhà nước vị trí 5"
                            name="stateVT5"
                            placeholder="Giá nhà nước vị trí 5"
                        />
                    </div>
                </Grid>
                <Grid item xs={12}>
                    <div className='form-group'>
                        <FormTextArea
                            title="Mô tả"
                            name="description"
                            placeholder="Mô tả"
                            aria-label="Mô tả"
                            minRows={3} />
                    </div>
                </Grid>
                <Grid item xs={6}>
                    <div className='form-group'>
                        <FormInput
                            title="Ngày áp dụng"
                            name="dateApply"
                            placeholder="Ngày áp dụng"
                            type="datetime-local"
                            InputLabelProps={{
                                shrink: true,
                            }}
                        />
                    </div>
                </Grid>
                <Grid item xs={6}>
                    <div className='form-group'>
                        <FormCheckbox
                            title="Kích hoạt"
                            name="isActive"
                        />
                    </div>
                </Grid>
            </Grid>
        </DialogContent>
    );
}
