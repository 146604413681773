import { useEffect, useState } from "react";

export const useScreenActions = (props) => {
  const { initialRequestParams, getDatas } = props;

  const [requestParams, setRequestParams] = useState(initialRequestParams);
  const [datas, setDatas] = useState([]);
  const [openAddOrEditDialog, setOpenAddOrEditDialog] = useState(false);
  const [data, setData] = useState(null);
  const [dataWillDelete, setDataWillDelete] = useState(null);
  useEffect(() => {
    const queryParams = new URLSearchParams(window.location.search);
   if (queryParams.get("pageIndex")) {
      setRequestParams((prev) => ({
        ...prev,
        pageIndex: Number(queryParams.get("pageIndex")),
      }));
    }
    if (queryParams.get("pageSize")) {
      setRequestParams((prev) => ({
        ...prev,
        pageSize: Number(queryParams.get("pageSize")),
      }));
    }
    if (queryParams.get("Keyword")) {
      setRequestParams((prev) => ({
        ...prev,
        Keyword: queryParams.get("Keyword"),
      }));
    }
    if (queryParams.get("order") && queryParams.get("orderBy")) {
      setRequestParams((prev) => ({
        ...prev,
        Sorting: `${queryParams.get("orderBy")} ${queryParams.get("order")}`  ,
      }));
    }
    getDatas(requestParams);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(requestParams)]);

  const handleChangeRequestParams = (field, data) => {
    setRequestParams((prev) => ({
      ...prev,
      [field]: data,
    }));
  };

  const handleAddOrEditSuccess = () => {
    setOpenAddOrEditDialog(false);
    setDataWillDelete(null);
    getDatas(initialRequestParams);
  };

  const onClickEdit = (item) => {
    setData(item);
    setOpenAddOrEditDialog(true);
  };

  const onClickAdd = () => {
    setOpenAddOrEditDialog(true);
    data && setData(null);
  };

  const onDeleteSuccess = () => {
    getDatas(requestParams);

    setDataWillDelete(null);
  };

  return {
    datas,
    data,
    dataWillDelete,
    openAddOrEditDialog,
    requestParams,
    setRequestParams,
    setDatas,
    setOpenAddOrEditDialog,
    setDataWillDelete,
    onClickEdit,
    onClickAdd,
    handleChangeRequestParams,
    handleAddOrEditSuccess,
    onDeleteSuccess,
  };
};
