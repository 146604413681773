import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { DropzoneArea } from "material-ui-dropzone";
import AsyncSelect from 'react-select/async';

//--- Styles
// import '../news.scss';

//--- Material Control
import {
    DialogActions,
    Button,
    TextField,
    DialogContent,
    DialogTitle,
    Dialog,
    Typography,
    IconButton,
    makeStyles,
    Checkbox,
} from '@material-ui/core';
import Autocomplete from "@material-ui/lab/Autocomplete";

//--- Material Icon
import CloseIcon from '@material-ui/icons/Close';
import SaveIcon from '@material-ui/icons/Save';
import ShowNotification from '../../../../components/react-notifications/react-notifications';
import {
    NotificationMessageType,
    APIUrlDefault,
    MaxSizeImageUpload,
    listStyle,
} from '../../../../utils/configuration';
import * as viVN from '../../../../language/vi-VN.json';
import * as sceneAction from "../../../../redux/store/vr360/vr360.store"

const useStyles = makeStyles((theme) => ({
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500],
    },
    formControl: {
        margin: theme.spacing(1),
    },
}));

var query = null;

const AddEdition = (props) => {
    const {
        isOpen,
        onClose,
        onSuccess,
        GetListAll,
        rowsPerPage,
        setOrder,
        setOrderBy,
    } = props;
    const classes = useStyles();

    const [viewDefault, setViewDefault] = useState(false);
    const [fileUpload, setFileUpload] = useState([]);
    const [defaultOptions, setDefaultOptions] = useState([]);
    const [keyword, setKeyword] = useState('');
    const [style, setStyle] = useState('');

    useEffect(() => {
        let params = {
            pageIndex: 1,
            pageSize: 100,
        }
        sceneAction.SceneGetListAll(params).then(res => {
            if (res && res.content) setDefaultOptions(res.content.items.map(item => {
                return { ...item, label: item.code }
            }))
        })
    }, [])

    const loadOptions = (inputValue, callback) => {
        if (query) {
            clearTimeout(query);
        }
        let params = {
            pageIndex: 1,
            pageSize: 100,
        }
        if (inputValue.length > 2) {
            query = setTimeout(() => {
                if (inputValue) {
                    const getListAddress = async () => {
                        let results = [];
                        const res = await sceneAction.SceneGetListAll({ ...params, keyword: inputValue });
                        if (res.content.items && res.content.items.length > 0) {
                            results = res.content.items.map(item => {
                                return {
                                    ...item,
                                    value: item.id,
                                    label: item.code,
                                };
                            })
                        }
                        if (results.length == 0) {
                            ShowNotification(
                                'Vui lòng nhập địa chỉ khác, hoặc thêm địa chỉ mới',
                                NotificationMessageType.Warning,
                                'center'
                            );
                        }
                        callback(results);
                    };
                    getListAddress();
                }
            }, 800);
        } else {
            callback([]);
        }
    };

    const handleChoose = (data) => {
        console.log('data', data);
        setKeyword(data)
    }


    const handleChangeViewDefault = () => {
        setViewDefault(!viewDefault);
    }
    const { register, handleSubmit, setError, errors, clearErrors, setValue } =
        useForm({
            mode: 'all',
            reValidateMode: 'onBlur',
        });

    const onSubmit = (data) => {
        console.log('data', data)
        const formData = new FormData();
        formData.append('Name', data.name);
        data.title && formData.append('Title', data.title);
        formData.append('SceneId', keyword.id);
        formData.append('Style', style);
        formData.append('Ath', data.ath);
        formData.append('Atv', data.atv);
        sceneAction.ConnectionSceneCreate(formData).then(res => {
            if (res && res.content) {
                onClose();
                GetListAll();
                ShowNotification(
                    'Thêm mới thành công',
                    NotificationMessageType.Success
                );
            }
        }).catch(err => {
            console.log(err)
        })
    }

    const changeStyle = (e, newValue) => {
        setStyle(newValue);
    }

    return (
        <Dialog open={isOpen} onClose={onClose} fullWidth={true} maxWidth='md'>
            <DialogTitle disableTypography className='border-bottom'>
                <Typography variant='h6'>Thêm khung hình liên kết</Typography>
                <IconButton
                    aria-label='close'
                    className={classes.closeButton}
                    onClick={onClose}>
                    <CloseIcon />
                </IconButton>
            </DialogTitle>
            <form onSubmit={handleSubmit(onSubmit)} autoComplete='off'>
                <DialogContent className='pt-4 pb-2'>
                    <div className='row form-group'>
                        <div className="col-lg-6">
                            <label className='text-dark'>
                                Tên liên kết<span className='required'></span>
                            </label>
                            <TextField
                                name='name'
                                error={
                                    errors.name &&
                                    (errors.name.type === 'required' ||
                                        errors.name.type === 'maxLength')
                                }
                                fullWidth
                                type='text'
                                className='form-control'
                                inputProps={{ maxLength: 150 }}
                                inputRef={register({ required: true, maxLength: 150 })}
                                onChange={(e) => setValue('name', e.target.value)}
                            />
                            {errors.name && errors.name.type === 'required' && (
                                <span className='error'>Trường này là bắt buộc</span>
                            )}
                            {errors.name && errors.name.type === 'maxLength' && (
                                <span className='error'>Trường này không quá 150 ký tự</span>
                            )}
                        </div>
                        <div className="col-lg-6">
                            <label className='text-dark'>
                                Tiêu đề
                            </label>
                            <TextField
                                name='title'
                                fullWidth
                                type='text'
                                className='form-control'
                                inputProps={{ maxLength: 150 }}
                                inputRef={register({ required: true})}
                                onChange={(e) => setValue('title', e.target.value)}
                            />
                        </div>
                    </div>
                    <div className='row form-group'>
                        <div className="col-lg-6">
                            <label className='text-dark'>
                                Khung hình<span className='required'></span>
                            </label>
                            <AsyncSelect
                                defaultOptions={defaultOptions}
                                className='search_input_select'
                                placeholder='Nhập để tìm kiếm'
                                isLoading={false}
                                loadOptions={loadOptions}
                                onChange={data => {
                                    handleChoose(data);
                                }}
                                value={keyword}
                                components={{ DropdownIndicator: null, LoadingIndicator: null, ClearIndicator: null }}
                            />
                        </div>
                        <div className="col-lg-6">
                            <label className='text-dark'>
                                Style<span className='required'></span>
                            </label>
                            <Autocomplete
                                id="combo-box-demo"
                                options={listStyle}
                                // getOptionLabel={(option) => option.name}
                                onChange={changeStyle}
                                value={style}
                                style={{ width: "100%" }}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        label="Style"
                                        variant="outlined"
                                        name="style"
                                        inputRef={register({ required: true })}
                                        error={
                                            errors.style &&
                                            errors.style.type === "required"
                                        }
                                        size="small"
                                    />
                                )}
                            />

                            {errors.style &&
                                errors.style.type === "required" && (
                                    <span className="error">Trường này là bắt buộc</span>
                                )}

                            {/* <TextField
                                name='style'
                                error={
                                    errors.style &&
                                    (errors.style.type === 'required' ||
                                        errors.style.type === 'maxLength')
                                }
                                fullWidth
                                type='text'
                                className='form-control'
                                inputProps={{ maxLength: 150 }}
                                inputRef={register({ required: true, maxLength: 150 })}
                                onChange={(e) => setValue('style', e.target.value)}
                            /> */}
                            {/* {errors.style && errors.style.type === 'required' && (
                            <span className='error'>Trường này là bắt buộc</span>
                        )}
                        {errors.style && errors.style.type === 'maxLength' && (
                            <span className='error'>Trường này không quá 150 ký tự</span>
                        )} */}
                        </div>
                    </div>
                    <div className='row form-group'>
                        <div className="col-lg-6">
                            <label className='text-dark'>
                                Ath<span className='required'></span>
                            </label>
                            <TextField
                                name='ath'
                                error={
                                    errors.ath &&
                                    (errors.ath.type === 'required' ||
                                        errors.ath.type === 'maxLength')
                                }
                                fullWidth
                                type='text'
                                className='form-control'
                                inputProps={{ maxLength: 150 }}
                                inputRef={register({ required: true, maxLength: 150 })}
                                onChange={(e) => setValue('ath', e.target.value)}
                            />
                            {errors.ath && errors.ath.type === 'required' && (
                                <span className='error'>Trường này là bắt buộc</span>
                            )}
                            {errors.ath && errors.ath.type === 'maxLength' && (
                                <span className='error'>Trường này không quá 150 ký tự</span>
                            )}
                        </div>

                        <div className="col-lg-6">
                            <label className='text-dark'>
                                Atv<span className='required'></span>
                            </label>
                            <TextField
                                name='atv'
                                error={
                                    errors.atv &&
                                    (errors.atv.type === 'required' ||
                                        errors.atv.type === 'maxLength')
                                }
                                fullWidth
                                type='text'
                                className='form-control'
                                inputProps={{ maxLength: 150 }}
                                inputRef={register({ required: true, maxLength: 150 })}
                                onChange={(e) => setValue('atv', e.target.value)}
                            />
                            {errors.atv && errors.atv.type === 'required' && (
                                <span className='error'>Trường này là bắt buộc</span>
                            )}
                            {errors.atv && errors.atv.type === 'maxLength' && (
                                <span className='error'>Trường này không quá 150 ký tự</span>
                            )}
                        </div>
                    </div>
                </DialogContent>
                <DialogActions>
                    <Button
                        type="submit"
                        color="primary"
                        variant="contained"
                    //   startIcon={!isSubmitting ? <SaveIcon></SaveIcon> : <CircularProgress size={20} color="white"/>}
                    //   disabled={isSubmitting}
                    >
                        Lưu
                    </Button>

                </DialogActions>
            </form>
        </Dialog >
    )
}

export default AddEdition;