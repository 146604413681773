import React from "react";
import Highcharts from "highcharts/highstock";
import PieChart from "highcharts-react-official";


const ChartPieNews = (props) => {
    const { userLoginType } = props;
    let datanew = {}
    if(userLoginType?.series?.length > 0) {
        datanew = {...userLoginType?.series[0]}
        datanew.colorByPoint = true;
    }
    console.log("data", datanew);
    const options = {
        chart: {
            plotBackgroundColor: null,
            plotBorderWidth: null,
            plotShadow: false,
            type: 'pie',
            height: '100%',
            width: 350,
        },
        title: {
            text: ''
        },
        tooltip: {
            pointFormat: 'SL Tin: <b>{point.y}</b> <br/> Tỷ lệ: {point.percentage:.1f} %'
        },
        accessibility: {
            point: {
                valueSuffix: '%'
            }
        },
        plotOptions: {
            pie: {
                allowPointSelect: true,
                cursor: 'pointer',
                dataLabels: {
                    enabled: true,
                    format: '<b>{point.name}</b>: {point.percentage:.1f} %',
                    connectorColor: 'silver'
                },
            }
        },
      series: [datanew]
    };
    
  return (
    <div style={{ width: '100%'}}>
      <PieChart height={300} width={300} highcharts={Highcharts} options={options} />
    </div>
  );
};

export default ChartPieNews;
