import Service from '../../../api/api-service';
import { ApiUrl } from '../../../api/api-url';
import * as config from '../../../common/config';

const service = new Service();

export const GetListAll = (
  langId,
  pageIndex = 1,
  pageSize = config.Configs.DefaultPageSize,
  sortExpression = 'modifiedDate desc',
  keyword = '',
  isDelete = false,
  projectName='',
  isFeature
) => {
  const params = new URLSearchParams();
  params.append('langId', langId);
  params.append('pageIndex', pageIndex);
  params.append('pageSize', pageSize);
  params.append('sorting', sortExpression);
  keyword && params.append('keyword', keyword);
  isFeature && params.append('isFeature', isFeature);
  params.append('isDelete', isDelete);
    projectName && params.append('projectName', projectName);
  return service
    .get(ApiUrl.ProjectGetListAll, params)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      throw err;
    });
};

export const ProjectCreate = (body) => {
  return service
    .post(ApiUrl.ProjectCreate, body)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      throw err;
    });
};

export const ProjectCreateWithUpload = (body) => {
    return service
        .post(ApiUrl.ProjectCreateWithUpload, body)
        .then((res) => {
            return res;
        })
        .catch((err) => {
            throw err;
        });
};

export const ProjectUpdate = (body) => {
  return service
    .post(ApiUrl.ProjectUpdate, body)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      throw err;
    });
};

export const ProjectUpdateWithUpload = (body) => {
    return service
        .post(ApiUrl.ProjectUpdateWithUpload, body)
        .then((res) => {
            return res;
        })
        .catch((err) => {
            throw err;
        });
};

export const ProjectDelete = (id) => {
  return service
    .post(ApiUrl.ProjectDelete(id))
    .then((res) => {
      return res;
    })
    .catch((err) => {
      throw err;
    });
};

export const EmptyTrash = () => {
  return service
    .post(ApiUrl.ProjectEmptyTrash)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      throw err;
    });
};

export const ProjectDetail = (id, langId) => {
  return service
    .get(ApiUrl.ProjectGetDetail(id, langId))
    .then((res) => {
      return res;
    })
    .catch((err) => {
      throw err;
    });
};

export const ProjectGetLookUp = (langId, limit) => {
  return service
    .get(ApiUrl.ProjectGetLookUp(langId, limit))
    .then((res) => {
      return res;
    })
    .catch((err) => {
      throw err;
    });
};

export const ProjectInfoLookUp = (langId, keyword, limit, pageIndex = 1) => {
  return service
    .get(ApiUrl.ProjecInforGetLookUp(langId, keyword, limit, pageIndex))
    .then((res) => {
      return res;
    })
    .catch((err) => {
      throw err;
    });
};
