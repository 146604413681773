import React, { useEffect, useState } from "react";

import * as mobileAppIntroduceAction from "../../redux/store/mobile-app-introduce/mobileAppIntroduce.store";
import * as lookupLanguageAction from "../../redux/store/lookup-language/lookupLanguage.store";

import { PageHeaderActions } from "../../components/pageHeaderActions/page-header-actions.view";
import * as config from "../../common/config";
import DeleteDialog from "../../components/dialog-delete/dialog-delete.view";
import { ListMobileAppIntroduceView } from "./list-mobile-app-introduce";
import { useAddOrEditMobileAppIntroduceDialog } from "./useAddOrEditMobileAppIntroduceDialog";
import { AddOrEditMobileAppDialog } from "./addOrEditMobileAppDialog";
import { useScreenActions } from "../../common/useScreenActions";
import * as appActions from "../../core/app.store";
import { useDispatch } from "react-redux";
const initialRequestParams = {
  isDelete: false,
  PageIndex: 0,
  PageSize: config.Configs.DefaultPageSize,
};

export const MobileAppIntroduce = () => {
  const [language, setLanguage] = useState([]);
  const dispatch = useDispatch();
  const {
    requestParams,
    datas,
    data,
    openAddOrEditDialog,
    dataWillDelete,
    setDataWillDelete,
    setOpenAddOrEditDialog,
    setDatas,
    onClickAdd,
    onClickEdit,
    handleChangeRequestParams,
    handleAddOrEditSuccess,
    onDeleteSuccess,
  } = useScreenActions({
    initialRequestParams,
    getDatas: (req) => getMobileAppIntroduce(req),
  });

  useEffect(() => {
    getLookupLanguageAction();
  }, []);

  const { onDelete } = useAddOrEditMobileAppIntroduceDialog({
    onSuccess: onDeleteSuccess,
  });

  const getMobileAppIntroduce = async (params) => {
    dispatch(appActions.ShowLoading(true))
    const res = await mobileAppIntroduceAction.GetListAll(params);
    if (res.content) {
      dispatch(appActions.ShowLoading(false))
      setDatas(res.content);
    }
  };

  const getLookupLanguageAction = async () => {
    const res = await lookupLanguageAction.GetLookupLanguage();

    if (res.content?.length) {
      setLanguage(res.content);
    }
  };

  const convertLanguageOptions = (arr) => {
    const itemsFilter = language.filter(
      (itemLanguage) =>
        !datas.map((itemDatas) => itemDatas.langId).includes(itemLanguage.id)
    );

    return convertArrToObj(itemsFilter);
  };

  const convertOptionsForEdit = () => {
    const itemFilter = language.find(
      (itemLanguage) => itemLanguage.id === data.langId
    );

    return convertArrToObj([itemFilter]);
  };

  const convertArrToObj = (arr) => {
    return arr.reduce((obj, item) => {
      return {
        ...obj,
        [item["id"]]: item["name"],
      };
    }, {});
  };

  return (
    <div className="slider">
      <PageHeaderActions
        title="Thông tin giới thiệu mobile app"
        labelAddButton="Thêm thông tin"
        onClickAdd={
          Object.keys(convertLanguageOptions())?.length ? onClickAdd : undefined
        }
        onChangeRequest={handleChangeRequestParams}
        showTrash={requestParams.isDelete}
        showSearchAndRefreshActions={false}
      />
      <ListMobileAppIntroduceView
        datas={datas}
        requestParams={requestParams}
        onChangeRequest={handleChangeRequestParams}
        onClickEdit={onClickEdit}
        onClickDelete={(data) => setDataWillDelete(data)}
      />
      <AddOrEditMobileAppDialog
        languageOptions={
          data ? convertOptionsForEdit() : convertLanguageOptions()
        }
        isOpen={openAddOrEditDialog}
        onClose={() => setOpenAddOrEditDialog(false)}
        onSuccess={handleAddOrEditSuccess}
        data={data}
      />
      <DeleteDialog
        isOpen={!!dataWillDelete?.id}
        onClose={() => setDataWillDelete(null)}
        onSuccess={() => onDelete(dataWillDelete.id)}
        header={"Xác nhận xóa"}
        content={"Bạn có chắc chắn muốn xóa?"}
      />
    </div>
  );
};
