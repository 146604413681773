import moment from 'moment';
export const convertNumberToCurrency = (number) => {
  return number?.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,') ?? 0;
};

export const currencyFormat = (_number, separate = ',') => {
  if (_number !== null && _number !== undefined) {
    const s = _number.toString();
    const regx = /\B(?=(\d{3})+(?!\d))/g;
    const ret = s.replace(regx, separate);
    return ret;
  }
  return _number;
};

export const formatDateToSubmit = (date) => moment(date).format('YYYY-MM-DD');

export const currencyFormatToShow = (num) => {
  if (num !== null && num !== undefined && num != '') {
    const n = +num;
    return n.toFixed(2).replace(/(\d)(?=(\d{3})+\.)/g, '$1,');
  }
  return num;
};

export const validatePhoneNumber = (phoneNumber) => {
  const regx = /^\+?([0-9]{2})\)?[-. ]?([0-9]{4})[-. ]?([0-9]{4})$/;

  if (!phoneNumber) {
    return true;
  }

  if (regx.test(phoneNumber)) {
    return true;
  }

  return false;
};

export const validateNumber = (number) => {
  const regx = /^\d+$/;

  if (!number) {
    return true;
  }

  if (number && regx.test(number)) {
    return true;
  }

  return false;
};
