import React, { createContext, useEffect, useReducer } from 'react';
import accountReducer from '../redux/store/account/accountReducer';
import {
  getCookies,
  getJWTCookies,
  getUserInfo,
  NotificationMessageType,
  removeCookies,
  removeJWTCookies,
  setJWTCookies,
  TokenKey,
  setCookies,
} from '../utils/configuration';
import * as accountAction from '../redux/store/account/account.store';
import * as viVN from '../language/vi-VN.json';
import ShowNotification from '../components/react-notifications/react-notifications';
import {
  ACCOUNT_INITIALISE,
  LOGIN,
  LOGOUT,
  REFRESH_TOKEN_INTERVAL,
  TIME_TO_IDLE,
} from '../common/constants';
import { useHistory, useLocation } from 'react-router-dom';
import { UrlCollection } from '../common/url-collection';
import { useIdle } from '../hook/useIdle';

const initialState = {
  isLoggedIn: false,
  isInitialised: false,
  user: null,
};

const JWTContext = createContext({
  ...initialState,
  login: () => Promise.resolve(),
  logout: () => {},
  refresh: () => {},
});

export const JWTProvider = ({ children }) => {
  const cookieReturnUrl = getCookies(TokenKey.returnUrl);
  const [state, dispatch] = useReducer(accountReducer, initialState);
  const { pathname } = useLocation();
  const history = useHistory();
  const isIdle = useIdle({
    timeToIdle: TIME_TO_IDLE * 1000,
    inactivityEvents: [],
    activityEvents: [
      'click',
      'mousemove',
      'keydown',
      'DOMMouseScroll',
      'mousewheel',
      'mousedown',
      'touchstart',
      'touchmove',
      'focus',
    ],
  });

  const login = async (username, password) => {
    accountAction
      .Login({
        email: username,
        password: password,
        rememberMe: true,
        returnUrl: cookieReturnUrl || '',
      })
      .then(
        (res) => {
          // If success
          if (res && res.content) {
            const { refreshToken, returnUrl, token } = res.content;
            // Store cookies
            setJWTCookies(refreshToken, returnUrl, token);

            // Update dispatch
            let user = getUserInfo();
            dispatch({
              type: LOGIN,
              payload: {
                isLoggedIn: true,
                user,
              },
            });

            if (returnUrl) {
              console.log(
                '🚀 ~ file: JWTContext.js ~ line 62 ~ login ~ returnUrl',
                returnUrl
              );
              // Redirect to returnUrl
              removeCookies(TokenKey.returnUrl);
              history.push(returnUrl);
              return;
            }
            history.push('/');
          } else {
            ShowNotification(
              viVN.Errors.UnableHandleException,
              NotificationMessageType.Error
            );
          }
        },
        (err) => {
          ShowNotification(
            viVN.Errors[(err && err.errorType) || 'UnableHandleException'],
            NotificationMessageType.Error
          );
        }
      );
  };

  const logout = async () => {
    // Set cookies return url based on current path
    const refreshToken = getCookies(TokenKey.refreshToken);
    // Remove cookies
    removeJWTCookies();
    // Call API clear refresh token
    accountAction
      .RemoveRefreshToken({
        request: refreshToken,
      })
      .then(
        (res) => {
          // TODO: Do something if remove refresh token success.
          // Currently do nothing, always clear token cookies when loggout, independent result remove refresh token api
        },
        (err) => {
          // TODO: Do something if remove refresh token failed
        }
      );
    history.push(UrlCollection.Login);
    dispatch({ type: LOGOUT });
  };

  const refresh = async () => {
    let jwtToken = getJWTCookies();
    accountAction
      .RefreshToken({
        returnUrl: jwtToken.returnUrl,
        accessToken: jwtToken.accessToken,
        refreshToken: jwtToken.refreshToken,
      })
      .then(
        (res) => {
          // If success
          if (res && res.content && res.content.err == null) {
            const { refreshTokens, returnUrl, token } = res.content;
            // Store cookies
            setJWTCookies(refreshTokens, returnUrl, token);
          } else {
            logout();
          }
        },
        (err) => {
          logout();
        }
      );
  };

  // Initial JWTContext
  useEffect(() => {
    const init = async () => {
      try {
        // Check token if have access token mean logged in
        const accessToken = getCookies(TokenKey.accessToken);
        if (accessToken) {
          try {
            // First time load page will refresh token for checking still not expire login session
            //refresh();

            let user = getUserInfo();
            dispatch({
              type: ACCOUNT_INITIALISE,
              payload: {
                isLoggedIn: true,
                user,
              },
            });

            if (pathname === UrlCollection.login) {
              history.push('/');
            }
          } catch (tokenErr) {
            dispatch({
              type: ACCOUNT_INITIALISE,
              payload: {
                isLoggedIn: false,
                user: null,
              },
            });
            logout();
          }
        } else {
          // If not have access token
          dispatch({
            type: ACCOUNT_INITIALISE,
            payload: {
              isLoggedIn: false,
              user: null,
            },
          });
          logout();
        }
      } catch (err) {
        // If have error
        dispatch({
          type: ACCOUNT_INITIALISE,
          payload: {
            isLoggedIn: false,
            user: null,
          },
        });
        logout();
      }
    };

    // Set interval for refresh after specific time
    const refreshInterval = setInterval(function () {
      refresh();
    }, REFRESH_TOKEN_INTERVAL * 1000);

    init();

    return () => {
      clearInterval(refreshInterval);
    };
  }, []);

  // Handle idle
  useEffect(() => {
    if (isIdle) {
      setCookies(TokenKey.returnUrl, pathname);
      logout();
    }
  }, [isIdle]);

  return (
    <JWTContext.Provider value={{ ...state, login, logout, refresh }}>
      {children}
    </JWTContext.Provider>
  );
};

export default JWTContext;
