import Service from "../../../api/api-service";
import { ApiUrl } from "../../../api/api-url";

const service = new Service();

export const GetLookupLanguage = async () => {
  try {
    return await service.get(ApiUrl.GetLookupLanguage);
  } catch (error) {
    return error;
  }
};
