import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Configs } from '../../common/config';
import { useForm } from 'react-hook-form';

//--- Material Control
import {
  Button,
  TextField,
  IconButton,
  Tooltip,
  Popover,
  Fab,
} from '@material-ui/core';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import ButtonGroup from '@material-ui/core/ButtonGroup';

//--- Styles
import './styles.scss';

//--- Material Icon
import DeleteCircle from '@material-ui/icons/DeleteForever';
import AddCircle from '@material-ui/icons/AddCircle';
import CloseIcon from '@material-ui/icons/Close';
import SearchIcon from '@material-ui/icons/Search';
import ClearAllIcon from '@material-ui/icons/ClearAll';
import RefreshIcon from '@material-ui/icons/Refresh';

//--- Component
import ListAddresses from './list-addresses/list-addresses.view';
import AddAddress from './add-addresses/add-addresses.view';
import EditAddress from './edit-addresses/edit-addresses.view';
import DeleteDialog from '../../components/dialog-delete/dialog-delete.view';
import ViewAddress from './view-addresses/view-addresses.view';

//--- Notifications
import ShowNotification from '../../components/react-notifications/react-notifications';
import { NotificationMessageType } from '../../utils/configuration';
import * as viVN from '../../language/vi-VN.json';

//--- Redux store
import * as provinceAction from '../../redux/store/province-management/province.store';
import * as addressActions from '../../redux/store/address/address.store';

export default function Address(props) {
  const { langId } = useSelector((state) => state.language);
  const { pathname } = props.location;
  const [addressModels, setAddressModels] = useState();
  const [totalItemCount, setTotalItemCount] = useState();
  const [searchData, setSearchData] = useState({
    keyword: '',
  });
  const [addressId, setAddressId] = useState();
  const [filePreview, setFilePreview] = useState([]);

  const [isDelete, setIsDelete] = useState(false);
  const [isShowTrash, setIsShowTrash] = useState(false);
  const [provinceSelect, setProvinceSelect] = useState();

  const [requestParams, setRequestParams] = useState({
    page: 1,
    rowsPerPage: Configs.DefaultPageSize,
    orderBy: 'modifiedDate',
    order: 'desc',
    keyword: '',
  });

  const { register, handleSubmit } = useForm({
    mode: 'all',
    reValidateMode: 'onBlur',
  });

  const GetListAll = () => {
    addressActions
      .GetListAll(
        langId,
        requestParams.page,
        requestParams.rowsPerPage,
        requestParams.orderBy + ' ' + requestParams.order,
        requestParams.keyword !== '' ? requestParams.keyword : undefined,
        isDelete
      )
      .then((res) => {
        setAddressModels(res.content.items);
        setTotalItemCount(res.content.totalItemCount);
      })
      .catch((err) => console.log(err));
  };

  useEffect(() => {
    if (langId) {
      GetListAll();
    }
  }, [pathname, langId, requestParams, isDelete]);

  useEffect(() => {
    Promise.all([GetLookupProvince()])
      .then((res) => {
        const [provinceLookupModels] = res;
        setProvinceSelect(
          provinceLookupModels && provinceLookupModels.content
            ? provinceLookupModels.content
            : []
        );
      })
      .catch((err) => {});
  }, []);

  const GetLookupProvince = () => {
    return new Promise((resolve, reject) => {
      provinceAction.GetLookupProvince().then(
        (res) => {
          resolve(res);
        },
        (err) => {
          reject(err);
          err &&
            err.errorType &&
            ShowNotification(
              viVN.Errors[err.errorType],
              NotificationMessageType.Error
            );
        }
      );
    });
  };

  //--- Dialog
  const [openAddDialog, setOpenAddDialog] = useState(false);
  const [openEditDialog, setOpenEditDialog] = useState(false);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [openViewAddressDialog, setOpenViewAddressDialog] = useState(false);

  const handleOpenAddDialog = () => {
    setOpenAddDialog(true);
  };

  const handleCloseAddDialog = () => {
    setOpenAddDialog(false);
  };

  const handleOpenEditDialog = (addressId) => {
    setAddressId(addressId);
    setOpenEditDialog(true);
  };
  const handleCloseViewAddressDialog = (event) => {
    setOpenViewAddressDialog(false);
    setFilePreview([]);
  };

  const handleCloseEditDialog = () => {
    setOpenEditDialog(false);
  };

  const handleOpenDeleteDialog = (addressId) => {
    setAddressId(addressId);
    setOpenDeleteDialog(true);
  };

  const handleCloseDeleteDialog = () => {
    setOpenDeleteDialog(false);
  };

  //--- Filter
  const [filterSection, setFilterSection] = useState(null);

  const handleClickFilter = (event) => {
    setFilterSection(event.currentTarget);
  };

  const handleCloseFilter = () => {
    setFilterSection(null);
  };

  const openFilter = Boolean(filterSection);
  const idFilter = openFilter ? 'popoverSlider' : undefined;
  const handleRestore = (id) => {
    // addressAction.RestoreAddress(id).then(
    //   (res) => {
    //     if (res && res.content && res.content.status) {
    //       GetListAll(
    //         searchData.categoryId,
    //         searchData.title,
    //         undefined,
    //         undefined,
    //         isDelete,
    //         searchData.status,
    //         1,
    //         rowsPerPage,
    //         orderBy + ' ' + order
    //       );
    //       ShowNotification(
    //         viVN.Success.AddressRestoreSuccess,
    //         NotificationMessageType.Success
    //       );
    //     }
    //   },
    //   (err) => {
    //     err &&
    //       err.errorType &&
    //       ShowNotification(
    //         viVN.Errors[err.errorType],
    //         NotificationMessageType.Error
    //       );
    //   }
    // );
  };
  const handleDelete = () => {
    addressActions.AddressDelete(addressId).then(
      (res) => {
        if (res && res.content && res.content.status) {
          GetListAll();
          handleCloseDeleteDialog();
          ShowNotification(
            viVN.Success.AddressDeleteSuccess,
            NotificationMessageType.Success
          );
        }
      },
      (err) => {
        err &&
          err.errorType &&
          ShowNotification(
            viVN.Errors[err.errorType],
            NotificationMessageType.Error
          );
      }
    );
  };

  const handleClearAllField = () => {
    refresh();
    setFilterSection(null);
  };

  const onSubmit = (data) => {
    setRequestParams((x) => ({
      ...x,
      page: 1,
    }));
    handleCloseFilter();
  };

  const refresh = () => {
    setRequestParams((x) => ({
      ...x,
      page: 1,
      order: 'desc',
      orderBy: 'modifiedDate',
      keyword: '',
    }));
  };

  const handleChangeTrash = (event) => {
    setIsShowTrash(event.target.checked);
    setIsDelete(event.target.checked ? true : false);
    setRequestParams((x) => ({
      ...x,
      page: 1,
    }));
  };

  const handleChangeSearchForm = (event) => {
    setRequestParams({
      ...requestParams,
      [event.target.name]: event.target.value,
    });
  };

  const handleEmptyTrash = () => {
    addressActions.EmptyTrash().then(
      (res) => {
        if (res && res.content && res.content.status) {
          setRequestParams((x) => ({
            ...x,
            page: 1,
          }));
          //handleCloseDeleteDialog();
          ShowNotification(
            viVN.Success.AddressDeleteSuccess,
            NotificationMessageType.Success
          );
        }
      },
      (err) => {
        err &&
          err.errorType &&
          ShowNotification(
            viVN.Errors[err.errorType],
            NotificationMessageType.Error
          );
      }
    );
  };

  return (
    <div className='slider'>
      <div className='d-sm-flex align-items-center justify-content-between mb-3'>
        <h1 className='h3 mb-0 text-gray-800'>
          Địa chỉ
          <Tooltip title='Tìm kiếm'>
            <Fab
              color='primary'
              aria-label='filter'
              size='small'
              className='ml-2'
              onClick={handleClickFilter}
              aria-describedby={idFilter}>
              <SearchIcon />
            </Fab>
          </Tooltip>
          <Tooltip title='Làm mới'>
            <Fab
              color='primary'
              aria-label='filter'
              size='small'
              onClick={refresh}
              className='ml-2'>
              <RefreshIcon />
            </Fab>
          </Tooltip>
          <span>&nbsp;&nbsp;</span>
          <FormControlLabel
            control={
              <Switch
                checked={isShowTrash}
                onChange={handleChangeTrash}
                name='checkedB'
                color='primary'
              />
            }
            label='Thùng rác'
          />
          <Popover
            id={idFilter}
            open={openFilter}
            anchorEl={filterSection}
            style={{ backgroundColor: 'rgba(0, 0, 0, 0.4)' }}
            onClose={handleCloseFilter}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'left',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'left',
            }}>
            <div className='p-3' style={{ width: '30rem' }}>
              <div className='text-right border-bottom mb-3 pb-2'>
                <IconButton
                  aria-label='close'
                  size='small'
                  onClick={handleCloseFilter}>
                  <CloseIcon fontSize='small' />
                </IconButton>
              </div>
              <form id='formSearch' onSubmit={handleSubmit(onSubmit)}>
                <div className='form-group'>
                  <label className='text-dark'>Từ khoá</label>
                  <TextField
                    className='w-100'
                    name='keyword'
                    value={searchData.keyword}
                    onChange={handleChangeSearchForm}
                    inputRef={register}
                  />
                </div>
                <div className='border-top'>
                  <div className='row'>
                    <div className='col-12 text-right mt-3'>
                      <Button
                        variant='contained'
                        color='primary'
                        onClick={onSubmit}>
                        <SearchIcon fontSize='small' /> Tìm kiếm
                      </Button>
                      <Button
                        variant='contained'
                        className='ml-2'
                        onClick={handleClearAllField}>
                        <ClearAllIcon fontSize='small' /> Bỏ lọc
                      </Button>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </Popover>
        </h1>
        <ButtonGroup
          variant='contained'
          color='primary'
          aria-label='contained primary button group'>
          {isShowTrash && (
            <Button
              variant='contained'
              color='secondary'
              onClick={handleEmptyTrash}
              startIcon={<DeleteCircle />}>
              Xóa thùng rác
            </Button>
          )}
          <Button
            variant='contained'
            color='primary'
            onClick={handleOpenAddDialog}
            startIcon={<AddCircle />}>
            Thêm Địa Chỉ
          </Button>
        </ButtonGroup>
      </div>
      {addressModels ? (
        <ListAddresses
          totalItemCount={totalItemCount}
          addressModels={addressModels}
          editAction={handleOpenEditDialog}
          deleteAction={handleOpenDeleteDialog}
          restoreAction={handleRestore}
          setRequestParams={setRequestParams}
          requestParams={requestParams}
        />
      ) : (
        ''
      )}
      {openAddDialog && (
        <AddAddress
          isOpen={openAddDialog}
          onClose={handleCloseAddDialog}
          onSuccess={handleCloseAddDialog}
          setRequestParams={setRequestParams}
          langId={langId}
          provinceSelect={provinceSelect}
        />
      )}

      {openEditDialog && (
        <EditAddress
          isOpen={openEditDialog}
          onClose={handleCloseEditDialog}
          onSuccess={handleCloseEditDialog}
          setRequestParams={setRequestParams}
          addressId={addressId}
          langId={langId}
          provinceSelect={provinceSelect}
        />
      )}

      {openDeleteDialog && (
        <DeleteDialog
          isOpen={openDeleteDialog}
          onClose={handleCloseDeleteDialog}
          onSuccess={handleDelete}
          header={'Xác nhận xóa'}
          content={'Bạn có chắc chắn muốn xóa?'}
        />
      )}
      {openViewAddressDialog && (
        <ViewAddress
          isOpen={openViewAddressDialog}
          onClose={handleCloseViewAddressDialog}
          filePreview={filePreview}
        />
      )}
    </div>
  );
}
