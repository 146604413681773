import Service from '../../../api/api-service';
import { ApiUrl } from '../../../api/api-url';
import * as config from '../../../common/config';

const service = new Service();

export const GetListAll = (
    keyword,
    langId,
    sortExpression = 'modifiedDate desc',
    pageIndex = 1,
    pageSize = config.Configs.DefaultPageSize,
    isDelete = false,
    paymentStatusId,
    isHot,
    isHomePage,
    publishDate
) => {
    const params = new URLSearchParams();

    params.append('Sorting', sortExpression);
    params.append('pageIndex', pageIndex);
    params.append('pageSize', pageSize);
    params.append('isDelete', isDelete);
    keyword && params.append('UserName', keyword);
    paymentStatusId && params.append('PaymentStatusId', paymentStatusId);
    // InvoiceStatusId && params.append('InvoiceStatusId', InvoiceStatusId);
    publishDate && params.append('PayDate', publishDate);
    // DueDate && params.append('DueDate', DueDate);
    // IsExpired && params.append('IsExpired', IsExpired);

    return service
        .get(ApiUrl.Invoice_GetlistAll, params)
        .then((res) => {
            return res;
        })
        .catch((err) => {
            throw err;
        });
};

export const InvoiceUpdate = (body) => {
    return service
        .post(ApiUrl.Invoice_Update, body)
        .then((res) => {
            return res;
        })
        .catch((err) => {
            throw err;
        });
};

export const InvoiceDelete = (id) => {
    return service
        .post(ApiUrl.Invoice_Delete(id))
        .then((res) => {
            return res;
        })
        .catch((err) => {
            throw err;
        });
};

export const InvoiceGetDetail = (id) => {
    return service
        .get(ApiUrl.Invoice_GetDetail(id))
        .then((res) => {
            return res;
        })
        .catch((err) => {
            throw err;
        });
};

export const GetLookupPaymentStatus = () => {
    return service
        .get(ApiUrl.GetLookupPaymentStatus)
        .then((res) => {
            return res;
        })
        .catch((err) => {
            throw err;
        });
};

export const GetLookupInvoiceStatus = () => {
    return service
        .get(ApiUrl.GetLookupInvoiceStatus)
        .then((res) => {
            return res;
        })
        .catch((err) => {
            throw err;
        });
};