import Service from "../../../api/api-service";
import { ApiUrl } from "../../../api/api-url";
import { stringify } from "querystring";
import { removedParams } from "../../../utils/helpers/removedParams";

const service = new Service();

export const GetListAll = async (params) => {
  try {
    return await service.get(
      ApiUrl.PageMetaTag_GetListAll,
      stringify(removedParams(params))
    );
  } catch (error) {
    return error;
  }
};

export const CreateMetaTag = async (body) => {
  try {
    return await service.post(ApiUrl.PageMetaTag_Create, body);
  } catch (error) {
    return error;
  }
};

export const UpdateMetaTag = async (body) => {
  try {
    return await service.post(ApiUrl.PageMetaTag_Update, body);
  } catch (error) {
    return error;
  }
};

export const DeleteMetaTag = async (metaTagId) => {
  try {
    return await service.post(ApiUrl.PageMetaTag_Delete(metaTagId));
  } catch (error) {
    return error;
  }
};
export const DetailMetaTag = async (langId, pageMetaTagId) => {
  try {
    return await service.get(ApiUrl.PageMetaTag_Detail(langId, pageMetaTagId));
  } catch (error) {
    return error;
  }
};

export const DeletePermanent = async (metaTagId) => {
  try {
    return await service.post(ApiUrl.PageMetaTag_Permanent(metaTagId));
  } catch (error) {
    return error;
  }
};

export const EmptyTrash = async () => {
  try {
    return await service.post(ApiUrl.PageMetaTag_EmptyTrash);
  } catch (error) {
    return error;
  }
};
