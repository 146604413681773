import Service from '../../../api/api-service';
import { ApiUrl } from '../../../api/api-url';
import * as config from '../../../common/config';

const service = new Service();

export const GetListAll = (
  langId = 'vi',
  pageIndex = 1,
  pageSize = config.Configs.DefaultPageSize,
  sortExpression = 'modifiedDate desc',
  keyword = undefined,
  isDelete = 0
) => {
  const params = new URLSearchParams();
  params.append('langId', langId);
  params.append('pageIndex', pageIndex);
  params.append('pageSize', pageSize);
  params.append('sorting', sortExpression);
  params.append('keyworld', keyword);
  params.append('isDelete', isDelete);
  return service
    .get(ApiUrl.Address_GetListAll, params)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      throw err;
    });
};

export const AddressAnalysis = (address) => {
  const params = new URLSearchParams();
  params.append('address', address);
  return service
    .get(ApiUrl.Address_Analysis, params)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      throw err;
    });
};

export const AddressCreate = (body) => {
  return service
    .post(ApiUrl.Address_Create, body)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      throw err;
    });
};

export const AddressUpdate = (body) => {
  return service
    .post(ApiUrl.Address_Update, body)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      throw err;
    });
};

export const AddressDelete = (id) => {
  return service
    .post(ApiUrl.Address_Delete(id))
    .then((res) => {
      return res;
    })
    .catch((err) => {
      throw err;
    });
};

export const EmptyTrash = () => {
  return service
    .post(ApiUrl.Address_EmptyTrash)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      throw err;
    });
};

export const AddressDetail = (addressId, langId) => {
  return service
    .get(ApiUrl.Address_GetDetail(addressId, langId))
    .then((res) => {
      return res;
    })
    .catch((err) => {
      throw err;
    });
};

export const GetLookUpAddress = (langId, keyword, limit) => {
  const params = new URLSearchParams();
  params.append("langId", langId);
  params.append("keyword", keyword);
  params.append("limit", limit);
  return service.get(ApiUrl.GetLookUpAddress, params).then(res => { return res }).catch(err => { throw err });
}

export const AnalysisAddressLongLat = (longtitude, latitude) => {
  const params = new URLSearchParams();
  params.append("longtitude", longtitude);
  params.append("latitude", latitude);
  return service.get(ApiUrl.AnalysisAddressLongLat, params).then(res => { return res }).catch(err => { throw err });
}