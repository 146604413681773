import React, { useState, useEffect } from "react";
import "suneditor/dist/css/suneditor.min.css";
import * as introduceAction from "../../redux/store/introduce/introduce.store";
import * as lookupLanguageAction from "../../redux/store/lookup-language/lookupLanguage.store";
import { useSelector } from "react-redux";
import AddOrEditIntroduceDialog from "./addOrEditIntroduceDialog";

export default function EditIntroduce (props) {
  const { idIntroduce } = props?.match?.params;
  const { langId } = useSelector((state) => state.language);
  const [language, setLanguage] = useState([]);
  const [data, setData] = useState();
  useEffect(() => {
     getLookupLanguageAction();
      async function getProjectDetails(_projectId) {
        try {
          const {content} = await introduceAction.Detail(idIntroduce);
  
          setData(content);
        } catch (error) {
          console.log(error)
        }
      }
      if (idIntroduce) {
        getProjectDetails(idIntroduce);
      }
  }, []);

  const getLookupLanguageAction = async () => {
    const res = await lookupLanguageAction.GetLookupLanguage();
    if (res.content?.length) {
      setLanguage(res.content);
    }
  };


  const convertOptionsForEdit = () => {
    if(language && language.length > 0) {
      const itemFilter = language.find(
        (itemLanguage) => itemLanguage.id === data?.langId
      );
      return convertArrToObj([itemFilter]);
    }
    return convertArrToObj([]);
  };
  const convertArrToObj = (arr) => {
    if(arr && arr.length > 0) {
      return arr.reduce((obj, item) => {
        return {
          ...obj,
          [item["id"]]: item["name"],
        };
      }, {});
    }
  };
  const languageOptions =  data ? convertOptionsForEdit() : {};
  return data ? (
    <AddOrEditIntroduceDialog {...props} langId={langId} data={data} languageOptionsEdit={languageOptions} />
  ) : null;
};
