import React, { useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import { Collapse } from "reactstrap";
import Icon from "@material-ui/core/Icon";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import LinkAdministratorItems from "./child-components/link-adminstrator-items.view";
import { UrlCollection } from "../../common/url-collection";

import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";

//--- Style
import "./sidebar.scss";

const SideBarMenu = ({ onChangeTitlePage, path, title, isSub, subSideBar, locate, ...rest }) => {
  const [toggled, setToggled] = useState(false);
  const currentLocation = useLocation();
  const toggle = () => {
    setToggled(!toggled)
    onChangeTitlePage(title)
  };

  return (
    <>
      <li className="nav-item">
        <Link
          className={`nav-link ${
            currentLocation.pathname === path || toggled ? "is-active" : ""
          }`}
          to={path || "#"}
          onClick={toggle}
        >
          <rest.icon fontSize="small" className="mr-2" />
          <span>{title}</span>
          {isSub && (
            <>
              {toggled ? (
                <KeyboardArrowDownIcon className="float-right" />
              ) : (
                <ChevronRightIcon className="float-right" />
              )}
            </>
          )}
        </Link>
        {isSub && (
          <Collapse isOpen={toggled}>
            <div className="py-2 collapse-inner rounded">
              {Array.isArray(subSideBar) &&
                subSideBar.map((item, index) => (
                  <LinkAdministratorItems
                    key={index}
                    icon={item.icon}
                    iconShow={item.iconShow}
                    pageLink={item.path}
                    title={item.title}
                    setToggled={setToggled}
                  />
                ))}
            </div>
          </Collapse>
        )}
      </li>

      <hr className="sidebar-divider mb-0" />
    </>
  );
};

export default React.memo(SideBarMenu);
