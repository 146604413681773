import Service from '../../../api/api-service';
import { ApiUrl } from '../../../api/api-url';
import * as config from '../../../common/config';

const service = new Service();

export const GetListAll = (
    title,
    districtId,
    provinceId,
    publishFrom,
    publishTo,
    sortExpression = 'publishDate desc',
    isDelete,
  pageIndex = 1,
  pageSize = config.Configs.DefaultPageSize,
) => {
  const params = new URLSearchParams();
    provinceId && params.append('provinceId', provinceId);
    districtId && params.append('districtId', districtId);
    title && params.append('title', title);
    publishFrom && params.append('publishFrom', publishFrom);
    publishTo && params.append('publishTo', publishTo);
    params.append('pageIndex', pageIndex);
  params.append('pageSize', pageSize);
  params.append('sorting', sortExpression);
    if (isDelete!=undefined && isDelete!=null) {
    params.append('isDelete', isDelete);
  }
  return service
    .get(ApiUrl.StatePriceFile_GetListAll, params)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      throw err;
    });
};

export const Create = (body) => {
    debugger
  return service
    .post(ApiUrl.StatePriceFile_Create, body)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      throw err;
    });
};

export const Update = (body) => {
  return service
    .post(ApiUrl.StatePriceFile_Update, body)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      throw err;
    });
};

export const Delete = (id) => {
  return service
    .post(ApiUrl.StatePriceFile_Delete(id))
    .then((res) => {
      return res;
    })
    .catch((err) => {
      throw err;
    });
};

export const EmptyTrash = () => {
  return service
    .post(ApiUrl.StatePriceFile_EmptyTrash)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      throw err;
    });
};

export const GetDetail = (id) => {
  return service
    .get(ApiUrl.StatePriceFile_GetDetail(id))
    .then((res) => {
      return res;
    })
    .catch((err) => {
      throw err;
    });
};
