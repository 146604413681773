import React from "react";
import { useForm } from "react-hook-form";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUser, faKey  } from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";
import { UrlCollection } from "../../common/url-collection";

import "./login.scss";
import useAuth from "../../hook/useAuth";

export default function LoginDesktop(props) {
    const {login} = useAuth();
    const { register, handleSubmit, errors } = useForm({ mode: "onBlur" });
    const onSubmit = async (data) => {
        if (!data) return;
        await login(data.email, data.password);
    };

    return (
        <div
            className="login-page"
            style={{ minHeight: window.innerHeight + "px" }}
        >
            <div className="login-form">
                <form onSubmit={handleSubmit(onSubmit)}>
                    <div className="row">
                        <div className="col-12">
                            {/* <FontAwesomeIcon
                                icon={faUserCircle}
                                className="icon-title text-center w-100"
                            /> */}
                            <div className="icon-title text-center w-100">
                                <img
                                    src={process.env.PUBLIC_URL + "/logo.png"}
                                    alt="Logo"
                                    width="170px"
                                />
                            </div>
                            <h3 className="title text-center w-100 mt-1">Đăng nhập</h3>
                        </div>
                        <div className="col-12 form-group mt-4">
                            <FontAwesomeIcon icon={faUser} className="icon-input" />
                            <input
                                type="text"
                                name="email"
                                className="form-control"
                                autoComplete="off"
                                placeholder="Email"
                                ref={register({
                                    required: true,
                                    pattern: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                                })}
                            />
                            {errors.email && errors.email.type === "required" && (
                                <span className="error">Trường này là bắt buộc</span>
                            )}
                            {errors.email && errors.email.type === "pattern" && (
                                <span className="error">Email không đúng định dạng</span>
                            )}
                        </div>
                        <div className="col-12 form-group">
                            <FontAwesomeIcon icon={faKey} className="icon-input" />
                            <input
                                type="password"
                                name="password"
                                className="form-control"
                                autoComplete="off"
                                placeholder="Mật khẩu"
                                ref={register({
                                    required: true,
                                    minLength: 8,
                                    pattern: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[#$^+=!*()@%&]).{8,}$/,
                                })}
                            />
                            {errors.password && errors.password.type === "required" && (
                                <span className="error">Trường này là bắt buộc</span>
                            )}
                            {errors.password && errors.password.type === "minLength" && (
                                <span className="error">Mật khẩu tối thiểu 8 ký tự</span>
                            )}
                            {errors.password && errors.password.type === "pattern" && (
                                <span className="error">
                                    Có it nhất 1 ký tự viết thường, hoa và ký tự đặc biệt
                                </span>
                            )}
                        </div>
                        <div className="col-12 text-center mb-4">
                            <button type="submit" className="btn btn-danger w-100">
                                Đăng nhập
              </button>
                        </div>
                        <div className="col-6 text-left">
                            {/* <Link to={UrlCollection.Register} className="create-account">
                <FontAwesomeIcon icon={faPlus} className="icon-link" /> Tạo tài
                khoản
              </Link> */}
                        </div>
                        <div className="col-6 text-right">
                            <Link to={UrlCollection.ForgotPassword} className="forgot-password">
                                Quên mật khẩu?
              </Link>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    );
}
