import Service from '../../../api/api-service';
import { ApiUrl } from '../../../api/api-url';
import * as config from '../../../common/config';

const service = new Service();

export const GetListAll = (
  postTypeId,
  langId,
  pageIndex = 1,
  pageSize = config.Configs.DefaultPageSize,
  sortExpression = 'modifiedDate desc',
  categoryId,
  status,
  isHot,
  isHomePage,
  keyword,
  isDelete,
  title
) => {
  const params = new URLSearchParams();
  //params.append('categoryId', categoryId);
  params.append('langId', langId);
  params.append('postTypeId', postTypeId);
  params.append('pageIndex', pageIndex);
  params.append('pageSize', pageSize);
  params.append('sorting', sortExpression);
  params.append('categoryId', categoryId);
    if (status == 1)
        params.append('isActive', true);
    else if (status == 0)
        params.append('isActive', false);
  params.append('isHot', isHot);
  params.append('isHomePage', isHomePage);
  keyword &&  params.append('keyword', keyword);
  title &&  params.append('title', title);
  if (isDelete) {
    params.append('isDelete', isDelete);
  }
  return service
    .get(ApiUrl.Posts_GetListAll, params)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      throw err;
    });
};

export const PostsCreate = (body) => {
  return service
    .post(ApiUrl.Posts_Create, body)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      throw err;
    });
};

export const PostsCreateWithUpload = (body) => {
    return service
        .post(ApiUrl.Posts_CreateWithUpload, body)
        .then((res) => {
            return res;
        })
        .catch((err) => {
            throw err;
        });
};

export const PostsUpdate = (body) => {
  return service
    .post(ApiUrl.Posts_Update, body)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      throw err;
    });
};

export const PostsUpdateWithUpload = (body) => {
    return service
        .post(ApiUrl.Posts_UpdateWithUpload, body)
        .then((res) => {
            return res;
        })
        .catch((err) => {
            throw err;
        });
};


export const PostsDelete = (id) => {
  return service
    .post(ApiUrl.Posts_Delete(id))
    .then((res) => {
      return res;
    })
    .catch((err) => {
      throw err;
    });
};

export const EmptyTrash = () => {
  return service
    .post(ApiUrl.Posts_EmptyTrash)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      throw err;
    });
};

export const PostDetail = (postId, langId) => {
  return service
    .get(ApiUrl.Posts_GetDetail(postId, langId))
    .then((res) => {
      return res;
    })
    .catch((err) => {
      throw err;
    });
};
