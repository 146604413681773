import { TextField } from "@material-ui/core";
import React from "react";

export const ELEMENT_TYPES = {
  TextField: "TEXTFIELD",
};

export const RenderElement = (props) => {
  const { type, ...args } = props;

  if (type === ELEMENT_TYPES.TextField) {
    return <TextField {...args} />;
  }
};
