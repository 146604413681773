import Service from '../../../api/api-service';
import { ApiUrl } from '../../../api/api-url';
import * as config from '../../../common/config';

const service = new Service();

export const GetListAll = (
  keyword,
  langId,
  sortExpression = 'modifiedDate desc',
  pageIndex = 1,
  pageSize = config.Configs.DefaultPageSize,
  isDelete
) => {
  const params = new URLSearchParams();
  //params.append('categoryId', categoryId);
  if (keyword) {
    params.append('keyword', keyword);
  }
  params.append('isDelete', isDelete);
  params.append('langId', langId);
  params.append('pageIndex', pageIndex);
  params.append('pageSize', pageSize);
  params.append('sorting', sortExpression);

  return service
    .get(ApiUrl.Faq_GetListAll, params)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      throw err;
    });
};

export const FaqCreate = (body) => {
  return service
    .post(ApiUrl.Faq_Create, body)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      throw err;
    });
};

export const FaqUpdate = (body) => {
  return service
    .post(ApiUrl.Faq_Update, body)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      throw err;
    });
};

export const FaqDelete = (id) => {
  return service
    .post(ApiUrl.Faq_delete(id))
    .then((res) => {
      return res;
    })
    .catch((err) => {
      throw err;
    });
};

export const EmptyTrash = () => {
  return service
    .post(ApiUrl.Faq_EmptyTrash)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      throw err;
    });
};

export const FaqDetail = (quesId, langId) => {
  return service
    .get(ApiUrl.Faq_GetDetail(quesId, langId))
    .then((res) => {
      return res;
    })
    .catch((err) => {
      throw err;
    });
};
