import React from 'react';
import {
  DialogContent,
  DialogTitle,
  Dialog,
  makeStyles,
  Typography,
  IconButton,
} from '@material-ui/core';
import FileViewer from '../../../components/file-viewer/components/file-viewer';
import { checkEmptyObject } from '../../../utils/configuration';
//--- Material Icon
import CloseIcon from '@material-ui/icons/Close';

const useStyles = makeStyles((theme) => ({
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
}));

const ViewPost = (props) => {
  const { filePreview } = props;
  const classes = useStyles();

  const { isOpen, onClose } = props;
  return (
    <Dialog open={isOpen} onClose={onClose} fullWidth={true} maxWidth='lg'>
      <DialogTitle disableTypography className='border-bottom'>
        <Typography variant='h6'>Xem file</Typography>
        <IconButton
          aria-label='close'
          className={classes.closeButton}
          onClick={onClose}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent dividers={'paper'}>
        {!checkEmptyObject(filePreview[0]) && (
          <FileViewer
            key={'123123'}
            fileId={'123123'}
            fileType={'docx'}
            filePath={
              'https://datvang-api.bnext.vn/Uploads/Thu%20moi%20tham%20gia%20sinh%20hoat%20giao%20ly.docx'
            }
          />
        )}
      </DialogContent>
    </Dialog>
  );
};

export default ViewPost;
