import React from 'react';
import {useDestructureListToOptions} from '../hooks';
import CustomFormSelect from './form-select';

const SelectProjectStatus = ({listOptions = []}) => {
  const projectStatusOptions = useDestructureListToOptions({
    listData: listOptions
  });

  return (
    <>
      <CustomFormSelect
        name="statusId"
        label="Trạng thái dự án"
        options={projectStatusOptions}
        isRequired
        defaultValue={null}
        placeholderText="Chọn trạng thái dự án"
      />
    </>
  );
};

export default SelectProjectStatus;
