/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import { Configs, UserSidePathUrl } from "../../../../common/config";
import {DomainUserSide,} from '../../../../utils/configuration';
import "date-fns";
import dateformat from "dateformat";

//--- Material Table
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import Paper from "@material-ui/core/Paper";

//--- Material Control
import Tooltip from "@material-ui/core/Tooltip";
import IconButton from "@material-ui/core/IconButton";
import ChatBubbleIcon from "@material-ui/icons/ChatBubble";
import Badge from "@material-ui/core/Badge";
import Dialog from "@material-ui/core/Dialog";
import Button from "@material-ui/core/Button";
import DialogContent from "@material-ui/core/DialogContent";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";

//--- Material Icon
import EditIcon from "@material-ui/icons/Edit";
import GridOnIcon from "@material-ui/icons/GridOn";
import AssignmentIcon from "@material-ui/icons/Assignment";
import DeleteIcon from "@material-ui/icons/Delete";

//--- Component
import ConsultTheCommunity from "../../consult-the-community/consult-the-community.view";
import CreateAnnounced from "../../announced/add-announced/add-announced.view";
import EditAnnounced from "../../announced/edit-announced/edit-announced.view";
import PlanningRelatedDialog from "../list-records-management/planning-related/planning-related.view";
import DocumentSettings from "../../document-settings/document-settings.view";
import FooterPagination from '../../../../components/footer-pagination/footer-pagination';

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  paper: {
    width: "100%",
    marginBottom: theme.spacing(2),
  },
  tableContainer: {
    maxHeight: window.outerHeight - 365,
    // overflow: "hidden",
  },
  table: {
    minWidth: 750,
  },
  visuallyHidden: {
    border: 0,
    clip: "rect(0 0 0 0)",
    height: 1,
    margin: -1,
    overflow: "hidden",
    padding: 0,
    position: "absolute",
    top: 20,
    width: 1,
  },
  appBar: {
    position: "relative",
    backgroundColor: "#00923F",
  },
  title: {
    marginLeft: theme.spacing(0),
    flex: 1,
  },
  button: {
    marginRight: theme.spacing(1),
  },
  instructions: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
}));

function EnhancedTableHead(props) {
  const { classes, order, orderBy, onRequestSort, headCells } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            sortDirection={orderBy === headCell.id ? order : false}
            className={
              "pt-3 pb-3 text-nowrap " +
              (headCell.id === "planningName" ? "MuiTableCell-freeze" : "")
            }
            hidden={!headCell.visibleColumn}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
              hideSortIcon={headCell.hideSortIcon ? true : false}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <span className={classes.visuallyHidden}>
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </span>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  classes: PropTypes.object.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

export default function ListRecordsManagement(props) {
  const {
    dataList,
    order,
    setOrder,
    orderBy,
    setOrderBy,
    page,
    setPage,
    pageSize,
    setPageSize,
    onGetListData,
    planning,
    setPlanning,
    planningId,
    setPlanningId,
    editAction,
    totalItemCount,
    headCells,
    showConsult,
    setShowConsult,
    deleteAction,
  } = props;

  const classes = useStyles();
  const [openDocumentSetting, setOpenDocumentSetting] = useState(false);
    const [planningName, setPlanningName] = useState("");
    const linkToView = DomainUserSide + UserSidePathUrl.map;

  useEffect(() => {
    if (showConsult) {
      setOpenConsultTheCommunityDialog(true);
      setPlanningName(props.planningName);
    }
  }, [props.showConsult]);

  //--- Setting table
  const emptyRows =
        pageSize - Math.min(pageSize, dataList.length - page * pageSize);
    const totalPage = Math.ceil(totalItemCount / pageSize);

  const onRequestSort = (event, property) => {
    let isAsc = orderBy === property && order === "asc";
    let sort = isAsc ? "desc" : "asc";
    let sortExpression = property + " " + sort;
    setOrder(sort);
    setOrderBy(property);
    onGetListData(page+1, pageSize, sortExpression);
  };

  const onChangePage = (event, newPage) => {
      setPage(newPage-1);
    onGetListData(newPage, pageSize, orderBy ? orderBy + " " + order : "");
  };

  const onChangePageSize = (event) => {
    setPageSize(Number(event.target.value));
    setPage(0);
    onGetListData(1, event.target.value, orderBy ? orderBy + " " + order : "");
  };

  //--- Consult community dialog
  const [
    openConsultTheCommunityDialog,
    setOpenConsultTheCommunityDialog,
  ] = useState(false);

  const handleClickOpenConsultTheComminityDialog = (
    id = "",
    planningName = ""
  ) => {
    if (id && planningName) {
      setPlanningId(id || planningId);
      setPlanningName(planningName);
      setOpenConsultTheCommunityDialog(true);
    }
  };

  const handleCloseConsultTheCommunityDialog = () => {
    setPlanningId(null);
    window.history.pushState({}, "", "/");
    setShowConsult(false);
    setOpenConsultTheCommunityDialog(false);
  };

  //--- Announced dialog
  const [openCreateAnnouncedDialog, setOpenCreateAnnouncedDialog] = useState(
    false
  );

  const onOpenCreateAnnouncedDialog = (item) => {
    if (item) {
      setPlanningId(item.id);
      setPlanning(item);
      setOpenCreateAnnouncedDialog(true);
    }
  };

  const onCloseCreateAnnouncedDialog = () => {
    setOpenCreateAnnouncedDialog(false);
    setPlanningId(null);
    setPlanning(null);
    setActiveStep(0);
  };

  const handleSuccessAnnouncedDialog = () => {
    onGetListData(page, pageSize, orderBy ? orderBy + " " + order : "");
    onCloseCreateAnnouncedDialog();
  };

  const [openEditAnnouncedDialog, setOpenEditAnnouncedDialog] = useState(false);

  const onOpenEditAnnouncedDialog = (item) => {
    if (item) {
      setPlanningId(item.id);
      setOpenEditAnnouncedDialog(true);
    }
  };

  const onCloseEditAnnouncedDialog = () => {
    setOpenEditAnnouncedDialog(false);
    setPlanningId(null);
  };

  const onSuccessEditAnnounced = () => {
    onGetListData(page, pageSize, orderBy ? orderBy + " " + order : "");
    onCloseEditAnnouncedDialog();
  };

  //--- Planning related dialog
  const [openPlanningRelatedDialog, setOpenPlanningRelatedDialog] = useState(
    false
  );

  const handleClickOpenPlanningRelatedDialog = (id, name) => {
    if (id && name) {
      setPlanningId(id);
      setPlanningName(name);
      setOpenPlanningRelatedDialog(true);
    }
  };

  const handleClosePlanningRelatedDialog = () => {
    setOpenPlanningRelatedDialog(false);
    setPlanningId(null);
  };

  const onSuccessPlanningRelationship = () => {
    onGetListData(page, pageSize, orderBy ? orderBy + " " + order : "");
    handleClosePlanningRelatedDialog();
  };

  //--- Step
  const steps = getSteps();
  const [activeStep, setActiveStep] = useState(0);
  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  return (
    <div className={classes.root}>
      <Paper className={classes.paper}>
        <TableContainer className={classes.tableContainer}>
          <Table className={classes.table} size="small" stickyHeader>
            <EnhancedTableHead
              classes={classes}
              order={order}
              orderBy={orderBy}
              onRequestSort={onRequestSort}
              rowCount={dataList.length}
              headCells={headCells}
            />
            <TableBody>
              {dataList && dataList.length > 0 ? (
                dataList.map((row, index) => {
                  return (
                    <TableRow hover key={index}>
                      {headCells.map((headItem, headIndex) => {
                        switch (headItem.id) {
                          case "planningName":
                            return (
                              <TableCell
                                key={headIndex}
                                hidden={!headItem.visibleColumn}
                                className="MuiTableCell-freeze shadow-sm"
                                >
                                    <a href={linkToView + row.id} target="review" tooltip="Click để xem trước">{row.name}</a>
                              </TableCell>
                            );
                          case "planningCode":
                            return (
                              <TableCell
                                key={headIndex}
                                hidden={!headItem.visibleColumn}
                              >
                                {row.planningCode}
                              </TableCell>
                            );
                          case "planningTypeName":
                            return (
                              <TableCell
                                key={headIndex}
                                hidden={!headItem.visibleColumn}
                              >
                                {row.planningTypeName}
                              </TableCell>
                            );
                          default:
                            break;
                        }
                      })}

                      {/* <TableCell>{row.planningCode}</TableCell> */}
                      {/* <TableCell className="text-nowrap">{row.name}</TableCell> */}
                      {/* <TableCell>{row.planningTypeName}</TableCell> */}
                      <TableCell>{row.place}</TableCell>
                      <TableCell>
                        <span
                          className="map-link"
                          onClick={() =>
                            props.openCreatMapModal(row.mapId, row.id, row.name)
                          }
                        >
                          {row.mapId ? "Chỉnh sửa" : "Tạo mới"}
                        </span>
                      </TableCell>
                      <TableCell className="text-nowrap">
                        <h6>
                                  {
                          //            row.planningStatusId === 1 ? (
                          //  <span className="badge badge-success p-2 text-uppercase">
                          //    {row.planningStatusName}
                          //  </span>
                          //) : (
                          //  <span className="badge badge-warning p-2 text-uppercase">
                          //    {row.planningStatusName}
                          //  </span>
                          //                )
                                  }
                                  {
                                      row.planningStatusId === 1 ? (
                                          <img
                                              src={require("../../../../assets/icon/tick.png")}
                                              alt="Tick"
                                          />
                                      ) : (
                                              <img
                                                  src={require("../../../../assets/icon/cancel.png")}
                                                  alt="Cancel"
                                              />
                                          )
                                  }
                        </h6>
                      </TableCell>
                      <TableCell>{row.planningLevelName}</TableCell>
                      <TableCell>{row.planningAgency}</TableCell>
                          <TableCell>{row.planningUnit}</TableCell>
                          <TableCell>
                              {row.isActive ? (
                                  <img
                                      src={require("../../../../assets/icon/tick.png")}
                                      alt="Tick"
                                  />
                              ) : (
                                      <img
                                          src={require("../../../../assets/icon/cancel.png")}
                                          alt="Cancel"
                                      />
                                  )}
                          </TableCell>
                          <TableCell>
                              {row.isShowHomePage ? (
                                  <img
                                      src={require("../../../../assets/icon/tick.png")}
                                      alt="Tick"
                                  />
                              ) : (
                                      <img
                                          src={require("../../../../assets/icon/cancel.png")}
                                          alt="Cancel"
                                      />
                                  )}
                          </TableCell>
                      <TableCell>{row.agencySubmitted}</TableCell>
                      <TableCell>{row.consultingUnit}</TableCell>
                      <TableCell>{row.investor}</TableCell>
                      <TableCell>{row.documentTypeName}</TableCell>
                      <TableCell>
                        {dateformat(row.modified_date, "dd/mm/yyyy")}
                      </TableCell>

                      {/*--- Xin ý kiến ---*/}
                      <TableCell>
                        <Tooltip title="Xin ý kiến">
                          <IconButton
                            aria-label="xin-y-kien"
                            onClick={() =>
                              handleClickOpenConsultTheComminityDialog(
                                row.id,
                                row.name
                              )
                            }
                          >
                            <Badge
                              color="primary"
                              badgeContent={row.numberOfConsultantCommunity}
                              showZero
                            >
                              <ChatBubbleIcon />
                            </Badge>
                          </IconButton>
                        </Tooltip>
                      </TableCell>

                      {/*--- Công bố ---*/}
                      <TableCell className="text-nowrap">
                        {row.planningStatusId === 1 &&
                          (row.statementStatus === 0 ? (
                            <Tooltip title="Công bố">
                              <Button
                                variant="contained"
                                color="primary"
                                onClick={() => onOpenCreateAnnouncedDialog(row)}
                              >
                                Công bố
                              </Button>
                            </Tooltip>
                          ) : row.statementStatus === 1 ? (
                            <Tooltip title="Đã công bố">
                              <Button
                                variant="contained"
                                className="bg-success text-white"
                                onClick={() => onOpenEditAnnouncedDialog(row)}
                              >
                                Đã công bố
                              </Button>
                            </Tooltip>
                          ) : (
                            <Tooltip title="Không công bố">
                              <Button
                                variant="contained"
                                className="bg-info text-white"
                                onClick={() => onOpenEditAnnouncedDialog(row)}
                              >
                                Không công bố
                              </Button>
                            </Tooltip>
                          ))}
                      </TableCell>

                      {/*--- Quy hoạch liên quan ---*/}
                      <TableCell align="center">
                        <Tooltip title="Quy hoạch liên quan">
                          <IconButton
                            onClick={() =>
                              handleClickOpenPlanningRelatedDialog(
                                row.id,
                                row.name
                              )
                            }
                          >
                            <Badge
                              color="primary"
                              badgeContent={row.numberOfRelatePlanning}
                              showZero
                            >
                              <GridOnIcon />
                            </Badge>
                          </IconButton>
                        </Tooltip>
                      </TableCell>

                      {/*--- Thiết lập hồ sơ ---*/}
                      <TableCell align="center">
                        <Tooltip title="Thiết lập hồ sơ">
                          <IconButton
                            onClick={() => {
                              setOpenDocumentSetting(true);
                              setPlanningId(row.id);
                              setPlanningName(row.name);
                            }}
                          >
                            <Badge
                              color="primary"
                              badgeContent={row.numberOfDocument}
                              showZero
                            >
                              <AssignmentIcon />
                            </Badge>
                          </IconButton>
                        </Tooltip>
                      </TableCell>

                      {/*--- Sửa/Xóa ---*/}
                      <TableCell align="right" className="text-nowrap">
                        <Tooltip title="Sửa">
                          <IconButton
                            aria-label="edit"
                            onClick={() => editAction(row.id)}
                          >
                            <EditIcon className="text-primary" />
                          </IconButton>
                        </Tooltip>
                        <Tooltip title="Xóa">
                          <IconButton
                            aria-label="delete"
                            onClick={() => deleteAction(row.id)}
                          >
                            <DeleteIcon className="text-danger" />
                          </IconButton>
                        </Tooltip>
                      </TableCell>
                    </TableRow>
                  );
                })
              ) : (
                <TableRow hover tabIndex={-1}>
                  <TableCell colSpan={18} className="text-center">
                    Không có dữ liệu
                  </TableCell>
                </TableRow>
              )}

              {emptyRows > 0 && (
                <TableRow style={{ height: 0 }}>
                  <TableCell
                    colSpan={4}
                    style={{ padding: 0, borderBottom: 0 }}
                  />
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>

              {totalItemCount && totalItemCount > 0 ? (

                  <FooterPagination
                      currentPage={page+1}
                      rowsPerPage={pageSize}
                      handleChangeRowsPerPage={onChangePageSize}
                      handleChangePage={onChangePage}
                      totalPage={totalPage}
                      totalItemCount= {totalItemCount}
                  />
              ) : (
                      ''
                  )}

              {
        //          totalItemCount && totalItemCount > 0 ? (
        //  <TablePagination
        //    rowsPerPageOptions={Configs.DefaultPageSizeOption}
        //    component="div"
        //    count={totalItemCount}
        //    rowsPerPage={pageSize}
        //    page={page - 1}
        //    onChangePage={onChangePage}
        //    onChangeRowsPerPage={onChangePageSize}
        //    labelRowsPerPage={"Số hàng mỗi trang"}
        //    labelDisplayedRows={({ from, to, count }) => {
        //      return "" + from + "-" + to + " trong " + count;
        //    }}
        //  />
        //) : (
        //  ""
        //          )
              }
      </Paper>

      {openConsultTheCommunityDialog && (
        <ConsultTheCommunity
          openConsultTheCommunityDialog={openConsultTheCommunityDialog}
          handleCloseConsultTheCommunityDialog={
            handleCloseConsultTheCommunityDialog
          }
          classes={classes}
          planningId={planningId}
          planningName={planningName}
          showConsult={showConsult}
        />
      )}

      {openCreateAnnouncedDialog && (
        <Dialog
          open={openCreateAnnouncedDialog}
          onClose={onCloseCreateAnnouncedDialog}
          aria-labelledby="annoucedDialog"
          aria-describedby="annoucedDescription"
          maxWidth={activeStep === steps.length - 1 ? "md" : "sm"}
          fullWidth={true}
        >
          <DialogContent className="p-0">
            <div className="w-100">
              <Stepper
                activeStep={activeStep}
                className="bg-light p-4 border-bottom"
              >
                {steps.map((label, index) => {
                  const stepProps = {};
                  const labelProps = {};

                  return (
                    <Step key={label} {...stepProps}>
                      <StepLabel {...labelProps}>{label}</StepLabel>
                    </Step>
                  );
                })}
              </Stepper>
              {getStepContent(
                activeStep,
                planningId,
                handleNext,
                onCloseCreateAnnouncedDialog,
                handleSuccessAnnouncedDialog,
                classes,
                planning
              )}
            </div>
          </DialogContent>
        </Dialog>
      )}

      {openEditAnnouncedDialog && (
        <EditAnnounced
          planningId={planningId}
          isOpen={openEditAnnouncedDialog}
          onClose={onCloseEditAnnouncedDialog}
          onSuccess={onSuccessEditAnnounced}
          classes={classes}
          planning={planning}
        />
      )}

      {/* Quy hoạch liên quan */}
      {openPlanningRelatedDialog && (
        <PlanningRelatedDialog
          openPlanningRelatedDialog={openPlanningRelatedDialog}
          handleClosePlanningRelatedDialog={handleClosePlanningRelatedDialog}
          onSuccess={onSuccessPlanningRelationship}
          planningId={planningId}
          pageSize={pageSize}
          page={page}
          setPage={setPage}
          planningName={planningName}
        />
      )}

      {openDocumentSetting && (
        <DocumentSettings
          isShowDialog={openDocumentSetting}
          onCloseDialog={() => setOpenDocumentSetting(false)}
          planningId={planningId}
          planningName={planningName}
        />
      )}
    </div>
  );
}

//--- Create steps
function getSteps() {
  return ["Công bố", "Tạo bài viết công bố"];
}

function getStepContent(
  step,
  planningId,
  handleNext,
  onCloseCreateAnnouncedDialog,
  handleSuccessAnnouncedDialog,
  classes,
  planning
) {
  switch (step) {
    case 0:
      return (
        <div>
          <div className="p-3 text-center">
            Bạn có chắc muốn công bố dự án này?
          </div>
          <div className="text-right border-top pt-3 pr-2 pb-3">
            <Button variant="contained" color="primary" onClick={handleNext}>
              Đồng ý
            </Button>
          </div>
        </div>
      );
    case 1:
      return (
        <CreateAnnounced
          planningId={planningId}
          onClose={onCloseCreateAnnouncedDialog}
          onSuccess={handleSuccessAnnouncedDialog}
          classes={classes}
          planning={planning}
        />
      );
    default:
      return "";
  }
}
