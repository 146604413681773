import { useSelector } from "react-redux";
import * as consultingServicesAction from "../../redux/store/consulting-services/consultingServices.store";
import * as viVN from "../../language/vi-VN.json";
import { NotificationMessageType } from "../../utils/configuration";
import ShowNotification from "../../components/react-notifications/react-notifications";

export const useAddOrEditConlsultingServicesDialog = (props) => {
  const { data, onSuccess } = props;
  const { langId } = useSelector((state) => state.language);

  const onSubmit = (formData) => {
    if (data) {
      handleEdit(formData);
    } else {
      handleAdd(formData);
    }
  };

  const handleAdd = async (formData) => {
    const { content, colorCode, link, icon1Id, icon2Id } = formData;
    const req = {
      translations: [
        {
          langId,
          content,
        },
      ],
      icon1Id,
      icon2Id,
      colorCode: `#${colorCode}`,
      link,
    };

    const res = await consultingServicesAction.Create(req);
    handleShowNotification(res, viVN.Success.CreateSuccess);
  };

  const handleEdit = async (formData) => {
    const { content, colorCode, link, icon1Id, icon2Id } = formData;

    const req = {
      id: data.translations[0].id,
      coreId: data.translations[0].coreId,
      core: {
        id: data.id,
        icon1Id,
        icon2Id,
        colorCode: `#${colorCode}`,
        link,
      },
      langId,
      content,
    };

    const res = await consultingServicesAction.Update(req);
    handleShowNotification(res, viVN.Success.UpdateSuccess);
  };

  const onDelete = async (id) => {
    const res = await consultingServicesAction.Delete(id);
    handleShowNotification(res, viVN.Success.DeleteSuccess);
  };

  const onDeletePermanant = async (id) => {
    const res = await consultingServicesAction.DeletePermanent(id);
    handleShowNotification(res, viVN.Success.DeleteSuccess);
  };

  const getDetail = async (id) => {
    const res = await consultingServicesAction.GetDetail(id, { langId });
    return res.content;
  };

  const onClearTrash = async () => {
    const res = await consultingServicesAction.EmptyTrash();
    handleShowNotification(res, viVN.Success.DeleteSuccess);
  };

  const handleShowNotification = (res, mess) => {
    if (res.content?.status) {
      ShowNotification(mess, NotificationMessageType.Success);

      onSuccess();
    } else {
      ShowNotification(
        viVN.Errors[res.errorType],
        NotificationMessageType.Error
      );
    }
  };

  return {
    onSubmit,
    onDelete,
    onClearTrash,
    onDeletePermanant,
    getDetail,
  };
};
