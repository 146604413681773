import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import * as configuration from '../../utils/configuration';
import dateformat from 'dateformat';

//--- Material Table
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';

//--- Material Icon
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import RestoreIcon from '@material-ui/icons/Restore';

//--- Material Control
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import { EnhancedTableHead } from '../../components/table/enhanced-table-head';
import { Pagination } from '../../components/table/pagination';
import { pageName } from './types/pageName';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  paper: {
    width: '100%',
    marginBottom: theme.spacing(2),
  },
  tableContainer: {
    maxHeight: window.outerHeight - 365,
  },
  table: {
    minWidth: 750,
  },
}));

const headCells = [
  { id: 'imageId', hideSortIcon: true, label: 'Ảnh' },
  { id: 'pageName', hideSortIcon: false, label: 'Tên trang' },
  { id: 'title', hideSortIcon: false, label: 'Tiêu đề' },
  { id: 'pageDescription', hideSortIcon: false, label: 'Chi tiết' },
  { id: 'createdBy', hideSortIcon: false, label: 'Người tạo' },
  { id: 'createdDate', hideSortIcon: false, label: 'Ngày tạo' },
  { id: 'modifiedBy', hideSortIcon: false, label: 'Người sửa' },
  { id: 'modifiedDate', hideSortIcon: false, label: 'Ngày sửa' },
  { id: 'actions', hideSortIcon: true, label: '' },
];

export default function ListMetaTags(props) {
  const {
    metaTags,
    requestParams,
    onChangeRequest,
    onClickEdit,
    onClickDelete,
  } = props;

  const classes = useStyles();

  const sorting = requestParams.Sorting.split(' ');

  return (
    <div className={classes.root}>
      <Paper className={classes.paper}>
        <TableContainer className={classes.tableContainer}>
          <Table className={classes.table} size='small' stickyHeader>
            <EnhancedTableHead
              headCells={headCells}
              order={sorting.pop()}
              orderBy={sorting.shift()}
              onChangeRequest={onChangeRequest}
            />
            <TableBody>
              {metaTags && !!metaTags?.items?.length ? (
                metaTags.items.map((row, index) => {
                  return (
                    <TableRow hover tabIndex={-1} key={row.id}>
                      <TableCell>
                        <img
                          src={configuration.APIUrlDefault + row.imageFile}
                          onError={(e) =>
                            (e.target.src =
                              process.env.PUBLIC_URL + '/logo.png')
                          }
                          alt={row.title}
                          className='logo'
                        />
                      </TableCell>
                      <TableCell>{pageName[row.pageName]}</TableCell>
                      <TableCell>{row.title}</TableCell>
                      <TableCell>{row.pageDescription}</TableCell>
                      <TableCell>{row.created_by}</TableCell>
                      <TableCell>
                        {dateformat(row.created_date, 'dd/mm/yyyy')}
                      </TableCell>
                      <TableCell>{row.modified_by}</TableCell>
                      <TableCell>
                        {dateformat(row.modified_date, 'dd/mm/yyyy')}
                      </TableCell>
                      <TableCell align='right' className='text-nowrap'>
                        {!requestParams.isDelete && (
                          <Tooltip title='Sửa'>
                            <IconButton
                              aria-label='edit'
                              onClick={() => onClickEdit(row)}>
                              <EditIcon className='text-primary' />
                            </IconButton>
                          </Tooltip>
                        )}
                        <Tooltip
                          title={
                            requestParams.isDelete ? 'Xóa vĩnh viễn' : 'Xóa'
                          }>
                          <IconButton
                            aria-label='delete'
                            onClick={() => onClickDelete(row)}>
                            <DeleteIcon className='text-danger' />
                          </IconButton>
                        </Tooltip>
                        {row.isDelete && (
                          <Tooltip title='Khôi phục'>
                            <IconButton
                              aria-label='restore'
                              //   onClick={() => restoreAction(row.id)}
                            >
                              <RestoreIcon className='text-success' />
                            </IconButton>
                          </Tooltip>
                        )}
                      </TableCell>
                    </TableRow>
                  );
                })
              ) : (
                <TableRow hover tabIndex={-1}>
                  <TableCell colSpan={8} className='text-center'>
                    Không có dữ liệu
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>

        <Pagination
          totalItemCount={metaTags?.totalItemCount || 0}
          rowsPerPage={requestParams.PageSize}
          page={requestParams.PageIndex}
          onPageChange={(value) => onChangeRequest('PageIndex', value)}
          onRowsPerPageChange={(value) => onChangeRequest('PageSize', value)}
        />
      </Paper>
    </div>
  );
}
