import Service from "../../../api/api-service";
import { ApiUrl } from "../../../api/api-url";

const service = new Service();

export const userRegisterSumary = () => {
    return service
      .get(`${ApiUrl.UserRegisterSumary}`)
      .then((res) => {
        return res;
      })
      .catch((err) => {
        throw err;
      });
  };
  export const userExternalLoginType = () => {
    return service
      .get(`${ApiUrl.UserExternalLoginType}`)
      .then((res) => {
        return res;
      })
      .catch((err) => {
        throw err;
      });
  };
  export const userRegisterReport = (param) => {
    return service
      .get(`${ApiUrl.UserRegisterReport}`+ param)
      .then((res) => {
        return res;
      })
      .catch((err) => {
        throw err;
      });
  };

  export const reportGetListPlanning = (param) => {
    return service
      .get(`${ApiUrl.reportGetListPlanning}`+ param)
      .then((res) => {
        return res;
      })
      .catch((err) => {
        throw err;
      });
  };
  export const reportPostsGetListAll = (param) => {
    return service
      .get(`${ApiUrl.Posts_GetListAll}`+ param)
      .then((res) => {
        return res;
      })
      .catch((err) => {
        throw err;
      });
  };
  export const classifiedWithRealEstateType = (param) => {
    return service
      .get(`${ApiUrl.ClassifiedWithRealEstateType}`+param)
      .then((res) => {
        return res;
      })
      .catch((err) => {
        throw err;
      });
  };


  export const reportClassifiedSumary = (param) => {
    return service
      .get(`${ApiUrl.reportClassifiedSumary}`+ param)
      .then((res) => {
        return res;
      })
      .catch((err) => {
        throw err;
      });
  };