import React, { useState } from 'react';
import { useForm } from 'react-hook-form';

//--- Material Control
import {
  DialogActions,
  Button,
  TextField,
  DialogContent,
  DialogTitle,
  Dialog,
  Typography,
  IconButton,
  makeStyles,
  Checkbox,
} from '@material-ui/core';

//--- Material Icon
import CloseIcon from '@material-ui/icons/Close';
import SaveIcon from '@material-ui/icons/Save';
import ShowNotification from '../../../components/react-notifications/react-notifications';
import { NotificationMessageType } from '../../../utils/configuration';
import * as viVN from '../../../language/vi-VN.json';
//--- Action
import * as FaqActions from '../../../redux/store/faq/faq.store';

const useStyles = makeStyles((theme) => ({
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  formControl: {
    margin: theme.spacing(1),
  },
}));

export default function AddNews(props) {
  const classes = useStyles();

  const {
    isOpen,
    onClose,
    onSuccess,
    GetListAll,
    rowsPerPage,
    setOrder,
    setOrderBy,
    langId,
  } = props;
  const [isActive, setActive] = useState(false);

  const { register, handleSubmit, setError, errors, clearErrors, setValue } =
    useForm({
      mode: 'all',
      reValidateMode: 'onBlur',
    });

  const handleChangeActive = (e) => {
    e.persist();
    setActive(e.target.checked);
  };

  const onSubmit = (data) => {
    const object = {
      translations: [
        {
          langId: langId,
          title: data.title,
          content: data.content,
        },
      ],
      questionAsker: data.questionAsker,
      phoneNumber: data.phoneNumber,
      email: data.email,
      quesAndAnsTypeId: 1,
      isActive: isActive,
    };
    FaqActions.FaqCreate(object)
      .then((res) => {
        setOrder('desc');
        setOrderBy('modifiedDate');
        GetListAll(undefined, langId, 'modifiedDate desc', 1, rowsPerPage);
        onSuccess();
        ShowNotification(
          viVN.Success.NewsAddSuccess,
          NotificationMessageType.Success
        );
      })
      .catch(
        (err) => viVN.Errors[err.errorType],
        NotificationMessageType.Error
      );
    console.log(data);
  };

  return (
    <div>
      <Dialog open={isOpen} onClose={onClose} fullWidth={true} maxWidth='lg'>
        <DialogTitle disableTypography className='border-bottom'>
          <Typography variant='h6'>Thêm FAQ</Typography>
          <IconButton
            aria-label='close'
            className={classes.closeButton}
            onClick={onClose}>
            <CloseIcon />
          </IconButton>
        </DialogTitle>

        <form onSubmit={handleSubmit(onSubmit)} autoComplete='off'>
          <DialogContent className='pt-4 pb-2'>
            <div className='form-group row'>
              <div className='col-12 col-md-4 col-lg-4'>
                <label className='text-dark'>
                  Họ và tên<span className='required'></span>
                </label>
                <TextField
                  name='questionAsker'
                  error={
                    errors.questionAsker &&
                    (errors.questionAsker.type === 'required' ||
                      errors.questionAsker.type === 'maxLength')
                  }
                  fullWidth
                  type='text'
                  className='form-control'
                  inputProps={{ maxLength: 150 }}
                  inputRef={register({ required: true, maxLength: 150 })}
                  inputProps={{ maxLength: 150 }}
                  onChange={(e) => setValue('questionAsker', e.target.value)}
                />
                {errors.questionAsker &&
                  errors.questionAsker.type === 'required' && (
                    <span className='error'>Trường này là bắt buộc</span>
                  )}
                {errors.questionAsker &&
                  errors.questionAsker.type === 'maxLength' && (
                    <span className='error'>
                      Trường này không quá 150 ký tự
                    </span>
                  )}
              </div>
              <div className='col-12 col-md-4 col-lg-4'>
                <label className='text-dark'>
                  Điện thoại<span className='required'></span>
                </label>
                <TextField
                  name='phoneNumber'
                  error={
                    errors.phoneNumber &&
                    (errors.phoneNumber.type === 'required' ||
                      errors.phoneNumber.type === 'maxLength')
                  }
                  fullWidth
                  type='number'
                  className='form-control'
                  inputProps={{ maxLength: 12 }}
                  inputRef={register({
                    required: true,
                    minLength: 6,
                    maxLength: 12,
                  })}
                  inputProps={{ maxLength: 12 }}
                  onChange={(e) => setValue('phoneNumber', e.target.value)}
                />
                {errors.phoneNumber &&
                  errors.phoneNumber.type === 'required' && (
                    <span className='error'>Trường này là bắt buộc(số)</span>
                  )}
                {errors.phoneNumber &&
                  errors.phoneNumber.type === 'maxLength' && (
                    <span className='error'>Trường này không quá 12 số</span>
                  )}
              </div>
              <div className='col-12 col-md-4 col-lg-4'>
                <label className='text-dark'>
                  Email<span className='required'></span>
                </label>
                <TextField
                  name='email'
                  error={
                    errors.email &&
                    (errors.email.type === 'required' ||
                      errors.email.type === 'maxLength')
                  }
                  fullWidth
                  type='email'
                  className='form-control'
                  inputProps={{ maxLength: 150 }}
                  inputRef={register({ required: true, pattern: /^\S+@\S+$/i })}
                  inputProps={{ maxLength: 150 }}
                  onChange={(e) => setValue('email', e.target.value)}
                />
                {errors.email && errors.email.type === 'required' && (
                  <span className='error'>
                    Trường này là bắt buộc (abc@mail.com)
                  </span>
                )}
                {errors.email && errors.email.type === 'maxLength' && (
                  <span className='error'>Trường này không quá 150 ký tự</span>
                )}
              </div>
            </div>

            <div className='form-group'>
              <label className='text-dark'>
                Câu hỏi<span className='required'></span>
              </label>
              <TextField
                name='title'
                error={
                  errors.title &&
                  (errors.title.type === 'required' ||
                    errors.title.type === 'maxLength')
                }
                fullWidth
                type='text'
                className='form-control'
                inputProps={{ maxLength: 150 }}
                inputRef={register({ required: true, maxLength: 150 })}
                inputProps={{ maxLength: 150 }}
                onChange={(e) => setValue('title', e.target.value)}
              />
              {errors.title && errors.title.type === 'required' && (
                <span className='error'>Trường này là bắt buộc</span>
              )}
              {errors.title && errors.title.type === 'maxLength' && (
                <span className='error'>Trường này không quá 150 ký tự</span>
              )}
            </div>
            <div className='form-group'>
              <label className='text-dark'>
                Nội dung<span className='required'></span>
              </label>
              <textarea
                name='content'
                rows='5'
                ref={register({ required: true, maxLength: 1000 })}
                maxLength='1000'
                className={
                  'form-control' +
                  (errors.content && errors.content.type === 'required'
                    ? ' is-invalid'
                    : '')
                }></textarea>
              {errors.content && errors.content.type === 'required' && (
                <span className='error'>Trường này là bắt buộc</span>
              )}
              {errors.content && errors.content.type === 'maxLength' && (
                <span className='error'>Trường này không quá 1000 ký tự</span>
              )}
            </div>
            <div className='form-group'>
              <div className='row'>
                <div className='col-12 col-md-4 col-lg-4'>
                  <label className='text-dark'>Hoạt động</label>
                  <Checkbox
                    checked={isActive}
                    onChange={handleChangeActive}
                    color='primary'
                    inputProps={{ 'aria-label': 'secondary checkbox' }}
                    className='p-0 mt-0 ml-4'
                  />
                </div>
              </div>
            </div>
          </DialogContent>

          <DialogActions className='border-top'>
            <Button
              type='submit'
              onClick={onClose}
              variant='contained'
              startIcon={<CloseIcon />}>
              Hủy
            </Button>
            <Button
              type='submit'
              color='primary'
              variant='contained'
              startIcon={<SaveIcon />}>
              Lưu
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    </div>
  );
}
