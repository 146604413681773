import { useSelector } from "react-redux";
import * as metaTagAction from "../../redux/store/meta-tag/metaTag.store";
import * as viVN from "../../language/vi-VN.json";
import { NotificationMessageType } from "../../utils/configuration";
import ShowNotification from "../../components/react-notifications/react-notifications";
import { useHistory } from "react-router-dom";
import { UrlCollection } from "../../common/url-collection";
export const useAddOrEditMetaTagDialog = (props) => {
  const { metaTag } = props;
  const { langId } = useSelector((state) => state.language);
  const history = useHistory();
  const onSubmit = async(data) => {
    if (metaTag) {
     await handleEditMetaTag(data);
    } else {
      await handleAddMetaTag(data);
    }
  };

  const handleAddMetaTag = async (data) => {
    const {
      imageId,
      metaKeywords,
      pageDescription,
      pageName,
      title,
      metaDescription,
      twitterDescription,
      fbDescription,
      fbImageId,
      twitterImageId,
    } = data;
    const req = {
      translations: [
        {
          langId,
          title,
          pageDescription,
          metaKeywords,
          metaDescription,
          twitterDescription,
          fbDescription,
        },
      ],
      imageId,
      fbImageId,
      twitterImageId,
      pageName,
    };

    const res = await metaTagAction.CreateMetaTag(req);
    history.push(UrlCollection.MetaTag)
    handleShowNotification(res, viVN.Success.CreateSuccess);
  };

  const handleEditMetaTag = async (data) => {
    const {
      imageId,
      metaKeywords,
      pageDescription,
      pageName,
      title,
      metaDescription,
      twitterDescription,
      fbDescription,
      fbImageId,
      twitterImageId,
    } = data;

    const req = {
      title,
      pageDescription,
      metaKeywords,
      metaDescription,
      id: metaTag.id,
      coreId: metaTag.id,
      langId,
      twitterDescription,
      fbDescription,
      core: {
        id: metaTag.id,
        imageId,
        pageName,
        fbImageId,
        twitterImageId,
      },
    };

    const res = await metaTagAction.UpdateMetaTag(req);
    handleShowNotification(res, viVN.Success.UpdateSuccess);
  };

  const onDeleteMetaTag = async (metatagId) => {
    const res = await metaTagAction.DeleteMetaTag(metatagId);
    handleShowNotification(res, viVN.Success.DeleteSuccess);
  };

  const onDeletePermanant = async (metatagId) => {
    const res = await metaTagAction.DeletePermanent(metatagId);
    handleShowNotification(res, viVN.Success.DeleteSuccess);
  };

  const onClearTrash = async () => {
    const res = await metaTagAction.EmptyTrash();
    handleShowNotification(res, viVN.Success.DeleteSuccess);
  };

  const handleShowNotification = (res, mess) => {
    if (res.content?.status) {
      ShowNotification(mess, NotificationMessageType.Success);
    } else {
      ShowNotification(
        viVN.Errors[res.errorType],
        NotificationMessageType.Error
      );
    }
  };

  return {
    onSubmit,
    onDeleteMetaTag,
    onClearTrash,
    onDeletePermanant,
  };
};
