import React from 'react';
import {
  TextField
} from '@material-ui/core';
import { Controller, useFormContext } from 'react-hook-form';
import './styles.scss';

const FormInput = ({
  title,
  name,
  validate,
  placeholder = 'Chọn...',
  type,
  className = 'form-control',
  titleClassName = 'text-dark',
  InputProps = {
    readOnly: false,
  },
  ...rest
}) => {
  const { control, errors } = useFormContext();
  return (
    <>
      <Controller
        control={control}
        name={name}
        rules={validate}
        placeholder={placeholder}
        render={({ onChange, name, onBlur, ref, value }) => (
          <>
            <label className={titleClassName}>
              {title}{validate?.required ? <span className='required'></span> : null}
            </label>
            <TextField
              type={type}
              onChange={e => onChange(e)}
              placeholder={placeholder}
              value={value}
              ref={ref}
              onBlur={onBlur}
              name={name}
              fullWidth
              className={className}
              InputProps={InputProps}
              {...rest}
            />
            {validate && errors[name] ? (
              <span className='error'>{errors[name] && errors[name].message}</span>
            ) : null}
          </>
        )
        }
      />
    </>
  );
};

export default FormInput;
