import React from "react";
import "./open-layer.scss";
import "ol/ol.css";
import { Map, View } from "ol";
import TileLayer from "ol/layer/Tile";
import OSM from "ol/source/OSM";
import XYZ from "ol/source/XYZ";
import VectorLayer from "ol/layer/Vector";
import VectorSource from "ol/source/Vector";
import Feature from "ol/Feature";
import Point from "ol/geom/Point";
import MapBrowserEvent from "ol/MapBrowserEvent";
import { Circle as CircleStyle, Fill, Stroke, Style, Icon } from "ol/style";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as OpenlayerStore from "../../redux/store/openlayer/openlayer.store";
import * as config from "../../utils/configuration"

class OpenlayerMapView extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      map: null,
      mapContainerStyle: null,
    };
  }

  componentDidMount() {
    const DefaultGroupLayer = [];
    DefaultGroupLayer.push(
      new TileLayer({
        source: new XYZ({
          url: 'https://mt0.google.com/vt/lyrs=m&hl=vi&x={x}&y={y}&z={z}',
        }),
      })
    );

    if (DefaultGroupLayer.length === 0) {
      DefaultGroupLayer.push(
        new TileLayer({
          source: new OSM(),
          visible: false,
        })
      );
    }
    const anchorXUnits = 'fraction';
    const anchorYUnits = 'pixels';

    const PinMarkerLayer = new VectorLayer({
      zIndex: 9999,
      className: 'pin-marker-vector-layer',
      source: new VectorSource({
        features: []
      }),
      style: new Style({
        image: new Icon({
          anchor: [0.5, 22],
          anchorXUnits: anchorXUnits,
          anchorYUnits: anchorYUnits,
          src: 'assets/icon/pin-home60x60.png'
        })
      })
    });
    DefaultGroupLayer.push(PinMarkerLayer);

    const defaultZoom = 5.5;
    const defaultMaxZoom = this.props.maxZoom || 24;
    const defaultMinZoom = this.props.minZoom || 1;
    const defaultProjection = this.props.projection || 'EPSG:4326';
    const defaultMapCenter = config.defaultMapCenter;

    const defaultExtend = this.props.extent || [
      config.defaultLeftCornerLng,
      config.defaultLeftCornerLat,
      config.defaultRightCornerLng,
      config.defaultRightCornerLat
    ];

    // this.props.SetOutSideHandleFunction(this.outSideHandleFunction);
    let mapOpenLayer = new Map({
      target: "map-container-id",
      layers: DefaultGroupLayer,
      view: new View({
        projection: defaultProjection,
        zoom: defaultZoom,
        minZoom: defaultMinZoom,
        maxZoom: defaultMaxZoom,
        extent: defaultExtend,
        center: defaultMapCenter
      }),
    });

    mapOpenLayer.on('singleclick', this.functionClickPinMarker_PinMarkerMode);
    this.setState({
      map: mapOpenLayer,
    });
    const openlayerHandleFunctionList = {
      setMapCenter: this.setMapCenter,
      setMapZoom: this.setMapZoom,
      unpinFeature: this.unpinFeature,
    };
    this.props.CreateMapController(openlayerHandleFunctionList);
  }

  setMapCenter = (coordinates) => {
    if (this.state.map) {
      const view = this.state.map.getView();
      view.setCenter(coordinates);
    }
  };

  setMapZoom = (zoomLevel) => {
    if (this.state.map) {
      const view = this.state.map.getView();
      view.setZoom(zoomLevel);
    }
  };

  handlePinMarkerMode = isTurnOn => {
    console.log('fdkshgkj')
    if (this.state.map) {
      const LayersCurrents = this.state.map.getLayers();
      const openlayerMap = this.state.map;
      const anchorXUnits = 'fraction';
      const anchorYUnits = 'pixels';
      if (isTurnOn) {
        const PinMarkerLayer = new VectorLayer({
          zIndex: 9999,
          className: 'pin-marker-vector-layer',
          source: new VectorSource({
            features: []
          }),
          style: new Style({
            image: new Icon({
              anchor: [0.5, 22],
              anchorXUnits: anchorXUnits,
              anchorYUnits: anchorYUnits,
              src: 'assets/icon/pin-home60x60.png'
            })
          })
        });
        LayersCurrents.push(PinMarkerLayer);
        openlayerMap.on('singleclick', this.functionClickPinMarker_PinMarkerMode);
      } else {
        let layerIndex = -1;
        LayersCurrents.forEach((layerObject, index) => {
          if (layerObject.getClassName() === 'pin-marker-vector-layer') {
            layerIndex = index;
            return;
          }
        });
        if (layerIndex != -1) LayersCurrents.removeAt(layerIndex);
        openlayerMap.un('singleclick', this.functionClickPinMarker_PinMarkerMode);
      }
    }
  };

  functionClickPinMarker_PinMarkerMode = event => {
    if (this.state.map) {
      console.log('event.coordinate', event.coordinate)
      this.props.setCoordinate(event.coordinate);
      const LayersCurrents = this.state.map.getLayers();
      LayersCurrents.forEach(layerObject => {
        if (layerObject.getClassName() === 'pin-marker-vector-layer') {
          const newMarker = new Feature({
            type: 'icon',
            geometry: new Point(event.coordinate)
          });
          let markerSrc = require('../../assets/icon/red-marker.png');

          newMarker.setStyle(
            new Style({
              image: new Icon({
                scale: 1,
                anchor: [0.5, 28],
                anchorXUnits: 'fraction',
                anchorYUnits: 'pixels',
                opacity: 1,
                src: markerSrc
              }),
            })
          );
          this.unpinFeature('pin-marker-vector-layer');

          layerObject.getSource().addFeature(newMarker);
          return;
        }
      });
    }
  };

  unpinFeature = () => {
    if (this.state.map) {
      const LayersCurrents = this.state.map.getLayers();
      LayersCurrents.forEach(layerObject => {
        if (layerObject.getClassName() === 'pin-marker-vector-layer') {
          layerObject.getSource().clear();
          return;
        }
      });
    }
  }

  render() {
    return (
      <div className="h-100" style={{ minHeight: '500px' }} id="map-container-id">
        {/* <MapToolPanel map={this.state.map} /> */}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  mapSetting: state.initMap.mapSetting,
  baseMaps: state.initMap.baseMaps,
  layers: state.initMap.layers,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      SetOutSideHandleFunction: OpenlayerStore.SetHandleOutsideFunction,
      CreateMapController: OpenlayerStore.CreateMapController,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(OpenlayerMapView);
