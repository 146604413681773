import React from "react";
import { TablePagination } from "@material-ui/core";
import { Configs } from "../../common/config";

export const Pagination = (props) => {
  const {
    totalItemCount,
    rowsPerPage,
    page,
    onPageChange,
    onRowsPerPageChange,
  } = props;

  if (!totalItemCount) return null;

  const handleRowsPerPageChange = (event) =>
    onRowsPerPageChange(event.target.value);

  const handlePageChange = (_, newPage) => onPageChange(newPage);

  return (
    <TablePagination
      rowsPerPageOptions={Configs.DefaultPageSizeOption}
      component="div"
      count={totalItemCount}
      rowsPerPage={rowsPerPage}
      page={page}
      onPageChange={handlePageChange}
      onRowsPerPageChange={handleRowsPerPageChange}
      labelRowsPerPage={"Số hàng mỗi trang"}
      labelDisplayedRows={({ from, to, count }) => {
        return "" + from + "-" + to + " trong " + count;
      }}
    />
  );
};
