/* eslint-disable no-sparse-arrays */
import React, { useEffect, useState } from "react";
import PeopleIcon from "@material-ui/icons/People";
import ShoppingCartIcon from "@material-ui/icons/ShoppingCart";
import MonetizationOnIcon from "@material-ui/icons/MonetizationOn";
import EmailIcon from "@material-ui/icons/Email";
import "./home.scss";
import * as homeActions from "../../redux/store/home/home.store";
import ChartPie from "./ChartPie";
import ChartColumn from "./ChartLine";
import DateFnsUtils from "@date-io/date-fns";
import viLocale from "date-fns/locale/vi";
import dateformat from "dateformat";
import {
  DatePicker,
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import { Select, MenuItem } from "@material-ui/core";
import moment from "moment";
import TablePlanning from "./Planning/TablePlanning";
import TableNews from "./Planning/TableNews";
import ChartPieNews from "./ChartPieNews";
const listDay = [
  {
    id: 1,
    value: "7days",
    label: "7 ngày qua",
  },
  {
    id: 2,
    value: "15days",
    label: "15 ngày qua",
  },
  {
    id: 3,
    value: "30days",
    label: "30 ngày qua",
  },
  ,
  {
    id: 4,
    value: "thangnay",
    label: "Tháng này",
  },
  {
    id: 5,
    value: "thangtruoc",
    label: "Tháng trước",
  },
  {
    id: 6,
    value: "namnay",
    label: "Năm nay",
  },
  {
    id: 7,
    value: "tuychon",
    label: "Tuỳ chọn",
  },
];
const dataSearchPlanning = [
  {
    id: 1,
    title: "Quy hoạch được xem nhiều nhất",
    label: "Được xem nhiều nhất",
    value: "viewCount desc"
  },
  {
    id: 2,
    title: "Quy hoạch được xem ít nhất",
    label: "Được xem ít nhất",
    value: "viewCount asc"
  },
  {
    id: 3,
    title: "Quy hoạch mới nhất",
    label: "Mới nhất",
    value: "createdDate desc"
  },
  {
    id: 4,
    title: "Quy hoạch cũ nhất",
    label: "Cũ nhất",
    value: "createdDate asc"
  }
]
const dataSearchNews = [
  {
    id: 1,
    title: "Tin tức được xem nhiều nhất",
    label: "Được xem nhiều nhất",
    value: "viewCount desc"
  },
  {
    id: 2,
    title: "Tin tức được xem ít nhất",
    label: "Được xem ít nhất",
    value: "viewCount asc"
  },
  {
    id: 3,
    title: "Tin tức mới nhất",
    label: "Mới nhất",
    value: "createdDate desc"
  },
  {
    id: 4,
    title: "Tin tức cũ nhất",
    label: "Cũ nhất",
    value: "createdDate asc"
  }
]

function HomePage() {
  const [typeTime, setTypeTime] = useState(listDay[0]);
  const [typeTimeNew, setTypeTimeNew] = useState(listDay[0]);
  const [selectPlanning, setSelectPlanning] = useState(dataSearchPlanning[0]);
  const [selectNews, setSelectNews] = useState(dataSearchNews[0]);
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [startDateNew, setStartDateNew] = useState(new Date());
  const [endDateNew, setEndDateNew] = useState(new Date());
  const [typeChart, setTypeChart] = useState("column");
  const [typeChartNew, setTypeChartNew] = useState("column");
  const [userSumary, setUserSumary] = useState([]);
  const [userLoginType, setUserLoginType] = useState([]);
  const [userRegisterReport, setUserRegisterReport] = useState([]);
  const [userClassifiedReport, setUserClassifiedReport] = useState([]);
  const [plannings, setPlannings] = useState([]);
  const [news, setNews] = useState([]);
  const [classifiedWithRealEstateType, setClassifiedWithRealEstateType] = useState([]);
  useEffect(() => {
    homeActions.userRegisterSumary().then((res) => {
      setUserSumary(res.content);
    });
    homeActions.userExternalLoginType().then((res) => {
      setUserLoginType(res.content);
    });
  }, []);
  useEffect(() => {
    let param = `?reportDayType=${typeTime.id}`;
    if (typeTime.id === 7) {
      param += `&fromDate=${moment(new Date(startDate)).format(
        "DD-MM-YYYY"
      )}&toDate=${moment(new Date(endDate)).format("DD-MM-YYYY")}`;
    }
    homeActions.userRegisterReport(param).then((res) => {
      setUserRegisterReport(res.content);
    });
  }, [typeTime, startDate, endDate]);
  useEffect(() => {
    let param = `?reportDayType=${typeTimeNew.id}`;
    if (typeTimeNew.id === 7) {
      param += `&fromDate=${moment(new Date(startDateNew)).format(
        "DD-MM-YYYY"
      )}&toDate=${moment(new Date(endDateNew)).format("DD-MM-YYYY")}`;
    }
    homeActions.reportClassifiedSumary(param).then((res) => {
      setUserClassifiedReport(res.content);
    });
    homeActions.classifiedWithRealEstateType(param).then((res) => {
      setClassifiedWithRealEstateType(res.content);
    });
  }, [typeTimeNew, startDateNew, endDateNew]);
 
  useEffect(() => {
    let param = `?pageIndex=1&pageSize=20&SortExpression=${selectPlanning.value}`;
    homeActions.reportGetListPlanning(param).then((res) => {
      setPlannings(res.content.items);
    });
  }, [selectPlanning]);
  useEffect(() => {
    let param = `?pageIndex=1&pageSize=20&Sorting=${selectNews.value}`;
    homeActions.reportPostsGetListAll(param).then((res) => {
      setNews(res.content.items);
    });
  }, [selectNews]);
 
  return (
    <div className="home-container">
      <div className="d-sm-flex align-items-center justify-content-between mb-4">
        <h1 className="h3 mb-0 text-gray-800">Trang chủ</h1>
      </div>
      <div className="row">
        <div className="col-xl-2 h-100 ">
          <div className="card shadow h-100 py-2">
            <div className="card-body">
              <div className="row no-gutters align-items-center">
                <div className="col ">
                  <div className="d-flex justify-content-between align-items-center text-xs font-weight-bold text-primary  mb-1">
                    <h6>Tổng User</h6>
                    <div>
                      <PeopleIcon />
                    </div>
                  </div>
                  <div className="row mt-2 mb-2">
                    <div className="col">
                      <div
                        className="progress progress-sm"
                        style={{ height: "5px" }}
                      >
                        <div
                          className="progress-bar bg-primary"
                          role="progressbar"
                          style={{ width: "50%" }}
                          aria-valuenow="50"
                          aria-valuemin="0"
                          aria-valuemax="100"
                        ></div>
                      </div>
                    </div>
                  </div>
                  <div className=" font-weight-bold text-primary  d-flex justify-content-between align-items-center mb-0 ">
                    <h6>{userSumary[0]?.length > 0 ? userSumary[0][1] : 0}</h6>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-xl-10">
          <div className="row">
            <div className="col-xl-3 col-md-6 mb-4">
              <div className="card shadow h-100 py-2">
                <div className="card-body">
                  <div className="row no-gutters align-items-center">
                    <div className="col">
                      <div className="d-flex justify-content-between align-items-center text-xs font-weight-bold text-success mb-1">
                        <h6>
                          Năm nay:{" "}
                          {userSumary[1]?.length > 0 ? userSumary[1][1] : 0}
                        </h6>
                        <div>
                          <PeopleIcon />
                        </div>
                      </div>
                      <div className="row mt-2 mb-2">
                        <div className="col">
                          <div
                            className="progress progress-sm "
                            style={{ height: "5px" }}
                          >
                            <div
                              className="progress-bar bg-success"
                              role="progressbar"
                              style={{ width: "50%" }}
                              aria-valuenow="50"
                              aria-valuemin="0"
                              aria-valuemax="100"
                            ></div>
                          </div>
                        </div>
                      </div>
                      <div className="d-flex justify-content-between align-items-center mb-0 ">
                        <div className="sub-text">Năm trước</div>
                        <div className="sub-text">
                          {userSumary[1]?.length > 0 ? userSumary[1][3] : 0}
                        </div>
                      </div>
                    </div>
                    <div className="col-auto">
                      <i className="fas fa-dollar-sign fa-2x text-gray-300"></i>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-xl-3 col-md-6 mb-4">
              <div className="card shadow h-100 py-2">
                <div className="card-body">
                  <div className="row no-gutters align-items-center">
                    <div className="col ">
                      <div className="d-flex justify-content-between align-items-center text-xs font-weight-bold text-info  mb-1">
                        <h6>
                          Tháng này:{" "}
                          {userSumary[2]?.length > 0 ? userSumary[2][1] : 0}
                        </h6>
                        <div>
                          <PeopleIcon />
                        </div>
                      </div>

                      <div className="row mt-2 mb-2">
                        <div className="col">
                          <div
                            className="progress progress-sm "
                            style={{ height: "5px" }}
                          >
                            <div
                              className="progress-bar bg-info"
                              role="progressbar"
                              style={{ width: "50%" }}
                              aria-valuenow="50"
                              aria-valuemin="0"
                              aria-valuemax="100"
                            ></div>
                          </div>
                        </div>
                      </div>
                      <div className="d-flex justify-content-between align-items-center mb-0 ">
                        <div className="sub-text">Tháng trước</div>
                        <div className="sub-text">
                          {userSumary[2]?.length > 0 ? userSumary[2][3] : 0}
                        </div>
                      </div>
                    </div>
                    <div className="col-auto">
                      <i className="fas fa-clipboard-list fa-2x text-gray-300"></i>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-xl-3 col-md-6 mb-4">
              <div className="card shadow h-100 py-2">
                <div className="card-body">
                  <div className="row no-gutters align-items-center">
                    <div className="col ">
                      <div className="d-flex justify-content-between align-items-center text-xs font-weight-bold text-warning  mb-1">
                        <h6>
                          Tuần này :
                          {userSumary[3]?.length > 0 ? userSumary[3][1] : 0}
                        </h6>
                        <div>
                          <PeopleIcon />
                        </div>
                      </div>
                      <div className="row mt-2 mb-2">
                        <div className="col">
                          <div
                            className="progress progress-sm"
                            style={{ height: "5px" }}
                          >
                            <div
                              className="progress-bar bg-warning"
                              role="progressbar"
                              style={{ width: "50%" }}
                              aria-valuenow="50"
                              aria-valuemin="0"
                              aria-valuemax="100"
                            ></div>
                          </div>
                        </div>
                      </div>
                      <div className="d-flex justify-content-between align-items-center mb-0 ">
                        <div className="sub-text">Tuần trước</div>
                        <div className="sub-text">
                          {userSumary[3]?.length > 0 ? userSumary[3][3] : 0}
                        </div>
                      </div>
                    </div>
                    <div className="col-auto">
                      <i className="fas fa-comments fa-2x text-gray-300"></i>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-xl-3 col-md-6 mb-4">
              <div className="card shadow h-100 py-2">
                <div className="card-body">
                  <div className="row no-gutters align-items-center">
                    <div className="col ">
                      <div className="d-flex justify-content-between align-items-center text-xs font-weight-bold text-danger  mb-1">
                        <h6>
                          Hôm nay :
                          {userSumary[4]?.length > 0 ? userSumary[4][1] : 0}
                        </h6>
                        <div>
                          <PeopleIcon />
                        </div>
                      </div>
                      <div className="row mt-2 mb-2">
                        <div className="col">
                          <div
                            className="progress progress-sm"
                            style={{ height: "5px" }}
                          >
                            <div
                              className="progress-bar bg-danger"
                              role="progressbar"
                              style={{ width: "50%" }}
                              aria-valuenow="50"
                              aria-valuemin="0"
                              aria-valuemax="100"
                            ></div>
                          </div>
                        </div>
                      </div>
                      <div className="d-flex justify-content-between align-items-center mb-0 ">
                        <div className="sub-text">Hôm qua</div>
                        <div className="sub-text">
                          {userSumary[4]?.length > 0 ? userSumary[4][3] : 0}
                        </div>
                      </div>
                    </div>
                    <div className="col-auto">
                      <i className="fas fa-comments fa-2x text-gray-300"></i>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-lg-8">
          <div className="card shadow">
            <div className="card-boby">
              <div class="card-body">
                <h5
                  class="card-title"
                  style={{ color: "black", fontWeight: 600, paddingBottom: 20 }}
                >
                  Thống kê số lượng User
                </h5>
                <div>
                  <div className=" d-flex justify-content-between align-items-center content-select pb-5 pt-1">
                    <div className="d-flex content-select-left">
                      <Select
                        name="status"
                        className=""
                        style={{ marginRight: 20, minWidth: 130 }}
                        variant="outlined"
                        defaultValue={listDay[0]}
                        onChange={(e) => setTypeTime(e.target.value)}
                        placeholder="Trạng thái"
                      >
                        {listDay.map((item) => (
                          <MenuItem key={item.value} value={item}>
                            {item.label}
                          </MenuItem>
                        ))}
                      </Select>
                      {typeTime.id === 7 ? (
                        <MuiPickersUtilsProvider
                          utils={DateFnsUtils}
                          locale={viLocale}
                        >
                          <KeyboardDatePicker
                            disableToolbar
                            disabled={typeTime.id !== 7}
                            inputVariant="outlined"
                            format="dd/MM/yyyy"
                            value={startDate}
                            onChange={(date) => setStartDate(date)}
                            margin="normal"
                            id="date-picker-inline"
                            label="Từ ngày"
                            KeyboardButtonProps={{
                              "aria-label": "change date",
                            }}
                          />
                          <KeyboardDatePicker
                            disableToolbar
                            inputVariant="outlined"
                            format="dd/MM/yyyy"
                            value={endDate}
                            onChange={(date) => setEndDate(date)}
                            disabled={typeTime.id !== 7}
                            margin="normal"
                            style={{ margin: "0px 20px" }}
                            id="date-picker-inline"
                            label="Đến ngày"
                            KeyboardButtonProps={{
                              "aria-label": "change date",
                            }}
                          />
                        </MuiPickersUtilsProvider>
                      ) : (
                        ""
                      )}
                    </div>
                    <div className="content-select-left">
                      <Select
                        name="status"
                        className="w-100 ml-2"
                        variant="outlined"
                        style={{ minWidth: 100 }}
                        onChange={(e) => setTypeChart(e.target.value)}
                        defaultValue="column"
                        placeholder="Trạng thái"
                      >
                        <MenuItem value="column">Column</MenuItem>
                        <MenuItem value="line">Line</MenuItem>
                      </Select>
                    </div>
                  </div>
                  <ChartColumn
                    userRegisterReport={userRegisterReport}
                    typeChart={typeChart}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-lg-4">
          <div className="card shadow" style={{height: '100%'}}>
            <div className="card-boby" style={{height: '100%'}}>
              <div class="card-body" style={{height: '100%'}}>
                <h5
                  class="card-title"
                  style={{ color: "black", fontWeight: 600 }}
                >
                  User login google, facebook, web
                </h5>
                <div></div>
                <ChartPie userLoginType={userLoginType} />
              </div>
            </div>
          </div>
        </div>
      </div>

   
      <div className="row mt-4">
        <div className="col-lg-8">
          <div className="card shadow">
            <div className="card-boby">
              <div class="card-body">
                <div className="d-flex justify-content-between align-items-center header-card">
                  <h5
                    class="card-title"
                    style={{
                      color: "black",
                      fontWeight: 600,
                      margin: 0,
                    }}
                  >
                    {selectPlanning.title}
                  </h5>
                  <Select
                        name="status"
                        className=""
                        style={{ marginRight: 20, minWidth:200 }}
                        variant="outlined"
                        defaultValue={dataSearchPlanning[0]}
                        value={selectPlanning}
                        onChange={(e) => setSelectPlanning(e.target.value)}
                      >
                        {dataSearchPlanning.map((item) => (
                          <MenuItem key={item.id} value={item}>
                            {item.label}
                          </MenuItem>
                        ))}
                      </Select>
                </div>
                <div>
                  <div className=" d-flex justify-content-between align-items-center content-select mt-3 pt-1">
                  <TablePlanning  plannings={plannings}/>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-lg-4">
          <div className="card shadow" style={{height: '100%'}}>
            <div className="card-boby" style={{height: '100%'}}>
              <div class="card-body" style={{height: '100%'}}>
               
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="row mt-4">
        <div className="col-lg-8">
          <div className="card shadow">
            <div className="card-boby">
              <div class="card-body">
                <h5
                  class="card-title"
                  style={{ color: "black", fontWeight: 600, paddingBottom: 20 }}
                >
                  Thống kê số lượng tin mua bán
                </h5>
                <div>
                  <div className=" d-flex justify-content-between align-items-center content-select pb-5 pt-1">
                    <div className="d-flex content-select-left">
                      <Select
                        name="status"
                        className=""
                        style={{ marginRight: 20, minWidth: 130 }}
                        variant="outlined"
                        defaultValue={listDay[0]}
                        onChange={(e) => setTypeTimeNew(e.target.value)}
                        placeholder="Trạng thái"
                      >
                        {listDay.map((item) => (
                          <MenuItem key={item.value} value={item}>
                            {item.label}
                          </MenuItem>
                        ))}
                      </Select>
                      {typeTimeNew.id === 7 ? (
                        <MuiPickersUtilsProvider
                          utils={DateFnsUtils}
                          locale={viLocale}
                        >
                          <KeyboardDatePicker
                            disableToolbar
                            disabled={typeTimeNew.id !== 7}
                            inputVariant="outlined"
                            format="dd/MM/yyyy"
                            value={startDateNew}
                            onChange={(date) => setStartDateNew(date)}
                            margin="normal"
                            id="date-picker-inline"
                            label="Từ ngày"
                            KeyboardButtonProps={{
                              "aria-label": "change date",
                            }}
                          />
                          <KeyboardDatePicker
                            disableToolbar
                            inputVariant="outlined"
                            format="dd/MM/yyyy"
                            value={endDateNew}
                            onChange={(date) => setEndDateNew(date)}
                            disabled={typeTimeNew.id !== 7}
                            margin="normal"
                            style={{ margin: "0px 20px" }}
                            id="date-picker-inline"
                            label="Đến ngày"
                            KeyboardButtonProps={{
                              "aria-label": "change date",
                            }}
                          />
                        </MuiPickersUtilsProvider>
                      ) : (
                        ""
                      )}
                    </div>
                    <div className="content-select-left">
                      <Select
                        name="status"
                        className="w-100 ml-2"
                        variant="outlined"
                        style={{ minWidth: 100 }}
                        onChange={(e) => setTypeChartNew(e.target.value)}
                        defaultValue="column"
                        placeholder="Trạng thái"
                      >
                        <MenuItem value="column">Column</MenuItem>
                        <MenuItem value="line">Line</MenuItem>
                      </Select>
                    </div>
                  </div>
                  <ChartColumn
                    userRegisterReport={userClassifiedReport}
                    typeChart={typeChartNew}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-lg-4">
          <div className="card shadow" style={{height: '100%'}}>
            <div className="card-boby" style={{height: '100%'}}>
              <div class="card-body" style={{height: '100%'}}>
              <h5
                  class="card-title"
                  style={{ color: "black", fontWeight: 600 }}
                >
                  Số lượng tin mua bán theo loại BĐS
                </h5>
                <div></div>
                <ChartPieNews userLoginType={classifiedWithRealEstateType} />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="row mt-4">
        <div className="col-lg-8">
          <div className="card shadow" >
            <div className="card-boby" >
              <div class="card-body" >
                <div className="d-flex justify-content-between align-items-center header-card">
                  <h5
                    class="card-title"
                    style={{
                      color: "black",
                      fontWeight: 600,
                      margin: 0,
                    }}
                  >
                    {selectNews.title}
                  </h5>
                  <Select
                        name="status"
                        className=""
                        style={{ marginRight: 20, minWidth:200 }}
                        variant="outlined"
                        defaultValue={dataSearchNews[0]}
                        value={selectNews}
                        onChange={(e) => setSelectNews(e.target.value)}
                      >
                        {dataSearchNews.map((item) => (
                          <MenuItem key={item.id} value={item}>
                            {item.label}
                          </MenuItem>
                        ))}
                      </Select>
                </div>
                <div>
                  <div className=" d-flex justify-content-between align-items-center content-select mt-3 pt-1">
                  <TableNews  plannings={news}/>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-lg-4">
          <div className="card shadow" style={{height: '100%'}}>
            <div className="card-boby" style={{height: '100%'}}>
              <div class="card-body" style={{height: '100%'}}>
               
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
export default HomePage;
