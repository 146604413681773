import React, { useState, useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

//--- Material Control
import {
  DialogActions,
  Button,
  DialogContent,
  DialogTitle,
  Dialog,
  Typography,
  IconButton,
  makeStyles,
  Grid,
} from '@material-ui/core';

//--- Material Icon
import CloseIcon from '@material-ui/icons/Close';
import SaveIcon from '@material-ui/icons/Save';

import 'suneditor/dist/css/suneditor.min.css';

import * as viVN from '../../../../language/vi-VN.json';
import ShowNotification from '../../../react-notifications/react-notifications';
import { NotificationMessageType } from '../../../../utils/configuration';

//--- Action
import * as addressAction from '../../../../redux/store/address/address.store';
import * as districtAction from '../../../../redux/store/district-management/district.store';
import * as communeAction from '../../../../redux/store/commune-management/commune.store';
import * as provinceAction from '../../../../redux/store/province-management/province.store';

import AddressFormItems from './components/form-items';

const useStyles = makeStyles((theme) => ({
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  formControl: {
    margin: theme.spacing(1),
  },
}));

function AddAddressStandAlone(props) {
  const {
    isOpen,
    onClose,
    onSuccess,
    langId,
    setFieldValue,
    fieldName,
    //setAddresId,
  } = props;
  const [choosePlace, setChoosePlace] = useState(null);
  const classes = useStyles();

  const methods = useForm({
    defaultValues: {
      commune: null,
      latitude: '',
      longitude: '',
      street: '',
      generalInfor: '',
      room: '',
      city: '',
      township: '',
      province: null,
      district: null,
    },
  });

  const { handleSubmit, setValue, watch } = methods;

  const [communes, setCommunes] = useState();
  const [provinceSelect, setProvinceSelect] = useState();
  const [districtByProvinceId, setDistrictByProvinceId] = useState();
  const [currentDistrictId, setCurrentDistrictId] = useState();
  const [currentCommuneId, setCurrentCommuneId] = useState();
  const [long, setLong] = useState('');
  const [lat, setLat] = useState('');
  const selectedProvince = watch('province');
  const selectedDistrict = watch('district');
  const [coordinate, setCoordinate] = useState([]);


  useEffect(() => {
    Promise.all([GetLookupProvince()])
      .then((res) => {
        const [provinceLookupModels] = res;
        setProvinceSelect(
          provinceLookupModels && provinceLookupModels.content
            ? provinceLookupModels.content
            : []
        );
      })
      .catch((err) => {});
  }, []);

  const GetLookupProvince = () => {
    return new Promise((resolve, reject) => {
      provinceAction.GetLookupProvince().then(
        (res) => {
          resolve(res);
        },
        (err) => {
          reject(err);
          err &&
            err.errorType &&
            ShowNotification(
              viVN.Errors[err.errorType],
              NotificationMessageType.Error
            );
        }
      );
    });
  };

  useEffect(() => {
    if (!selectedProvince) return;
    GetDistrictByProvinceId(selectedProvince.id);
  }, [selectedProvince]);

  const GetDistrictByProvinceId = (provinceId) => {
    if (!provinceId) return;
    return districtAction
      .GetDistrictByProvinceId(provinceId)
      .then((res) => {
        setDistrictByProvinceId(res && res.content ? res.content : []);
        setValue(
          'district',
          currentDistrictId
            ? res.content?.find((item) => item.id === currentDistrictId)
            : null
        );
      })
      .catch((err) => {});
  };

  useEffect(() => {
    if (!selectedDistrict) return;
    Promise.all([GetLookupCommune(selectedDistrict.id)])
      .then((res) => {
        const [communeLookupModels] = res;
        setCommunes(
          communeLookupModels && communeLookupModels.content
            ? communeLookupModels.content
            : []
        );
        setValue(
          'commune',
          currentCommuneId
            ? communeLookupModels.content?.find(
                (item) => item.id === currentCommuneId
              )
            : null
        );
      })
      .catch((err) => {});
  }, [selectedDistrict]);

  const GetLookupCommune = (districtId) => {
    return new Promise((resolve, reject) => {
      communeAction.GetCommunesByDistrictId(districtId).then(
        (res) => {
          resolve(res);
        },
        (err) => {
          reject(err);
          err &&
            err.errorType &&
            ShowNotification(
              viVN.Errors[err.errorType],
              NotificationMessageType.Error
            );
        }
      );
    });
  };

  const onAddAddressSubmit = (formData) => {
    if (coordinate[0]) { //dòng này sử dụng khi bản đồ active
      //if (formData.longitude !== '' && formData.latitude !== '') {
      console.log('coordinate', coordinate, formData)
      let object = {
        translations: [
          {
            langId: langId,
            generalInfor: formData.generalInfor,
            street: formData?.street?.label ? formData.street.label : '',
            city: '',
            township: '',
          },
        ],
        room: formData.room,
        landPriceInforId: 0,
        communeId: formData.commune.communeId,
        longitude: coordinate[0], //dòng này sử dụng khi bản đồ active
        latitude: coordinate[1], //dòng này sử dụng khi bản đồ active
          //longitude: formData.longitude,
          //latitude: formData.latitude,
        street: {
          id: formData?.street?.value ? formData.street.value : 0,
          name: formData?.street?.label ? formData.street.label : '',
        },
      };
      addressAction
        .AddressCreate(object)
        .then((res) => {
          if (res.content) {
            setFieldValue(fieldName, {
              value: res.content.id,
              label: res.content.name,
            });
            //setAddresId(res.content.id);
          }

          onSuccess();
          ShowNotification(
            viVN.Success.NewsAddSuccess,
            NotificationMessageType.Success
          );
        })
        .catch(
          (err) => viVN.Errors[err.errorType],
          NotificationMessageType.Error
        );
    } else {
      ShowNotification(
        viVN.Errors['LatLongValid'],
        NotificationMessageType.Error
      );
    }
  };

  const handleChoose = address => {
    console.log(address)
    if (!address) return
    handleSetMap([address.long, address.lat], 15);
    setValue('commune', address);
  }

  const handleSetMap = (mapCenter, mapZoom) => {
    if (props.mapController) {
      props.mapController.setMapCenter(mapCenter);
      props.mapController.setMapZoom(mapZoom);
    }
  }

  return (
    <div>
      <Dialog open={isOpen} onClose={onClose} fullWidth={true} maxWidth='lg'>
        <DialogTitle disableTypography className='border-bottom'>
          <Typography variant='h6'>Thêm địa chỉ</Typography>
          <IconButton
            aria-label='close'
            className={classes.closeButton}
            onClick={onClose}>
            <CloseIcon />
          </IconButton>
        </DialogTitle>

        <FormProvider {...methods}>
          <form autoComplete='off'>
            <AddressFormItems
              setCoordinate={setCoordinate}
              handleChoose={handleChoose}
            />

            <DialogActions className='border-top'>
              <Button
                type='button'
                onClick={onClose}
                variant='contained'
                startIcon={<CloseIcon />}>
                Hủy
              </Button>
              <Button
                type='button'
                onClick={handleSubmit(onAddAddressSubmit)}
                color='primary'
                variant='contained'
                startIcon={<SaveIcon />}>
                Lưu
              </Button>
            </DialogActions>
          </form>
        </FormProvider>
      </Dialog>
    </div>
  );
}

const mapStateToProps = state => ({
  mapController: state.openLayer.mapController,
})

const mapDispatchToProps = dispatch => bindActionCreators({
}, dispatch)

export default connect(mapStateToProps, mapDispatchToProps)(AddAddressStandAlone)