import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import { Configs } from '../../../common/config';
import dateformat from 'dateformat';

//--- Material Table
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Paper from '@material-ui/core/Paper';
import FooterPagination from '../../../components/footer-pagination/footer-pagination';

//--- Material Icon
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import RestoreIcon from '@material-ui/icons/Restore';

//--- Material Control
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import { convertNumberToCurrency } from '../../../utils/helpers';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  paper: {
    width: '100%',
    marginBottom: theme.spacing(2),
  },
  tableContainer: {
    maxHeight: window.outerHeight - 365,
  },
  table: {
    minWidth: 750,
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
}));

const headCells = [
  { id: 'index', hideSortIcon: false, label: 'STT' },
  { id: 'soilTypeName', hideSortIcon: false, label: 'Loại đất' },
  { id: 'statePrice', hideSortIcon: false, label: 'Giá trung bình nhà nước' },
  { id: 'vt1', hideSortIcon: false, label: 'VT1' },
  { id: 'vt2', hideSortIcon: false, label: 'VT2' },
  { id: 'vt3', hideSortIcon: false, label: 'VT3' },
  { id: 'vt4', hideSortIcon: false, label: 'VT4' },
  { id: 'vt5', hideSortIcon: false, label: 'VT5' },
  { id: 'dateApply', hideSortIcon: false, label: 'Ngày áp dụng' },
  { id: 'isActive', hideSortIcon: false, label: 'Kích hoạt' },
  { id: 'modifiedDate', hideSortIcon: false, label: 'Ngày sửa' },
  { id: 'actions', hideSortIcon: true, label: '' },
];

function EnhancedTableHead(props) {
  const { classes, order, orderBy, onRequestSort } = props;

  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            sortDirection={orderBy === headCell.id ? order : false}
            className='pt-3 pb-3 text-nowrap'>
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
              hideSortIcon={headCell.hideSortIcon ? true : false}>
              {headCell.label}
              {orderBy === headCell.id ? (
                <span className={classes.visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </span>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  classes: PropTypes.object.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

export default function ListLandPriceInfos(props) {
  const {
    editAction,
    deleteAction,
    restoreAction,
    landPriceInfoModels,
    totalItemCount,
    setRequestParams,
    requestParams,
  } = props;

  //--- Config table
  const classes = useStyles();

  //--- Handle sort, change page, change row per page
  const handleRequestSort = (event, property) => {
    const isAsc =
      requestParams.orderBy === property && requestParams.order === 'asc';
    let sort = isAsc ? 'desc' : 'asc';
    setRequestParams((x) => ({
      ...x,
      orderBy: property,
      order: sort,
    }));
  };

  const handleChangePage = (event, newPage) => {
    setRequestParams((x) => ({
      ...x,
      page: newPage,
    }));
  };

  const handleChangeRowsPerPage = (event) => {
    let newRowsPerPage = parseInt(event.target.value, 10);
    setRequestParams((x) => ({
      ...x,
      page: 1,
      rowsPerPage: newRowsPerPage,
    }));
  };

  const emptyRows =
    requestParams.rowsPerPage -
    Math.min(
      requestParams.rowsPerPage,
      landPriceInfoModels.length -
        requestParams.page * requestParams.rowsPerPage
    );

  const totalPage = Math.ceil(totalItemCount / requestParams.rowsPerPage);

  return (
    <div className={classes.root}>
      <Paper className={classes.paper}>
        <TableContainer className={classes.tableContainer}>
          <Table className={classes.table} size='small' stickyHeader>
            <EnhancedTableHead
              classes={classes}
              order={requestParams.order}
              orderBy={requestParams.orderBy}
              onRequestSort={handleRequestSort}
              rowCount={landPriceInfoModels.length}
            />
            <TableBody>
              {landPriceInfoModels && landPriceInfoModels.length > 0 ? (
                landPriceInfoModels.map((row, index) => {
                  return (
                    <TableRow hover tabIndex={-1} key={row.id}>
                      <TableCell className='text-center'>{index + 1}</TableCell>
                      <TableCell className='text-center'>
                        {row.soilTypeName}
                      </TableCell>
                      <TableCell className='text-center'>
                        {convertNumberToCurrency(row.statePrice)}
                      </TableCell>
                      <TableCell className='text-center'>
                        <div className='price blue'>
                          {convertNumberToCurrency(row.stateVT1)}
                        </div>
                      </TableCell>
                      <TableCell className='text-center'>
                        <div className='price blue'>
                          {convertNumberToCurrency(row.stateVT2)}
                        </div>
                      </TableCell>
                      <TableCell className='text-center'>
                        <div className='price blue'>
                          {convertNumberToCurrency(row.stateVT3)}
                        </div>
                      </TableCell>
                      <TableCell className='text-center'>
                        <div className='price blue'>
                          {convertNumberToCurrency(row.stateVT4)}
                        </div>
                      </TableCell>
                      <TableCell className='text-center'>
                        <div className='price blue'>
                          {convertNumberToCurrency(row.stateVT5)}
                        </div>
                      </TableCell>
                      <TableCell>
                        {dateformat(row.dateApply, 'dd/mm/yyyy')}
                      </TableCell>
                      <TableCell className='text-center'>
                        {row.isActive ? (
                          <img
                            src={require('../../../assets/icon/tick.png')}
                            alt='Tick'
                          />
                        ) : (
                          <img
                            src={require('../../../assets/icon/cancel.png')}
                            alt='Cancel'
                          />
                        )}
                      </TableCell>
                      <TableCell>
                        {dateformat(row.modified_date, 'dd/mm/yyyy')}
                      </TableCell>
                      <TableCell align='right' className='text-nowrap'>
                        <Tooltip title='Sửa'>
                          <IconButton
                            aria-label='edit'
                            onClick={() => editAction(row.id)}>
                            <EditIcon className='text-primary' />
                          </IconButton>
                        </Tooltip>
                        <Tooltip title='Xóa'>
                          <IconButton
                            aria-label='delete'
                            onClick={() => deleteAction(row.id)}>
                            <DeleteIcon className='text-danger' />
                          </IconButton>
                        </Tooltip>
                        {row.isDelete ? (
                          <Tooltip title='Khôi phục'>
                            <IconButton
                              aria-label='restore'
                              onClick={() => restoreAction(row.id)}>
                              <RestoreIcon className='text-success' />
                            </IconButton>
                          </Tooltip>
                        ) : (
                          ''
                        )}
                      </TableCell>
                    </TableRow>
                  );
                })
              ) : (
                <TableRow hover tabIndex={-1}>
                  <TableCell colSpan={8} className='text-center'>
                    Không có dữ liệu
                  </TableCell>
                </TableRow>
              )}
              {emptyRows > 0 && (
                <TableRow style={{ height: 0 }}>
                  <TableCell
                    colSpan={8}
                    style={{ padding: 0, borderBottom: 0 }}
                  />
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>

        {totalItemCount && totalItemCount > 0 ? (
          <FooterPagination
            rowsPerPage={requestParams.rowsPerPage}
            handleChangeRowsPerPage={handleChangeRowsPerPage}
            handleChangePage={handleChangePage}
            totalPage={totalPage}
          />
        ) : (
          ''
        )}
      </Paper>
    </div>
  );
}
