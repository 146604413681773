import Service from '../../../api/api-service';
import { ApiUrl } from '../../../api/api-url';
import * as config from '../../../common/config';

const service = new Service();

export const SceneGetListAll = (data) => {
    const params = new URLSearchParams();

    data.keyword && params.append('Keyword', data.keyword);
    data.sorting && params.append('sorting', data.sorting);
    data.pid && params.append('VRProjectId', data.pid);
    params.append('pageIndex', data.pageIndex);
    params.append('pageSize', data.pageSize);

    return service
        .get(ApiUrl.Scene_GetlistAll, params)
        .then((res) => {
            return res;
        })
        .catch((err) => {
            throw err;
        });
};

export const SceneCreate = (body) => {
    return service
        .post(ApiUrl.Scene_Create, body)
        .then((res) => {
            return res;
        })
        .catch((err) => {
            throw err;
        });
};

export const SceneUpdate = (body) => {
    return service
        .post(ApiUrl.Scene_Update, body)
        .then((res) => {
            return res;
        })
        .catch((err) => {
            throw err;
        });
};

export const SceneDelete = (id) => {
    return service
        .post(ApiUrl.Scene_Delete(id))
        .then((res) => {
            return res;
        })
        .catch((err) => {
            throw err;
        });
};

export const SceneGetDetail = (id) => {
    return service
        .get(ApiUrl.Scene_GetDetail(id))
        .then((res) => {
            return res;
        })
        .catch((err) => {
            throw err;
        });
};

export const ConnectionSceneGetListAll = (data) => {
    const params = new URLSearchParams();

    data.keyword && params.append('Keyword', data.keyword);
    data.sorting && params.append('sorting', data.sorting);
    data.sceneId && params.append('sceneId', data.sceneId);
    params.append('pageIndex', data.pageIndex ?? 1);
    params.append('pageSize', data.pageSize ?? 20);

    return service
        .get(ApiUrl.ConnectionScene_GetlistAll, params)
        .then((res) => {
            return res;
        })
        .catch((err) => {
            throw err;
        });
};

export const ConnectionSceneCreate = (body) => {
    return service
        .post(ApiUrl.ConnectionScene_Create, body)
        .then((res) => {
            return res;
        })
        .catch((err) => {
            throw err;
        });
};

export const ConnectionSceneUpdate = (body) => {
    return service
        .post(ApiUrl.ConnectionScene_Update, body)
        .then((res) => {
            return res;
        })
        .catch((err) => {
            throw err;
        });
};

export const ConnectionSceneDelete = (id) => {
    return service
        .post(ApiUrl.ConnectionScene_Delete(id))
        .then((res) => {
            return res;
        })
        .catch((err) => {
            throw err;
        });
};

export const ConnectionSceneGetDetail = (id) => {
    return service
        .get(ApiUrl.ConnectionScene_GetDetail(id))
        .then((res) => {
            return res;
        })
        .catch((err) => {
            throw err;
        });
};

export const HotspotGetListAll = (data) => {
    const params = new URLSearchParams();

    data.sorting && params.append('sorting', data.sorting);
    params.append('pageIndex', data.pageIndex);
    params.append('pageSize', data.pageSize);
    data.pid && params.append('VRProjectId', data.pid);
    data.sceneId && params.append('SceneId', data.sceneId);
    data.keyword && params.append('Keyword', data.keyword);

    return service
        .get(ApiUrl.Hotspot_GetlistAll, params)
        .then((res) => {
            return res;
        })
        .catch((err) => {
            throw err;
        });
};

export const HotspotCreate = (body) => {
    return service
        .post(ApiUrl.Hotspot_Create, body)
        .then((res) => {
            return res;
        })
        .catch((err) => {
            throw err;
        });
};

export const HotspotUpdate = (body) => {
    return service
        .post(ApiUrl.Hotspot_Update, body)
        .then((res) => {
            return res;
        })
        .catch((err) => {
            throw err;
        });
};

export const HotspotActive = (id) => {
    return service
        .post(ApiUrl.Hotspot_Active(id))
        .then((res) => {
            return res;
        })
        .catch((err) => {
            throw err;
        });
};

export const HotspotDeActive = (id) => {
    return service
        .post(ApiUrl.Hotspot_DeActive(id))
        .then((res) => {
            return res;
        })
        .catch((err) => {
            throw err;
        });
};

export const HotspotDelete = (id) => {
    return service
        .post(ApiUrl.Hotspot_Delete(id))
        .then((res) => {
            return res;
        })
        .catch((err) => {
            throw err;
        });
};

export const HotspotGetDetail = (id) => {
    return service
        .get(ApiUrl.Hotspot_GetDetail(id))
        .then((res) => {
            return res;
        })
        .catch((err) => {
            throw err;
        });
};

export const VrProjectGetListAll = (data) => {
    const params = new URLSearchParams();

    data.keyword && params.append('Keyword', data.keyword);
    data.sorting && params.append('sorting', data.sorting);
    params.append('pageIndex', data.pageIndex);
    params.append('pageSize', data.pageSize);

    return service
        .get(ApiUrl.VrProject_GetlistAll, params)
        .then((res) => {
            return res;
        })
        .catch((err) => {
            throw err;
        });
};

export const VrProjectGetLookup = () => {
    return service
        .get(ApiUrl.VRProject_GetLookup)
        .then((res) => {
            return res;
        })
        .catch((err) => {
            throw err;
        });
}

export const VrProjectCreate = (body) => {
    return service
        .post(ApiUrl.VrProject_Create, body)
        .then((res) => {
            return res;
        })
        .catch((err) => {
            throw err;
        });
};

export const VrProjectUpdate = (body) => {
    return service
        .post(ApiUrl.VrProject_Update, body)
        .then((res) => {
            return res;
        })
        .catch((err) => {
            throw err;
        });
};

export const VrProjectDelete = (id) => {
    return service
        .post(ApiUrl.VrProject_Delete(id))
        .then((res) => {
            return res;
        })
        .catch((err) => {
            throw err;
        });
};

export const VrProjectGetDetail = (id) => {
    return service
        .get(ApiUrl.VrProject_GetDetail(id))
        .then((res) => {
            return res;
        })
        .catch((err) => {
            throw err;
        });
};

export const GetLookupVrProject = () => {
    return service
        .get(ApiUrl.VRProject_GetLookup)
        .then((res) => {
            return res;
        })
        .catch((err) => {
            throw err;
        });
};