import Service from "../../../api/api-service";
import { ApiUrl } from "../../../api/api-url";
import * as config from "../../../common/config"

const service = new Service();

export const GetListLandPriceInfo = (pageIndex = 1, pageSize = config.Configs.DefaultPageSize, sortExpression = "", name = "") => {
    const params = new URLSearchParams();
    params.append("pageIndex", pageIndex);
    params.append("pageSize", pageSize);
    sortExpression && params.append("sortExpression", sortExpression);
    name && params.append("name", name.trim());
    return service.get(ApiUrl.LandPriceInfo_GetListAll, params).then((res) => { return res }).catch(err => { throw err });
}

export const GetListAll = (
  langId = 'vi',
  pageIndex = 1,
  pageSize = config.Configs.DefaultPageSize,
  sortExpression = 'modifiedDate desc',
  keyword = undefined,
  isDelete = 0
) => {
  const params = new URLSearchParams();
  params.append('langId', langId);
  params.append('pageIndex', pageIndex);
  params.append('pageSize', pageSize);
  params.append('sorting', sortExpression);
  params.append('keyworld', keyword);
  params.append('isDelete', isDelete);
  return service
    .get(ApiUrl.LandPriceInfo_GetListAll, params)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      throw err;
    });
};

export const LandPriceInfoCreate = (body) => {
  return service
    .post(ApiUrl.LandPriceInfo_Create, body)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      throw err;
    });
};

export const LandPriceInfoUpdate = (body) => {
  return service
    .post(ApiUrl.LandPriceInfo_Update, body)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      throw err;
    });
};

export const LandPriceInfoDelete = (id) => {
  return service
    .post(ApiUrl.LandPriceInfo_Delete(id))
    .then((res) => {
      return res;
    })
    .catch((err) => {
      throw err;
    });
};

export const EmptyTrash = () => {
  return service
    .post(ApiUrl.LandPriceInfo_EmptyTrash)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      throw err;
    });
};

export const LandPriceInfoDetail = (addressId, langId) => {
  return service
    .get(ApiUrl.LandPriceInfo_GetDetail(addressId, langId))
    .then((res) => {
      return res;
    })
    .catch((err) => {
      throw err;
    });
};

export const GetLookUpLandPriceInfo = (langId, keyword, limit) => {
  const params = new URLSearchParams();
  params.append("langId", langId);
  params.append("keyword", keyword);
  params.append("limit", limit);
  return service.get(ApiUrl.GetLookUpLandPriceInfo, params).then(res => { return res }).catch(err => { throw err });
}