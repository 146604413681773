import Service from '../../../api/api-service';
import { ApiUrl } from '../../../api/api-url';
import * as config from '../../../common/config';

const service = new Service();

export const GetListAll = (
    sortExpression = 'modifiedDate desc',
    pageIndex = 1,
    pageSize = config.Configs.DefaultPageSize,
) => {
    const params = new URLSearchParams();

    params.append('Sorting', sortExpression);
    params.append('pageIndex', pageIndex);
    params.append('pageSize', pageSize);

    return service
        .get(ApiUrl.Edition_GetlistAll, params)
        .then((res) => {
            return res;
        })
        .catch((err) => {
            throw err;
        });
};

export const EditionCreate = (body) => {
    return service
        .post(ApiUrl.Edition_Create, body)
        .then((res) => {
            return res;
        })
        .catch((err) => {
            throw err;
        });
};

export const EditionUpdate = (body) => {
    return service
        .post(ApiUrl.Edition_Update, body)
        .then((res) => {
            return res;
        })
        .catch((err) => {
            throw err;
        });
};

export const EditionDelete = (id) => {
    return service
        .post(ApiUrl.Edition_Delete, {id: id})
        .then((res) => {
            return res;
        })
        .catch((err) => {
            throw err;
        });
};

export const EditionGetDetail = (id) => {
    return service
        .get(ApiUrl.Edition_GetDetail(id))
        .then((res) => {
            return res;
        })
        .catch((err) => {
            throw err;
        });
};