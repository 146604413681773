const env = process.env.NODE_ENV || 'development';

const apiEnvironment = {
  development: {
    api: 'https://api.datvangvietnam.net/',
    // api: 'https://api-test.datvangvietnam.net/',
    domainAdminSide: 'http://localhost:3000',
    domainUserSide: 'https://dev.datvangvietnam.net',
    // domainUserSide: 'https://datvangvietnam.net',
    domainName: '',
  },
  production: {
    api: 'https://api.datvangvietnam.net/',
    domainAdminSide: 'https://admin.datvangvietnam.net',
    domainUserSide: 'https://datvangvietnam.net',
    domainName: 'admin.datvangvietnam.net',
    // api: 'https://api-test.datvangvietnam.net/',
    // domainName: 'admin-dev.datvangvietnam.net',
    // domainAdminSide: 'https://admin-dev.datvangvietnam.net',
    // domainUserSide: 'https://dev.datvangvietnam.net',
  },
};

module.exports = apiEnvironment[env];
