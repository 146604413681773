import React, {useEffect, useMemo} from 'react';
import {useFormContext} from 'react-hook-form';
import SunEditor from 'suneditor-react';
import {TextField} from '@material-ui/core';
import {APIUrlDefault, MaxSizeImageUpload} from '../../../utils/configuration';
import {ApiUrl} from '../../../api/api-url';
import 'suneditor/dist/css/suneditor.min.css';

const ProjectContent = ({
  onChangeContent,
  currentProject,
  name = 'projectContent',
  contentValue
}) => {
  const {register, errors, clearErrors} = useFormContext();

  const content = useMemo(() => {
    if (currentProject && currentProject.translations) {
      const {description} = currentProject.translations[0];
      return description;
    }

    return '';
  }, []);

  useEffect(() => {
    if (contentValue) {
      clearErrors(name);
    }
  }, [contentValue, clearErrors]);

  return (
    <div className="form-group">
      <label className="text-dark">
       Giới thiệu dự án<span className="required"></span>
      </label>
      <SunEditor
        enableToolbar={true}
        showToolbar={true}
        setContents={content}
        setOptions={{
          imageAccept: '.jpg,.jpeg,.png,.gift,.svg,.tif',
          imageUploadSizeLimit: {MaxSizeImageUpload},
          imageUploadUrl: `${APIUrlDefault}${ApiUrl.UrlUploadFromEditor}`,
          height: 320,
          buttonList: [
            [
              'undo',
              'redo',
              'font',
              'fontSize',
              'formatBlock',
              'paragraphStyle',
              'blockquote',
              'bold',
              'underline',
              'italic',
              'strike',
              'subscript',
              'superscript',
              'fontColor',
              'hiliteColor',
              'textStyle',
              'removeFormat',
              'outdent',
              'indent',
              'align',
              'horizontalRule',
              'list',
              'lineHeight',
              'table',
              'link',
              'image',
              'video',
              'audio',
              'fullScreen',
              'showBlocks',
              'codeView'
            ]
          ]
        }}
        onChange={onChangeContent}
        onBlur={(event, editorContents) => onChangeContent(editorContents)}
      />

      <TextField
        inputRef={register({required: true})}
        type="hidden"
        name="imageId"
        name={name}
        value={contentValue || ''}
      />
      {errors[name] && errors[name].type === 'required' && (
        <p className="error">Trường này là bắt buộc</p>
      )}
    </div>
  );
};

export default ProjectContent;
