import React, { useEffect } from "react";
import { connect } from "react-redux";
import { Link, useLocation } from "react-router-dom";
import { onChangeTitlePage } from "./../../../core/app.store";
function LinkAdministratorItems(props) {
  const IconReactNode = props?.icon;
  const currentLocation = useLocation();
  const isActive = currentLocation.pathname === props.pageLink;
  const { onChangeTitlePage } = props;
  useEffect(() => {
    if(currentLocation.pathname === props.pageLink) {
      props.setToggled(true)
    }
  }, [currentLocation.pathname]);
  return (
    <Link
      className={`collapse-item ${
        isActive && "sub-link-adminstrator-active"
      } text-wrap`}
      to={props.pageLink}
      onClick={() => onChangeTitlePage(props.title)}
    >
      {props?.iconShow ? (
        <>
          <div className="timeline-date"></div>
          <span className="timeline-date-title">{props.title}</span>
        </>
      ) : (
        <>
          <IconReactNode fontSize="small" className="mr-2 mt-n1" />
          <span>{props.title}</span>
        </>
      )}
    </Link>
  );
}
const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    onChangeTitlePage: (payload) => {
      dispatch(onChangeTitlePage(payload));
    },
  };
};
export default connect(null, mapDispatchToProps)(LinkAdministratorItems);
