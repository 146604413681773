import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Configs } from "../../common/config";
import { useForm } from "react-hook-form";
import dateformat from 'dateformat';

//--- Material Control
import {
  Button,
  TextField,
  IconButton,
  Tooltip,
  Popover,
  Select,
  MenuItem,
  Fab,
} from "@material-ui/core";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";
import ButtonGroup from "@material-ui/core/ButtonGroup";
import DateFnsUtils from "@date-io/date-fns";
import { MuiPickersUtilsProvider, KeyboardDatePicker } from "@material-ui/pickers";

//--- Styles
import "./classified.scss";

//--- Material Icon
import DeleteCircle from "@material-ui/icons/DeleteForever";
import AddCircle from "@material-ui/icons/AddCircle";
import CloseIcon from "@material-ui/icons/Close";
//import FilterListIcon from '@material-ui/icons/FilterList';
import SearchIcon from "@material-ui/icons/Search";
import ClearAllIcon from "@material-ui/icons/ClearAll";
import RefreshIcon from "@material-ui/icons/Refresh";

//--- Component
import ListClassified from "./list-classified/list-classified.view";
import DeleteDialog from "../../components/dialog-delete/dialog-delete.view";
import ConfirmDialog from "../../components/confirm-modal/confirm-modal";
import AddClassified from "./add-classified/add-classified.view";
import EditClassified from "./edit-classified/edit-classified.view";
import * as MasterActions from "../../redux/store/master-data/master-data.store";

//--- Notifications
import ShowNotification from "../../components/react-notifications/react-notifications";
import { NotificationMessageType } from "../../utils/configuration";
import * as viVN from "../../language/vi-VN.json";

//--- Redux store
import * as faqActions from "../../redux/store/faq/faq.store";
import * as classifiedActions from "../../redux/store/classified/classified.store";
import * as invoiceAction from "../../redux/store/transaction/transaction.store";
import * as securityMatrixAction from "../../redux/store/security-matrix/security-matrix.store";
import { makeStyles } from "@material-ui/core/styles";
import { useHistory } from "react-router-dom";
import { UrlCollection } from "./../../common/url-collection";
import * as appActions from "../../core/app.store";
// const useStyles = makeStyles((theme) => ({
//   formControl: {
//     margin: theme.spacing(1),
//     minWidth: 120,
//   },
//   selectEmpty: {
//     marginTop: theme.spacing(2),
//   },
// }));

export default function Faq(props) {
  //  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();
  const [newsModels, setNewsModels] = useState();
  const { pathname } = props.location;
  const { langId } = useSelector((state) => state.language);
  const [totalItemCount, setTotalItemCount] = useState();
  const [searchData, setSearchData] = useState({
    //  categoryId: 1,
    keyword: "",
    user: "",
    PaymentStatusId: 0,
    // description: '',
    status: 2,
  });
  const [classId, setClassId] = useState();
  const [newsxmin, setNewsxmin] = useState();
  const [order, setOrder] = useState("desc");
  const [orderBy, setOrderBy] = useState("createdDate");
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(Configs.DefaultPageSize);

  const [isDelete, setIsDelete] = useState(false);
  const [isShowTrash, setIsShowTrash] = useState(false);
  const [paymentStatusId, setPaymentStatusId] = useState();
  const [paymentStatusModel, setPaymentStatusModel] = useState();
  const [invoiceStatusId, setInvoiceStatusId] = useState();
  const [invoiceStatusModel, setInvoiceStatusModel] = useState();

  const { register, handleSubmit } = useForm({
    mode: "all",
    reValidateMode: "onBlur",
  });

  useEffect(() => {
    const queryParams = new URLSearchParams(window.location.search);
    let _langId = langId;
    let _page = page;
    let _rowsPerPage = rowsPerPage;
    let _order = order;
    let _orderBy = orderBy;
    let _isDelete = isDelete;
    let _keyword = undefined;
    let _user = undefined;
    let _isActive = undefined;
    if (queryParams.get("langId")) {
      _langId = queryParams.get("langId");
    }
    if (queryParams.get("pageIndex")) {
      _page = queryParams.get("pageIndex");
      setPage(Number(_page));
    }
    if (queryParams.get("pageSize")) {
      _rowsPerPage = queryParams.get("pageSize");
      setRowsPerPage(Number(_rowsPerPage));
    }
    if (queryParams.get("keyword")) {
      _keyword = queryParams.get("keyword");
      setSearchData({ ...searchData, keyword: _keyword });
    }
    if (queryParams.get("user")) {
      _user = queryParams.get("user");
      setSearchData({ ...searchData, user: _user });
    }
    if (queryParams.get("_order")) {
      _order = queryParams.get("_order");
      setOrder(_order);
    }
    if (queryParams.get("_orderBy")) {
      _orderBy = queryParams.get("_orderBy");
      setOrder(_orderBy);
    }
    if (queryParams.get("isDelete")) {
      _isDelete = queryParams.get("isDelete");
      setIsDelete(_isDelete);
    }
    if (queryParams.get("status")) {
      _isActive = queryParams.get("status") == 1 ? true : false;
      setSearchData({ ...searchData, status: queryParams.get("status") });
    }
    GetListAll(
      _keyword,
      _langId,
      _orderBy + " " + _order,
      Number(_page) + 1,
      Number(_rowsPerPage),
      _isDelete,
      _isActive,
      undefined,
      undefined,
      _user
    );
    MasterActions.GetMasterDataFromLangId({ langId })
      .then((res) => {
        if (res.realEstateTypeCache) {
          setOptionRealEstate(res.realEstateTypeCache);
        }
      })
      .catch((err) => console.log(err));
    checkUpdatePermission();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]);

  useEffect(()=>{
    GetLookupPaymentStatus();
  },[])

  const GetLookupPaymentStatus = () => {
    dispatch(appActions.ShowLoading(true))
    invoiceAction.GetLookupPaymentStatus().then(res => {
      if (res && res.content) {
        setPaymentStatusModel(res.content)
        dispatch(appActions.ShowLoading(false))
      }
    }).catch((err) => {
      dispatch(appActions.ShowLoading(false))
      console.log(err)
    });
  }

  const GetLookupInvoiceStatus = () => {
    dispatch(appActions.ShowLoading(true))
    invoiceAction.GetLookupInvoiceStatus().then(res => {
      if (res && res.content) {
        setInvoiceStatusModel(res.content)
        dispatch(appActions.ShowLoading(false))
      }
    }).catch((err) => {
      dispatch(appActions.ShowLoading(false))
      console.log(err)
    });
  }

  const GetListAll = (
    keyword,
    langId,
    sortExpression,
    pageIndex,
    pageSize,
    isDelete,
    paymentStatusId,
    isHot,
    isHomePage,
    publishDate
  ) => {
    dispatch(appActions.ShowLoading(true))
    setPage(pageIndex - 1);
    let sortExprs = "";
    if (sortExpression) sortExprs = sortExpression;
    else sortExprs = orderBy + " " + order;
    invoiceAction
      .GetListAll(
        keyword ? keyword : searchData.keyword,
        langId,
        sortExprs,
        pageIndex,
        pageSize,
        isDelete,
        paymentStatusId,
        isHot,
        isHomePage,
        publishDate
      )
      .then((res) => {
        if (res && res.content) {
          dispatch(appActions.ShowLoading(false))
          setNewsModels(res.content.items);
          setTotalItemCount(res.content.totalItemCount);
        }
      })
      .catch((err) => {
        dispatch(appActions.ShowLoading(false))
        console.log(err)
      });
  };

  //--- Dialog
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
  const [question, setQuestion] = useState("");
  const [type, setType] = useState(0);
  const [status, setStatus] = useState();
  const [optionRealEstate, setOptionRealEstate] = useState([]);
  const [updatePermission, setUpdatePermission] = useState(false);
  const [publishDate, setPublishDate] = useState(null)
  const handleOpenAddDialog = () => {
    const param = getParamSearch();
    history.push(UrlCollection.AddClassified + param);
  };

  const handleOpenEditDialog = (classObj) => {
    setClassId(classId);
    const param = getParamSearch();
    history.push(UrlCollection.EditClassified + classObj.id + param);
  };

  const getParamSearch = () => {
    let param = `?langId=${langId}`;
    if (page) {
      param = param + `&pageIndex=${page}`;
    }
    if (rowsPerPage) {
      param = param + `&pageSize=${rowsPerPage}`;
    }
    if (searchData?.keyword) {
      param = param + `&keyword=${searchData.keyword}`;
    }
    if (searchData?.status) {
      param = param + `&status=${searchData.status}`;
    }
    if (searchData?.user) {
      param = param + `&user=${searchData.user}`;
    }
    param = param + `&orderBy=${orderBy}`;
    param = param + `&order=${order}`;
    param = param + `&isDelete=${isDelete}`;
    return param;
  };

  const handleOpenDeleteDialog = (classId, newsxmin) => {
    setClassId(classId);
    setNewsxmin(newsxmin);
    setOpenDeleteDialog(true);
  };

  const handleOpenConfirmDialog = (classId, type, status) => {
    setClassId(classId);
    setType(type);
    setStatus(status);
    switch (type) {
      case 1:
        setQuestion("Bạn có chắc chắn muốn ngưng kích hoạt?");
        break;
      case 2:
        setQuestion("Bạn có chắc chắn muốn kích hoạt?");
        break;
      case 3:
        setQuestion("Bạn có chắc chắn muốn ngừng hiện trên web?");
        break;
      case 4:
        setQuestion("Bạn có chắc chắn muốn hiện web?");
        break;
      case 5:
        setQuestion("Bạn có chắc chắn muốn bật trạng thái chưa bán?");
        break;
      case 6:
        setQuestion("Bạn có chắc chắn muốn xác nhận đã bán?");
        break;
    }

    setOpenConfirmDialog(true);
  };

  const checkUpdatePermission = () => {
    securityMatrixAction
      .CheckUserPermission("UPDATE", "CLASSFIED")
      .then((res) => {
        if (res && res.content) {
          setUpdatePermission(res.content.status);
          console.log("update permission", res.content.status);
        }
      })
      .catch((err) => { });
  };

  const handleChangeStatus = () => {
    switch (type) {
      case 1:
        classifiedActions.ClassifiedDeActive(classId).then(
          (res) => {
            if (res && res.content && res.content.status) {
              GetListAll(
                searchData.keyword,
                langId,
                orderBy + " " + order,
                page + 1,
                rowsPerPage,
                isDelete,
                undefined,
                undefined,
                undefined,
                searchData.user
              );
              handleCloseDeleteDialog();
              ShowNotification(
                viVN.Success.ClassifiedDeActiveSuccess,
                NotificationMessageType.Success
              );
            }
          },
          (err) => {
            err &&
              err.errorType &&
              ShowNotification(
                viVN.Errors[err.errorType],
                NotificationMessageType.Error
              );
          }
        );
        break;
      case 2:
        classifiedActions.ClassifiedActive(classId).then(
          (res) => {
            if (res && res.content && res.content.status) {
              GetListAll(
                searchData.keyword,
                langId,
                orderBy + " " + order,
                page + 1,
                rowsPerPage,
                isDelete,
                undefined,
                undefined,
                undefined,
                searchData.user
              );
              handleCloseDeleteDialog();
              ShowNotification(
                viVN.Success.ClassifiedActiveSuccess,
                NotificationMessageType.Success
              );
            }
          },
          (err) => {
            err &&
              err.errorType &&
              ShowNotification(
                viVN.Errors[err.errorType],
                NotificationMessageType.Error
              );
          }
        );
        break;
      case 3:
        classifiedActions.ClassifiedHideOnWeb(classId).then(
          (res) => {
            if (res && res.content && res.content.status) {
              GetListAll(
                searchData.keyword,
                langId,
                orderBy + " " + order,
                page + 1,
                rowsPerPage,
                isDelete,
                undefined,
                undefined,
                undefined,
                searchData.user
              );
              handleCloseDeleteDialog();
              ShowNotification(
                viVN.Success.ClassifiedActiveSuccess,
                NotificationMessageType.Success
              );
            }
          },
          (err) => {
            err &&
              err.errorType &&
              ShowNotification(
                viVN.Errors[err.errorType],
                NotificationMessageType.Error
              );
          }
        );
        break;
      case 4:
        classifiedActions.ClassifiedShowOnWeb(classId).then(
          (res) => {
            if (res && res.content && res.content.status) {
              GetListAll(
                searchData.keyword,
                langId,
                orderBy + " " + order,
                page + 1,
                rowsPerPage,
                isDelete,
                undefined,
                undefined,
                undefined,
                searchData.user
              );
              handleCloseDeleteDialog();
              ShowNotification(
                viVN.Success.ClassifiedActiveSuccess,
                NotificationMessageType.Success
              );
            }
          },
          (err) => {
            err &&
              err.errorType &&
              ShowNotification(
                viVN.Errors[err.errorType],
                NotificationMessageType.Error
              );
          }
        );
        break;
      case 5:
        classifiedActions.ClassifiedSetOnSale(classId).then(
          (res) => {
            if (res && res.content && res.content.status) {
              GetListAll(
                searchData.keyword,
                langId,
                orderBy + " " + order,
                page + 1,
                rowsPerPage,
                isDelete,
                undefined,
                undefined,
                undefined,
                searchData.user
              );
              handleCloseDeleteDialog();
              ShowNotification(
                viVN.Success.ClassifiedChangeOnSaleSuccess,
                NotificationMessageType.Success
              );
            }
          },
          (err) => {
            err &&
              err.errorType &&
              ShowNotification(
                viVN.Errors[err.errorType],
                NotificationMessageType.Error
              );
          }
        );
        break;
      case 6:
        classifiedActions.ClassifiedSetSoldOut(classId).then(
          (res) => {
            if (res && res.content && res.content.status) {
              GetListAll(
                searchData.keyword,
                langId,
                orderBy + " " + order,
                page + 1,
                rowsPerPage,
                isDelete,
                undefined,
                undefined,
                undefined,
                searchData.user
              );
              handleCloseDeleteDialog();
              ShowNotification(
                viVN.Success.ClassifiedChangeSoldOutSuccess,
                NotificationMessageType.Success
              );
            }
          },
          (err) => {
            err &&
              err.errorType &&
              ShowNotification(
                viVN.Errors[err.errorType],
                NotificationMessageType.Error
              );
          }
        );
        break;
    }
    handleCloseConfirmDialog();
  };

  const changeRealEstateType = (classifiedId, realEstateTypeId) => {
    classifiedActions
      .ClassifiedChangeRealEstateType(classifiedId, realEstateTypeId)
      .then(
        (res) => {
          if (res && res.content && res.content.status) {
            GetListAll(
              searchData.keyword,
              langId,
              orderBy + " " + order,
              page + 1,
              rowsPerPage,
              isDelete,
              undefined,
              undefined,
              undefined,
              searchData.user
            );
            handleCloseDeleteDialog();
            ShowNotification(
              viVN.Success.ChangeRealEstateTypeClassifiedSuccess,
              NotificationMessageType.Success
            );
          }
        },
        (err) => {
          err &&
            err.errorType &&
            ShowNotification(
              viVN.Errors[err.errorType],
              NotificationMessageType.Error
            );
        }
      );
  };

  const handleInvoice = (data) => {
    console.log('đang update')
  }

  const handleCloseDeleteDialog = () => {
    setOpenDeleteDialog(false);
  };

  const handleCloseConfirmDialog = () => {
    setOpenConfirmDialog(false);
  };

  //--- Filter
  const [filterSection, setFilterSection] = useState(null);

  const handleClickFilter = (event) => {
    setFilterSection(event.currentTarget);
  };

  const handleCloseFilter = () => {
    setFilterSection(null);
  };

  const ShowNotificationError = (messages) => {
    ShowNotification(messages, NotificationMessageType.Error);
  };

  const openFilter = Boolean(filterSection);
  const idFilter = openFilter ? "popoverSlider" : undefined;

  const handleRestore = (id) => {
    // faqActions.RestoreNews(id).then(
    //   (res) => {
    //     if (res && res.content && res.content.status) {
    //       GetListAll(
    //         searchData.categoryId,
    //         searchData.title,
    //         undefined,
    //         undefined,
    //         isDelete,
    //         searchData.status,
    //         1,
    //         rowsPerPage,
    //         orderBy + ' ' + order
    //       );
    //       ShowNotification(
    //         viVN.Success.NewsRestoreSuccess,
    //         NotificationMessageType.Success
    //       );
    //     }
    //   },
    //   (err) => {
    //     err &&
    //       err.errorType &&
    //       ShowNotification(
    //         viVN.Errors[err.errorType],
    //         NotificationMessageType.Error
    //       );
    //   }
    // );
  };
  const handleDelete = () => {
    classifiedActions.ClassifiedDelete(classId).then(
      (res) => {
        if (res && res.content && res.content.status) {
          GetListAll(
            searchData.keyword,
            langId,
            orderBy + " " + order,
            page + 1,
            rowsPerPage,
            isDelete,
            undefined,
            undefined,
            undefined,
            searchData.user
          );
          handleCloseDeleteDialog();
          ShowNotification(
            viVN.Success.ClassifiedDeleteSuccess,
            NotificationMessageType.Success
          );
        }
      },
      (err) => {
        err &&
          err.errorType &&
          ShowNotification(
            viVN.Errors[err.errorType],
            NotificationMessageType.Error
          );
      }
    );
  };

  const handelChangeFeature = (newsId, value) => {
    alert("change " + newsId + " feature to" + value);
  };
  const handleClearAllField = () => {
    refresh();
    setFilterSection(null);
  };

  const onSubmit = async (data) => {
    await GetListAll(
      searchData.keyword,
      langId,
      orderBy + " " + order,
      page + 1,
      rowsPerPage,
      isDelete,
      paymentStatusId,
      undefined,
      undefined,
      dateformat(publishDate, "yyyy-mm-dd")
    );
    handleCloseFilter();
  };

  const refresh = () => {
    setIsDelete(false);
    setIsShowTrash(false);
    setSearchData({
      keyword: "",
      user: "",
    });
    setOrderBy("createdDate");
    setOrder("desc");
    GetListAll(
      undefined,
      langId,
      orderBy + " " + order,
      page + 1,
      rowsPerPage,
      isDelete,
      undefined,
      undefined,
      undefined
    );
  };

  const handleChangeTrash = (event) => {
    setIsShowTrash(event.target.checked);
    setIsDelete(event.target.checked);
    GetListAll(
      undefined,
      langId,
      orderBy + " " + order,
      page + 1,
      rowsPerPage,
      event.target.checked,
      undefined,
      undefined,
      undefined
    );
  };

  const handleChangeSearchForm = (event) => {
    setSearchData({ ...searchData, [event.target.name]: event.target.value });
  };

  const handleEmptyTrash = () => {
    // faqActions.EmptyTrash().then(
    //   (res) => {
    //     if (res && res.content && res.content.status) {
    //       GetListAll(
    //         undefined,
    //         langId,
    //         orderBy + ' ' + order,
    //         page + 1,
    //         rowsPerPage
    //       );
    //       //handleCloseDeleteDialog();
    //       ShowNotification(
    //         viVN.Success.NewsDeleteSuccess,
    //         NotificationMessageType.Success
    //       );
    //     }
    //   },
    //   (err) => {
    //     err &&
    //       err.errorType &&
    //       ShowNotification(
    //         viVN.Errors[err.errorType],
    //         NotificationMessageType.Error
    //       );
    //   }
    // );
  };

  return (
    <div className="slider">
      <div className="d-sm-flex align-items-center justify-content-between mb-3">
        <h1 className="h3 mb-0 text-gray-800">
          Quản lý giao dịch
          <Tooltip title="Làm mới">
            <Fab
              color="primary"
              aria-label="filter"
              size="small"
              onClick={refresh}
              className="ml-2"
            >
              <RefreshIcon />
            </Fab>
          </Tooltip>
          <span>&nbsp;&nbsp;</span>
          <FormControlLabel
            control={
              <Switch
                checked={isShowTrash}
                onChange={handleChangeTrash}
                name="checkedB"
                color="primary"
              />
            }
            label="Thùng rác"
          />

        </h1>
        <ButtonGroup
          variant="contained"
          color="primary"
          aria-label="contained primary button group"
        >
          {isShowTrash && (
            <Button
              variant="contained"
              color="secondary"
              onClick={handleEmptyTrash}
              startIcon={<DeleteCircle />}
            >
              Xóa thùng rác
            </Button>
          )}

        </ButtonGroup>
        {/* <Button
          variant="contained"
          color="primary"
          onClick={handleOpenAddDialog}
          startIcon={<AddCircle />}
        >
          Thêm tin mua bán
        </Button> */}
      </div>
      <div className="d-flex justify-content-between align-items-end">
        <form id="formSearch" onSubmit={handleSubmit(onSubmit)}>
          <div className="d-flex align-items-end">
            <div className="form-group">
              <TextField
                className="w-100"
                name="keyword"
                placeholder="Từ khoá"
                variant="outlined"
                value={searchData.keyword}
                onChange={handleChangeSearchForm}
                inputRef={register}
              />
            </div>
            <div className="form-group form_date ml-3">
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <KeyboardDatePicker
                  id="publishDate"
                  name="publishDate"
                  onChange={(date) => date && setPublishDate(date)}
                  value={publishDate}
                  placeholder='Ngày tạo'
                  // value={'Mon Nov MM YYYY 09:52:25 GMT+0700 (Giờ Đông Dương)'}
                  format="dd/MM/yyyy"
                  fullWidth
                  showTodayButton={true}
                />
              </MuiPickersUtilsProvider>

            </div>
            <div className="form-group ml-2">
              <Select
                name="PaymentStatusId"
                className="w-100 ml-2"
                placeholder="Trạng thái"
                variant="outlined"
                value={searchData.PaymentStatusId}
                onChange={e => setPaymentStatusId(e.target.value)}
              >
                <MenuItem value={0}>Trạng thái giao dịch</MenuItem>
                {paymentStatusModel && paymentStatusModel.map((item) => (
                <MenuItem value={item.id}>{item.name}</MenuItem>
                ))}
              </Select>
            </div>
            <div className="form-group ml-2">
              <Button variant="contained" color="primary" onClick={onSubmit} className="ml-2">
                <SearchIcon fontSize="small" /> Tìm kiếm
              </Button>
              <Button
                variant="contained"
                className="ml-2"
                onClick={handleClearAllField}
              >
                <ClearAllIcon fontSize="small" /> Bỏ lọc
              </Button>
            </div>
          </div>
        </form>
        <div className="form-group">

        </div>
      </div>
      {newsModels && (
        <ListClassified
          updatePermission={updatePermission}
          totalItemCount={totalItemCount}
          newsModels={newsModels}
          GetListAll={GetListAll}
          title={searchData.title}
          categoryId={searchData.categoryId}
          isDelete={isDelete}
          editAction={handleOpenEditDialog}
          deleteAction={handleOpenDeleteDialog}
          changeStatusAction={handleOpenConfirmDialog}
          optionRealEstate={optionRealEstate}
          changeRealEstateType={changeRealEstateType}
          restoreAction={handleRestore}
          changeFeatureAction={handelChangeFeature}
          setOrder={setOrder}
          setOrderBy={setOrderBy}
          setPage={setPage}
          setRowsPerPage={setRowsPerPage}
          order={order}
          orderBy={orderBy}
          page={page}
          rowsPerPage={rowsPerPage}
          langId={langId}
          handleInvoice={handleInvoice}
        />
      )}
      {openDeleteDialog && (
        <DeleteDialog
          isOpen={openDeleteDialog}
          onClose={handleCloseDeleteDialog}
          onSuccess={handleDelete}
          header={"Xác nhận xóa"}
          content={"Bạn có chắc chắn muốn xóa?"}
          langId={langId}
        />
      )}

      {openConfirmDialog && (
        <ConfirmDialog
          open={openConfirmDialog}
          handleClose={handleCloseConfirmDialog}
          handleAccept={handleChangeStatus}
          title={"Xác nhận"}
          content={question}
          langId={langId}
        />
      )}
    </div>
  );
}
