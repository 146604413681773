import React from 'react';
import AsyncSelect from 'react-select/async';
import { Controller, useFormContext } from 'react-hook-form';

import './styles.scss';

const FormAsyncSelect = ({
  promiseOptions,
  title,
  name,
  validate,
  placeholder = 'Chọn...',
  options = [],
  className = 'form-control',
  titleClassName = 'text-dark',
  langId = 'vi',
  InputProps = {
    readOnly: false,
  },
  ...rest
}) => {
  const { control, errors } = useFormContext();

  return (
    <>
      <Controller
        control={control}
        name={name}
        rules={validate}
        placeholder={placeholder}
        render={({ onChange, name, value }) => (
          <>
            <label className={titleClassName}>
              {title}
              {validate?.required ? <span className='required'></span> : null}
            </label>
            <AsyncSelect
              cacheOptions
              defaultOptions={options}
              loadOptions={promiseOptions}
              onChange={onChange}
              value={value}
              isClearable
              placeholder={placeholder}
              closeMenuOnSelect={true}
              {...rest}
            />
            {validate && errors[name] ? (
              <span className='error'>
                {errors[name] && errors[name].message}
              </span>
            ) : null}
          </>
        )}
      />
    </>
  );
};

export default FormAsyncSelect;
