import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import * as configuration from "../../utils/configuration";

//--- Material Table
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";

//--- Material Icon
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";

//--- Material Control
import Tooltip from "@material-ui/core/Tooltip";
import IconButton from "@material-ui/core/IconButton";
import { EnhancedTableHead } from "../../components/table/enhanced-table-head";
import { Pagination } from "../../components/table/pagination";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  paper: {
    width: "100%",
    marginBottom: theme.spacing(2),
  },
  tableContainer: {
    maxHeight: window.outerHeight - 365,
  },
  table: {
    minWidth: 750,
  },
}));

const headCells = [
  { id: "imageId", hideSortIcon: true, label: "Ảnh" },
  { id: "title", hideSortIcon: true, label: "Tiêu đề giới thiệu" },
  { id: "content", hideSortIcon: true, label: "Nội dung giới thiệu" },
  { id: "actions", hideSortIcon: true, label: "" },
];

export const ListMobileAppIntroduceView = (props) => {
  const { datas, requestParams, onChangeRequest, onClickEdit, onClickDelete } =
    props;

  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Paper className={classes.paper}>
        <TableContainer className={classes.tableContainer}>
          <Table className={classes.table} size="small" stickyHeader>
            <EnhancedTableHead
              headCells={headCells}
              onChangeRequest={onChangeRequest}
            />
            <TableBody>
              {datas && !!datas?.length ? (
                datas.map((row, index) => {
                  return (
                    <TableRow hover tabIndex={-1} key={row.id}>
                      <TableCell align="center">
                        <img
                          src={configuration.APIUrlDefault + row.imageFile}
                          onError={(e) =>
                            (e.target.src =
                              process.env.PUBLIC_URL + "/logo.png")
                          }
                          alt={row.title}
                          className="logo"
                        />
                      </TableCell>
                      <TableCell>{row.title || "-"}</TableCell>
                      <TableCell>{row.content || "-"}</TableCell>
                      <TableCell align="right" className="text-nowrap">
                        {!requestParams.isDelete && (
                          <Tooltip title="Sửa">
                            <IconButton
                              aria-label="edit"
                              onClick={() => onClickEdit(row)}
                            >
                              <EditIcon className="text-primary" />
                            </IconButton>
                          </Tooltip>
                        )}
                        {!requestParams.isDelete && (
                          <Tooltip title="Xóa">
                            <IconButton
                              aria-label="delete"
                              onClick={() => onClickDelete(row)}
                            >
                              <DeleteIcon className="text-danger" />
                            </IconButton>
                          </Tooltip>
                        )}
                      </TableCell>
                    </TableRow>
                  );
                })
              ) : (
                <TableRow hover tabIndex={-1}>
                  <TableCell colSpan={8} className="text-center">
                    Không có dữ liệu
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>

        <Pagination
          totalItemCount={datas?.totalItemCount || 0}
          rowsPerPage={requestParams.PageSize}
          page={requestParams.PageIndex}
          onPageChange={(value) => onChangeRequest("PageIndex", value)}
          onRowsPerPageChange={(value) => onChangeRequest("PageSize", value)}
        />
      </Paper>
    </div>
  );
};
