import React from "react";
import {
    Select,
    MenuItem
} from '@material-ui/core';

export default class EditLayerSettingView extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
        is_check: this.props.data.is_check,
        is_tiff_layer: this.props.data.is_tiff_layer,
        layer_kind: this.props.data.layer_kind,
      layerName: this.props.data.name,
      maxZoom: this.props.data.max_zoom,
      minZoom: this.props.data.min_zoom,
      z_index: this.props.data.z_index,
    };
  }
  
  componentWillUnmount() {
    this.props.setData({
      ...this.props.data,
        is_check: this.state.is_check,
        is_tiff_layer: this.state.is_tiff_layer,
        layer_kind:this.state.layer_kind,
      name: this.state.layerName,
      max_zoom: this.state.maxZoom,
      min_zoom: this.state.minZoom,
      z_index: this.state.z_index,
    });
  }

  render() {
    return (
      <div>
        <div className="form-group">
          <label for="layerNameInput">Tên layer</label>
          <input
            type="text"
            className="form-control"
            value={this.state.layerName}
            onChange={(event) => {
              this.setState({ layerName: event.target.value });
            }}
            id="layerNameInput"
            placeholder="Nhập tên layer"
          />
        </div>
        <div className="container-fluid m-0 p-0">
          <div className="row">
            <div className="col-4">
              <div className="form-group">
                <label for="ZindexInput">Z index</label>
                <input
                  type="number"
                  className="form-control"
                  id="ZindexInput"
                  value={this.state.z_index}
                  onChange={(event) => {
                    this.setState({ z_index: event.target.value });
                  }}
                  placeholder="Nhập tên layer"
                />
              </div>
            </div>
            <div className="col-4">
              <div className="form-group">
                <label for="MinzoomInput">Min zoom</label>
                <input
                  type="number"
                  className="form-control"
                  id="MinzoomInput"
                  value={this.state.minZoom}
                  onChange={(event) => {
                    this.setState({ minZoom: event.target.value });
                  }}
                  placeholder="Nhập tên layer"
                />
              </div>
            </div>
            <div className="col-4">
              <div className="form-group">
                <label for="MaxzoomInput">Max zoom</label>
                <input
                  type="number"
                  className="form-control"
                  id="MaxzoomInput"
                  value={this.state.maxZoom}
                  onChange={(event) => {
                    this.setState({ maxZoom: event.target.value });
                  }}
                  placeholder="Nhập tên layer"
                />
              </div>
            </div>
          </div>
            </div>
            <div className="row">
                <div className="form-check col-6">
                    <input
                        className="form-check-input"
                        type="checkbox"
                        checked={this.state.is_check}
                        onClick={() => this.setState({ is_check: !this.state.is_check })}
                        id="defaultViewCheckbox"
                    />
                    <label className="form-check-label" for="defaultViewCheckbox">
                        Hiển thị mặc định
                    </label>
                </div>

                <div className="col-6">
                    <div className="form-group">
                        <label for="layer_kind">Loại lớp dữ liệu: </label>
                        <Select
                            name='layer_kind'
                            id='layer_kind'
                            value={this.state.layer_kind}
                            onChange={(event) => this.setState({ layer_kind: event.target.value })}>
                            <MenuItem value={0}>--Chọn loại -----</MenuItem>
                            <MenuItem value={1}>Lớp bản đồ ảnh</MenuItem>
                            <MenuItem value={2}>Lớp sử dụng đất</MenuItem>
                            <MenuItem value={3}>Lớp chia lô</MenuItem>
                            <MenuItem value={4}>Lớp giao thông</MenuItem>
                            <MenuItem value={10}>Lớp khác</MenuItem>
                        </Select>
                    </div>
                </div>
            </div>
      </div>
    );
  }
}
