import Service from '../../../api/api-service';
import { ApiUrl } from '../../../api/api-url';
import * as config from '../../../common/config';

const service = new Service();

export const GetLookUpStreet = (inputValue) => {
  const params = new URLSearchParams();
  params.append("name", inputValue);
  return service
    .postParams(ApiUrl.StreetGetLookUp, params)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      throw err;
    });
};