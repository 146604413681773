import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

//--- Material Control
import Button from "@material-ui/core/Button";

//--- Material Icon
import AddCircle from "@material-ui/icons/AddCircle";

import ShowNotification from "../../components/react-notifications/react-notifications";
import { NotificationMessageType } from "../../utils/configuration";
import * as viVN from "../../language/vi-VN.json";
import * as notificationTemplateAction from "../../redux/store/notification/notification.store";
import * as config from "../../common/config";
import * as appActions from "../../core/app.store";

//--- Component
import NotificationAddDiaLog from "./add-notification/add-notification.view";
import NotificationEditDiaLog from "./edit-notification/edit-notification.view";
import SearchNotification from "./search-notification/search-notification.view";
import ListNotification from "./list-notification/list-notification.view";
import DiaLogDelete from "../../components/dialog-delete/dialog-delete.view";

function Notifications(props) {
  const { isLoading, showLoading } = props;
  const [isAddDialog, setIsAddDialog] = useState(false);
  const [isDelete, setIsDelete] = useState("");
  const [notificationModels, setNotificationModels] = useState([]);
  const [notificationDel, setNotificationDel] = useState("");
  const [contentModel, setContentModel] = useState("");
  const [notificationEditCode, setEditNotificationCode] = useState("");
  const [dataSearch, setDataSearch] = useState({
    content: "",
    type: "",
    isPushed: "",
    isActive: "",
  });
  const [rowsPerPageCommon, setRowsPerPageCommon] = useState();
  const [page, setPage] = useState(0);
  const [order, setOrder] = useState("desc");
  const [orderBy, setOrderBy] = useState("modifiedDate");

  useEffect(() => {
    getListNotificationModels();
  }, []);

  // --- Add Dialog
  const [openAddDialog, setOpenAddDialog] = useState(false);
  const getListNotificationModels = (
    pageIndex = 1,
    pageSize = 10,
    content = "",
    type = "",
    isPushed = undefined,
    isActive = undefined,
    isDelete = undefined
  ) => {
    showLoading(true);
    setPage(pageIndex - 1);
    notificationTemplateAction
      .GetListNotifications(pageIndex, pageSize, content, type, isPushed, isActive, isDelete)
      .then(
        (res) => {
          if (res && res.content && res.content.items) {
            setNotificationModels(res.content.items);
            setContentModel(res.content);
          }
          showLoading(false);
        },
        (err) => {
          showLoading(false)
          err &&
            err.errorType &&
            ShowNotification(
              viVN.Errors[err.errorType],
              NotificationMessageType.Error
            );
        }
      );
  };
  const onChangeFormSearch = (e) =>{
    const { name, value } = e.target;
    setDataSearch({...dataSearch, [name]: value})
  }
  const handleOpenAddDialog = () => {
    setIsAddDialog(true);
    setOpenAddDialog(true);
  };

  const onHideModal = () => {
    setOpenAddDialog(false);
  };

  //Edit Dialog
  const handleOpenEditDialog = (data) => {
    setIsAddDialog(false);
    setEditNotificationCode(data);
    setOpenAddDialog(true);
  };

  //--- Delete Dialog
  const [openDeleteDialog, setOpenDeleteDialog] = React.useState(false);

  const handleOpenDeleteDialog = (code) => {
    setOpenDeleteDialog(true);
    setNotificationDel(code);
  };

  const handleCloseDeleteDialog = () => {
    setOpenDeleteDialog(false);
  };

  const handleDelete = () => {
    notificationTemplateAction.DeleteNotification(notificationDel).then(
      (res) => {
        if (res && res.content && res.content.status) {
          getListNotificationModels(1, rowsPerPageCommon, dataSearch.content, dataSearch.type, dataSearch.isPushed , dataSearch.isActive, isDelete);
          handleCloseDeleteDialog();
          ShowNotification(
            viVN.Success.DeleteSuccess,
            NotificationMessageType.Success
          );
        }
      },
      (err) => {
        err &&
          err.errorType &&
          ShowNotification(
            viVN.Errors[err.errorType],
            NotificationMessageType.Error
          );
      }
    );
  };
  const onChangeActive = (id) => {
    notificationTemplateAction
    .ActiveNotification(id)
    .then(
      (res) => {
        if (res && res.content) {
          getListNotificationModels(1, rowsPerPageCommon, dataSearch.content, dataSearch.type, dataSearch.isPushed , dataSearch.isActive, isDelete);
        }
        showLoading(false);
      },
      (err) => {
        showLoading(false)
        err &&
          err.errorType &&
          ShowNotification(
            viVN.Errors[err.errorType],
            NotificationMessageType.Error
          );
      }
    );
  }
  const onChangeDeActive = (id) => {
    notificationTemplateAction
    .DeActiveNotification(id)
    .then(
      (res) => {
        if (res && res.content) {
          getListNotificationModels(1, rowsPerPageCommon, dataSearch.content, dataSearch.type, dataSearch.isPushed , dataSearch.isActive, isDelete);
        }
        showLoading(false);
      },
      (err) => {
        showLoading(false)
        err &&
          err.errorType &&
          ShowNotification(
            viVN.Errors[err.errorType],
            NotificationMessageType.Error
          );
      }
    );
  }
  const onChangePushFirebaseNotification = (id) => {
    notificationTemplateAction
    .PushFirebaseNotification(id)
    .then(
      (res) => {
        if (res && res.content) {
          ShowNotification(
            viVN.Success.SendNotiSuccess,
            NotificationMessageType.Success
          );
        }
        showLoading(false);
      },
      (err) => {
        showLoading(false)
        err &&
          err.errorType &&
          ShowNotification(
            viVN.Errors[err.errorType],
            NotificationMessageType.Error
          );
      }
    );
  }
  return (
    <div className="notifications">
        <SearchNotification
          getListNotificationModels={getListNotificationModels}
          dataSearch={dataSearch}
          setDataSearch={setDataSearch}
          rowsPerPageCommon={rowsPerPageCommon}
          setOrderBy={setOrderBy}
          setOrder={setOrder}
          orderBy={orderBy}
          order={order}
          isDelete={isDelete}
          setIsDelete={setIsDelete}
          onChangeFormSearch={onChangeFormSearch}
          handleOpenAddDialog={handleOpenAddDialog}
        ></SearchNotification>

      {/* List Element Notifications */}
      <ListNotification
        notificationModels={notificationModels}
        handleOpenEditDialog={handleOpenEditDialog}
        handleOpenDeleteDialog={handleOpenDeleteDialog}
        getListNotificationModels={getListNotificationModels}
        dataSearch={dataSearch}
        contentModel={contentModel}
        setRowsPerPageCommon={setRowsPerPageCommon}
        page={page}
        setDataSearch={setDataSearch}
        setPage={setPage}
        setOrder={setOrder}
        setOrderBy={setOrderBy}
        order={order}
        orderBy={orderBy}
        onChangeActive={onChangeActive}
        onChangeDeActive={onChangeDeActive}
        onChangePushFirebaseNotification={onChangePushFirebaseNotification}
      />

      {openAddDialog ? (
        isAddDialog ? (
          <NotificationAddDiaLog
            rowsPerPageCommon={rowsPerPageCommon}
            openAddDialog={openAddDialog}
            onHideModal={onHideModal}
            getListNotificationModels={getListNotificationModels}
            setOrder={setOrder}
            setOrderBy={setOrderBy}
          ></NotificationAddDiaLog>
        ) : (
            <NotificationEditDiaLog
              rowsPerPageCommon={rowsPerPageCommon}
              notificationEditCode={notificationEditCode}
              openAddDialog={openAddDialog}
              onHideModal={onHideModal}
              getListNotificationModels={getListNotificationModels}
              setOrder={setOrder}
              setOrderBy={setOrderBy}
            ></NotificationEditDiaLog>
          )
      ) : (
          ""
        )}

      {openDeleteDialog ? (
        <DiaLogDelete
          isOpen={openDeleteDialog}
          rowsPerPageCommon={rowsPerPageCommon}
          onClose={handleCloseDeleteDialog}
          onSuccess={handleDelete}
        />
      ) : (
          ""
        )}
    </div>
  );
}
const mapStateToProps = (state) => ({
  isLoading: state.app.loading,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      showLoading: appActions.ShowLoading,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(Notifications);
