import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Configs } from "../../common/config";
import { useForm } from "react-hook-form";

//--- Material Control
import {
  Button,
  TextField,
  IconButton,
  Tooltip,
  Popover,
  Select,
  MenuItem,
  Checkbox,
  Fab,
} from "@material-ui/core";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";
import ButtonGroup from "@material-ui/core/ButtonGroup";

//--- Styles
import "./state-price-file.scss";

//--- Material Icon
import DeleteCircle from "@material-ui/icons/DeleteForever";
import AddCircle from "@material-ui/icons/AddCircle";
import CloseIcon from "@material-ui/icons/Close";
//import FilterListIcon from '@material-ui/icons/FilterList';
import SearchIcon from "@material-ui/icons/Search";
import ClearAllIcon from "@material-ui/icons/ClearAll";
import RefreshIcon from "@material-ui/icons/Refresh";

//--- Component
import ListStatePriceFile from "./list-state-price-file/list-state-price-file.view";
import AddStatePriceFile from "./add-state-price-file/add-state-price-file.view";
import EditStatePriceFile from "./edit-state-price-file/edit-state-price-file.view";
import ViewStatePriceFile from "./view-state-price-file/view-state-price-file.view";
import DeleteDialog from "../../components/dialog-delete/dialog-delete.view";

//--- Notifications
import ShowNotification from "../../components/react-notifications/react-notifications";
import { NotificationMessageType } from "../../utils/configuration";
import * as viVN from "../../language/vi-VN.json";

//--- Redux store
import * as statePriceFileActions from "../../redux/store/state-price-file-management/state-price-file.store";
import * as appActions from "../../core/app.store";
export default function StatePriceFile(props) {
  //const classes = useStyles();
  const dispatch = useDispatch();
  const [statePriceFileModels, setStatePriceFileModels] = useState();
  const [totalItemCount, setTotalItemCount] = useState();
  const [searchData, setSearchData] = useState({
    keyword: "",
    status: 2,
    districtId: null,
    provinceId: null,
  });
  const [statePriceFileId, setStatePriceFileId] = useState();
  const [order, setOrder] = useState("desc");
  const [orderBy, setOrderBy] = useState("publishDate");
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(Configs.DefaultPageSize);
  const [filePreview, setFilePreview] = useState([]);
  const [isDelete, setIsDelete] = useState(false);
  const [isShowTrash, setIsShowTrash] = useState(false);

  const { register, handleSubmit } = useForm({
    mode: "all",
    reValidateMode: "onBlur",
  });

  useEffect(() => {
    GetListAll(isDelete, orderBy + " " + order, page + 1, rowsPerPage);
  }, []);

  const GetListAll = (del, sortExpression, pageIndex, pageSize) => {
    dispatch(appActions.ShowLoading(true))
    setPage(pageIndex - 1);
    statePriceFileActions
      .GetListAll(
        searchData.keyword,
        searchData.districtId,
        searchData.provinceId,
        searchData.publishFrom,
        searchData.publishTo,
        sortExpression,
        del != undefined && del != null ? del : isDelete,
        pageIndex,
        pageSize
      )
      .then((res) => {
        dispatch(appActions.ShowLoading(false))
        setStatePriceFileModels(res.content.items);
        setTotalItemCount(res.content.totalItemCount);
      })
      .catch((err) => {
        dispatch(appActions.ShowLoading(false))
        console.log(err)
      });
  };

  //--- Dialog
  const [openAddDialog, setOpenAddDialog] = useState(false);
  const [openEditDialog, setOpenEditDialog] = useState(false);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [openViewFileDialog, setOpenViewFileDialog] = useState(false);

  const handleOpenAddDialog = () => {
    setOpenAddDialog(true);
  };

  const handleCloseAddDialog = () => {
    setOpenAddDialog(false);
  };

  const handleOpenEditDialog = (postId) => {
    setStatePriceFileId(postId);
    setOpenEditDialog(true);
  };

  const handleOpenViewFileDialog = (event, id) => {
    const newArr = statePriceFileModels.filter((item) => item.id === id);
    if (newArr && newArr.length > 0) {
      setOpenViewFileDialog(true);
      setFilePreview(newArr[0].path);
    }
  };
  const handleCloseViewFileDialog = (event) => {
    setOpenViewFileDialog(false);
    setFilePreview([]);
  };

  const handleCloseEditDialog = () => {
    setOpenEditDialog(false);
  };

  const handleOpenDeleteDialog = (postId) => {
    setStatePriceFileId(postId);
    setOpenDeleteDialog(true);
  };

  const handleCloseDeleteDialog = () => {
    setOpenDeleteDialog(false);
  };

  //--- Filter
  const [filterSection, setFilterSection] = useState(null);

  const handleClickFilter = (event) => {
    setFilterSection(event.currentTarget);
  };

  const handleCloseFilter = () => {
    setFilterSection(null);
  };

  const ShowNotificationError = (messages) => {
    ShowNotification(messages, NotificationMessageType.Error);
  };


  const handleRestore = (id) => {
    // newsAction.RestoreNews(id).then(
    //   (res) => {
    //     if (res && res.content && res.content.status) {
    //       GetListAll(
    //         searchData.categoryId,
    //         searchData.title,
    //         undefined,
    //         undefined,
    //         isDelete,
    //         searchData.status,
    //         1,
    //         rowsPerPage,
    //         orderBy + ' ' + order
    //       );
    //       ShowNotification(
    //         viVN.Success.NewsRestoreSuccess,
    //         NotificationMessageType.Success
    //       );
    //     }
    //   },
    //   (err) => {
    //     err &&
    //       err.errorType &&
    //       ShowNotification(
    //         viVN.Errors[err.errorType],
    //         NotificationMessageType.Error
    //       );
    //   }
    // );
  };
  const handleDelete = () => {
    statePriceFileActions.Delete(statePriceFileId).then(
      (res) => {
        if (res && res.content && res.content.status) {
          GetListAll(isDelete, orderBy + " " + order, 1, rowsPerPage);
          handleCloseDeleteDialog();
          ShowNotification(
            viVN.Success.NewsDeleteSuccess,
            NotificationMessageType.Success
          );
        }
      },
      (err) => {
        err &&
          err.errorType &&
          ShowNotification(
            viVN.Errors[err.errorType],
            NotificationMessageType.Error
          );
      }
    );
  };

  const handelChangeFeature = (postId, value) => {
    alert("change " + postId + " feature to" + value);
  };
  const handleClearAllField = () => {
    refresh();
    setFilterSection(null);
  };

  const onSubmit = async (data) => {
    await GetListAll(isDelete, orderBy + " " + order, 1, rowsPerPage);
    handleCloseFilter();
  };

  const refresh = () => {
    setSearchData({
      keyword: "",
    });
    setOrderBy("publishDate");
    setOrder("desc");
    GetListAll(isDelete, orderBy + " " + order, 1, rowsPerPage);
  };

  const handleChangeTrash = (event) => {
    setIsShowTrash(event.target.checked);
    setIsDelete(event.target.checked);
    GetListAll(event.target.checked, orderBy + " " + order, 1, rowsPerPage);
  };

  const handleChangeSearchForm = (event) => {
    setSearchData({ ...searchData, [event.target.name]: event.target.value });
  };

  const handleChangeChecked = (event) => {
    event.persist();
    setSearchData({ ...searchData, [event.target.name]: event.target.checked });
  };

  const handleEmptyTrash = () => {
    statePriceFileActions.EmptyTrash().then(
      (res) => {
        if (res && res.content && res.content.status) {
          GetListAll(isDelete, orderBy + " " + order, 1, rowsPerPage);
          //handleCloseDeleteDialog();
          ShowNotification(
            viVN.Success.NewsDeleteSuccess,
            NotificationMessageType.Success
          );
        }
      },
      (err) => {
        err &&
          err.errorType &&
          ShowNotification(
            viVN.Errors[err.errorType],
            NotificationMessageType.Error
          );
      }
    );
  };

  return (
    <div className="slider">
      <div className="d-sm-flex align-items-center justify-content-between mb-3">
        <h1 className="h3 mb-0 text-gray-800">
          File giá đất nhà nước
          <Tooltip title="Làm mới">
            <Fab
              color="primary"
              aria-label="filter"
              size="small"
              onClick={refresh}
              className="ml-2"
            >
              <RefreshIcon />
            </Fab>
          </Tooltip>
          <span>&nbsp;&nbsp;</span>
          <FormControlLabel
            control={
              <Switch
                checked={isShowTrash}
                onChange={handleChangeTrash}
                name="checkedB"
                color="primary"
              />
            }
            label="Thùng rác"
          />
        </h1>
        <ButtonGroup
          variant="contained"
          color="primary"
          aria-label="contained primary button group"
        >
          {isShowTrash && (
            <Button
              variant="contained"
              color="secondary"
              onClick={handleEmptyTrash}
              startIcon={<DeleteCircle />}
            >
              Xóa thùng rác
            </Button>
          )}
        </ButtonGroup>
        <Button
            variant="contained"
            color="primary"
            onClick={handleOpenAddDialog}
            startIcon={<AddCircle />}
          >
            Thêm mới file
          </Button>
      </div>
      <div className="d-flex justify-content-between align-items-end">
        <form id="formSearch" onSubmit={handleSubmit(onSubmit)}>
          <div className="d-flex align-items-end">
            <div className="form-group">
              <TextField
                className="w-100"
                name="keyword"
                variant="outlined"
                placeholder="Từ khoá"
                value={searchData.keyword}
                onChange={handleChangeSearchForm}
                inputRef={register}
              />
            </div>
            <div className="form-group">
              <Select
                name="status"
                className="w-100 ml-2"
                variant="outlined"
                placeholder="Trạng thái"
                value={searchData.status}
                onChange={handleChangeSearchForm}
              >
                <MenuItem value={2}>------------</MenuItem>
                <MenuItem value={1}>Kích hoạt</MenuItem>
                <MenuItem value={0}>Không kích hoạt</MenuItem>
              </Select>
            </div>
            <div className="form-group ml-4">
              <Checkbox
                checked={searchData.isHot}
                onChange={handleChangeChecked}
                name="isHot"
                color="primary"
                inputProps={{ "aria-label": "secondary checkbox" }}
                className="p-0 mt-0 ml-2"
              />
              <label className="text-dark">Tin nóng</label>
            </div>
            <div className="form-group ml-4">
              <Checkbox
                checked={searchData.isHomePage}
                name="isHomePage"
                variant="outlined"
                onChange={handleChangeChecked}
                color="primary"
                inputProps={{ "aria-label": "secondary checkbox" }}
                className="p-0 mt-0 ml-4"
              />
              <label className="text-dark">Hiển thị ở trang chủ</label>
            </div>
            
            <div className="form-group  ml-2">
              <Button variant="contained" color="primary" onClick={onSubmit} className="ml-2">
                <SearchIcon fontSize="small" /> Tìm kiếm
              </Button>
              <Button
                variant="contained"
                className="ml-2"
                onClick={handleClearAllField}
              >
                <ClearAllIcon fontSize="small" /> Bỏ lọc
              </Button>
            </div>
          </div>
        </form>
        <div className="form-group">
         
        </div>
      </div>
      {statePriceFileModels ? (
        <ListStatePriceFile
          totalItemCount={totalItemCount}
          statePriceFileModels={statePriceFileModels}
          GetListAll={GetListAll}
          title={searchData.keyword}
          isDelete={isDelete}
          editAction={handleOpenEditDialog}
          viewFileAction={handleOpenViewFileDialog}
          deleteAction={handleOpenDeleteDialog}
          restoreAction={handleRestore}
          setOrder={setOrder}
          setOrderBy={setOrderBy}
          setPage={setPage}
          setRowsPerPage={setRowsPerPage}
          order={order}
          orderBy={orderBy}
          page={page}
          rowsPerPage={rowsPerPage}
        />
      ) : (
        ""
      )}
      {openAddDialog && (
        <AddStatePriceFile
          isOpen={openAddDialog}
          onClose={handleCloseAddDialog}
          onSuccess={handleCloseAddDialog}
          ShowNotificationError={ShowNotificationError}
          GetListAll={GetListAll}
          rowsPerPage={rowsPerPage}
          setOrder={setOrder}
          setOrderBy={setOrderBy}
        />
      )}

      {openEditDialog && (
        <EditStatePriceFile
          isOpen={openEditDialog}
          onClose={handleCloseEditDialog}
          onSuccess={handleCloseEditDialog}
          statePriceFileId={statePriceFileId}
          ShowNotificationError={ShowNotificationError}
          GetListAll={GetListAll}
          rowsPerPage={rowsPerPage}
          setOrder={setOrder}
          setOrderBy={setOrderBy}
        />
      )}

      {openDeleteDialog && (
        <DeleteDialog
          isOpen={openDeleteDialog}
          onClose={handleCloseDeleteDialog}
          onSuccess={handleDelete}
          header={"Xác nhận xóa"}
          content={"Bạn có chắc chắn muốn xóa?"}
        />
      )}
      {openViewFileDialog && (
        <ViewStatePriceFile
          isOpen={openViewFileDialog}
          onClose={handleCloseViewFileDialog}
          filePreview={filePreview}
        />
      )}
    </div>
  );
}
