import axios from 'axios';

const MAP_4D_API_KEY = 'ac543d442b430dcfbe8ed6d89fe5e0d8';
const MAP_4D_URL = 'https://api.map4d.vn/sdk';

const Urls = {
	autoSuggest: `${MAP_4D_URL}/autosuggest`,
	placeDetail: `${MAP_4D_URL}/place/detail`,
	textSearch: `${MAP_4D_URL}/place/text-search`,
	nearBySearch: `${MAP_4D_URL}/place/nearby-search`,
	viewBoxSearch: `${MAP_4D_URL}/place/viewbox-search`,
}

export const searchAutoSuggest = async (textSearch, limit = 5) => {
	const res = await axios.get(
		`${Urls.autoSuggest}`,
		{
			params: {
				key: MAP_4D_API_KEY,
				text: textSearch
			}
		}
	)
	let data = res.data?.result || [];
	data = data.slice(0, limit - 1);
	return data;
}

export const getPlaceDetail = async (placeId) => {
	const res = await axios.get(
		`${Urls.placeDetail}/${placeId}`,
		{
			params: {
				key: MAP_4D_API_KEY
			}
		}
	)
	console.log({ res })
}