import React, { useEffect, useState } from 'react';
import { connect, useSelector } from 'react-redux';
import { bindActionCreators } from 'redux';

import { getCategoryGetLookUp } from '../../redux/store/category/category.store';

//--- Material Icon

import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';

import { makeStyles } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import MapIcon from '@material-ui/icons/Map';
import HelpIcon from '@material-ui/icons/Help';

import * as clientSettingAction from '../../redux/store/client_setting/client_setting.store';

import ConfigSideBar from './ConfigSideBar';
import SideBarMenu from './sidebar-component.view';
import { withRouter } from 'react-router-dom'
//--- Style
import './sidebar.scss';
import { onChangeTitlePage } from '../../core/app.store';
import { useParams } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
  appBar: {
    backgroundColor: '#00923F',
  },
}));

function Sidebar(props) {
  const { settings, getSettings, onChangeTitlePage } = props;
  let { id, postTypeId } = useParams();
  //const data = useSelector(state => console.log(state))
  useEffect(() => {
    getSettings();
  }, [getSettings]);

  useEffect(() => {
    setClientSetting(settings);
  }, [settings]);

  const [listCategoryPost, setListCategoryPost] = useState([]);
  const [listSideBarMenu, setListSideBarMenu] = useState([...ConfigSideBar]);

  useEffect(() => {
    getCategoryGetLookUp('vi').then((res) => {
      if (res.content) {
        const arrayNews = res.content.map((item) => {
          return {
            path: `/tin-tuc/${item.id}/${item.postTypeId}`,
            icon: AddIcon,
            iconShow: true,
            title: item.name,
            postTypeId: item.postTypeId,
          };
        });
        if(id && postTypeId) {
          const tmp = res.content.find((item) => item.id == id )
          if(tmp) {
            onChangeTitlePage(tmp.name);
          }
        }
        const arrWithOutGuide = arrayNews.filter(
          (item) => item.postTypeId !== 5
        );

        const MenuGuide = arrayNews
          .filter((item) => item.postTypeId === 5)
            .map((item) => ({ ...item, icon: HelpIcon }));

        setListSideBarMenu([...listSideBarMenu, ...MenuGuide]);
        setListCategoryPost(arrWithOutGuide);
      }
    });
  }, []);
  const [clientSetting, setClientSetting] = useState();

  const [isToggleSidebar, setIsToggleSidebar] = useState(false);

  const onToggleSidebar = () => {
    setIsToggleSidebar(!isToggleSidebar);
  };

  return (
    <span>
      {clientSetting && (
        <ul
          style={{ background: clientSetting.color }}
          className={
            'navbar-nav bg-gradient-primary sidebar sidebar-dark accordion' +
            (isToggleSidebar ? ' toggled' : '')
          }
          id='accordionSidebar'>
          <a
            className='sidebar-brand d-flex align-items-center justify-content-center bg-white'
            href='/'>
            <div className='sidebar-brand-icon'>
              <img
                src={process.env.PUBLIC_URL + '/logo.png'}
                alt='Logo'
                width='221px'
              />
            </div>
          </a>

          <hr className='sidebar-divider my-0' />

          {listSideBarMenu.map((item, index) => {
            if (item.id === 6) {
              const newSubSideBar = [...item.subSideBar, ...listCategoryPost];
              return (
                <SideBarMenu
                  title={item.title}
                  path={item.path}
                  icon={item.icon}
                  iconShow={item.iconShow || false}
                  isSub={item.isSub}
                  subSideBar={newSubSideBar}
                  key={index}
                  locate={index}
                  onChangeTitlePage={(title) => onChangeTitlePage(title)}
                />
              );
            }
            return (
              <SideBarMenu
                title={item.title}
                path={item.path}
                icon={item.icon}
                isSub={item.isSub}
                iconShow={item.iconShow || false}
                subSideBar={item.subSideBar}
                key={index}
                locate={index}
                onChangeTitlePage={(title) => onChangeTitlePage(title)}
              />
            );
          })}

          <hr className='sidebar-divider d-none d-md-block' />

          <div className='text-center d-none d-md-inline'>
            <button
              className='rounded-circle border-0'
              id='sidebarToggle'
              onClick={onToggleSidebar}>
              <ChevronLeftIcon
                fontSize='small'
                className={'text-white' + (isToggleSidebar ? ' d-none' : '')}
              />
              <ChevronRightIcon
                fontSize='small'
                className={'text-white' + (!isToggleSidebar ? ' d-none' : '')}
              />
            </button>
          </div>
        </ul>
      )}
    </span>
  );
}

const mapStateToProps = (state) => ({
  settings: state.clientSetting.clientSetting,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      getSettings: clientSettingAction.getSettings,
      onChangeTitlePage,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(React.memo(Sidebar)));
