/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  IconButton,
  makeStyles,
  Select,
  TextareaAutosize,
  MenuItem,
  Checkbox,
} from "@material-ui/core";

import SaveIcon from "@material-ui/icons/Save";
import CloseIcon from "@material-ui/icons/Close";

//--- SunEditor
import SunEditor, { buttonList } from "suneditor-react";
import "suneditor/dist/css/suneditor.min.css";

import * as config from "../../../common/config";
import * as notificationTemplateAction from "../../../redux/store/notification/notification.store";
import ShowNotification from "../../../components/react-notifications/react-notifications";
import { NotificationMessageType } from "../../../utils/configuration";
import * as viVN from "../../../language/vi-VN.json";

const useStyles = makeStyles((theme) => ({
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  formControl: {
    margin: theme.spacing(1),
  },
}));

function EditNotification(props) {
  const [active, setActive] = useState(false);
  const [type, setType] = useState("");
  const classes = useStyles();
  const {
    openAddDialog,
    onHideModal,
    notificationEditCode,
    getListNotificationModels,
    rowsPerPageCommon,
    setOrder,
  } = props;
  const { register, handleSubmit, errors, setError, clearErrors, setValue } = useForm({
    mode: "all",
    reValidateMode: "onBlur",
  });
  const [notificationEditModel, setNotificationEditModel] = useState();

  useEffect(() => {
    getDetailData()
  }, []);
  const getDetailData = async() => {
   await notificationTemplateAction
    .GetDetailNotification(notificationEditCode)
    .then((res) => {
      if(res && res.content) {
        setType(res.content?.type)
        setActive(res.content?.isActive)
        setNotificationEditModel(res.content)
      }
    })
    .catch((rejects) => console.log(rejects));
  }
  const onEditSubmit = (data) => {
    const notificationModel = {
      ...notificationEditModel,
      ...data,
      isActive: active,
      type: type
    }
    notificationTemplateAction.UpdateNotification(notificationModel).then(
      (res) => {
        if (res && res.content) {
          getListNotificationModels(1, rowsPerPageCommon, "", "", "", "", "");
          onHideModal();
          ShowNotification(
            viVN.Success.UpdateSuccess,
            NotificationMessageType.Success
          );
        }
      },
      (err) => {
        err &&
          err.errorType &&
          ShowNotification(
            viVN.Errors[err.errorType],
            NotificationMessageType.Error
          );
      }
    );
  };

  return (
    <div>
      <Dialog
        open={openAddDialog}
        onClose={onHideModal}
        fullWidth={true}
        maxWidth="md"
      >
        <DialogTitle id="alert-dialog-title">
          {"Sửa Thông báo"}
          <IconButton
            aria-label="close"
            className={classes.closeButton}
            onClick={onHideModal}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>

        {notificationEditModel ? (
          <form onSubmit={handleSubmit(onEditSubmit)}>
         <DialogContent className="pt-4 pb-2">
            <div className="form-group">
              <div className="row">
                <div className="col-12 col-md-12 col-lg-12">
                  <label className="text-dark">
                    Nội dung<span className="required"></span>
                  </label>
                  <TextareaAutosize
                    name='content'
                    rowsMin={3}
                    value={notificationEditModel.content}
                    className={'form-control'}
                    ref={register({ required: true })}
                    onChange={(e) =>
                      setValue("type", e.target.value, "")
                    }
                  />
                  {errors.content && errors.content.type === "required" && (
                    <span className="error">Trường này là bắt buộc</span>
                  )}
                </div>
              </div>
            </div>

            <div className="form-group">
              <div className="row">
                <div className="col-12 col-md-6 col-lg-6">
                  <label className="text-dark">Loại</label>
                  <Select
                    name="type"
                    variant="outlined"
                    value={type}
                    className="w-100"
                    onChange={(e) =>
                      setType(e.target.value)
                    }
                  >
                    <MenuItem value=""></MenuItem>
                    <MenuItem value="PLANNING">Quy hoạch </MenuItem>
                    <MenuItem value="PROJECT">Dự án</MenuItem>
                    <MenuItem value="CLASSIFIED">Giá đất</MenuItem>
                  </Select>
                </div>
                <div className="col-12 col-md-6 col-lg-6">
                  <label className="text-dark">
                    Resource Id
                  </label>
                  <TextField
                    name="resourceId"
                    type="text"
                    fullWidth
                    value={notificationEditModel.resourceId}
                    variant="outlined"
                    onChange={(e) =>
                      setValue("resourceId", e.target.value.replace(/[^0-9]/g, ""))
                    }
                  />

                </div>

              </div>
            </div>
            <div className='form-group'>
              <div className='row'>
                <div className='col-12 col-md-4 col-lg-4'>
                  <label className='text-dark'>Kích hoạt</label>
                  <Checkbox
                    checked={active}
                    onChange={(e) => setActive(e.target.checked)}
                    color='primary'
                    inputProps={{ 'aria-label': 'secondary checkbox' }}
                    className='p-0 mt-0 ml-4'
                  />
                </div>
              </div>
            </div>
          </DialogContent>
            <DialogActions>
              <Button
                onClick={onHideModal}
                variant="contained"
                startIcon={<CloseIcon />}
              >
                Hủy
              </Button>
              <Button
                type="submit"
                color="primary"
                variant="contained"
                startIcon={<SaveIcon />}
              >
                {config.NotificationConfig.update}
              </Button>
            </DialogActions>
          </form>
        ) : (
          "Xảy ra lỗi trong hệ thống"
        )}
      </Dialog>
    </div>
  );
}
export default EditNotification;
