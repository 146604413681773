/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

//--- Action
import * as viVN from '../../../language/vi-VN.json';

//--- Material Control
import {
  DialogActions,
  //TextareaAutosize,
  Button,
  TextField,
  DialogContent,
  DialogTitle,
  Dialog,
  makeStyles,
  Typography,
  IconButton,
    Select,
    MenuItem,
  Checkbox,
} from '@material-ui/core';

//--- Material Icon
import CloseIcon from '@material-ui/icons/Close';
import SaveIcon from '@material-ui/icons/Save';

//--- Notifications
//import FileInputComponent from '../../../components/file-input/file-input.view';
import ShowNotification from '../../../components/react-notifications/react-notifications';
import {
  NotificationMessageType,
  APIUrlDefault,
} from '../../../utils/configuration';
import * as configCommon from '../../../common/config';
import * as statePriceFileActions from '../../../redux/store/state-price-file-management/state-price-file.store';
import * as provinceAction from "../../../redux/store/province-management/province.store";
import * as districtAction from "../../../redux/store/district-management/district.store";
import FileInputComponent from '../../../components/file-input/file-input.view';
//--- Styles
import '../state-price-file.scss';

const useStyles = makeStyles((theme) => ({
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
}));

function EditStatePriceFile(props) {
  const classes = useStyles();

  const {
    isOpen,
    onClose,
    onSuccess,
    statePriceFileId,
    setOrder,
    setOrderBy,
    GetListAll,
    rowsPerPage,
  } = props;

    const [statePriceFileModel, setStatePriceFile] = useState();

    const [files, setFiles] = useState();
    const [provinceSelect, setProvinceSelect] = useState([]);
    const [districtSelect, setDistrictSelect] = useState([]);
    const [provinceId, setProvinceId] = useState();
    const [districtId, setDistrictId] = useState();
    const [fileUpload, setFileUpload] = useState([]);

    useEffect(() => {
        GetLookupProvince();
    statePriceFileActions
      .GetDetail(statePriceFileId)
      .then((res) => {
        if (res && res.content) {
            setStatePriceFile(res.content);
            setFiles(res.content.path ? res.content.path : "");
            setProvinceId(res.content.provinceId);
            GetDistrictByProvinceId(res.content.provinceId);
            setDistrictId(res.content.districtId);
            console.log(res.content);
        }
      })
      .catch((err) => console.log(err));
  }, []);

    const GetLookupProvince = () => {
        return new Promise((resolve, reject) => {
            provinceAction.GetLookupProvince().then(
                (res) => {
                    setProvinceSelect(res && res.content ? res.content : []);
                },
                (err) => {
                    reject(err);
                    err &&
                        err.errorType &&
                        ShowNotification(
                            viVN.Errors[err.errorType],
                            NotificationMessageType.Error
                        );
                }
            );
        });
    };

    const GetDistrictByProvinceId = (provinceId) => {
        if (!provinceId) return;
        return districtAction
            .GetDistrictByProvinceId(provinceId)
            .then((res) => {
                setDistrictSelect(res && res.content ? res.content : []);
            })
            .catch((err) => {
            });
    };

    const handleChangeSelectProvince = (event) => {
        setValue("provinceId", event.target.value);
        setProvinceId(event.target.value);
        GetDistrictByProvinceId(event.target.value);
    };

    const handleChangeSelectDistrict = (event) => {
        setValue("districtId", event.target.value);
        setDistrictId(event.target.value);
    };

  const { register, handleSubmit, setError, errors, clearErrors, setValue } =
    useForm({
      mode: 'all',
      reValidateMode: 'onBlur',
    });

  const onSubmit = (data) => {
    let object = {
        ...statePriceFileModel,
        title: data.title,
        districtId: districtId,
    };

      const formData = new FormData();
      formData.append("id", statePriceFileModel.id);
      formData.append("title", data.title);
      formData.append("districtId", districtId);
      formData.append("documentUploadId", statePriceFileModel.documentUploadId);
      if (fileUpload)
          formData.append("statePriceFile", fileUpload);
      
    statePriceFileActions
        .Update(formData)
      .then((res) => {
        if (res) {
          setOrder('desc');
          setOrderBy('modifiedDate');
          GetListAll(
              undefined,
              'modifiedDate desc',
            1,
            rowsPerPage
          );
          onSuccess();
          ShowNotification(
            viVN.Success.EditPostsSuccess,
            NotificationMessageType.Success
          );
        }
      })
      .catch((err) => {
        onSuccess();
        ShowNotification(
          viVN.Errors[err.errorType],
          NotificationMessageType.Error
        );
      });
  };

  return (
    <div>
      <Dialog open={isOpen} onClose={onClose} fullWidth={true} maxWidth='md'>
        <DialogTitle disableTypography className='border-bottom'>
          <Typography variant='h6'>Chỉnh sửa giá đất nhà nước</Typography>
          <IconButton
            aria-label='close'
            className={classes.closeButton}
            onClick={onClose}>
            <CloseIcon />
          </IconButton>
        </DialogTitle>

        <form onSubmit={handleSubmit(onSubmit)} autoComplete='off'>
          {statePriceFileModel && (
            <DialogContent className='pt-4 pb-2'>
                          <div className="row">
                              <div className="col-12 col-md-6 col-lg-6">
                                  <div className='form-group'>
                                      <label className='text-dark'>
                                          Tiêu đề<span className='required'></span>
                                      </label>
                                      <TextField
                                          inputRef={register({ required: true, maxLength: 150 })}
                                          name='title'
                                          error={errors.title && errors.title.type === 'required'}
                                          fullWidth
                                          type='text'
                                          className='form-control'
                                          inputProps={{ maxLength: 150 }}
                                          defaultValue={statePriceFileModel.title}
                                          onChange={(e) => setValue('title', e.target.value)}
                                      />
                                      {errors.title && errors.title.type === 'required' && (
                                          <span className='error'>Trường này là bắt buộc</span>
                                      )}
                                      {errors.title && errors.title.type === 'maxLength' && (
                                          <span className='error'>Trường này không quá 150 ký tự</span>
                                      )}
                                  </div>
                              </div>
                          </div>
                          <div className="row">
                              <div className="col-12 col-md-6 col-lg-6">
                                  <label className="text-dark">
                                      Tỉnh/ thành phố<span className="required"></span>
                                  </label>
                                  <Select
                                      fullWidth
                                      defaultValue={statePriceFileModel.provinceId}
                                      error={
                                          errors.provinceId && errors.provinceId.type === "required"
                                      }
                                      onChange={handleChangeSelectProvince}
                                  >
                                      {provinceSelect && provinceSelect.length > 0 ? (
                                          provinceSelect.map((item, index) => (
                                              <MenuItem key={index} value={item.id}>
                                                  {item.name}
                                              </MenuItem>
                                          ))
                                      ) : (
                                              <MenuItem value="">Không có tỉnh thành nào</MenuItem>
                                          )}
                                  </Select>
                                  <TextField
                                      type="text"
                                      name="provinceId"
                                      className="w-100"
                                      defaultValue={statePriceFileModel.provinceId}
                                      inputRef={register({ required: true })}
                                      hidden
                                  />
                                  {errors.provinceId &&
                                      errors.provinceId.type === "required" && (
                                          <span className="error">Trường này là bắt buộc</span>
                                      )}
                              </div>
                              <div className="col-12 col-md-6 col-lg-6">
                                  <label className="text-dark">
                                      Quận - huyện<span className="required"></span>
                                  </label>
                                  <Select
                                      disabled={provinceId ? false : true}
                                      fullWidth
                                      defaultValue={statePriceFileModel.districtId}
                                      error={
                                          errors.districtId && errors.districtId.type === "required"
                                      }
                                      onChange={handleChangeSelectDistrict}
                                  >
                                      {districtSelect && districtSelect.length > 0 ? (
                                          districtSelect.map((item) => (
                                              <MenuItem value={item.id}>{item.name}</MenuItem>
                                          ))
                                      ) : (
                                              <MenuItem value="">Không có quận huyện nào</MenuItem>
                                          )}
                                  </Select>
                                  <TextField
                                      type="text"
                                      name="districtId"
                                      className="w-100"
                                      defaultValue={statePriceFileModel.districtId}
                                      inputRef={register({ required: true })}
                                      hidden
                                  />
                                  {errors.districtId &&
                                      errors.districtId.type === "required" && (
                                          <span className="error">Trường này là bắt buộc</span>
                                      )}
                              </div>
                          </div>

                            <div className='form-group row'>
                              <div className='col-12'>
                                  <label className='text-dark'>
                                      Tài liệu<span className='required'></span>
                                  </label>
                                  {statePriceFileModel.fileName &&
                                      (
                                      <div>{statePriceFileModel.fileName}</div>
                                      )}
                                  <div>
                                      <TextField
                                          inputRef={register({ required: true })}
                                          type='hidden'
                                          name='filePdf'
                                          value={
                                              (statePriceFileModel.fileName) || ''
                                          }
                                      />
                                      {errors.filePdf && errors.filePdf.type === 'required' && (
                                          <p className='error'>Trường này là bắt buộc</p>
                                      )}
                                  </div>
                              </div>
                          </div>
                          <div className="row">
                              <div className="col-12">
                                  <FileInputComponent
                                      onChangeFiles={setFileUpload}
                                      maxFileSize={configCommon.Configs.fileDocument}
                                      filesLimit={1}
                                      sizePriority={true}
                                      acceptedFiles={["pdf",]}
                                      placeholder='Chọn file giá đất nhà nước'
                                      {...props}
                                  />
                              </div>
                          </div>
            </DialogContent>
          )}

          <DialogActions className='border-top'>
            <Button
              type='button'
              onClick={onClose}
              variant='contained'
              startIcon={<CloseIcon />}>
              Hủy
            </Button>
            <Button
              type='submit'
              color='primary'
              variant='contained'
              startIcon={<SaveIcon />}>
              Lưu
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    </div>
  );
}
const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      //showLoading: appActions.ShowLoading,
    },
    dispatch
  );

export default connect(null, mapDispatchToProps)(EditStatePriceFile);
