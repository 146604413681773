import {useEffect, useMemo} from 'react';

export const useGetDefaultProject = currentProject => {
  return useMemo(() => {
    if (!currentProject) {
      return {
        acreage: '',
        address: null,
        addressId: '',
        apartmentNumber: '',
        buildingDensity: '',
        isActive: true,
        isFeature: false,
        utilities: [],
        phoneNumber: '',
        priceFrom: 0,
        priceTo: 0,
        translations: [],
        investorId: null,
        progressId: null,
        statusId: null,
        provinceId: null,
        startDate: new Date(),
        endDate: new Date()
      };
    }

    const {
      id,
      acreage,
      address,
      addressId,
      addressDetail,
      apartmentNumber,
      buildingDensity,
      isActive,
      isFeature,
      listUtilities,
      phoneNumber,
      priceFrom,
      priceTo,
      translations
    } = currentProject;

    // Date
    let {startDate, endDate} = currentProject;
    startDate = new Date(startDate);
    endDate = new Date(endDate);

    // Image
    let {
      fbImageFile,
      fbImageId,
      imageId,
      imageFile,
      twitterImageFile,
      twitterImageId
    } = currentProject;

    // Select
    let {investor, projectProgress, projectStatus, province, realEstateType} =
      currentProject;
    let investorId = investor
      ? {...investor, value: investor?.id, label: investor?.name}
      : null;
    let progressId = projectProgress
      ? {
          ...projectProgress,
          value: projectProgress?.id,
          label: projectProgress?.name
        }
      : null;
    let statusId = projectStatus
      ? {...projectStatus, value: projectStatus?.id, label: projectStatus?.name}
      : null;
    let provinceId = province
      ? {...province, value: province?.id, label: province?.name}
      : null;
    let realEstateTypeId = realEstateType
      ? {
          ...realEstateType,
          value: realEstateType?.id,
          label: realEstateType?.name
        }
      : null;

    let destructureAddress = {
      label: addressDetail,
      value: addressId
    };

    return {
      id,
      acreage,
      address: destructureAddress,
      addressId,
      apartmentNumber,
      buildingDensity,
      isActive,
      isFeature,
      utilities: listUtilities,
      phoneNumber,
      priceFrom,
      priceTo,
      translations,
      investorId,
      progressId,
      statusId,
      provinceId,
      realEstateTypeId,
      startDate,
      endDate
    };
  }, [currentProject]);
};
